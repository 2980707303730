'use strict';

angular.module('app').enum('UNCONFIRMED_EMAIL_STEPS', ['SEND', 'SENT']).enum('UNCONFIRMED_EMAIL_ACTIONS', ['SEND', 'RESEND']).enum('UNCONFIRMED_EMAIL_ERRORS', ['EXPIRY', 'OTHERS']).controller('waitinglist_UnconfirmedEmailController',
/* @ngInject */function (
// From Reslove
fan, EMAIL_CONFIRMATION_ERROR,
// Injection
$log, FanApiService, AuthApiService, Alert, UNCONFIRMED_EMAIL_ERRORS, UNCONFIRMED_EMAIL_STEPS, AUTH_VALIDATION_ERRORS, UNCONFIRMED_EMAIL_ACTIONS, $modalInstance, StrsNotificationsStack, NOTIFICATIONS_STACK_TYPES, ModalsService, $location, $state, $base64, AppService, ActivateSendCall) {
  var vm = this;
  var actionBtnLabels = {
    SEND: 'waitinglist_unconfirmed-email-modal_send-email-btn',
    RESEND: 'button_text_resend_email'
  };

  vm.ctx = $modalInstance;
  vm.email = fan.email;
  vm.UNCONFIRMED_EMAIL_ERRORS = UNCONFIRMED_EMAIL_ERRORS;
  vm.steps = UNCONFIRMED_EMAIL_STEPS;
  vm.step = UNCONFIRMED_EMAIL_STEPS.SEND;
  vm.prestine = true;

  /* function setCurrentErrorType(errorType) {
    vm.CURRENT_ERROR_TYPE = errorType;
  } */
  // setCurrentErrorType(EMAIL_CONFIRMATION_ERROR);

  function setActionBtnLabel(actionName) {
    vm.actionBtnLabel = actionBtnLabels[actionName];
  }
  setActionBtnLabel(UNCONFIRMED_EMAIL_ACTIONS.SEND);

  vm.send = function (email) {
    var redirect = AppService.generateCurrentEncodedRedirect();
    return FanApiService.sendValidationEmail(email, redirect).then(function () {
      $log.debug('[unconfirmed email] sent confirmation email');
      vm.step = UNCONFIRMED_EMAIL_STEPS.SENT;
      setActionBtnLabel(UNCONFIRMED_EMAIL_ACTIONS.RESEND);
      StrsNotificationsStack.add({
        type: NOTIFICATIONS_STACK_TYPES.GENERAL_SUCCESS,
        data: vm.prestine ? 'email_confirmation_text_email_sent_successfully' : 'waitinglist_unconfirmed-email-modal_email-resent-alert'
      });
      vm.prestine = false;
    }).catch(function () {
      StrsNotificationsStack.runSequence([{
        action: 'remove',
        options: {
          type: NOTIFICATIONS_STACK_TYPES.CONFIRM_EMAIL
        }
      }, {
        action: 'add',
        options: {
          type: NOTIFICATIONS_STACK_TYPES.EMAIL_SENDING_ERROR,
          data: { error: UNCONFIRMED_EMAIL_ERRORS.OTHERS }
        }
      }]);
    });
  };
  /* if (ActivateSendCall) {
    vm.send(vm.email);
  } */
  vm.changeEmail = function () {
    StrsNotificationsStack.remove({
      type: NOTIFICATIONS_STACK_TYPES.CONFIRM_EMAIL
    });
    return ModalsService.changeEmail();
  };

  vm.validate = function (email, code) {
    // Avoid sending empty codes
    if (!angular.isString(code) || code.trim() === '') {
      return;
    }
    $log.debug('[unconfirmed email] validating code %s for email %s', code, email);
    AuthApiService.validateEmail(email, code).then(function () {
      $log.debug('[unconfirmed email] validation code OK');
      vm.ctx.close();
    }, function (err) {
      if (err === AUTH_VALIDATION_ERRORS.WRONG_CODE) {
        $log.debug('[unconfirmed email] validation failed - wrong code');
        Alert.warning('waitinglist_unconfirmed-email-modal_wrong-code' /* @trl*/);
      } else {
        vm.ctx.dismiss();
      }
    });
  };
});