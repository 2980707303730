'use strict';

angular.module('app')
/* eslint-disable no-magic-numbers */
.constant('SEARCH_PAGE_SIZE', 10000)
// eslint-disable-next-line no-magic-numbers
.constant('SEARCH_QUERY_MIN_LENGTH', 2).constant('SEARCH_INPUT_FIELD_ID', 'searchInput')
/* eslint-enable no-magic-numbers */

.config(function ($urlRouterProvider, $compileProvider, markedProvider) {
  // Strip trailing slashes
  // https://stackoverflow.com/questions/24420578/handling-trailing-slashes-in-angularui-router
  // eslint-disable-next-line consistent-return
  $urlRouterProvider.rule(function ($injector, $location) {
    var path = $location.path();
    var hasTrailingSlash = path[path.length - 1] === '/';

    if (hasTrailingSlash) {
      // If last charcter is a slash, return the same url without the slash
      var newPath = path.substr(0, path.length - 1);
      return newPath;
    }
  });

  // #improve in order to mitigate code injection risks, do not Sanitize links globaly.
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|http|ftp|mailto|coui|seaters|intent):/);

  markedProvider.setRenderer({
    link: function link(href, title, text) {
      if (href.indexOf(window.location.hostname) === -1) {
        return (
          // eslint-disable-next-line quotes,max-len
          "<a href='" + href + "'" + (title ? " title='" + title + "'" : '') + " target='_blank'>" + text + '</a>'
        );
      }

      // eslint-disable-next-line quotes
      return "<a href='" + href + "'" + (title ? " title='" + title + "'" : '') + '>' + text + '</a>';
    }
  });
});