'use strict';

angular.module('app').controller('main_dashboard_DashboardController', function () {
  var vm = this;

  vm.navEntries = [{
    // My Waitinglists
    text: 'dashboard_menu_myqueues',
    state: 'app.main.dashboard.my-waitinglists',
    icon: ['strs-icon', 'my_waitinglists']
  }, {
    // My Seats'
    text: 'dashboard_menu_myseats',
    state: 'app.main.dashboard.my-seats',
    icon: ['strs-icon', 'my_seat']
  }];
});