'use strict';

angular.module('app').directive('strsToast',
/* @ngInject */function ($log, $timeout, StrsToastService, TranslationService, $rootScope) {
  return {
    restrict: 'E',
    templateUrl: 'components/strs-toast/strs-toast.html',
    transclude: true,
    replace: false,
    scope: {
      handle: '=strsToastHandle'
    },
    link: function link($scope, element) {
      $scope.app = $rootScope.app;
      if (!$scope.handle) {
        $log.error('[strsToast] strs-toast-handle is mandatory');
      }

      var positions = StrsToastService.getPositions();

      // Private functions
      function updateElements(el, options) {
        if (options.themed) {
          if (options.themeBgColor) {
            el.css({
              'background-color': options.themeBgColor
            });
          }
          if (options.themeFgColor) {
            el.css({
              color: options.themeFgColor
            });
          }
        }

        if (options.sticky) {
          if (options.position === positions.TOP) {
            el.css({
              top: options.positionOffset
            });
          }
          if (options.position === positions.BOTTOM) {
            el.css({
              bottom: options.positionOffset
            });
          }
        }
      }

      // Scope variables
      $scope.shown = false;
      $scope.positions = StrsToastService.getPositions();
      $scope.types = StrsToastService.getTypes();

      // eslint-disable-next-line id-length
      $scope.t = TranslationService.t;

      // Scope functions
      $scope.isClosable = function () {
        return $scope.handle.options.closable;
      };

      $scope.close = function () {
        $scope.handle.hide();
      };

      // Hooks
      $scope.handle.onShowRequested(function (cb) {
        $scope.shown = true;
        $timeout(function () {
          updateElements(angular.element(element[0].getElementsByClassName('strs-toast')), $scope.options);
        });

        if (cb) {
          return cb($scope.shown);
        }
        return $scope.shown;
      });

      $scope.handle.onHideRequested(function (cb) {
        $scope.shown = false;

        if (cb) {
          return cb($scope.shown);
        }
        return $scope.shown;
      });

      $scope.handle.onOptionsUpdated(function (cb) {
        $scope.options = $scope.handle.getOptions();
        updateElements(angular.element(element[0].getElementsByClassName('strs-toast')), $scope.options);

        if (cb) {
          return cb($scope.shown);
        }
        return $scope.shown;
      });

      // Update the options initially
      $scope.handle.updateOptions($scope.handle.getOptions(), function () {
        // Wait untill the template has been rendered
        $timeout(function () {
          // Notify listeners that the directive template has been loaded
          $scope.handle.load(function (cb) {
            if (cb) {
              return cb(true);
            }
            return true;
          });
        });
      });

      // Unlink observers when directive is destroyed
      $scope.$on('$destroy', function () {
        $scope.handle.clearObservers();
      });
    }
  };
});