'use strict';

angular.module('app').constant('FB_PIXELS_ID', { dev: '1387180588029050', prod: '195264240984602' }).constant('FB_PIXELS_EVENTS', ['PageView', 'ViewContent', 'Search', 'AddToCart', 'AddToWishlist', 'InitiateCheckout', 'AddPaymentInfo', 'Purchase', 'Lead', 'CompleteRegistration']).config(
/* @ngInject */function () {
  /**
   * Facebook Pixels Code
   * https://www.facebookmarketingdevelopers.com/pixels/up
   * We are adding it in the config phase rather than a script tag!
   */
  /* eslint-disable */
  !function (f, b, e, v, n, t, s) {
    if (f.fbq) return;

    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };

    if (!f._fbq) f._fbq = n;

    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  }(window, document, 'script', '//connect.facebook.net/en_US/fbevents.js');
  /* eslint-enable */
}).service('FbPixelsService',
/* @ngInject */function ($rootScope, $window, $transitions, FB_PIXELS_ID, FB_PIXELS_EVENTS, lodash, $log, EnvService) {
  var service = {};
  /**
   * Main Pixel Initializer method
   */
  service.initialize = function () {
    service.registerEventTrack('init');
    /**
     * Track user activies per state
     * using "PageView" Pixels Event
     */
    $transitions.onSuccess({}, function () {
      service.registerEventTrack('PageView');
    });
  };
  /**
   * Register Standard / Custom Event
   * @param {string} action
   * @param {object} properties
   */
  service.registerEventTrack = function (action, properties) {
    // Safe checker if Facebook Pixels fails to adds "fbq" to window,
    // Happens in the Config phase
    if (!$window.fbq) {
      return;
    }
    var fbPixelsId = FB_PIXELS_ID[EnvService.name()];
    // Intialization event
    if (action === 'init') {
      $window.fbq('init', fbPixelsId);
      return;
    }
    // Standard or custom event
    var eventType = lodash.includes(FB_PIXELS_EVENTS, action) ? 'track' : 'trackCustom';
    properties = properties || {};
    // $log.log(eventType, ' > ', action, 'with : ', properties);
    $window.fbq(eventType, action, properties);
  };
  return service;
}).run(function (FbPixelsService) {
  FbPixelsService.initialize();
});