'use strict';

angular.module('app').directive('strsNotificationsStack',
/* @ngInject */function (StrsNotificationsStack) {
  return {
    templateUrl: 'components/strs-notifications-stack/strs-notifications-stack.html',
    link: function link($scope, element) {
      /*
      @TODO
      To be used in case the design required 
      fixed header.
      
      function setContainerPadding(paddingTop) {
        var appBody = document.querySelector('.app-body');
        appBody.style.paddingTop = paddingTop ? paddingTop + 'px' : 'inherit';
      }
       $scope.$watch(
        function() {
          return element.parent().outerHeight();
        },
        function(newValue, oldValue) {
          setContainerPadding(newValue);
        },
        true
      );
      **/
    }
  };
});