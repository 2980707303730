'use strict';

angular.module('app').controller('AuthenticateForgotPasswordController', function ($state, $stateParams, lodash, FanGroupService, URLService, SessionService, Alert, AUTH_RESETPASSWORD_ERRORS, ValidationService) {
  /**
   *  CONSTANTS
   */
  var USER_DEFAULTS = {
    email: ''
  };

  /**
   *  PRIVATE VARIABLES
   */
  var queryParameters = URLService.getQueryParameters();

  /**
   *  PRIVATE FUNCTIONS
   */

  /**
   *  VM VARIABLES
   */
  var vm = this;
  vm.user = lodash.extend(USER_DEFAULTS, queryParameters);
  vm.fanGroup = FanGroupService.getPublicFanGroup();
  vm.loading = false;
  vm.emailSent = false;
  vm.tokenExpired = queryParameters.expired !== undefined;
  /**
   *  VM FUNCTIONS
   */
  vm.isSubmitDisabled = function (form) {
    return vm.loading;
  };

  vm.submit = function () {
    vm.loading = true;

    // Attempt login
    SessionService.resetPassword(vm.user.email).then(function () {
      vm.emailSent = true;
    })
    // Handle errors
    .catch(function (error) {
      ValidationService.processValidations(error, vm.forgotPasswordForm);
    })
    // Finish
    .then(function () {
      vm.loading = false;
    });
  };

  /**
   *  SETUP
   */
});