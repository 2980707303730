'use strict';

angular.module('app').constant('ENVIRONMENT_HOSTNAMES', {
    dev: ['localhost', '0.0.0.0', 'www.dev-seaters.com', 'dev-seaters.com', 'www.qa-seaters.com', 'qa-seaters.com', 'www.staging-seaters.com', 'staging-seaters.com'],
    prod: ['www.seaters.com', 'seaters.com']
}).service('EnvService',
/* @ngInject */
function (ENVIRONMENT_HOSTNAMES, $http, lodash, $window) {
    var service = {};

    service.name = function () {
        // console.log(ENVIRONMENT_HOSTNAMES);
        var env = '';
        var currentHostname = location.hostname;
        lodash.forEach(ENVIRONMENT_HOSTNAMES, function (hostNames, environmentName) {
            if (lodash.includes(hostNames, currentHostname)) {
                env = environmentName;
            } else {
                env = 'dev';
            }
        });
        return env;
    };

    var meta = $window.ENV;

    service.apiLocation = meta.apiLocation;

    service.clientVersion = meta.clientVersion;

    service.buildInfo = meta.buildInfo;

    return service;
});