'use strict';

angular.module('app').constant('SEATERS_COOKIES_ACCEPTED', 'SEATERS_COOKIES_ACCEPTED').controller('TopbarController',
/* @ngInject */
function ($scope, StrsSidebarService, StrsIdentity, SessionService, AppService, $window, $timeout, lodash, SEARCH_QUERY_MIN_LENGTH, $state, StrsSearchService, toastr, TranslationService, FanGroupService, $log, $rootScope, SEATERS_COOKIES_ACCEPTED, $location, $anchorScroll) {
    var vm = this;
    var TOGGLE_SEARCH_TIMEOUT = 250;
    vm.loaded = false;
    vm.showMyProfileList = true;
    vm.showMyFanGroupsList = true;
    vm.showHelpList = true;
    vm.searchMode = false;
    vm.searchQuery = '';
    vm.strsSidebarService = StrsSidebarService;
    vm.locale = AppService.getLocale();
    vm.locales = AppService.getLanguages();
    vm.signout = SessionService.signout;
    vm.isAuthenticated = SessionService.isLoggedIn;
    vm.group = FanGroupService.getPublicFanGroup();
    // console.log(vm.group);
    vm.ppUrl = vm.group && vm.group.privacyPolicyUrls ? vm.group.privacyPolicyUrls[vm.locale] : 'www.getseaters.com/privacy';

    if (vm.group.banner) {
        vm.bannerTitle = vm.group.banner.title;
        vm.bannerContent = vm.group.banner.content;
        vm.bannerCtaText = vm.group.banner.callToActionLabel;
        vm.bannerColor = vm.group.banner.color || '#D93A3A';
    }

    vm.showBanner = vm.group.showBanner;

    vm.bannerRedirect = function () {
        if (vm.group.banner.callToActionRedirectUrl) {
            window.open(vm.group.banner.callToActionRedirectUrl.en, '_blank');
            // $state.go('app.main.informations', {});
        } else {
            $state.go('app.informations', {});
        }
    };
    vm.toggleMyProfileList = function (event) {
        event.stopPropagation();
        vm.showMyProfileList = !vm.showMyProfileList;
    };

    vm.toggleMyFanGroupsList = function (event) {
        event.stopPropagation();
        vm.showMyFanGroupsList = !vm.showMyFanGroupsList;
    };

    vm.toggleHelpList = function (event) {
        event.stopPropagation();
        vm.showHelpList = !vm.showHelpList;
    };

    vm.changeLocale = function (locale) {
        return AppService.changeLocale(locale).then(function () {
            vm.locale = locale;
        }).catch(function (err) {
            toastr.error(TranslationService.tk('general.unexpected-problem'));
            $log.error('unable to change locale', err);
        });
    };

    vm.toggleSearchMode = function () {
        vm.searchMode = !vm.searchMode;
        if (vm.searchMode) {
            $timeout(function () {
                var searchInputField = $window.document.getElementById('searchInputField');
                searchInputField.focus();
                $rootScope.app.disableBodyScroll();
            }, TOGGLE_SEARCH_TIMEOUT);
        } else {
            $rootScope.app.enableBodyScroll();
        }
    };

    vm.search = function () {
        if (!StrsSearchService.isValidSearchQuery(vm.searchQuery)) {
            return;
        }

        $state.go('app.main.dashboard.search', {
            query: vm.searchQuery
        }).then(function () {
            vm.searchQuery = '';
            vm.toggleSearchMode();
        });
    };

    vm.resetPassword = function () {
        $state.go('app.main.settings', {
            slug: FanGroupService.getCurrentFanGroupSlug()
        }).then(function () {
            $location.hash('settings-password');
            $anchorScroll();
        });
    };

    vm.identity = StrsIdentity.cachedEntities;

    vm.getSeatersCookiesAccepted = function () {
        return localStorage.getItem(SEATERS_COOKIES_ACCEPTED);
    };

    vm.acceptCookies = function () {
        localStorage.setItem(SEATERS_COOKIES_ACCEPTED, true);
        vm.cookiesAccepted = vm.getSeatersCookiesAccepted();
    };

    vm.cookiesAccepted = vm.getSeatersCookiesAccepted();

    vm.me = undefined;

    function initialize(userIdentity) {
        vm.me = userIdentity.me;
        vm.isRoleHost = vm.me.roles.filter(function (role) {
            return role === 'HOST';
        }).length > 0;
        vm.isRoleApprover = vm.me.roles.filter(function (role) {
            return role === 'APPROVER';
        }).length > 0;
        vm.fanGroup = userIdentity.currentGroup;
        vm.groupSlug = userIdentity.currentGroup.slug;
        vm.myGroups = userIdentity.myGroups;
        vm.myWishListsTotal = userIdentity.myWishLists.filter(function (item) {
            return item.groupId === FanGroupService.getPublicFanGroup().fanGroupId;
        }).length;
        vm.mySeatsTotal = userIdentity.mySeats.filter(function (item) {
            return item.groupId === FanGroupService.getPublicFanGroup().fanGroupId;
        }).length;
        vm.loaded = true;
    }

    function initializeDefault(noUserIdentity) {
        vm.fanGroup = noUserIdentity.publicFanGroup;
        vm.loaded = true;
    }

    vm.showPasswordReset = false;

    function init() {
        return StrsIdentity.get().then(function (identity) {
            if (identity.me) {
                var monthInMs = 2592000000;
                vm.showPasswordReset = new Date(identity.me.passwordExpirationDate) - new Date() <= monthInMs && new Date(identity.me.passwordExpirationDate) - new Date() > 0;
            }
            if (identity.currentGroup) {
                return initialize(identity);
            }
            return initializeDefault(identity);
        });
    }

    init();

    $scope.$watch(function () {
        return vm.isAuthenticated();
    }, function (newAuth, oldAuth) {
        if (newAuth && newAuth !== oldAuth) {
            init();
        }
    });
});

/* eslint-disable */
/**
 * This implementation overwrites the original dropdownToggle from foundation,
 * it asnwers the design requirement so far.
 *
 * TODO: Find more independent and fucture compatable solution.
 *
 */
angular.module('mm.foundation.dropdownToggle', ['mm.foundation.position', 'mm.foundation.mediaQueries']).controller('DropdownToggleController', ['$scope', '$attrs', 'mediaQueries', function ($scope, $attrs, mediaQueries) {
    this.small = function () {
        return mediaQueries.small() && !mediaQueries.medium();
    };
}]).directive('dropdownToggle', ['$document', '$window', '$location', '$position', function ($document, $window, $location, $position) {
    var openElement = null,
        _closeMenu = angular.noop;
    return {
        restrict: 'CA',
        controller: 'DropdownToggleController',
        link: function link(scope, element, attrs, controller) {
            var parent = element.parent(),
                dropdown = angular.element($document[0].querySelector(attrs.dropdownToggle));

            var parentHasDropdown = function parentHasDropdown() {
                return parent.hasClass('has-dropdown');
            };

            var onClick = function onClick(event) {
                dropdown = angular.element($document[0].querySelector(attrs.dropdownToggle));
                var elementWasOpen = element === openElement;

                event.preventDefault();
                event.stopPropagation();

                if (!!openElement) {
                    _closeMenu();
                }

                function setDropdownCss() {
                    var offset = $position.offset(element);
                    var parentOffset = $position.offset(angular.element(dropdown[0].offsetParent));
                    var dropdownWidth = dropdown.prop('offsetWidth');
                    var css = {
                        top: offset.top - parentOffset.top + offset.height + 10 + 'px'
                    };

                    var left = Math.round(offset.left - parentOffset.left - (dropdownWidth - offset.width));
                    var rightThreshold = $window.innerWidth - dropdownWidth - 8;
                    if (left > rightThreshold) {
                        left = rightThreshold;
                        dropdown.removeClass('left').addClass('right');
                    }
                    css.left = left + 'px';
                    css.position = null;
                    css['max-width'] = null;

                    dropdown.css(css);
                }
                if (!elementWasOpen && !element.hasClass('disabled') && !element.prop('disabled')) {
                    dropdown.css('display', 'block'); // We display the element so that offsetParent is populated
                    // dropdown.addClass('f-open-dropdown');

                    setDropdownCss();
                    angular.element($window).bind('resize', setDropdownCss);

                    element.addClass('expanded');

                    if (parentHasDropdown()) {
                        parent.addClass('hover');
                    }

                    openElement = element;

                    _closeMenu = function closeMenu(event) {
                        angular.element($window).unbind('resize', setDropdownCss);
                        $document.off('click', _closeMenu);
                        dropdown.css('display', 'none');
                        dropdown.removeClass('f-open-dropdown');
                        element.removeClass('expanded');
                        _closeMenu = angular.noop;
                        openElement = null;
                        if (parent.hasClass('hover')) {
                            parent.removeClass('hover');
                        }
                    };
                    $document.on('click', _closeMenu);
                }
            };

            if (dropdown) {
                dropdown.css('display', 'none');
            }

            scope.$watch('$location.path', function () {
                _closeMenu();
            });

            element.on('click', onClick);
            element.on('$destroy', function () {
                element.off('click', onClick);
            });
        }
    };
}]);