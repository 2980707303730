'use strict';

angular.module('app').factory('SessionChecker',
/* @ngInject */function ($rootScope, $q, DateService, locker, LOCKER_NS_SESSION, LOCKER_SESSION_CURRENT, SESSION_EVENTS) {
  var service = {};
  service.request = function (config) {
    var session = locker.driver('local').namespace(LOCKER_NS_SESSION).get(LOCKER_SESSION_CURRENT);

    var validSession = angular.isObject(session) && DateService.utcMoment(session.expiresOn).diff(DateService.utcMoment()) > 0;

    if (session && !validSession) {
      $rootScope.$emit(SESSION_EVENTS.SESSION_EXPIRED);
      $q.reject(SESSION_EVENTS.SESSION_EXPIRED);
    }

    return config;
  };
  return service;
}).config(function ($httpProvider) {
  $httpProvider.interceptors.push('SessionChecker');
});