'use strict';

(function () {
  var old = angular.module;
  angular.module = function () {
    var mod = old.apply(null, arguments);
    mod.enum = function (name, values) {
      var mapping = angular.copy(values);
      values.forEach(function (value) {
        mapping[value] = value;
      });
      return mod.constant(name, mapping);
    };
    return mod;
  };
})();