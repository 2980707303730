'use strict';

angular.module('app').controller('LoginSAMLController',
/* @ngInject */function ($scope, $stateParams, SessionService, $location, FanApiService, AuthApiService, AppService, FanGroupService, lodash, $base64) {

  var vm = this;
  var relayState = { fangroupSlug: $stateParams.slug, destinationPath: $base64.decode($stateParams.destinationPath) };

  AuthApiService.getSAMLConfig($stateParams.provider).then(function (config) {
    $scope.encodedRequest = config.encodedRequest;
    vm.targetUrl = config.targetUrl;
    vm.bindingType = config.bindingType;
    document.getElementById('SAMLRequest').value = $scope.encodedRequest;
    document.getElementById('RelayState').value = $base64.encode(JSON.stringify(relayState));
    document.getElementById('TheForm').action = config.targetUrl;
    document.getElementById('TheForm').submit();
  });
});