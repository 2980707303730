'use strict';

angular.module('app').directive('strsLanguageSwitcher', function ($rootScope) {
  return {
    templateUrl: 'components/strs-language-switcher/strs-language-switcher.html',
    replace: true,
    restrict: 'E',
    scope: {
      switchLangPromise: '=',
      locales: '=',
      lang: '='
    },
    link: function link( /* @ngInject */scope) {
      scope.opened = false;
      scope.loading = false;

      scope.switchLang = function (lang) {
        scope.loading = true;
        scope.opened = false;

        scope.switchLangPromise(lang).then(function () {
          scope.loading = false;
        }).catch(function () {
          scope.loading = false;
        });
      };

      scope.toggleList = function () {
        scope.opened = !scope.opened;
      };

      scope.hideList = function () {
        scope.opened = false;
      };
      scope.app = $rootScope.app;
    }
  };
});