'use strict';

angular.module('app').directive('strsRadioAlike', function ( /* @ngInject */$rootScope) {
  return {
    templateUrl: 'components/strs-radio-alike/strs-radio-alike.html',
    replace: true,
    scope: {
      selected: '=',
      label: '@',
      onSelect: '&',
      disabled: '=ngDisabled'
    },
    link: function link($scope) {
      $scope.app = $rootScope.app;
      $scope.executeOnSelect = function () {
        if ($scope.disabled) return;
        $scope.onSelect();
      };
    }
  };
});