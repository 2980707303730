'use strict';

angular.module('app').filter('formatInTz', function ($rootScope) {
  return function (datetime, format) {
    if (!angular.isString(datetime)) {
      return '/';
    }
    return $rootScope.formatInTz(datetime, format);
  };
}).filter('formatDateInTz', function ($rootScope) {
  return function (datetime) {
    if (!angular.isString(datetime)) {
      return '/';
    }
    return $rootScope.formatInTz(datetime, $rootScope.APP.localeFormats.date);
  };
}).filter('formatTimeInTz', function ($rootScope) {
  return function (datetime) {
    if (!angular.isString(datetime)) {
      return '/';
    }
    return $rootScope.formatInTz(datetime, $rootScope.APP.localeFormats.time);
  };
}).filter('formatShortDateInTz', function ($rootScope) {
  return function (datetime) {
    if (!angular.isString(datetime)) {
      return '/';
    }
    return $rootScope.formatInTz(datetime, $rootScope.APP.localeFormats.shortDate);
  };
}).filter('formatDayInTz', function ($rootScope) {
  return function (datetime) {
    if (!angular.isString(datetime)) {
      return '/';
    }
    return $rootScope.formatInTz(datetime, $rootScope.APP.localeFormats.day);
  };
}).filter('formatMonthInTz', function ($rootScope) {
  return function (datetime) {
    if (!angular.isString(datetime)) {
      return '/';
    }
    return $rootScope.formatInTz(datetime, $rootScope.APP.localeFormats.month);
  };
});