/* eslint-disable max-len */
'use strict';

angular.module('app').controller('MyWaitinglistsController', function (FanApiService, group, TranslationService, FanGroupService, Alert, $log, myWishLists) {
  var vm = this;

  vm.loading = false;
  vm.pageSize = 10;
  vm.offset = 0;
  vm.group = group;

  vm.loadMoreWaitinglists = function () {
    if (vm.loading || vm.totalSize === 0 || vm.totalSize && vm.offset >= vm.totalSize) {
      // Don't do anything if we're already loading more
      return;
    }
    vm.loading = true;
    FanApiService.joinedWaitinglists(vm.pageSize, vm.offset).then(function (paged) {
      var itemsFiltered = paged.items.filter(function (item) {
        return item.groupId === FanGroupService.getPublicFanGroup().fanGroupId;
      });
      var nrOfItems = itemsFiltered.length;
      $log.debug('dashboard-wls - fetched accessible groups %s => %s', vm.offset, vm.offset + nrOfItems);
      vm.totalSize = paged.totalSize;
      vm.offset += nrOfItems;
      return itemsFiltered;
    }).then(function (items) {

      vm.waitinglists = vm.waitinglists ? vm.waitinglists.concat(items) : items;
      vm.loading = false;
    }).catch(function () {
      Alert.handleServerProblem('dashboard-wls - unable to fetch fan waitinglists');
      vm.loading = false;
    });
  };
  vm.loadMoreWaitinglists();
});