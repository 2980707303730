'use strict';

angular.module('app').directive('strsThemed', function ( /* @ngInject */$timeout, $rootScope, lodash) {
  var THEMED_TEMPLATES = {
    COLOR: 'COLOR',
    HOVER_COLOR: 'HOVER_COLOR',

    BG_COLOR: 'BG_COLOR',
    HOVER_BG_COLOR: 'HOVER_BG_COLOR',

    BORDER_COLOR: 'BORDER_COLOR',
    HOVER_BORDER_COLOR: 'HOVER_BORDER_COLOR'
  };

  return {
    restrict: 'A',
    replace: false,
    scope: false,
    link: function link(scope, element, attributes) {
      /*
        Attributes:
        color: '@?strsThemedColor',
       backgroundColor: '@?strsThemedBackgroundColor',
       borderColor: '@?strsThemedBorderColor',
        hoverColor: '@?strsThemedHoverColor',
       hoverBackgroundColor: '@?strsThemedHoverBackgroundColor',
       hoverBorderColor: '@?strsThemedHoverBorderColor'
        template: '@?strsThemedTemplate'
       */

      var mouseEnterHandler = undefined;
      var mouseLeaveHandler = undefined;

      function theme() {
        // Check if there is a template set
        // If there is, prefill the colors

        if (attributes.strsThemedTemplate) {
          var templates = attributes.strsThemedTemplate.split('|');

          if (lodash.includes(templates, THEMED_TEMPLATES.COLOR)) {
            attributes.strsThemedColor = $rootScope.app.color();
          }

          if (lodash.includes(templates, THEMED_TEMPLATES.HOVER_COLOR)) {
            attributes.strsThemedHoverColor = $rootScope.app.color();
          }

          if (lodash.includes(templates, THEMED_TEMPLATES.BG_COLOR)) {
            attributes.strsThemedBackgroundColor = $rootScope.app.color();
          }

          if (lodash.includes(templates, THEMED_TEMPLATES.HOVER_BG_COLOR)) {
            attributes.strsThemedHoverBackgroundColor = $rootScope.app.color();
          }

          if (lodash.includes(templates, THEMED_TEMPLATES.BORDER_COLOR)) {
            attributes.strsThemedBorderColor = $rootScope.app.color();
          }

          if (lodash.includes(templates, THEMED_TEMPLATES.HOVER_BORDER_COLOR)) {
            attributes.strsThemedHoverBorderColor = $rootScope.app.color();
          }
        }

        $timeout(function () {
          element.css({
            color: attributes.strsThemedColor ? attributes.strsThemedColor : '',
            backgroundColor: attributes.strsThemedBackgroundColor ? attributes.strsThemedBackgroundColor : '',
            borderColor: attributes.strsThemedBorderColor ? attributes.strsThemedBorderColor : ''
          });

          element.find('a, a > *').css({
            color: attributes.strsThemedColor ? attributes.strsThemedColor : '',
            backgroundColor: attributes.strsThemedBackgroundColor ? attributes.strsThemedBackgroundColor : '',
            borderColor: attributes.strsThemedBorderColor ? attributes.strsThemedBorderColor : ''
          });

          if (mouseEnterHandler) {
            element.unbind('mouseenter', mouseEnterHandler);
          }

          if (mouseLeaveHandler) {
            element.unbind('mouseleave', mouseLeaveHandler);
          }

          mouseEnterHandler = element.bind('mouseenter', function () {
            element.css({
              color: attributes.strsThemedHoverColor ? attributes.strsThemedHoverColor : '',
              backgroundColor: attributes.strsThemedHoverBackgroundColor ? attributes.strsThemedHoverBackgroundColor : '',
              borderColor: attributes.strsThemedHoverBorderColor ? attributes.strsThemedHoverBorderColor : ''
            });

            element.find('a, a > *').css({
              color: attributes.strsThemedHoverColor ? attributes.strsThemedHoverColor : '',
              backgroundColor: attributes.strsThemedHoverBackgroundColor ? attributes.strsThemedHoverBackgroundColor : '',
              borderColor: attributes.strsThemedHoverBorderColor ? attributes.strsThemedHoverBorderColor : ''
            });
          });

          mouseLeaveHandler = element.bind('mouseleave', function () {
            element.css({
              color: attributes.strsThemedColor ? attributes.strsThemedColor : '',
              backgroundColor: attributes.strsThemedBackgroundColor ? attributes.strsThemedBackgroundColor : '',
              borderColor: attributes.strsThemedBorderColor ? attributes.strsThemedBorderColor : ''
            });

            element.find('a, a > *').css({
              color: attributes.strsThemedColor ? attributes.strsThemedColor : '',
              backgroundColor: attributes.strsThemedBackgroundColor ? attributes.strsThemedBackgroundColor : '',
              borderColor: attributes.strsThemedBorderColor ? attributes.strsThemedBorderColor : ''
            });
          });
        });
      }

      $timeout(function () {
        theme();

        attributes.$observe(function () {
          theme();
        });

        // For custom checkboxes
        element.bind('click', function () {
          theme();
        });
      });
    }
  };
});