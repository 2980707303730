'use strict';

angular.module('app').value('ERR_UNAUTHORIZED', { status: 401, statusText: 'Not Logged In' }).controller('main_MainController', function ($rootScope, $scope, $state, $location, SessionService, identity, $stateParams, $base64, lodash, TranslationService, AppService, FanGroupService) {
  var helpdeskLink = {
    text: 'entry_links_terms-help',
    href: 'helpdesk_url'
  };

  // Avoid logged user going on SAML Protected FanGroups
  var saml = lodash.includes(FanGroupService.getLoginTypes, 'SAML') && FanGroupService.getLoginTypes.length === 1;
  var logged = SessionService.isLoggedIn();
  var isMember = identity.currentGroup.fanMember;

  if (saml && logged && !isMember) {
    $state.go('app.authenticate.saml-login', { destinationPath: $base64.encode($location.$$path),
      provider: FanGroupService.getProvider() });
  }

  $scope.$watch(function () {
    return TranslationService.tk('helpdesk_url');
  }, function (link) {
    helpdeskLink.href = link;
  });

  var vm = this;
  return vm;
});