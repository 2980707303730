'use strict';

angular.module('app').enum('HOSTED_FIELD_NAMES', ['general', 'number', 'cvv', 'expirationDay']).enum('BRAINTREE_PAYMENT_TYPES', ['HOSTED_FIELDS', 'IDEAL', 'MASTERPASS', 'THREEDSECURE', 'PAYPAL']).enum('GROUP_PAYMENT_METHODS', ['CREDIT_CARD', 'IDEAL', 'MASTERPASS']).enum('PAYMENT_STATUSES', ['INITIALIZING_PAYMENT', 'OBTAINING_BRAINTREE_PAYMENT_INFO', 'LOADING_ESSENTIAL_PAYMENT_METHOD', 'LOADING_ENABLED_PAYMENT_METHODS', 'READY_FOR_PAYMENT', 'PAYMENT_IN_PROGRESS', 'FINALIZING_PAYMENT', 'PAYMENT_ERROR', 'PAYMENT_COMPLETED', 'ESSENTIAL_PAYMENT_METHOD_READY', 'THREEDSECURE_READY', 'IDEAL_READY', 'MASTERPASS_READY', 'ESSENTIAL_PAYMENT_METHOD_IN_PROGRESS', 'THREEDSECURE_IN_PROGRESS', 'IDEAL_IN_PROGRESS', 'IDEAL_IN_PROGRESS_BANK_PAYMENT_STARTED', 'MASTERPASS_IN_PROGRESS']).enum('PAYMENT_ERRORS', ['PAYMENT_CANCELLED', 'GENERAL_PAYMENT_ISSUE', 'PAYMENT_SUBMISSION_ERROR', 'PAYMENT_PREPARATION_ISSUE', 'ISSUE_CREATING_BRAINTREE_CLIENT', 'ISSUE_CREATING_ENABLED_PAYMENT_METHOD', 'THREEDSECURE_IFRAME_ISSUE', 'THREEDSECURE_VERIFICATION_ISSUE', 'THREEDSECURE_VERIFICATION_FAILED', 'MASTERPASS_PAYMENT_ISSUE', 'IDEAL_PAYMENT_ISSUE', 'IDEAL_PAYMENT_NOT_COMPLETE_OR_PENDING']).service('BraintreePaymentSystemService',
/* @ngInject */
function ($modal, $log, $q, $rootScope, $window, FanApiService, PAYMENT_ERRORS, PAYMENT_STATUSES, TRANSACTION_POLLING_ERRORS, SIMPLIFIED_TRANSACTION_STATUS, UtilService, WaitinglistService, lodash, HOSTED_FIELD_NAMES, BRAINTREE_PAYMENT_TYPES) {
    /**
     * @TODO List
     * - Complete methods documentation
     * - iDEAL Payment
     * - Remove mock masterpass
     * - Gather all Progress and Errors labels for translation
     * - Remove unused methods and views, related to new changes
     */

    var service = {};
    var braintree = $window.braintree;
    var logP = UtilService.logAndContinue;

    // Main Deferred
    var braintreeDeferred = null;

    // Modal and Modal scope
    var braintreeModal = null;
    var braintreeModalScope = null;

    // Config
    var paymentInfo = null;
    var readyForPayment = {};

    // Instances
    var braintreeClientInstance = null;
    var hostedFieldsInstance = null;
    var masterpassInstance = null;
    var idealInstance = null;
    var threeDSecureInstance = null;
    var localPaymentInstance = null;

    var waitingList = null;
    var waitingListId = null;

    /**
     *
     * Resets all global variables, tears down Braintree instances,
     * distroy exsisting scopes and closes modal
     *
     */
    function resetPayment() {
        if (braintreeModal) {
            braintreeModal.close();
            braintreeModal = null;
        }

        if (braintreeModalScope) {
            braintreeModalScope.$destroy();
            braintreeModalScope = null;
        }

        if (hostedFieldsInstance) {
            hostedFieldsInstance.teardown();
            hostedFieldsInstance = null;
        }

        if (masterpassInstance) {
            masterpassInstance.teardown();
            masterpassInstance = null;
        }

        if (idealInstance) {
            idealInstance.teardown();
            idealInstance = null;
        }

        if (threeDSecureInstance) {
            threeDSecureInstance.teardown();
            threeDSecureInstance = null;
        }

        braintreeClientInstance = null;
        braintreeDeferred = null;
        paymentInfo = null;
        readyForPayment = {};
    }

    /*    Error handeling methods   */

    function setGeneralErrorMessage(error) {
        braintreeModalScope.generalErrorMessage = error;
    }

    function isFormattedError(error) {
        return lodash.has(error, 'code') && lodash.has(error, 'details');
    }

    function formatError(error) {
        return isFormattedError(error) ? error : {
            code: PAYMENT_ERRORS.GENERAL_PAYMENT_ISSUE,
            details: error
        };
    }

    function isPaymentCanceled(error) {
        return error && error.code && error.code === PAYMENT_ERRORS.PAYMENT_CANCELLED;
    }

    function rejector(code, error) {
        var data = {
            code: code,
            details: error
        };
        console.error(data);
        braintreeDeferred.reject(data);
        return $q.reject();
    }

    function setError(error) {
        braintreeModalScope.paymentStatus = PAYMENT_STATUSES.PAYMENT_ERROR;
        braintreeModalScope.paymentTypeInProgress = null;
        setGeneralErrorMessage(error);
    }

    function cancelPayment(_reason) {
        var reason = _reason ? _reason : {
            code: PAYMENT_ERRORS.PAYMENT_CANCELLED,
            details: null
        };
        $log.debug('[BraintreePaymentSystemService] payment cancelled due to (%s)', reason.code || 'automatic');
        braintreeDeferred.reject(reason);
    }

    /*    Progress methods   */

    /**
     * Sets progress label to indicate the status of the ongoing operation
     *
     * @param {string} label
     */
    function setProgressLabel(label) {
        braintreeModalScope.progressLabel = label;
    }

    /**
     * Sets the view to loading state
     * based on the paymentType
     *
     * @param {any} paymentType
     * @returns {Promise}
     */
    function startPayment(paymentType) {
        setGeneralErrorMessage(null);
        braintreeModalScope.paymentTypeInProgress = paymentType;
        braintreeModalScope.paymentStatus = PAYMENT_STATUSES.PAYMENT_IN_PROGRESS;
        return $q.resolve();
    }

    /**
     * Stops loading state
     *
     * @returns {Promise}
     */
    function stopPayment() {
        braintreeModalScope.paymentTypeInProgress = null;
        braintreeModalScope.paymentStatus = PAYMENT_STATUSES.READY_FOR_PAYMENT;
        return $q.resolve();
    }

    function paymentHasError() {
        return braintreeModalScope.paymentStatus === PAYMENT_STATUSES.PAYMENT_ERROR;
    }

    function paymentIsCompleted() {
        return braintreeModalScope.paymentStatus === PAYMENT_STATUSES.PAYMENT_COMPLETED;
    }

    function loadingPayment() {
        return !braintreeModalScope.readyToPay;
    }

    function paymentIsInProgress() {
        return braintreeModalScope.paymentStatus === PAYMENT_STATUSES.PAYMENT_IN_PROGRESS;
    }

    function paymentNotInProgress() {
        return !paymentHasError() && !braintreeModalScope.paymentTypeInProgress;
    }

    function hostedFieldsIsInProgress() {
        return braintreeModalScope.paymentTypeInProgress === BRAINTREE_PAYMENT_TYPES.HOSTED_FIELDS;
    }

    function masterpassIsInProgress() {
        return braintreeModalScope.paymentTypeInProgress === BRAINTREE_PAYMENT_TYPES.MASTERPASS;
    }

    function idealIsInProgress() {
        return braintreeModalScope.paymentTypeInProgress === BRAINTREE_PAYMENT_TYPES.IDEAL;
    }

    function threeDSecureIsInProgress() {
        return braintreeModalScope.paymentTypeInProgress === BRAINTREE_PAYMENT_TYPES.THREEDSECURE;
    }

    function loading() {
        return !paymentHasError() && (loadingPayment() || paymentIsInProgress() && !readyForPayment.threeDSecure);
    }

    var threeDSecureParameters = null;

    function setThreeDSecureParameters(nonce, bin, amount, userInfo) {
        threeDSecureParameters = {
            amount: amount,
            nonce: nonce,
            bin: bin,
            email: userInfo.username,
            billingAddress: {
                givenName: escape(userInfo.firstName),
                surname: escape(userInfo.lastName)
            },
            onLookupComplete: function onLookupComplete(data, next) {
                // use `data` here, then call `next()`
                next();
            }
        };
    }

    /*     3D Secure methods     */
    function processThreeDSecureResponse(response) {
        if (response.liabilityShiftPossible) {
            if (response.liabilityShifted) {
                $log.debug('[BraintreePaymentSystemService] verify 3DSecure OK');
                braintreeDeferred.resolve(response.nonce);
            } else {
                $log.warn('[BraintreePaymentSystemService] 3DSecure verification failed');
                rejector(PAYMENT_ERRORS.THREEDSECURE_VERIFICATION_FAILED, response);
            }
        } else {
            $log.debug('[BraintreePaymentSystemService] 3DSecure not available');
            braintreeDeferred.resolve(response.nonce);
        }
    }

    function threeDSverifyCard(amount, tokenizedCard, userInfo) {
        // var threedSecureIframe = null;
        return threeDSecureInstance.verifyCard(threeDSecureParameters);
    }

    function verify3DSecure(tokenizedCard) {
        setProgressLabel(PAYMENT_STATUSES.THREEDSECURE_IN_PROGRESS);
        return startPayment(BRAINTREE_PAYMENT_TYPES.THREEDSECURE).then(function () {
            return braintree.threeDSecure.create({
                version: 2,
                client: braintreeClientInstance
            });
        }).then(function (_threeDSecureInstance) {
            threeDSecureInstance = _threeDSecureInstance;
            return FanApiService.me();
        }).then(function (userInfo) {
            setThreeDSecureParameters(tokenizedCard.nonce, tokenizedCard.details.bin, paymentInfo.total, userInfo);
            return threeDSverifyCard(Number(paymentInfo.total), tokenizedCard, userInfo);
        }).then(function (response) {
            processThreeDSecureResponse(response);
        }).catch(function (error) {
            rejector(PAYMENT_ERRORS.THREEDSECURE_VERIFICATION_ISSUE, error);
        });
    }

    /*     Hosted Fields Methods     */

    function addValidationError(error, key) {
        braintreeModalScope.hostedFieldsErrors[key] = error;
    }

    function clearFieldsErrors() {
        braintreeModalScope.hostedFieldsErrors = {};
    }

    function processHostedFieldsErrors(tokenizeErr) {
        if (tokenizeErr) {
            switch (tokenizeErr.code) {
                case 'HOSTED_FIELDS_FIELDS_EMPTY':
                    // occurs when none of the fields are filled in
                    $log.warn('[BraintreePaymentSystemService] All fields are empty! Please fill out the form.');
                    addValidationError(tokenizeErr.code, HOSTED_FIELD_NAMES.general);
                    break;
                case 'HOSTED_FIELDS_FIELDS_INVALID':
                    // occurs when certain fields do not pass client side validation
                    $log.warn('[BraintreePaymentSystemService] Some fields are invalid:', tokenizeErr.details.invalidFieldKeys);
                    tokenizeErr.details.invalidFieldKeys.forEach(function (key) {
                        addValidationError(tokenizeErr.code, key);
                    });
                    break;
                case 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE':
                    // occurs when:
                    //   * the client token used for client authorization was generated
                    //     with a customer ID and the fail on duplicate payment method
                    //     option is set to true
                    //   * the card being tokenized has previously been vaulted (with any customer)
                    // eslint-disable-next-line
                    // See: https://developers.braintreepayments.com/reference/request/client-token/generate/#options.fail_on_duplicate_payment_method
                    $log.warn('[BraintreePaymentSystemService] This payment method already exists in your vault.');
                    rejector(tokenizeErr.code, tokenizeErr);
                    break;
                case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED':
                    // occurs when:
                    //   * the client token used for client authorization was generated
                    //     with a customer ID and the verify card option is set to true
                    //     and you have credit card verification turned on in the Braintree
                    //     control panel
                    // eslint-disable-next-line
                    //   * the cvv does not pass verfication (https://developers.braintreepayments.com/reference/general/testing/#avs-and-cvv/cid-responses)
                    // eslint-disable-next-line
                    // See: https://developers.braintreepayments.com/reference/request/client-token/generate/#options.verify_card
                    $log.warn('[BraintreePaymentSystemService] CVV did not pass verification');
                    addValidationError(tokenizeErr.code, HOSTED_FIELD_NAMES.cvv);
                    break;
                case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
                    // occurs for any other tokenization error on the server
                    $log.warn('[BraintreePaymentSystemService] Tokenization failed server side. Is the card valid?');
                    addValidationError(tokenizeErr.code, HOSTED_FIELD_NAMES.general);
                    break;
                case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
                    // occurs when the Braintree gateway cannot be contacted
                    $log.warn('[BraintreePaymentSystemService] Network error occurred when tokenizing.');
                    addValidationError(tokenizeErr.code, HOSTED_FIELD_NAMES.general);
                    break;
                default:
                    $log.warn('[BraintreePaymentSystemService] Something bad happened!', tokenizeErr);
                    addValidationError(tokenizeErr.code, HOSTED_FIELD_NAMES.general);
            }
        }
    }

    function updateCardImage(newState) {
        // Change card bg depending on card type
        if (newState.cards.length === 1) {
            angular.element('#card-image').removeClass().addClass(newState.cards[0].type);
            var AMERICAN_EXPRESS_CVV_LENGTH = 4;
            // Change the CVV length for AmericanExpress cards
            if (newState.cards[0].code.size === AMERICAN_EXPRESS_CVV_LENGTH) {
                hostedFieldsInstance.setAttribute({
                    field: 'cvv',
                    attribute: 'placeholder',
                    value: '####'
                });
            }
        } else {
            angular.element('#card-image').removeClass();
            hostedFieldsInstance.setAttribute({
                field: 'cvv',
                attribute: 'placeholder',
                value: '###'
            });
        }
    }

    function startWatchingHostedFields() {
        braintreeModalScope.$watch(function () {
            return hostedFieldsInstance.getState();
        }, function (newState, oldState) {
            if (!lodash.isEqual(newState, oldState)) {
                braintreeModalScope.formIsValid = lodash.keys(newState.fields).every(function (key) {
                    return newState.fields[key].isValid;
                });
                updateCardImage(newState);
            }
        });
    }

    function onPaymentMethodReceived(data) {
        $log.debug('[BraintreePaymentSystemService] nonce received (%s - %s)', data.type, data.details.cardType);
        if (paymentInfo.threeDSEnabled) {
            verify3DSecure(data);
        } else {
            braintreeDeferred.resolve(data.nonce);
        }
    }

    function payViaHostedFields() {
        // Clear form errors after each submission
        clearFieldsErrors();
        setProgressLabel(PAYMENT_STATUSES.PAYMENT_IN_PROGRESS);
        return startPayment(BRAINTREE_PAYMENT_TYPES.HOSTED_FIELDS).then(function () {
            return hostedFieldsInstance.tokenize();
        }).then(function (payload) {
            return onPaymentMethodReceived(payload);
        }).catch(function (tokenizeErr) {
            return stopPayment().then(function () {
                processHostedFieldsErrors(tokenizeErr);
            });
        });
    }

    function createHostedFieldsOptions() {
        // http://braintree.github.io/braintree-web/current/module-braintree-web_hosted-fields.html#~fieldOptions
        return {
            client: braintreeClientInstance,
            styles: {
                input: {
                    'font-size': '14px'
                },
                'input.invalid': {
                    color: 'red'
                },
                'input.valid': {
                    color: 'green'
                }
            },
            fields: {
                number: {
                    selector: '#card-number',
                    placeholder: '#### #### #### ####',
                    'input.invalid': {
                        color: 'red'
                    },
                    'input.valid': {
                        color: 'green'
                    }
                },
                cvv: {
                    selector: '#cvv',
                    placeholder: '###',
                    'input.invalid': {
                        color: 'red'
                    },
                    'input.valid': {
                        color: 'green'
                    }
                },
                expirationDate: {
                    selector: '#expiration-date',
                    placeholder: 'MM / YYYY',
                    'input.invalid': {
                        color: 'red'
                    },
                    'input.valid': {
                        color: 'green'
                    }
                }
            }
        };
    }

    /*     Masterpass Methods     */

    function processMasterpassErrors(tokenizeErr) {
        tokenizeErr = tokenizeErr ? tokenizeErr : {
            code: PAYMENT_ERRORS.MASTERPASS_PAYMENT_ISSUE,
            details: null
        };
        switch (tokenizeErr.code) {
            case 'MASTERPASS_POPUP_CLOSED':
                $log.warn('[BraintreePaymentSystemService] Customer closed Masterpass popup.');
                break;
            case 'MASTERPASS_ACCOUNT_TOKENIZATION_FAILED':
                $log.warn('[BraintreePaymentSystemService] Masterpass tokenization failed. See details:', tokenizeErr.details);
                break;
            case 'MASTERPASS_FLOW_FAILED':
                $log.warn('[BraintreePaymentSystemService] Unable to initialize Masterpass flow. Are your options correct?', tokenizeErr.details);
                break;
            default:
                $log.warn('[BraintreePaymentSystemService] Error!', tokenizeErr);
        }
        setGeneralErrorMessage(tokenizeErr.code);
    }

    function generateMasterpassPayment() {
        return {
            currencyCode: paymentInfo.currency,
            subtotal: Number(paymentInfo.total),
            frameOptions: {
                // @TODO Check with designer what is the best alignment
                // width: $window.outerWidth,
                // height: $window.outerHeight,
                // top: 0,
                // left: 0
            }
        };
    }

    function payViaMasterpass() {
        var payment = generateMasterpassPayment();
        setProgressLabel(PAYMENT_STATUSES.MASTERPASS_IN_PROGRESS);
        return startPayment(BRAINTREE_PAYMENT_TYPES.MASTERPASS).then(function () {
            return masterpassInstance.tokenize(payment);
        }).then(function (payload) {
            braintreeDeferred.resolve(payload.nonce);
        }).catch(function (tokenizeErr) {
            return stopPayment().then(function () {
                processMasterpassErrors(tokenizeErr);
            });
        });
    }

    /*     Ideal Methods     */

    function processIdeaErrors(startPaymentError) {
        startPaymentError = startPaymentError ? startPaymentError : {
            code: PAYMENT_ERRORS.IDEAL_PAYMENT_ISSUE,
            details: null
        };
        switch (startPaymentError.code) {
            case 'IDEAL_WINDOW_CLOSED':
                $log.warn('[BraintreePaymentSystemService] Customer closed iDEAL window.');
                break;
            case 'IDEAL_START_PAYMENT_FAILED':
                $log.warn('[BraintreePaymentSystemService] iDEAL startPayment failed. See details:', startPaymentError.details);
                break;
            default:
                $log.warn('[BraintreePaymentSystemService] Error!', startPaymentError);
        }
        setGeneralErrorMessage(startPaymentError.code);
    }

    function setIdealPaymentStatus(status) {
        if (status === PAYMENT_STATUSES.IDEAL_IN_PROGRESS) {
            braintreeModalScope.idealPaymentStatus = 'payment_ideal_in_progress_do_not_close';
        } else if (status === PAYMENT_STATUSES.IDEAL_IN_PROGRESS_BANK_PAYMENT_STARTED) {
            braintreeModalScope.idealPaymentStatus = 'payment_ideal_bank_payment_in_progress_do_not_close';
        }
    }

    function onIdealBankPaymentStart() {
        setProgressLabel(PAYMENT_STATUSES.IDEAL_IN_PROGRESS_BANK_PAYMENT_STARTED);
        setIdealPaymentStatus(PAYMENT_STATUSES.IDEAL_IN_PROGRESS_BANK_PAYMENT_STARTED);
    }

    function generateIdealPayment() {
        return {
            currency: paymentInfo.currency,
            amount: 1,
            // @TODO: To be discussed with backend
            orderId: 'xxxxxxxx',
            onPaymentStart: function onPaymentStart(data) {
                onIdealBankPaymentStart();
                // onPaymentStart is called when the customer starts the
                // iDEAL payment by selecting their bank. Once this
                // callback occurs it is possible the iDEAL payment
                // will complete. The iDEAL Payment ID is provided as a
                // field in data and this ID can be used to get the
                // status of the iDEAL payment.
                //
                // data.id - the iDEAL payment ID
            }
        };
    }

    function isIdealComplete(payload) {
        return payload.details && payload.details.status === 'COMPLETE';
    }

    function isIdealPending(payload) {
        return payload.details && payload.details.status === 'PENDING';
    }
    // eslint-disable-next-line
    function isIdealFailed(payload) {
        return payload.details && payload.details.status === 'FAILED';
    }

    function payViaIdeal(userInfo) {
        var localPaymentId;

        localPaymentInstance.startPayment({
            paymentType: 'ideal',
            amount: paymentInfo.total,
            fallback: { // see Fallback section for details on these params
                url: window.location.href,
                buttonText: 'Complete Payment'
            },
            currencyCode: 'EUR',
            givenName: userInfo.firstName,
            surname: userInfo.lastName,
            address: {
                countryCode: userInfo.citizenshipCountryCode
            },
            onPaymentStart: function onPaymentStart(data, start) {
                FanApiService.postPaymentReference(waitingListId, data.paymentId);
                localPaymentId = data.paymentId;
                // NOTE: It is critical here to store data.paymentId on your server
                //       so it can be mapped to a webhook sent by Braintree once the
                //       buyer completes their payment. See Start the payment
                //       section for details.

                // Call start to initiate the popup
                start();
            }
        }, function (startPaymentError, payload) {
            braintreeModalScope.paymentStatus = PAYMENT_STATUSES.PAYMENT_IN_PROGRESS;
            braintreeModalScope.paymentTypeInProgress === BRAINTREE_PAYMENT_TYPES.IDEAL;
            if (startPaymentError) {
                startPaymentError.details = {
                    code: startPaymentError.code,
                    message: startPaymentError.message
                };
                setError(formatError(startPaymentError));
                if (startPaymentError.code === 'LOCAL_PAYMENT_POPUP_CLOSED') {
                    console.error('Customer closed Local Payment popup.');
                } else {
                    console.error('Error!', startPaymentError);
                }
            } else {
                // Send the nonce to your server to create a transaction
                return submitBraintreePayment(waitingList, payload.nonce, "IDEAL", localPaymentId).catch(function (error) {
                    return $q.reject({
                        code: PAYMENT_ERRORS.PAYMENT_SUBMISSION_ERROR,
                        details: {
                            message: error
                        }
                    });
                }).then(logP('[BraintreePaymentSystemService] transaction successful')).then(function (updatedWaitinglist) {
                    angular.extend(waitingList, updatedWaitinglist);
                    location.reload();
                    return setCompleted();
                }).catch(function (error) {
                    if (isPaymentCanceled(error)) {
                        return resetPayment();
                    }
                    return setError(formatError(error));
                });
            }
        });
    }

    /*    Braintree instances Methods    */

    function merchantAccountId() {
        return "EUR_local";
    }

    function createAndCacheBraintreeClientInstance(token) {
        return braintree.client.create({
            authorization: token
        }).then(function (clientInstance) {
            braintreeClientInstance = clientInstance;
            //return clientInstance;
            braintree.localPayment.create({
                client: clientInstance,
                merchantAccountId: merchantAccountId()
            }, function (localPaymentErr, paymentInstance) {

                // Stop if there was a problem creating local payment component.
                // This could happen if there was a network error or if it's incorrectly
                // configured.
                if (localPaymentErr) {
                    console.error('Error creating local payment:', localPaymentErr);
                    return;
                }
                localPaymentInstance = paymentInstance;
                return clientInstance;
            });
        });
    }

    function createEssentialPaymentMethodInstance() {
        var hostedFieldsOptions = createHostedFieldsOptions(braintreeClientInstance);
        return braintree.hostedFields.create(hostedFieldsOptions);
    }

    function createEnabledPaymentMethodsInstances() {
        var promisesObject = {};

        if (paymentInfo.masterpassEnabled) {
            promisesObject.masterpass = braintree.masterpass.create({
                client: braintreeClientInstance
            });
        }

        if (paymentInfo.idealEnabled) {
            // paymentInfo.idealEnabled
            promisesObject.ideal = braintree.ideal.create({
                client: braintreeClientInstance
            });
        }

        return $q.all(promisesObject);
    }

    /*     Ready for payment methods     */

    function setEssentialPaymentMethodsReady(_hostedFieldsInstance) {
        hostedFieldsInstance = _hostedFieldsInstance;
        readyForPayment.hostedFields = true;

        // Watch the validity of the form
        // and update card image
        startWatchingHostedFields();
    }

    function setEnabledPaymentMethodsReady(enabledPaymentMethodsInstences) {
        masterpassInstance = enabledPaymentMethodsInstences.masterpass;
        idealInstance = enabledPaymentMethodsInstences.ideal;

        if (masterpassInstance) {
            // Masterpass should not be activated with threeDSecure
            if (paymentInfo.threeDSEnabled) {
                $log.warn('Masterpass should not be activated with threeDSecure');
            } else {
                readyForPayment.masterpass = true;
            }
        }

        if (paymentInfo.paymentMethods.includes("IDEAL")) {
            readyForPayment.ideal = true;
        }
    }

    function setReadyToPay() {
        braintreeModalScope.readyToPay = true;
        braintreeModalScope.readyForPayment = readyForPayment;
        return $q.resolve();
    }

    function setCompleted() {
        braintreeModalScope.paymentStatus = PAYMENT_STATUSES.PAYMENT_COMPLETED;
    }

    /*     Submitting payment and obtaining infromation methods    */

    function obtainBraintreePaymentInfo(waitinglist) {
        setProgressLabel(PAYMENT_STATUSES.OBTAINING_BRAINTREE_PAYMENT_INFO);
        return FanApiService.getPositionBraintreePaymentInfo(waitinglist.waitingListId);
    }

    function submitBraintreePayment(waitinglist, braintreeNonce, localPaymentType, localPaymentId) {
        var options = {
            braintreePayment: {
                paymentMethodNonce: braintreeNonce,
                localPaymentType: localPaymentType,
                localPaymentId: localPaymentId
            }
        };
        // @TODO Check the payPosition vs preauthorizePosition in SDK
        return FanApiService.payPosition(waitinglist.waitingListId, options);
    }

    /*    Payment instantiation methods   */

    /**
     * Opens the main braintree modal
     * and returns modal options
     *
     * @requires braintreeModalScope
     *
     * @returns {Object} Modal Options
     */
    function generateAndOpenBraintreeModal() {
        return $modal.open({
            // @TODO : Next line To be used inside the checkout page
            // parent: angular.element('#checkout-container'),
            templateUrl: 'components/payment/braintree.modal.html',
            windowClass: 'medium strs-modal-alt',
            scope: braintreeModalScope,
            keyboard: false,
            backdrop: 'static',
            closeOnClick: false
        });
    }

    function braintreeButton(waitinglist) {
        return {
            wishlistPaymentType: WaitinglistService.canPreauthorize(waitinglist) ? 'wl_preauth-btn' : 'wl_pay-btn',
            scope: {
                seats: waitinglist.position.numberOfSeats,
                price: WaitinglistService.formatPrice(waitinglist)
            }
        };
    }

    /**
     *
     * Resets global variables, creates new:
     *    - deferred
     *    - modalScope, and adds required methods on it
     *    - modal and open it,
     *
     * @param {Object} waitinglist
     */
    function initiatePreliminaries(waitinglist) {
        // Resetter
        resetPayment();
        // Set global deferred
        braintreeDeferred = $q.defer();
        // Set global modal scope
        braintreeModalScope = $rootScope.$new();
        // Generate global modal object, and open it in the view
        braintreeModal = generateAndOpenBraintreeModal();
        // Set required values on the scope
        braintreeModalScope.button = braintreeButton(waitinglist);
        braintreeModalScope.PAYMENT_STATUSES = PAYMENT_STATUSES;
        braintreeModalScope.BRAINTREE_PAYMENT_TYPES = BRAINTREE_PAYMENT_TYPES;
        braintreeModalScope.paymentHasError = paymentHasError;
        braintreeModalScope.loadingPayment = loadingPayment;
        braintreeModalScope.paymentIsCompleted = paymentIsCompleted;
        braintreeModalScope.paymentIsInProgress = paymentIsInProgress;
        braintreeModalScope.paymentNotInProgress = paymentNotInProgress;
        braintreeModalScope.hostedFieldsIsInProgress = hostedFieldsIsInProgress;
        braintreeModalScope.masterpassIsInProgress = masterpassIsInProgress;
        braintreeModalScope.idealIsInProgress = idealIsInProgress;
        braintreeModalScope.threeDSecureIsInProgress = threeDSecureIsInProgress;
        braintreeModalScope.resetPayment = resetPayment;
        braintreeModalScope.payViaHostedFields = payViaHostedFields;
        braintreeModalScope.payViaMasterpass = payViaMasterpass;
        braintreeModalScope.payViaIdeal = payViaIdeal;
        braintreeModalScope.loading = loading;
    }

    /**
     * Opens a modal and performs necessary payment
     * operations via Braintree client based on
     * a given wishlist and it's payment configurations,
     * the flow supports:
     *
     * - Credit cards via Hosted fields
     * - 3D Secure
     * - Masterpass
     * - iDEAL
     *
     * The opened modal should never closes automatically,
     * the user is always required to close the model
     * on all occasions to ensure the understanding
     * of the statues of the payment.
     *
     * It returns a promise,
     * which resolves on successful payment operation,
     * or rejects on error.
     *
     * TEST CARDS
     * 3530111333300000 (3dsecure not available, JCB)
     * 4111111111111111 (3dsecure available - always works, Visa)
     * 5200000000000007 (3dsecure available - liability shifted, Mastercard)
     * 4000000000000028 (3dsecure available - liability not shifted, Visa)
     *
     * @param {Object} waitinglist
     * @returns {Promise}
     */
    function pay(waitinglist) {
        // Set global requirement & open a modal
        initiatePreliminaries(waitinglist);
        waitingList = waitinglist;
        waitingListId = waitinglist.waitingListId;
        setProgressLabel(PAYMENT_STATUSES.INITIALIZING_PAYMENT);

        // Once the modal is opened, start loading then viewing payment methods
        braintreeModal.opened.then(logP('[BraintreePaymentSystemService] obtain a braintree payment info')).then(function () {
            return obtainBraintreePaymentInfo(waitinglist).catch(function (error) {
                return rejector(PAYMENT_ERRORS.PAYMENT_PREPARATION_ISSUE, error);
            });
        }).then(logP('[BraintreePaymentSystemService] creating braintree client instance')).then(function (_paymentInfo) {
            paymentInfo = _paymentInfo;
            console.log(paymentInfo);
            setProgressLabel(PAYMENT_STATUSES.LOADING_ESSENTIAL_PAYMENT_METHOD);
            return createAndCacheBraintreeClientInstance(paymentInfo.token).catch(function (error) {
                return rejector(PAYMENT_ERRORS.ISSUE_CREATING_BRAINTREE_CLIENT, error);
            });
        }).then(logP('[BraintreePaymentSystemService] creating essential payment methods')).then(function () {
            return createEssentialPaymentMethodInstance().catch(function (error) {
                return rejector(PAYMENT_ERRORS.ISSUE_CREATING_ESSENTIAL_PAYMENT_METHOD, error);
            });
        }).then(logP('[BraintreePaymentSystemService] set essential payment method ready')).then(function (_hostedFieldsInstance) {
            return setEssentialPaymentMethodsReady(_hostedFieldsInstance);
        }).then(logP('[BraintreePaymentSystemService] creating enabled payment methods')).then(function () {
            setProgressLabel(PAYMENT_STATUSES.LOADING_ENABLED_PAYMENT_METHODS);
            return createEnabledPaymentMethodsInstances().catch(function (error) {
                return rejector(PAYMENT_ERRORS.ISSUE_CREATING_ENABLED_PAYMENT_METHOD, error);
            });
        }).then(logP('[BraintreePaymentSystemService] set enabled payment methods ready')).then(function (enabledPaymentMethodsInstences) {
            return setEnabledPaymentMethodsReady(enabledPaymentMethodsInstences);
        }).then(FanApiService.me).then(function (user) {
            // Make the view ready for the user to pay
            var btn = document.querySelector('#ideal-button');

            btn.addEventListener('click', function () {
                payViaIdeal(user);
            });
            return setReadyToPay();
        });

        // Handle closing modal
        braintreeModal.result.then(resetPayment).catch(cancelPayment);

        return braintreeDeferred.promise.then(function (braintreeNonce) {
            startPayment(PAYMENT_STATUSES.PAYMENT_IN_PROGRESS);
            setProgressLabel(PAYMENT_STATUSES.FINALIZING_PAYMENT);
            return braintreeNonce;
        }).then(logP('[BraintreePaymentSystemService] submit braintree payment to seaters')).then(function (braintreeNonce) {
            return submitBraintreePayment(waitinglist, braintreeNonce).catch(function (error) {
                return $q.reject({
                    code: PAYMENT_ERRORS.PAYMENT_SUBMISSION_ERROR,
                    details: {
                        message: error
                    }
                });
            });
        }).then(logP('[BraintreePaymentSystemService] transaction successful')).then(function (updatedWaitinglist) {
            angular.extend(waitinglist, updatedWaitinglist);
            location.reload();
            return setCompleted();
        }).catch(function (error) {
            if (isPaymentCanceled(error)) {
                return resetPayment();
            }
            return setError(formatError(error));
        });
    }

    service.pay = pay;
    return service;
});