'use strict';

angular.module('app').constant('APP_DEFAULT_LOCALE', 'en').constant('LOCKER_NS_APP', 'app').constant('LOCKER_INITIAL_REDIRECT', 'InitialRedirect').constant('LOCKER_USER_LOCALE', 'user-locale').constant('APP_DEFAULTBG_MAIN', 'images/webapp/backgrounds/dashboard_bg.jpg').constant('APP_DEFAULTCOLOR', '#3E82F7').value('APP_LOCALE_FORMATS', {
  en: {
    time: 'h:mm A',
    date: 'MMMM D YYYY',
    shortDate: 'MMM D',
    datetime: 'MMM D YYYY h:mm A',
    month: 'MMM',
    day: 'D'
  },
  default: {
    time: 'HH:mm',
    date: 'D MMMM YYYY',
    shortDate: 'D MMM',
    datetime: 'dd/MM/YYYY HH:mm',
    month: 'MMM',
    day: 'D'
  }
}).service('AppService',
/* @ngInject */function (SDKService, $q, $log, $location, $window, $state, $rootScope, locker, $stateParams, MomentService, lodash, AppApiService, FanApiService, CachedResourceFactory, FacebookService, LOCKER_NS_APP, APP_DEFAULTCOLOR, LOCKER_INITIAL_REDIRECT, LOCKER_USER_LOCALE, APP_DEFAULTBG_MAIN, APP_DEFAULT_LOCALE, APP_LOCALE_FORMATS, APP_SETTINGS, $base64, $document) {
  function appLocker() {
    return locker.driver('local').namespace(LOCKER_NS_APP);
  }

  var currentGroup = undefined;

  var service = $rootScope.APP = {};

  function getUserDefaultLocale() {
    // Use previously stored locale if available
    var storedLocale = appLocker().get(LOCKER_USER_LOCALE);
    if (storedLocale) {
      $log.debug('[AppService] user default locale from local storage');
      return $q.resolve(storedLocale);
    }
    // Otherwise ask the api based on Accept-Language header
    $log.debug('[AppService] user default locale from api');
    return SDKService.client.appService.getUserDefaultLocale().then(function (locale) {
      // Store to avoid fetching the locale again
      appLocker().put(LOCKER_USER_LOCALE, locale);
      return locale;
    });
  }

  function getLocaleFormats(locale) {
    if (APP_LOCALE_FORMATS[locale]) {
      return APP_LOCALE_FORMATS[locale];
    }
    return APP_LOCALE_FORMATS.default;
  }

  service.getLocale = function () {
    return service.locale;
  };

  var translationMap = null;

  function loadTranslations(locale) {
    $log.debug('[AppService] loading translations for locale "%s"...', locale);
    return AppApiService.translationsInTargetLocale(AppApiService.TARGET_CLIENT.WEB, locale).then(function (translations) {
      translationMap = lodash.chain(translations).keyBy('key').mapValues('text').value();
    });
  }

  function initializeLocale() {
    return getUserDefaultLocale().then(function (locale) {
      return loadTranslations(locale).then(function () {
        service.locale = locale;
        service.localeFormats = getLocaleFormats(locale);
        MomentService.setLocale(locale);
      });
    });
  }

  service.getTranslation = function (key) {
    return translationMap[key];
  };

  service.changeLocale = function (locale) {
    // don't change locale if not needed
    if (locale === service.getLocale()) {
      return $q.when(true);
    }
    $log.debug('[AppService] changing locale %s', locale);
    appLocker().put(LOCKER_USER_LOCALE, locale);
    return initializeLocale().then(location.reload());
  };

  var countriesCache = undefined;

  service.getCountries = function () {
    return countriesCache.data;
  };

  function loadCountries() {
    countriesCache = CachedResourceFactory.createCachedResource('app_countries', function () {
      return AppApiService.getCountries();
    });
    return countriesCache.load();
  }

  var languagesCache = undefined;

  service.getLanguages = function () {
    return languagesCache.data;
  };

  function loadLanguages() {
    languagesCache = CachedResourceFactory.createCachedResource('app_languages', function () {
      return AppApiService.getLanguages();
    });
    return languagesCache.load();
  }

  var envCache = undefined;

  function loadEnv() {
    envCache = CachedResourceFactory.createCachedResource('app_env', function () {
      return AppApiService.getEnv().then(function (env) {
        // Localhost facebook override
        if ($location.host() === 'localhost') {
          env.facebookAppId = '882161518515065';
        }
        return env;
      });
    });
    return envCache.load();
  }

  service.getEnv = function () {
    return angular.extend({}, $window.ENV, envCache.data);
  };

  function loadFacebook(envP) {
    return envP.then(function (env) {
      return FacebookService.initializeSDK(env.facebookAppId);
    });
  }

  var deferredReadyP = $q.defer();
  service.whenReady = deferredReadyP.promise;

  function generateDefaultNextState() {
    var slug = service.getSlug();
    return {
      stateName: 'app.main.group',
      stateParams: { slug: slug }
    };
  }
  function isValidInitialRedirect(initialRedirectState) {
    return angular.isObject(initialRedirectState) && !initialRedirectState.stateName.startsWith('app.authenticate');
  }
  function generateNextState(redirect) {
    var defaultNextState = generateDefaultNextState();
    var nextState = defaultNextState;
    var initialRedirectState = appLocker().get(LOCKER_INITIAL_REDIRECT);
    var validInitialRedirectState = isValidInitialRedirect(initialRedirectState);
    var validRedirect = angular.isObject(redirect);
    /* Removed see SM-881
    var onboardingCarouselTokenShown = Boolean(localStorage.getItem(APP_SETTINGS.onboardingCarouselShownTokenName));
    */

    // Set the the initial redirect state
    if (validInitialRedirectState) {
      nextState = {
        stateName: initialRedirectState.stateName,
        stateParams: initialRedirectState.stateParams
      };

      // Given redirect overides the initial redirect state
    } else if (validRedirect) {
      nextState = {
        stateName: redirect.stateName,
        stateParams: redirect.stateParams
      };

      // Check if the onboarding is not shown yet, otherwise show this first -- Removed see SM-881
    } /* else if (!onboardingCarouselTokenShown) {
      nextState = {
        stateName: 'app.main.getting-started',
        stateParams: { nextState: nextState }
      };
      } */

    return nextState;
  }

  /* function clearInitialRedirect() {
    appLocker().forget(LOCKER_INITIAL_REDIRECT);
  } */

  service.signOutRedirect = function () {
    // console.log('redirect');
    // clearInitialRedirect();
    $state.go('app.authenticate.landing', { slug: currentGroup.slug }, { reload: true });
  };

  var initialRedirectIsSet = false;
  // UI Router transition https://ui-router.github.io/ng1/docs/1.0.0-rc.1/classes/transition.transition-1.html#_from
  service.setInitialRedirect = function (transition) {
    // use "targetState" over "to" method - "to" is bugged
    var to = transition.targetState();
    var from = transition.from();
    if (from.name === '' && !initialRedirectIsSet) {
      var stateToRedirectTo = {
        stateName: to.name(),
        stateParams: to.params().slug === 'security' ? JSON.parse(localStorage.getItem('app.InitialRedirect')).stateParams : to.params()
      };
      appLocker().put(LOCKER_INITIAL_REDIRECT, stateToRedirectTo);
      initialRedirectIsSet = true;
      $log.debug('[AppService] setting initial state to %s(%s)', to.name(), JSON.stringify(to.params()));
    }
  };

  var backgroundImage = false;
  service.getBackground = function () {
    if (backgroundImage) {
      return backgroundImage;
    }
    var stateName = $state.current.name;
    if (stateName.startsWith('app.main.')) {
      return APP_DEFAULTBG_MAIN;
    }

    return '';
  };

  service.setBackground = function (url) {
    // TODO - fetch background url, convert to base64 then set
    backgroundImage = url;
  };

  service.setCurrentGroup = function (group) {
    currentGroup = group;
  };
  service.getCurrentGroup = function () {
    // console.log('ALLO GET CURR');
    return currentGroup;
  };

  service.setCurrentWaitingListTitle = function (waitinglist) {
    if (waitinglist.translatedExperienceName) {
      $document[0].title = waitinglist.translatedGroupName + ' - ' + waitinglist.translatedExperienceName;
    } else {
      $document[0].title = waitinglist.translatedGroupName + ' - ' + waitinglist.translatedEventName;
    }
  };

  var currentWaitinglist = undefined;
  service.setCurrentWaitinglist = function (waitinglist) {
    currentWaitinglist = waitinglist;
    service.setCurrentWaitingListTitle(waitinglist);
  };

  service.getCurrentWaitinglist = function () {
    return currentWaitinglist;
  };

  service.generateCurrentGroupEncodedRedirect = function (group) {
    var groupSlug = group ? group.slug : service.getCurrentGroup().slug;
    var state = JSON.stringify({
      stateName: 'app.main.group',
      stateParams: { slug: groupSlug }
    });
    var redirect = $base64.encode(state);
    var urlSafeRedirect = encodeURIComponent(redirect);
    return urlSafeRedirect;
  };

  service.generateCurrentEncodedRedirect = function (objToRedirectTo) {
    var objUsed = objToRedirectTo ? objToRedirectTo : service.getCurrentWaitinglist();
    var state = {};
    // If no current wishlist, return current group instead
    if (!objUsed) {
      return service.generateCurrentGroupEncodedRedirect();
    }

    if (objUsed.groupSlug) {
      state = {
        stateName: 'app.main.waitinglist',
        stateParams: {
          slug: objUsed.groupSlug,
          waitinglistId: objUsed.waitingListId
        }
      };
    }

    if (objUsed.slug) {
      state = {
        stateName: 'app.main.group',
        stateParams: {
          slug: objUsed.slug,
          fanGroupId: objUsed.fanGroupId
        }
      };
    }
    var stringify = JSON.stringify(state);
    var encode = $base64.encode(stringify);
    var urlSafeRedirect = encodeURIComponent(encode);
    return urlSafeRedirect;
  };

  var customColor = false;
  service.getColor = function () {
    return customColor || APP_DEFAULTCOLOR;
  };

  service.setColor = function (color) {
    if (angular.isString(color) && color.length > 0) {
      // Prefix color if needed
      if (color.indexOf('#') !== 0) {
        color = '#' + color;
      }
      // Check if it's a valid HEX color
      if (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color)) {
        customColor = color;
      } else {
        $log.warn('AppService - trying to set invalid HEX color %s', color);
      }
    } else {
      $log.warn('AppService - trying to set empty color');
    }
  };

  // Example : hexToRGB('#FF0000', 0.5);
  function hexToRGB(hex, alpha) {
    /* eslint-disable */
    var red = parseInt(hex.slice(1, 3), 16);
    var green = parseInt(hex.slice(3, 5), 16);
    var blue = parseInt(hex.slice(5, 7), 16);
    /* eslint-enable */

    if (alpha) {
      return 'rgba(' + red + ', ' + green + ', ' + blue + ', ' + alpha + ')';
    }
    return 'rgb(' + red + ', ' + green + ', ' + blue + ')';
  }

  service.getTransColor = function (percentage) {
    return hexToRGB(service.getColor(), percentage);
  };

  service.getGroupName = function (group) {
    var locale = service.getLocale();
    return group.name[locale];
  };

  service.getGroupWelcomeText = function (group) {
    var locale = service.getLocale();
    return group.welcomeText[locale];
  };

  service.getGroupDescription = function (group) {
    var locale = service.getLocale();
    return group.description[locale];
  };

  var defaultPrivacyPolicyUrls = {
    en: 'http://getseaters.com/privacy/',
    fr: 'http://www.getseaters.com/fr/politique-de-confidentialite/'
  };
  service.getPrivacyPolicyUrl = function (group) {
    var locale = service.getLocale();
    return group.privacyPolicyUrls && group.privacyPolicyUrls[locale] || defaultPrivacyPolicyUrls[locale] || 'http://getseaters.com/user-agreement/';
  };

  var defaultTermsAndConditionsUrls = {
    en: 'http://getseaters.com/user-agreement/',
    fr: 'http://www.getseaters.com/fr/conditions-dutilisation/'
  };

  service.getTermsAndConditionsUrl = function (group) {
    var locale = service.getLocale();
    return group.termsAndConditionsUrls && group.termsAndConditionsUrls[locale] || defaultTermsAndConditionsUrls[locale] || 'http://getseaters.com/privacy/';
  };

  service.setDefaults = function () {
    backgroundImage = false;
    customColor = false;
  };

  function stateParts() {
    return $state.get().map(function (state) {
      return (state.url || '').replace(/\?.*/g, '').split('/').filter(function (part) {
        return (/^[a-zA-Z0-9_-]+$/.test(part)
        );
      });
    }).reduce(function (list, current) {
      return list.concat(current);
    }, []);
  }

  // TODO - only filter out root state parts
  var illegalSlugs = stateParts();
  service.checkSlug = function (slug) {
    return illegalSlugs.indexOf(slug) < 0 && /^[a-zA-Z0-9_-]+$/.test(slug) &&
    // Backwards compatible urls
    // Unknown slug - doesn't matter
    slug !== '_';
  };

  service.getSlug = function () {
    var path = $location.path();
    var parts = path.split('/');
    var slug = parts[1];
    if (service.checkSlug(slug)) {
      return slug;
    }
    return false;
  };

  service.afterLoginState = function (redirect) {
    // console.log(FanApiService.me());
    return FanApiService.me().then(function (fan) {
      // console.log("nextState");
      var nextState = generateNextState(redirect);
      // console.log(nextState);
      $log.debug('[AppService] after login state: %s', nextState.stateName);
      return nextState;
    });
  };

  service.goBack = function () {
    $window.history.back();
  };

  service.inState = function (stateName) {
    return $state.current.name === stateName;
  };

  function inStateHierarchy(stateName, origin) {
    return stateName.startsWith('^.') || origin === stateName || origin.startsWith(stateName + '.');
  }

  service.inStateHierarchy = function (stateName, origin) {
    return inStateHierarchy(stateName, origin || $state.current.name);
  };

  service.stateHref = function (stateName, stateParams) {
    return $state.href(stateName, stateParams);
  };

  service.goTo = function (stateName, stateParams) {
    return $state.go(stateName, stateParams);
  };
  service.setCurrentGroupTitle = function (fanGroup) {
    var group = fanGroup ? fanGroup : service.getCurrentGroup();
    $document[0].title = group.translatedName || '';
  };
  service.setGroupVariables = function (fanGroup) {
    service.setCurrentGroup(fanGroup);
    service.setCurrentGroupTitle(fanGroup);
    service.setBackground(fanGroup.backgroundImageUrl || 'images/webapp/backgrounds/dashboard_bg.jpg');
  };

  service.initialize = function () {
    $log.debug('App.initialize');
    var envP = loadEnv();
    // Don't wait for facebook to be initialized
    loadFacebook(envP);
    return $q.all([envP, initializeLocale(), loadCountries(), loadLanguages()]).then(function () {
      // WhenReady will be triggered
      deferredReadyP.resolve();
      return service;
    });
  };

  return service;
});