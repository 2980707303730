'use strict';

angular.module('app').enum('API_EVENTS', ['REQUEST', 'ERROR']).constant('HTTP_STATUS', {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  PAGE_NOT_FOUND: 404,
  SERVICE_NOT_AVAILABLE: 503
}).constant('hasHTTPCode', function (httpCode, error) {
  return error.status === httpCode && !error.data;
}).service('APIService',
/* @ngInject */function ($q, $http, $log, $rootScope, EnvService, API_EVENTS) {
  var apiUrl = EnvService.apiLocation;

  var defaultHeaders = {};

  var defaultEndpointParams = {};

  var defaultQueryParams = {};

  var service = {};

  /**
  * Add a header that will be attached to every request
  */
  service.setDefaultHeader = function (header, value) {
    defaultHeaders[header] = value;
  };

  /**
  * Remove a header that was attached to every request
  */
  service.removeDefaultHeader = function (header) {
    if (defaultHeaders[header]) {
      delete defaultHeaders[header];
    }
  };

  /**
  * Remove a parameter that will be replaced in every url unless overriden
  */
  service.setDefaultEndpointParam = function (param, value) {
    defaultEndpointParams[param] = value;
  };

  /**
  * Remove a parameter that will be replaced in every url unless overriden
  */
  service.removeDefaultEndpointParam = function (param) {
    if (defaultEndpointParams[param]) {
      delete defaultEndpointParams[param];
    }
  };

  /**
  * Remove a query parameter that will be replaced in every url unless overriden
  */
  service.setDefaultQueryParam = function (param, value) {
    defaultQueryParams[param] = value;
  };

  /**
  * Remove a query parameter that will be replaced in every url unless overriden
  */
  service.removeDefaultQueryParam = function (param) {
    if (defaultQueryParams[param]) {
      delete defaultQueryParams[param];
    }
  };

  /**
  * Create a new resource that uses the configured API
  */
  service.createResource = function (resourceName) {
    function prefixEndpoint(endpoint) {
      return apiUrl + '/' + resourceName + endpoint;
    }

    // Prepend the API URL to the given resourceName
    function resourceUrl(endpoint, endpointParams, queryParams) {
      if (!angular.isString(endpoint)) {
        throw new Error('endpoint must be a string, given:' + angular.toJson(endpoint));
      }
      if (endpoint !== '') {
        // Prefix with / only if there is some path
        endpoint = '/' + endpoint;
        endpointParams = angular.extend({}, defaultEndpointParams, endpointParams);

        var rx = /:([a-zA-Z_][a-zA-Z_0-9-]*)/g;
        endpoint = endpoint.replace(rx, function (match, capture) {
          if (endpointParams[capture]) {
            return encodeURIComponent(endpointParams[capture]);
          }
          throw new Error('endpoint parameter value missing for "' + capture + '"');
        });
      }
      if (queryParams && Object.keys(queryParams).length > 0) {
        endpoint = endpoint + '?' + Object.keys(queryParams).map(function (qp) {
          return encodeURIComponent(qp) + '=' + encodeURIComponent(queryParams[qp]);
        }).join('&');
      }
      return prefixEndpoint(endpoint);
    }

    function ErrorHandler(req) {
      return function (err) {
        if (angular.isObject(err) && angular.isObject(err.data)) {
          $log.warn('%s %s => %s - %s [%s]', req.method, req.url, err.status, err.statusText, err.data.message);
        } else {
          $log.warn('%s %s => %s - %s', req.method, req.url, err.status, err.statusText);
        }
        var rejection = angular.extend({ _req: req }, angular.isObject(err) ? err : { err: err });
        $rootScope.$emit(API_EVENTS.ERROR, rejection);
        return $q.reject(rejection);
      };
    }

    function SuccessHandler(req) {
      return function (res) {
        // $log.info(req.method + ' ' + req.url);
        return res;
      };
    }

    function buildRequest(method, endpoint, options) {
      if (!options) {
        options = {};
      }
      var endpointParams = angular.extend({}, defaultEndpointParams, options.endpointParams);
      var queryParams = angular.extend({}, defaultQueryParams, options.queryParams);
      var headers = angular.extend({}, defaultHeaders, options.headers);
      return angular.extend({
        method: method,
        abstractUrl: prefixEndpoint('/' + endpoint),
        url: resourceUrl(endpoint, endpointParams, queryParams),
        headers: headers
      }, options);
    }

    function executeRequest(method, endpoint, options) {
      var request = buildRequest(method, endpoint, options);
      var promise = $http(request).then(new SuccessHandler(request), new ErrorHandler(request));
      $rootScope.$emit(API_EVENTS.REQUEST, { request: request, promise: promise });
      return promise;
    }

    var resource = {};

    resource.LIST_MAX = 10000;
    resource.DEFAULT_MAX_PAGE_SIZE = 10;

    /**
    * Create a custom request, not covered by the base CRUD operations
    */
    resource.custom = function (method, endpoint, options) {
      function doRequest() {
        return executeRequest(method, endpoint, options).then(function (res) {
          return options && options.raw ? res : res.data;
        });
      }

      var isV2 = endpoint.includes('V2');

      // Enrich
      if (options && options.paging) {
        var itemOffset = options.paging.itemOffset || 0;
        var maxPageSize = options.paging.maxPageSize || options.paging.size || resource.DEFAULT_MAX_PAGE_SIZE;
        options.queryParams = isV2 ? angular.extend({}, options.queryParams, {
          itemOffset: itemOffset,
          maxPageSize: maxPageSize
        }) : angular.extend({}, options.queryParams, {
          itemOffset: itemOffset,
          size: maxPageSize
        });
        return doRequest().then(function (pagedResult) {
          if (!(angular.isObject(pagedResult) && angular.isArray(pagedResult.items))) {
            $log.warn('custom paged request to non-paged result: %s %s', method, resourceUrl(endpoint, options.endpointParams, options.queryParams));
          }

          pagedResult.$hasNext = function () {
            return pagedResult.itemOffset + pagedResult.items.length < pagedResult.totalSize;
          };

          pagedResult.$hasPrevious = function () {
            return pagedResult.itemOffset > 0;
          };

          return pagedResult;
        });
      }

      return doRequest();
    };

    resource.get = function (id) {
      if (id) {
        return resource.custom('GET', ':id', {
          endpointParams: { id: id }
        });
      }

      return resource.custom('GET', '');
    };

    resource.create = function (model) {
      return resource.custom('POST', '', {
        data: model
      });
    };

    resource.update = function (model, id) {
      return resource.custom('PUT', ':id', {
        endpointParams: { id: id },
        data: model
      });
    };

    resource.remove = function (id) {
      return resource.custom('DELETE', ':id', {
        endpointParams: { id: id }
      });
    };

    resource.pagedList = function (itemOffset, maxPageSize) {
      return resource.custom('GET', '', {
        paging: {
          itemOffset: itemOffset,
          maxPageSize: maxPageSize
        }
      });
    };

    resource.listAll = function () {
      return resource.pagedList(0, resource.LIST_MAX).then(function (pagedResult) {
        if (pagedResult.totalSize > resource.LIST_MAX) {
          $log.warn('listAll => only listing %s outof %s results', resource.LIST_MAX, pagedResult.totalSize);
        }
        return pagedResult.items;
      });
    };

    resource.list = function () {
      return resource.custom('GET', '');
    };

    return resource;
  };

  return service;
});