'use strict';

angular.module('app').service('SeatersPaymentSystemService',
/* @ngInject */function ($modal, $log, $rootScope, $q, $window, FanApiService, UtilService, WaitinglistService, ValidationService, lodash) {
  /**
   *  CONSTANTS
   */

  /**
   *  PRIVATE VARIABLES
   */

  var paymentInProgress = false;
  var paymentCompleted = false;

  /**
   *  PRIVATE FUNCTIONS
   */

  function getPaymentInfo(waitinglist) {
    return FanApiService.getPositionSeatersPaymentInfo(waitinglist.waitingListId);
  }

  function payPosition(waitinglist, options, form) {
    paymentInProgress = true;

    return FanApiService.payPosition(waitinglist.waitingListId, options).then(function (response) {
      paymentInProgress = false;
      paymentCompleted = true;
      location.reload();
      return response;
    }).catch(function (response) {
      paymentInProgress = false;
      return ValidationService.processValidations(response, form);
    });
  }

  function payViaVirtual(waitinglist, virtualForm) {
    var options = {
      seatersPayment: {
        paymentMethod: 'VIRTUAL'
      }
    };

    return payPosition(waitinglist, options, virtualForm);
  }

  function isPaymentInProgress() {
    return paymentInProgress;
  }

  function isPaymentCompleted() {
    return paymentCompleted;
  }

  /**
   *  API FUNCTIONS
   */

  function pay(waitinglist) {
    // Reset payment
    paymentCompleted = false;
    paymentInProgress = false;

    // Get payment info for the Seaters payment system
    return getPaymentInfo(waitinglist).then(function (paymentInfo) {
      var modalScope = $rootScope.$new();

      modalScope.waitinglist = waitinglist;
      modalScope.paymentInfo = paymentInfo;

      modalScope.payViaVirtual = payViaVirtual;
      modalScope.isPaymentInProgress = isPaymentInProgress;
      modalScope.isPaymentCompleted = isPaymentCompleted;

      return $modal.open({
        templateUrl: 'components/payment/seaters.modal.html',
        windowClass: 'medium strs-modal-alt',
        scope: modalScope,
        keyboard: false,
        backdrop: 'static',
        closeOnClick: false
      });
    });
  }

  /**
   *  API
   */
  return {
    pay: pay
  };
});