/* eslint-disable no-shadow */
'use strict';

// const { FanGroupService } = require("seaters/dist/services");

angular.module('app').config(function ($stateProvider, $urlRouterProvider, $locationProvider, lodash) {
    /**
     *  Routes
     */
    $stateProvider
    /**
     * No slug - direct to getseaters.com
     */
    .state('blank', {
        url: '/',
        template: '',
        resolve: {
            redirect: function redirect($window, $log, $q) {
                $log.log('No slug given - redirect to getseaters.com');
                $window.location.href = 'http://www.getseaters.com/welcome';
                // never resolving promise to avoid any further processing
                return $q.defer().promise;
            }
        }
    })
    /**
     *  Root route
     *  Seaters is B2B, so all routes contain a fan group slug
     */
    .state('app', {
        abstract: true,
        url: '/:slug',
        templateUrl: 'routes/app.html',
        controller: 'AppController',
        controllerAs: 'app',
        onEnter: function onEnter(AppService, publicFanGroup) {
            return AppService.setCurrentGroupTitle(publicFanGroup);
        },
        resolve: {
            // Initialize the app
            appResolved: function appResolved($injector) {
                var AppService = $injector.get('AppService');
                return AppService.initialize();
            },

            // Load the query parameters from the URL
            queryParametersResolved: function queryParametersResolved(appResolved, $injector) {
                var URLService = $injector.get('URLService');
                return URLService.loadQueryParametersFromURL();
            },

            // Try to log in
            appRestoreSessionResolved: function appRestoreSessionResolved(queryParametersResolved, $injector, $stateParams) {
                var SessionService = $injector.get('SessionService');
                return SessionService.loadSession();
            },

            // Fetch the public data of the current fan group based on the slug
            // and provide it to the child routes
            publicFanGroup: function publicFanGroup( /* @ngInject */StrsIdentity, $stateParams) {
                return StrsIdentity.initialize($stateParams.slug).then(function (identity) {
                    return identity.publicFanGroup;
                });
            },

            // If the query parameters contain a redirect, then we should go to this state first
            redirectGuardResolved: function redirectGuardResolved( /* @ngInject */publicFanGroup, URLService) {
                return URLService.handleRedirect();
            }
        }
    }).state('app.informations', {
        url: '/informations',
        views: {
            'body@app': {
                templateUrl: 'routes/informations/coronavirus.html',
                controller: 'InformationsController',
                controllerAs: 'vm'
            },
            'topbar@app': {
                templateUrl: 'layout/topbar/topbar.html',
                controller: 'TopbarController',
                controllerAs: 'vm'
            },
            'footer@app': {
                templateUrl: 'layout/footer/footer.html',
                controller: 'FooterController',
                controllerAs: 'vm'
            }
        }
    })

    /**
     *  Authentication routes
     *  The user should be logged out for these
     */
    // Parent - In charge of fetching the fan group and guarding the /authenticate routes
    .state('app.authenticate', {
        abstract: true,
        url: '/authenticate',
        views: {
            'body@app': {
                templateUrl: 'routes/authenticate/authenticate.html',
                controller: 'AuthenticateController',
                controllerAs: 'vm'
            },
            'topbar@app': {
                templateUrl: 'layout/topbar/topbar.html',
                controller: 'TopbarController',
                controllerAs: 'vm'
            },
            'footer@app': {
                templateUrl: 'layout/footer/footer.html',
                controller: 'FooterController',
                controllerAs: 'vm'
            }
        },
        resolve: {
            authenticateGuardResolved: function authenticateGuardResolved(
            /* @ngInject */
            $state, $location, $window, $q, FanGroupService, $stateParams, SessionService) {
                // Only logged out users can access the /authenticate/... routes
                // Except for autolog route && SAML routes
                var saml = lodash.includes(FanGroupService.getLoginTypes, 'SAML') && FanGroupService.getLoginTypes.length === 1;
                if (SessionService.isLoggedIn() && !lodash.includes($location.absUrl(), 'autolog') && !saml) {
                    // If logged in, redirect to fan group page
                    return $q.reject({
                        routeGuarded: true,
                        redirect: 'app.main.group',
                        redirectParams: {
                            slug: $stateParams.slug
                        }
                    });
                }
                return $q.resolve();
            }
        }
    })

    // Welcome
    .state('app.authenticate.landing', {
        url: '/landing',
        templateUrl: 'routes/authenticate/landing/landing.html',
        controller: 'AuthenticateLandingController',
        controllerAs: 'vm'
    })
    // Welcome
    .state('app.authenticate.autolog', {
        url: '/autolog/:token',
        templateUrl: 'routes/authenticate/autolog/autolog.html',
        controller: 'AuthenticateAutologController',
        controllerAs: 'vm'
    }).state('app.authenticate.redirectOauth', {
        url: '/external-login',
        templateUrl: 'routes/authenticate/oauth-redirect/redirect.html',
        controller: 'OauthRedirectController',
        controllerAs: 'vm'
    })
    // Login
    .state('app.authenticate.login', {
        url: '/login',
        templateUrl: 'routes/authenticate/login/login.html',
        resolve: {
            mainGuardResolved: function mainGuardResolved(
            /* @ngInject */
            $q, $stateParams, FanGroupService, $location, $base64) {
                // Redirect to landing if the FG is protected by SSO Login only
                var onlySSO = (lodash.includes(FanGroupService.getLoginTypes, 'SAML') || lodash.includes(FanGroupService.getLoginTypes, 'OAUTH')) && FanGroupService.getLoginTypes.length === 1;
                if (onlySSO) {
                    return $q.reject({
                        routeGuarded: true,
                        redirect: 'app.authenticate.landing',
                        redirectParams: {
                            slug: $stateParams.slug,
                            destinationPath: $base64.encode($location.$$path),
                            provider: FanGroupService.getProvider()
                        }
                    });
                }

                return $q.resolve();
            }
        },
        controller: 'AuthenticateLoginController',
        controllerAs: 'vm'
    })
    // Signup
    .state('app.authenticate.signup', {
        url: '/signup',
        templateUrl: 'routes/authenticate/signup/signup.html',
        resolve: {
            mainGuardResolved: function mainGuardResolved(
            /* @ngInject */
            $q, $stateParams, FanGroupService, $location, $base64) {
                // Redirect to landing if the FG is protected by SSO Login only
                var onlySSO = (lodash.includes(FanGroupService.getLoginTypes, 'SAML') || lodash.includes(FanGroupService.getLoginTypes, 'OAUTH')) && FanGroupService.getLoginTypes.length === 1;
                if (onlySSO) {
                    return $q.reject({
                        routeGuarded: true,
                        redirect: 'app.authenticate.landing',
                        redirectParams: {
                            slug: $stateParams.slug,
                            destinationPath: $base64.encode($location.$$path),
                            provider: FanGroupService.getProvider()
                        }
                    });
                }

                return $q.resolve();
            }
        },
        controller: 'AuthenticateSignupController',
        controllerAs: 'vm'
    })
    // Forgot password
    .state('app.authenticate.forgot-password', {
        url: '/forgot-password?expired',
        templateUrl: 'routes/authenticate/forgot-password/forgot-password.html',
        controller: 'AuthenticateForgotPasswordController',
        controllerAs: 'vm'
    }).state('app.authenticate.forgot-password-verify', {
        url: '/forgot-password/verify/:token',
        templateUrl: 'routes/authenticate/forgot-password/forgot-password-verify/forgot-password-verify.html',
        controller: 'AuthenticateForgotPasswordVerifyController',
        controllerAs: 'vm'
    }).state('app.authenticate.password-reset-successfully', {
        url: '/password-reset-successfully',
        templateUrl: 'routes/authenticate/forgot-password/password-reset-successfully/password-reset-successfully.html',
        controller: 'AuthenticatePasswordResetSuccessfullyController',
        controllerAs: 'vm'
    })
    // OAuth
    .state('app.authenticate.oauth', {
        url: '/oauth/:provider',
        templateUrl: 'routes/authenticate/oauth/oauth.html',
        controller: 'AuthenticateOAuthController',
        controllerAs: 'vm'
    }).state('app.authenticate.saml-login', {
        url: '/saml/login/:provider/:destinationPath',
        templateUrl: 'routes/authenticate/saml/login/login.html',
        controller: 'LoginSAMLController',
        controllerAs: 'vm'
    })

    /**
     *  Main
     */
    .state('app.main', {
        abstract: true,
        views: {
            'body@app': {
                templateUrl: 'routes/main/main-body.html',
                controller: 'main_MainController',
                controllerAs: 'vm'
            },
            'topbar@app': {
                templateUrl: 'layout/topbar/topbar.html',
                controller: 'TopbarController',
                controllerAs: 'vm'
            },
            'footer@app': {
                templateUrl: 'layout/footer/footer.html',
                controller: 'FooterController',
                controllerAs: 'vm'
            }
        },
        resolve: {
            mainGuardResolved: function mainGuardResolved(
            /* @ngInject */
            $q, $state, $stateParams, SessionService, FanGroupService, $location, $base64, appRestoreSessionResolved) {
                // Only logged in users can access the main routes
                if (!SessionService.isLoggedIn()) {
                    var redirect = 'app.authenticate.landing';

                    if (lodash.includes(FanGroupService.getLoginTypes, 'SAML') && FanGroupService.getLoginTypes.length === 1) {
                        localStorage.setItem('ngStorage-wishListId', $stateParams.waitinglistId);
                        redirect = 'app.authenticate.saml-login';
                    }

                    return $q.reject({
                        routeGuarded: true,
                        redirect: redirect,
                        redirectParams: {
                            slug: $stateParams.slug,
                            destinationPath: $base64.encode($location.$$path),
                            provider: FanGroupService.getProvider()
                        }
                    });
                }
                return $q.resolve();
            },

            identity: function identity( /* @ngInject */mainGuardResolved, StrsIdentity, $stateParams) {
                return StrsIdentity.initialize($stateParams.slug);
            },

            // Fetch the logged in user's identity
            me: function me( /* @ngInject */mainGuardResolved, FanApiService, identity) {
                return identity.me;
            },

            group: function group( /* @ngInject */identity) {
                return identity.currentGroup;
            }
        }
    }).state('app.main.styleguide', {
        url: '/styleguide',
        templateUrl: 'routes/styleguide/styleguide.html',
        controller: 'styleguide_StyleguideController',
        controllerAs: 'vm'
    }).state('app.main.getting-started', {
        url: '/getting-started',
        params: {
            afterLoginUnlockGroup: undefined,
            unlockGroupError: undefined
        },
        views: {
            '@app.main': {
                templateUrl: 'routes/main/onboarding/onboarding.html',
                controller: 'main_onboarding_OnboardingController',
                controllerAs: 'vm'
            }
        }
    })
    /**
     * Account
     */
    .state('app.email-confirmation', {
        url: '/email-confirmation?error&redirect',
        templateUrl: 'routes/email-confirmation/email-confirmation.html',
        controller: 'email-confirmation_EmailConfirmationController',
        controllerAs: 'vm',
        resolve: {
            error: function error($stateParams) {
                return angular.isDefined($stateParams.error) ? $stateParams.error : null;
            },
            emailConfirmationRedirect: function emailConfirmationRedirect($stateParams) {
                return angular.isDefined($stateParams.redirect) ? $stateParams.redirect : null;
            }
        }
    }).state('app.email-unsubscribed', {
        url: '/unsubscribe',
        templateUrl: 'routes/email-unsubscribed/email-unsubscribed.html',
        controller: 'email-unsubscribed_EmailUnsubscribedController',
        controllerAs: 'vm',
        resolve: {}
    })
    /**
     *  Dashboard
     */
    .state('app.main.dashboard', {
        url: '/dashboard',
        templateUrl: 'routes/main/dashboard/dashboard.html',
        controller: 'main_dashboard_DashboardController',
        controllerAs: 'dashboard',
        resolve: {
            mainDashboardResolved: function mainDashboardResolved(AppService) {
                AppService.setBackground('images/webapp/backgrounds/dashboard_bg.jpg');
                return true;
            }
        }
    }).state('app.main.dashboard.my-seats', {
        url: '/my-seats',
        views: {
            '@app.main.dashboard': {
                templateUrl: 'routes/main/dashboard/my-seats/my-seats.html',
                controller: 'MySeatsController',
                controllerAs: 'mySeats'
            },
            'topbar-context@app.main': {
                template: '{{:: t("seatlist_title")}}'
            }
        },
        resolve: {
            mySeats: function mySeats( /* @ngInject */identity) {
                return identity.mySeats;
            }
        }
    }).state('app.main.dashboard.my-waitinglists', {
        url: '/my-waitinglists',
        views: {
            '@app.main.dashboard': {
                templateUrl: 'routes/main/dashboard/my-waitinglists/my-waitinglists.html',
                controller: 'MyWaitinglistsController',
                controllerAs: 'myWaitinglists'
            },
            'topbar-context@app.main': {
                template: '{{:: t("my-waitinglist_title")}}'
            }
        },
        resolve: {
            myWishLists: function myWishLists( /* @ngInject */identity) {
                return identity.myWishLists;
            }
        }
    }).state('app.main.dashboard.search', {
        url: '/search?query',
        views: {
            '@app.main.dashboard': {
                templateUrl: 'routes/main/dashboard/search/search.html',
                controller: 'main_dashboard_search_SearchController',
                controllerAs: 'search'
            },
            'topbar-context@app.main': {
                template: '{{:: t("search_title")}}'
            }
        },
        resolve: {
            group: function group(identity) {
                return identity.currentGroup;
            },

            searchQuery: function searchQuery($stateParams) {
                return $stateParams.query;
            },

            searchResults: function searchResults($rootScope, lodash, appResolved, group, searchQuery, StrsSearchService, SEARCH_PAGE_SIZE) {
                return StrsSearchService.search(group.id, searchQuery, {
                    page: 0,
                    maxPageSize: SEARCH_PAGE_SIZE
                });
            }
        }
    })
    /**
     *  Groups
     */
    .state('app.main.group', {
        url: '?page',
        params: {
            afterLoginUnlockGroup: undefined,
            unlockGroupError: undefined,
            page: {
                value: undefined,
                dynamic: true
            },
            persistContext: true
        },
        views: {
            '@app.main': {
                templateUrl: 'routes/main/group/group.html',
                controller: 'main_group_GroupController',
                controllerAs: 'vm'
            }
        },
        reloadOnSearch: false,
        onEnter: function onEnter(AppService, StrsIdentity, group) {

            return AppService.setGroupVariables(group);
        },
        onExit: function onExit(APP_GROUP_SCROLL_TOP) {
            return localStorage.setItem(APP_GROUP_SCROLL_TOP, window.$(window).scrollTop());
        },
        resolve: {
            pageNumber: function pageNumber($stateParams) {
                var page = localStorage.getItem('wl_page') ? localStorage.getItem('wl_page') : $stateParams.page;
                return page;
            },
            hasGeoFiltering: function hasGeoFiltering(FanApiService, FanGroupService, group) {
                return FanApiService.hasGeoFilteredWaitingLists(FanGroupService.getPublicFanGroup().fanGroupId).then(function (results) {
                    return results && results.hasGeoFilteredWaitingLists;
                });
            }
        }
    }).state('app.main.group.myinvites', {
        url: '/invites',
        params: {
            waitinglistId: null
        },
        views: {
            '@app.main': {
                templateUrl: 'routes/main/waitinglist/guestlist/myinvites.html',
                controller: 'main_invite_InviteController',
                controllerAs: 'vm'
            },
            'topbar-authenticated@app': {},
            'footer@app': {}
        },
        resolve: {
            waitinglistId: function waitinglistId($stateParams) {
                return $stateParams.waitinglistId;
            }
        }
    }).state('app.main.group.communication', {
        url: '/communication',
        params: {
            inviteId: null,
            waitingListId: null
        },
        views: {
            '@app.main': {
                templateUrl: 'routes/main/waitinglist/guestlist/communication.html',
                controller: 'main_communication_CommunicationController',
                controllerAs: 'vm'
            },
            'topbar-authenticated@app': {},
            'footer@app': {}
        },
        resolve: {}
    }).state('app.main.group.communication-list', {
        url: '/communication-list',
        params: {
            inviteList: null,
            waitingListId: null
        },
        views: {
            '@app.main': {
                templateUrl: 'routes/main/waitinglist/guestlist/communication-list.html',
                controller: 'main_communication_CommunicationListController',
                controllerAs: 'vm'
            },
            'topbar-authenticated@app': {},
            'footer@app': {}
        },
        resolve: {}
    }).state('app.main.group.myapprovals', {
        url: '/approvals',
        views: {
            '@app.main': {
                templateUrl: 'routes/main/waitinglist/guestlist/myapprovals.html',
                controller: 'main_approvals_ApprovalController',
                controllerAs: 'vm'
            },
            'topbar-authenticated@app': {},
            'footer@app': {}
        },
        resolve: {
            waitinglistId: function waitinglistId($stateParams) {
                return $stateParams.waitinglistId;
            }
        }
    }).state('app.main.group.about', {
        url: '/about',
        templateUrl: 'routes/main/group/group-about.html',
        controller: 'main_group_AboutController',
        controllerAs: 'about'
    })
    /**
     *  Waiting lists
     */
    .state('app.main.waitinglist', {
        url: '/waitinglist/:waitinglistId',
        params: {
            checkoutStatus: null,
            noHistoryAppend: null,
            persistContext: false,
            optionUsed: null
        },
        views: {
            '@app.main': {
                templateUrl: 'routes/main/waitinglist/waitinglist.html',
                controller: 'main_waitinglist_WaitinglistController',
                controllerAs: 'wl'
            }
        },
        onEnter: function onEnter(AppService, waitinglist) {
            return AppService.setCurrentWaitingListTitle(waitinglist);
        },
        onExit: function onExit(AppService) {
            return AppService.setCurrentGroupTitle();
        },

        resolve: {
            groupSlug: function groupSlug(identity) {
                return identity.currentGroup.slug;
            },
            waitinglistId: function waitinglistId($stateParams) {
                return $stateParams.waitinglistId;
            },
            persistContext: function persistContext($stateParams) {
                return $stateParams.persistContext;
            },
            waitinglist: function waitinglist(mainGuardResolved, waitinglistId, FanApiService) {
                return FanApiService.waitinglistById(waitinglistId).then(function (res) {
                    return FanApiService.waitinglistById(waitinglistId);
                }, function (err) {
                    var samlNotFanError = 403;
                    if (err.errors[0].statusCode === samlNotFanError) {
                        window.location.href = location.origin + '/proximusemployees/authenticate/landing';
                    }
                });
            },
            surveyInformation: function surveyInformation(SurveyService, waitinglistId, lodash) {
                return SurveyService.getWaitingListSurveyQuestions(waitinglistId).then(function (questions) {
                    return lodash.isEmpty(questions) ? null : questions;
                });
            },
            surveyCheckoutInformation: function surveyCheckoutInformation(SurveyService, waitinglistId, lodash) {
                return SurveyService.getWaitingListSurveyQuestions(waitinglistId, 'AT_CHECKOUT').then(function (questions) {
                    return lodash.isEmpty(questions) ? null : questions;
                });
            },
            groupFromUrl: function groupFromUrl($stateParams, groupSlug, GroupService, AppService) {
                // Backwards compatible URL will sometimes not provide the slug/id
                // If given via the URL we don't have to wait for the WL to resolve
                if (AppService.checkSlug(groupSlug)) {
                    return GroupService.groupBySlugOrId(groupSlug);
                }

                return false;
            },
            mainWaitinglistResolved: function mainWaitinglistResolved(waitinglist, identity, AppService) {
                AppService.setCurrentGroup(identity.currentGroup);
                AppService.setCurrentWaitinglist(waitinglist);
                return true;
            },
            me: function me(identity) {
                return identity.me;
            },
            translatedVenueConditions: function translatedVenueConditions(waitinglistId, FanApiService) {
                return FanApiService.translatedVenueConditions(waitinglistId);
            }
        }
    }).state('app.main.waitinglist.guestlistWithoutHosts', {
        url: '/guestlist',
        params: {
            nrOfSeats: null,
            locationEditGuestList: null
        },
        views: {
            '@app.main': {
                templateUrl: 'routes/main/waitinglist/guestlist/guestlist-without-hosts.html',
                controller: 'main_guestlistWithoutHosts_GuestListController',
                controllerAs: 'vm'
            },
            'topbar-authenticated@app': {},
            'footer@app': {}
        },
        resolve: {
            waitinglistId: function waitinglistId($stateParams) {
                return $stateParams.waitinglistId;
            }
        }
    }).state('app.main.waitinglist.guestlist', {
        url: '/guestlist',
        params: {
            nrOfSeats: null
        },
        views: {
            '@app.main': {
                templateUrl: function templateUrl($stateParams) {
                    return 'routes/main/waitinglist/guestlist/guestlist.html';
                },
                controller: 'main_guestlist_GuestListController',
                controllerAs: 'vm'
            },
            'topbar-authenticated@app': {},
            'footer@app': {}
        },
        resolve: {
            waitinglistId: function waitinglistId($stateParams) {
                return $stateParams.waitinglistId;
            }
        }
    }).state('app.main.waitinglist.checkout', {
        url: '/checkout',
        params: {
            checkoutStatus: null
        },
        views: {
            '@app.main': {
                templateUrl: 'routes/main/waitinglist/checkout/checkout.html',
                controller: 'main_checkout_CheckoutController',
                controllerAs: 'vm'
            },
            'topbar-authenticated@app': {},
            'footer@app': {}
        },
        resolve: {
            waitinglistId: function waitinglistId($stateParams) {
                return $stateParams.waitinglistId;
            },
            surveyInformation: function surveyInformation(SurveyService, waitinglistId, lodash) {
                return SurveyService.getWaitingListSurveyQuestions(waitinglistId, 'AT_CHECKOUT').then(function (questions) {
                    return lodash.isEmpty(questions) ? null : questions;
                });
            }
        }
    })
    /**
     *  Settings
     */
    .state('app.main.settings', {
        url: '/settings',
        params: {
            slug: undefined
        },
        templateUrl: 'routes/main/settings/settings.html',
        controller: 'main_settings_SettingsController',
        controllerAs: 'vm',
        resolve: {
            me: function me(identity) {
                return identity.me;
            }
        }
    })
    /**
     *  Interests
     */
    .state('app.main.interests', {
        url: '/interests',
        params: {
            slug: undefined
        },
        templateUrl: 'routes/main/interests/interests.html',
        controller: 'main_interests_InterestsController',
        controllerAs: 'vm',
        resolve: {
            me: function me(identity) {
                return identity.me;
            }
        }
    })
    /**
     *  Error, maintenance & unavailability
     */
    .state('app.unavailable', {
        views: {
            'body@app': {
                templateUrl: 'routes/unavailable/unavailable.html'
            }
        }
    }).state('app.error', {
        url: '/error',
        params: {
            errorCode: undefined,
            data: undefined
        },
        views: {
            'body@app': {
                templateUrl: 'routes/error/error.html',
                controller: 'error_ErrorController',
                controllerAs: 'error',
                resolve: {
                    errorCode: function errorCode($stateParams) {
                        return $stateParams.errorCode;
                    },
                    data: function data($stateParams) {
                        return $stateParams.data;
                    }
                }
            },
            'topbar@app': {
                templateUrl: 'layout/topbar/topbar.html',
                controller: 'TopbarController',
                controllerAs: 'vm'
            },
            'footer@app': {
                templateUrl: 'layout/footer/footer.html',
                controller: 'FooterController',
                controllerAs: 'vm'
            }
        }
    }).state('maintenance', {
        // Template is inlined so when the CDN fails, we can still show the maintenance page
        /* eslint-disable max-len */
        template: '<div class="container">\n  <header class="topbar not-responsive">\n    <div class="row collapse">\n      <div class="small-2 columns collapse-right"></div>\n      <div class="small-10 columns">\n        <div class="right">\n          <strs-language-switcher lang="maintenance.locale" locales="maintenance.locales"\n            switch-lang-promise="maintenance.changeLocale"></strs-language-switcher>\n        </div>\n      </div>\n    </div>\n  </header>\n  <div class="app-body">\n    <div style="background:white; margin: 15px; min-height:400px;">\n      <div class="text-center">\n        <h1>{{maintenance.to(maintenance.title)}}</h1>\n        <p>{{maintenance.to(maintenance.info)}}</p>\n        <img src="images/webapp/backgrounds/samtheseater.png" width="200px" />\n      </div>\n    </div>\n  </div>\n</div>',
        /* eslint-enable max-len */
        // Uncomment to debug / test
        // Url: '/maintenance',
        // TemplateUrl: 'routes/maintenance.html',
        controller: function controller( /* @ngInject */$q) {
            var vm = this;

            vm.to = function (obj) {
                return obj[vm.locale] || obj.en;
            };

            vm.title = {
                en: 'Server Maintenance',
                nl: 'Server Onderhoud'
            };

            vm.info = {
                en: 'The server is currently unavailable due to maintenance. Please try again later',
                nl: 'De server is momenteel niet bereikbaar door onderhoud. Gelieve het later nogmaals te proberen'
            };

            vm.locale = 'en';
            vm.locales = [{
                locale: 'en',
                name: {
                    en: 'English',
                    fr: 'Anglais',
                    es: 'Inglès',
                    nl: 'Engels'
                }
            }, {
                locale: 'es',
                name: {
                    en: 'Español',
                    fr: 'Español',
                    nl: 'Español',
                    es: 'Español'
                }
            }, {
                locale: 'fr',
                name: {
                    en: 'French',
                    fr: 'Français',
                    nl: 'Frans',
                    es: 'francès'
                }
            }, {
                locale: 'nl',
                name: {
                    en: 'Dutch',
                    fr: 'Néerlandais',
                    nl: 'Nederlands',
                    es: 'Flamenco'
                }
            }];
            vm.changeLocale = function (locale) {
                vm.locale = locale;
                return $q.resolve();
            };
        },
        controllerAs: 'maintenance'
    });

    /**
     *  Configure location provider
     */
    $locationProvider.html5Mode(true).hashPrefix('!');

    /**
     *  Provide backwards compatibility for URL aliases
     */
    function /* @ngInject */loginAliasMatcher($match, $state) {
        $state.go('app.authenticate.login', {
            slug: $match.slug
        });
        return true;
    }

    function /* @ngInject */groupLoginAliasMatcher($match, $state) {
        $state.go('app.authenticate.login', {
            slug: $match.slug
        });
        return true;
    }

    function /* @ngInject */autoLogAliasMatcher($match, $state) {
        $state.go('app.authenticate.autolog', {
            slug: $match.slug
        });
        return true;
    }

    function /* @ngInject */groupAliasMatcher($match, $state) {
        $state.go('app.main.group', {
            slug: $match.slug
        });
        return true;
    }

    function /* @ngInject */waitinglistAliasMatcher($match, $state, $log) {
        $log.debug('waitinglistAliasMatcher :: {wlId:%s,groupId:%s}', $match.waitinglistId, $match.groupId);
        $state.go('app.main.waitinglist', {
            waitinglistId: $match.waitinglistId,
            slug: $match.groupId || '_'
        });
        return true;
    }

    $urlRouterProvider.when('/entry', loginAliasMatcher);
    $urlRouterProvider.when('/entry', autoLogAliasMatcher);
    $urlRouterProvider.when('/entry/group/:slug', groupLoginAliasMatcher);
    $urlRouterProvider.when('/group/slug/:slug', groupAliasMatcher);
    $urlRouterProvider.when('/queue/:waitinglistId/group/:groupId/infos', waitinglistAliasMatcher);
    $urlRouterProvider.when('/seat/:waitinglistId/infos', waitinglistAliasMatcher);

    /**
     *  In all other cases go to the error page (maintenance, no matches, errors, ...)
     */

    $urlRouterProvider.otherwise(function (injector, location) {
        var state = injector.get('$state');
        state.go('app.error', {
            errorCode: 404,
            data: location.path()
        });
        return location.path();
    });
});