'use strict';

// Depends on foundation-datepicker:
// https://github.com/najlepsiwebdesigner/foundation-datepicker

angular.module('app').directive('datepicker', function ( /* @ngInject */$filter, DateService, $log, $window, MomentService, lodash, FanGroupService) {
  var inputElement = null;

  return {
    restrict: 'E',
    templateUrl: 'components/datepicker/datepicker.html',
    scope: {
      dpModel: '=',
      // Format of the model
      dpModelFormat: '@?',
      // Format to show enduser
      dpOutputFormat: '@',
      // Placeholder if no date is set
      dpPlaceholder: '=?',
      // Timezone to render the date in
      dpTimeZone: '=?',
      // Fdatepicker options
      dpDatepickerOptions: '=?',
      // Optional requiredness
      dpRequired: '=?'
    },
    link: function link(scope, element) {
      // Setup the model & output formatting for both fdatepicker and moment
      var modelFormat = scope.dpModelFormat || 'yyyy-MM-dd';
      var momentModelFormat = MomentService.getMoment()().toMomentFormatString(modelFormat);
      var outputFormat = scope.dpOutputFormat || 'dd/MM/yyyy';
      var momentOutputFormat = MomentService.getMoment()().toMomentFormatString(outputFormat);

      // Options for datepicker
      // http://bootstrap-datepicker.readthedocs.org/en/latest/options.html
      var datepickerOptions = angular.extend({
        format: outputFormat.replace(/M/g, 'm'),
        autoclose: true
      }, scope.dpDatepickerOptions);

      scope.checkIfSAML = (lodash.includes(FanGroupService.getLoginTypes, 'SAML') || lodash.includes(FanGroupService.getLoginTypes, 'OAUTH')) && FanGroupService.getLoginTypes.length === 1;

      // Grab the input element to initialize datepicker on it
      inputElement = element.find('input');
      var fdatepicker = inputElement.fdatepicker(datepickerOptions);

      // Whenever the date changes; do a diff with the model
      fdatepicker.on('changeDate', function (dateEvent) {
        // We use on('changeDate') to capture the actual date value
        // Rather than the display value
        var timestamp = dateEvent.date.valueOf();
        $log.debug('[datepicker] datepicker timestamp: %s', timestamp);
        var datepickerMoment = MomentService.getMoment().utc(timestamp);
        var datepickerModel = datepickerMoment.format(momentModelFormat);
        if (datepickerModel !== scope.dpModel) {
          $log.debug('[datepicker] updating model: %s => %s', scope.dpModel, datepickerModel);
          scope.dpModel = datepickerModel;
        }
      });

      scope.$watch('dpModel', function (externalModel) {
        var datepickerModel = scope.date;
        if (angular.isString(externalModel)) {
          var expectedOutput = MomentService.getMoment().utc(externalModel, momentModelFormat).format(momentOutputFormat);
          if (datepickerModel !== expectedOutput) {
            $log.debug('[datepicker] updating datepicker model: %s => %s', datepickerModel, expectedOutput);
            scope.date = expectedOutput;
          }
        } else if (angular.isString(datepickerModel)) {
          $log.debug('[datepicker] unsetting datepicker model');
          delete scope.date;
        }
      });

      // Emptying date doesn't fire changeDate, so capture this manually
      scope.$watch('date', function (formatted, old) {
        scope.invalid = false;
        if (formatted && formatted !== '') {
          var momentDate = MomentService.getMoment().utc(formatted, momentOutputFormat);
          // eslint-disable-next-line no-negated-condition
          if (momentDate.format(momentOutputFormat).trim() !== formatted.trim()) {
            scope.invalid = true;
          } else {
            var model = momentDate.format(momentModelFormat);
            if (scope.dpModel !== model) {
              scope.dpModel = model;
            }
          }
        } else if (old && (!formatted || formatted === '')) {
          delete scope.dpModel;
        }
      });
    }
  };
});