'use strict';

angular.module('app').controller('main_dashboard_search_SearchController', function (searchQuery, searchResults, StrsSearchService, $scope, $log, group, Alert, SEARCH_PAGE_SIZE) {
  $log.debug('dashboard-search - fetched results %s => %s', 0, searchResults.items.length);

  var vm = this;
  var page = 0;
  vm.group = group;
  vm.query = searchQuery;

  function setSearchResults(results) {
    vm.results = results.items;
    vm.total = results.items.length;
    vm.meta = results;
  }
  setSearchResults(searchResults);

  vm.performSearch = function (sq) {
    vm.loading = true;
    return StrsSearchService.search(vm.group.id, sq, {
      page: 0,
      maxPageSize: SEARCH_PAGE_SIZE
    }).then(function (results) {
      setSearchResults(results);
      vm.loading = false;
      return StrsSearchService.updateParams(sq);
    });
  };

  vm.clearSearch = function () {
    vm.query = '';
  };

  vm.loadMore = function () {
    // Avoid parallel requests
    if (vm.loading) {
      return;
    }
    // Don't fetch beyond the total number hits
    if (vm.results.length >= vm.total) {
      return;
    }
    vm.loading = true;
    StrsSearchService.search(vm.group.id, searchQuery, {
      page: page + 1,
      maxPageSize: SEARCH_PAGE_SIZE
    }).then(function (nextPage) {
      var oldLength = vm.results.length;
      page += 1;
      vm.results = vm.results.concat(nextPage.items);
      $log.debug('dashboard-search - fetched results %s => %s', oldLength, vm.results.length);
    }, Alert.handleServerProblem('dashboard-search - could not load more')).then(function () {
      vm.loading = false;
    }).catch(function () {
      vm.loading = false;
    });
  };

  $scope.$watch(function () {
    return vm.query;
  }, function (newQuery, oldQuery) {
    if (!vm.loading && StrsSearchService.isValidForNewSearch(newQuery, oldQuery)) {
      vm.performSearch(vm.query);
    }
  }, true);
});