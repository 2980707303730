/* eslint-disable max-len */
'use strict';

angular.module('app').controller('MySeatsController', function (FanApiService, FanGroupService, TranslationService, $window, Alert, $log) {
  var vm = this;
  vm.loading = false;
  vm.pageSize = 10;
  vm.offset = 0;

  vm.shouldShowHistory = FanGroupService.getPublicFanGroup().showHistory;
  vm.loadMoreWaitinglists = function () {
    if (vm.loading || vm.totalSize === 0 || vm.totalSize && vm.offset >= vm.totalSize) {
      // Don't do anything if we're already loading more
      return;
    }
    vm.loading = true;
    FanApiService.activeWaitinglistsWithSeat(vm.pageSize, vm.offset).then(function (paged) {
      var itemsFiltered = paged.items.filter(function (item) {
        return item.groupId === FanGroupService.getPublicFanGroup().fanGroupId;
      });

      var nrOfItems = itemsFiltered.length;
      $log.debug('dashboard-seats - fetched active seats %s => %s', vm.offset, vm.offset + nrOfItems);
      vm.totalSize = paged.totalSize;
      vm.offset += nrOfItems;
      return itemsFiltered;
    }).then(function (items) {
      if (vm.shouldShowHistory) {
        return Promise.all([items, FanApiService.historyWaitinglistsWithSeat(FanGroupService.getPublicFanGroup().fanGroupId, vm.pageSize, vm.offset)]);
      }
      return Promise.all([items]);
    }).then(function (history) {
      vm.waitinglists = vm.waitinglists ? vm.waitinglists.concat(history[0]) : history[0];
      vm.history = history[1].items.filter(function (item) {
        var rangeDays = FanGroupService.getPublicFanGroup().historyDateRangeInDay;
        return $window.moment(item.eventStartDate).isAfter($window.moment().subtract(rangeDays, 'days'));
      });
      vm.loading = false;
    }).catch(function () {
      vm.loading = false;
      Alert.handleServerProblem('dashboard-wls - unable to fetch fan seats');
    });
  };
  vm.loadMoreWaitinglists();
});