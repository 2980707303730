'use strict';

angular.module('app').directive('wlPreview',
/* @ngInject */
function (WaitinglistService, StrsIdentity, FanGroupService) {
    return {
        restrict: 'E',
        templateUrl: 'routes/main/waitinglist/guestlist/shared/wl-preview/wl-preview.html',
        scope: {
            waitinglist: '=',
            inviteGrouped: '=',
            groupColor: '=',
            location: '=?',
            totalSelected: '=?',
            totalCost: '=?',
            totalApproved: '=?'
        },
        link: function link($scope) {

            var vm = this;

            vm.fanGroup = FanGroupService.getPublicFanGroup();

            function init() {
                initIdentity();
            }

            init();
            function initIdentity() {
                return StrsIdentity.get().then(function (identity) {
                    if (identity.me) {
                        var monthInMs = 2592000000;
                        $scope.showPasswordReset = new Date(identity.me.passwordExpirationDate) - new Date() <= monthInMs && new Date(identity.me.passwordExpirationDate) - new Date() > 0;
                    }
                    if (identity.currentGroup) {
                        return initialize(identity);
                    }

                    return initializeDefault(identity);
                });
            }

            function initialize(userIdentity) {
                $scope.me = userIdentity.me;
                $scope.isRoleHost = $scope.me.roles.filter(function (role) {
                    return role === 'HOST';
                }).length > 0;
                $scope.isRoleApprover = $scope.me.roles.filter(function (role) {
                    return role === 'APPROVER';
                }).length > 0;
                $scope.fanGroup = userIdentity.currentGroup;
                $scope.groupSlug = userIdentity.currentGroup.slug;
                $scope.myGroups = userIdentity.myGroups;
                $scope.myWishListsTotal = userIdentity.myWishLists.filter(function (item) {
                    return item.groupId === FanGroupService.getPublicFanGroup().fanGroupId;
                }).length;
                $scope.mySeatsTotal = userIdentity.mySeats.filter(function (item) {
                    return item.groupId === FanGroupService.getPublicFanGroup().fanGroupId;
                }).length;
                $scope.loaded = true;
            }

            function initializeDefault(noUserIdentity) {
                $scope.fanGroup = noUserIdentity.publicFanGroup;
                $scope.loaded = true;
            }

            $scope.totalApproved = $scope.totalApproved;
            $scope.period = WaitinglistService.generateWaitinglistPeriod($scope.waitinglist);

            /// I fixed that to the vue, but i don't know the consequences, so i keep the function "just in case"
            // $scope.getWaitingListName = function(waitingList) {
            //     console.log(document.querySelectorAll(".wl-name")[0].innerText.length);
            //     // if (document.querySelectorAll(".wl-name")[0].innerText.length === 0){
            //     //     console.log(document.querySelectorAll(".wl-name")[0].innerText);
            //     //     if ($scope.location === 'GUESTLIST') {
            //     //         return waitingList.experienceName.en || waitingList.eventName.en;
            //     //     }
            //     //     if (waitingList.experienceName.length > 0) {
            //     //         return waitingList.experienceName.filter(function(name) {
            //     //             return name.lang === 'en';
            //     //         })[0].text;
            //     //     }

            //     //     if (waitingList.eventName){
            //     //         return waitingList.eventName.filter(function(name) {
            //     //             return name.lang === 'en';                             
            //     //         })[0].text;
            //     //     }             
            //     // }
            // }

            $scope.getTotalTicketsSelected = function () {
                var total = $scope.inviteGrouped ? $scope.inviteGrouped.hostList.reduce(function (acc1, hostInvite) {
                    return acc1 + hostInvite.invites.reduce(function (acc2, invite) {
                        return acc2 + Number(invite.nbrOfRequestedSeats);
                    }, 0);
                }, 0) : $scope.totalSelected;
                return total || sessionStorage.getItem('totalTickets');
            };

            $scope.getTotalTickets = function () {
                return sessionStorage.getItem('totalTickets');
            };

            $scope.getTotalCost = function () {
                var basicPrice = Number($scope.waitinglist.price.facialPrice) || Number($scope.waitinglist.price.facialPrice) >= 0 ? Number($scope.waitinglist.price.facialPrice) : Number($scope.waitinglist.price);
                var total = $scope.inviteGrouped ? $scope.inviteGrouped.hostList.reduce(function (acc1, hostInvite) {
                    return acc1 + hostInvite.invites.reduce(function (acc2, invite) {
                        return acc2 + Number(invite.nbrOfRequestedSeats);
                    }, 0);
                }, 0) * Number($scope.waitinglist.price) : $scope.totalSelected * basicPrice;
                return total;
            };

            $scope.getFee = function () {
                var pct = 100;
                if ($scope.waitinglist.billingVariables) {
                    return Number($scope.waitinglist.billingVariables.distributionRate) * pct;
                }
                return Number($scope.waitinglist.feeCalculationParameters.distributionRate) * pct;
            };
        }
    };
});