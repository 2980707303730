'use strict';

angular.module('app').controller('AuthenticatePasswordResetSuccessfullyController', function () {
   /**
      *  CONSTANTS
      */
   /**
      *  PRIVATE VARIABLES
      */
   /**
      *  PRIVATE FUNCTIONS
      */
   /**
      *  VM VARIABLES
      */
   /**
      *  VM FUNCTIONS
      */
   /**
      *  SETUP
      */
});