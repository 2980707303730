'use strict';

angular.module('app').directive('tableHeaderWithoutGuests',
/* @ngInject */
function ($window, FanGroupService) {
    return {
        restrict: 'E',
        templateUrl: 'routes/main/waitinglist/guestlist/shared/table-header-without-guests/table-header-without-guests.html',
        scope: {
            fetch: '=',
            showSeats: '=',
            sortable: '=',
            columnLength: '=',
            hideActions: '=',
            showColumnEdit: '=',
            isOrdered: '='
        },
        link: function link($scope) {
            $scope.groupColor = FanGroupService.getPublicFanGroup().color;

            $scope.group = FanGroupService.getPublicFanGroup();
            $scope.pointerCursor = false;

            if ($scope.group != undefined && $scope.group.properties != undefined && $scope.group.properties.invite != undefined && $scope.group.properties.invite.guestSelectionModeNoHostFirstEnabled === true) {
                $scope.color = '#515151';
                $scope.pointerCursor = true;
            } else {
                $scope.pointerCursor = false;
                $scope.color = '#9b9ca1';
            }

            $scope.labels = [{
                name: 'Status',
                trl: 'guestlist_status',
                class: 'status',
                key: 'status',
                lastSort: 'ASC',
                showSort: false,
                sortActivated: false

            }, {
                name: 'Name',
                trl: 'guestlist_name',
                class: 'name',
                key: 'lastName',
                lastSort: 'ASC',
                isOrdered: $scope.isOrdered === true && $scope.pointerCursor === true ? true : false,
                sortActivated: false

            }, {
                name: 'Company',
                trl: 'guestlist_company',
                class: 'company',
                key: 'company',
                lastSort: 'ASC',
                sortActivated: false

            }, {
                name: 'Position',
                trl: 'guestlist_job_title',
                class: 'jobTitle',
                key: 'jobTitle',
                show: $scope.group.properties && $scope.group.properties.invite && $scope.group.properties.invite.hideActionsColums === true ? true : false,

                lastSort: 'ASC',
                sortActivated: false

            }, {
                name: 'Nr of Seats',
                trl: 'guestlist_seats',
                class: 'nrofseats',
                key: 'nrofseats',
                lastSort: 'ASC',
                show: $scope.showSeats,
                sortActivated: false

            }, {
                name: 'Last Rsvp',
                trl: 'guestlist_last_rsvp',
                class: 'rsvp',
                key: 'lastRsvp',
                lastSort: 'ASC',
                sortActivated: false

            }, {
                name: 'Attendance',
                trl: 'guestlist_attendance',
                class: 'attendance',
                key: 'attendance',
                lastSort: 'ASC',
                show: $scope.group.properties && $scope.group.properties.invite && $scope.group.properties.invite.hideActionsColums === true ? false : true,
                sortActivated: false

            }, {
                name: 'Cost/Contact YTD',
                trl: 'guestlist_cost_contact',
                class: 'contact',
                key: 'contactCost',
                lastSort: 'ASC',
                sortActivated: false

            }, {
                name: 'Actions',
                trl: 'guestlist_actions',
                class: 'notes',
                show: $scope.group.properties && $scope.group.properties.invite && $scope.group.properties.invite.hideActionsColums === true ? false : true,
                lastSort: 'ASC',
                showSort: false,
                sortActivated: false
            }, {
                name: 'Edit',
                trl: 'guestlist_actions_edit',
                class: 'edit',
                show: $scope.showColumnEdit === true ? false : true,
                lastSort: 'ASC',
                showSort: false,
                sortActivated: false
            }];

            function resetActivatedSort() {
                $scope.labels.map(function (label) {
                    label.sortActivated = false;
                    return label;
                });
            }
            $scope.sortBy = function (label) {
                label.loading = true;
                resetActivatedSort();
                label.sortActivated = true;

                if (label.isOrdered === true && label.key === "lastName" && label.key != 'status' && label.name != 'Actions') {
                    if (label.lastSort === 'ASC') {
                        $scope.fetch('', label.key + ':DESC:nullsLast').then(function () {
                            label.loading = false;
                            label.lastSort = 'DESC';
                        });
                    } else {
                        $scope.fetch('', label.key + ':ASC:nullsLast').then(function () {
                            label.loading = false;
                            label.lastSort = 'ASC';
                        });
                    }
                }
            };

            $scope.getIconClass = function (label) {
                if (label.loading) {
                    return 'fa-spinner fa-pulse';
                }
                if (label.lastSort === 'ASC') {
                    return 'fa-sort-amount-asc';
                }
                return 'fa-sort-amount-desc';
            };
        }
    };
});