'use strict';

angular.module('app').filter('strsMoment', function (MomentService) {
  var DATE_SLICE_IDX = 23;

  return function (inputDate, dateFormat, upperCase, useStdZone) {
    if (!inputDate) {
      return '';
    }

    if (!useStdZone) {
      inputDate = inputDate.slice(0, DATE_SLICE_IDX);
    }

    var str = MomentService.getMoment()(inputDate).format(dateFormat);

    if (upperCase) {
      str = str.substr(0, dateFormat.length).toUpperCase();
    }

    return str;
  };
});