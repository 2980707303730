/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-vars */
/* eslint-disable no-fallthrough */
/* eslint-disable no-use-before-define */
'use strict';

angular.module('app').controller('main_guestlist_GuestListController', function (waitinglistId, waitinglist, $stateParams, me, CheckoutService, StrsIdentity, AppService, WIZARD_STEPS, surveyInformation, surveyCheckoutInformation, CHECKOUT_STEPS, CHECKOUT_STATUS, ATTENDEE_SAVING_ERRORS, FanApiService, FanGroupService, MomentService, WaitinglistService, UtilService, TranslationService, toastr, $state, SurveyService, $scope, $modal, $window) {

    var vm = this;

    vm.isContest = waitinglist.displayMode === 'CONTEST';
    vm.isDetailed = waitinglist.displayMode === 'DETAILED';
    vm.isPickMode = waitinglist.positionsDistributionMode === 'wl_positions_distribution_mode_pick';

    // Flags
    $scope.loading = false;
    $scope.reserveCollapsed = false;
    $scope.step = 'SELECTING';
    vm.identity = StrsIdentity.cachedEntities;
    // Arrays
    $scope.guestlist = [];
    $scope.filterableGuestList = [];
    $scope.selectedGuestList = [];
    $scope.optionnalGuestList = [];
    $scope.inviteList = [];
    $scope.salesList = [];
    $scope.questions = [];
    $scope.requiredGuestList = [];

    // Object
    $scope.group = AppService.getCurrentGroup() || FanGroupService.getPublicFanGroup();
    $scope.groupId = $scope.group.id;
    $scope.waitingList = waitinglist;
    $scope.wlPeriod = WaitinglistService.generateWaitinglistPeriod(waitinglist);

    //Boolean
    $scope.iskipConfirmationPopUp = FanGroupService.getPublicFanGroup() && FanGroupService.getPublicFanGroup().properties && FanGroupService.getPublicFanGroup().properties.invite && FanGroupService.getPublicFanGroup().properties.invite.skipConfirmationPopUp;
    $scope.isSurveyToShow = false;

    // Mutable Object
    $scope.additionalInfos = undefined;
    $scope.searchGuestQuery = undefined;

    // Count
    $scope.totalSelected = 0;
    $scope.totalCost = 0;
    $scope.ticketsCnt = 0;
    $scope.ticketsDemanded = $stateParams.nrOfSeats || 0;
    $scope.initTicketsDemanded = $stateParams.nrOfSeats || 0;
    $scope.page = 0;
    $scope.pageSize = 20;
    $scope.sort = '';

    // Drag&Drop
    $scope.sortableOptions = {
        'ui-preserve-size': true,
        axis: 'y',
        items: 'li:not(.not-sortable)'
    };

    // Translations

    init();

    // INITIALIZATION

    function init() {
        initTheming();
        getData();
        initIdentity();
        isSurveyToShow();
    }

    function isSurveyToShow() {
        return SurveyService.getWaitingListSurveyQuestions(waitinglistId, 'BEFORE_JOINING_WAITINGLIST').then(function (information) {
            if (information.length > 0) {
                $scope.isSurveyToShow = true;
            }
        });
    }

    function initIdentity() {
        return StrsIdentity.get().then(function (identity) {
            if (identity.me) {
                var monthInMs = 2592000000;
                vm.showPasswordReset = new Date(identity.me.passwordExpirationDate) - new Date() <= monthInMs && new Date(identity.me.passwordExpirationDate) - new Date() > 0;
            }
            if (identity.currentGroup) {
                return initialize(identity);
            }

            return initializeDefault(identity);
        });
    }

    function initialize(userIdentity) {
        vm.me = userIdentity.me;
        vm.isRoleHost = vm.me.roles.filter(function (role) {
            return role === 'HOST';
        }).length > 0;
        vm.isRoleApprover = vm.me.roles.filter(function (role) {
            return role === 'APPROVER';
        }).length > 0;
        vm.fanGroup = userIdentity.currentGroup;
        vm.groupSlug = userIdentity.currentGroup.slug;
        vm.myGroups = userIdentity.myGroups;
        vm.myWishListsTotal = userIdentity.myWishLists.filter(function (item) {
            return item.groupId === FanGroupService.getPublicFanGroup().fanGroupId;
        }).length;
        vm.mySeatsTotal = userIdentity.mySeats.filter(function (item) {
            return item.groupId === FanGroupService.getPublicFanGroup().fanGroupId;
        }).length;
        vm.loaded = true;
    }

    function initializeDefault(noUserIdentity) {
        vm.fanGroup = noUserIdentity.publicFanGroup;
        vm.loaded = true;
    }

    function initTheming() {
        var Color = $window.net.brehaut.Color;
        $scope.groupColor = $scope.group.color;
        var complementaryScheme = Color($scope.groupColor).tetradicScheme($scope.groupColor);
        var complementaryColor = complementaryScheme[3].toRGB();
        $scope.complementaryColor = 'rgb(' + complementaryColor.red * 256 + ',' + complementaryColor.green * 256 + ',' + complementaryColor.blue * 256 + ')';
    }

    function getData() {
        reset();
        fetchGuestList().then(mapHostAsGuest).then(fetchInviteList).then(mapInvitesWithComments).then(mapGuestsWithInvites).then(addHostInSelectedGuestList).then(getSurveyAtGuestlist).then(stopLoading).catch(console.log);
    }

    function reset() {
        $scope.filterableGuestList = [];
        $scope.selectedGuestList = [];
        $scope.optionnalGuestList = [];
        $scope.loading = true;
    }

    function fetchGuestList(query, sort) {
        if (sort) {
            $scope.sort = sort;
            $scope.isOrder = true;
        }
        return FanApiService.fetchGuestList($scope.groupId, query, $scope.page, sort || $scope.sort).then(function (result) {
            $scope.guestlist = result.content;
            $scope.totalGuests = result.totalElements;
            $scope.filterableGuestList = result.content.map(function (guest) {
                guest.numberOfSeats = 1;
                return guest;
            });
        });
    }

    $scope.fetchGuestList = function (query, sort) {
        return fetchGuestList(query, sort);
    };

    function mapHostAsGuest() {
        return FanApiService.meAsHost().then(function (host) {
            $scope.hostAsGuest = {
                firstName: host.firstName,
                lastName: host.lastName,
                guestEmail: host.email,
                guestPhone: host.phone,
                guestId: host.userId,
                numberOfSeats: 0,
                importance: 'REQUIRED',
                host: true
            };
            $scope.filterableGuestList.push($scope.hostAsGuest);
        });
    }

    function fetchInviteList() {
        return FanApiService.fetchInviteList(waitinglistId).then(function (inviteList) {
            $scope.inviteList = inviteList.content;
            return $scope.inviteList;
        });
    }

    function mapInvitesWithComments(inviteList) {
        return Promise.all(inviteList.map(function (invite) {
            return FanApiService.getCommentOfInvite(invite.id).catch(console.log);
        })).then(function (comments) {
            var hostInvite = undefined;
            $scope.inviteList.map(function (invite) {
                comments.forEach(function (comment) {
                    if (comment.content.length > 0 && comment.content[0].waitingListInvitationId === invite.id) {
                        invite.notes = comment.content;
                    }
                });
                if (invite.numberOfNonAllocatedSeats > 0) {
                    hostInvite = _.cloneDeep(invite);
                }
                return invite;
            });
            return $scope.inviteList;
        });
    }

    function mapGuestsWithInvites(inviteList) {
        $scope.filterableGuestList = $scope.filterableGuestList.map(function (guest) {
            $scope.inviteList.forEach(function (invite) {
                if (invite.invitationReason) {
                    $scope.invitationReason = invite.invitationReason;
                }
                if (invite.guest.guestId === guest.guestId) {
                    invite.linked = true;
                    mapGuestWithInvite(guest, invite);
                    if (!isGuestInSelectedList(guest) && invite.status !== 'CANCELLED' && invite.importance === 'REQUIRED') {
                        if (guest.host) {
                            $scope.selectedGuestList.splice(0, 0, guest);
                        } else {
                            $scope.selectedGuestList.push(guest);
                        }
                    }
                    if (guest.importance === 'OPTIONAL' && !isGuestInOptionnalList(guest) && invite.status !== 'CANCELLED') {
                        $scope.optionnalGuestList.push(guest);
                    }
                }
            });
            return guest;
        });

        // Apply for the invites that haven't found their guest
        $scope.inviteList.filter(function (invite) {
            return !invite.linked && invite.status !== 'CANCELLED' && !isGuestInSelectedList(invite.guest);
        }).forEach(function (invite) {
            mapGuestWithInvite(invite.guest, invite);
            if (invite.importance === 'REQUIRED') {
                $scope.selectedGuestList.push(invite.guest);
            } else {
                $scope.optionnalGuestList.push(invite.guest);
            }
        });
    }

    function mapGuestWithInvite(guest, invite) {
        guest.invited = true;
        guest.selected = guest.host || invite.status !== 'CANCELLED' && invite.status !== 'REJECTED';
        guest.importance = invite.status === 'CANCELLED' || invite.status === 'REJECTED' ? null : invite.importance;
        guest.inviteStatus = invite.status;
        guest.position = invite.position;
        guest.nonAllocatedSeatsStatus = invite.nonAllocatedSeatsStatus;
        guest.inviteId = invite.id;
        guest.order = invite.order;
        guest.notes = invite.notes;
        guest.invitationReason = invite.invitationReason;
        guest.rejectionReason = invite.rejectionReason;
        guest.numberOfSeats = invite.nbrOfRequestedSeats;
        guest.numberOfNonAllocatedSeats = guest.host ? invite.numberOfNonAllocatedSeats : 0;
    }

    function isGuestInSelectedList(guestToFind) {
        return $scope.selectedGuestList.filter(function (guest) {
            return guest.guestId === guestToFind.guestId;
        }).length === 1;
    }

    function isGuestInOptionnalList(guestToFind) {
        return $scope.optionnalGuestList.filter(function (guest) {
            return guest.guestId === guestToFind.guestId;
        }).length === 1;
    }

    function addHostInSelectedGuestList() {
        if (!isHostInSelectedGuestList()) {
            $scope.hostAsGuest.selected = true;
            $scope.hostAsGuest.importance = 'REQUIRED';
            $scope.hostAsGuest.numberOfSeats = $scope.ticketsDemanded > 0 ? 1 : 0;
            $scope.hostAsGuest.numberOfNonAllocatedSeats = $scope.ticketsDemanded > 0 ? $scope.ticketsDemanded - 1 : 0;
            $scope.hostAsGuest.selected = true;
            $scope.selectedGuestList.push($scope.hostAsGuest);
        }
    }

    function isHostInSelectedGuestList() {
        return $scope.selectedGuestList.filter(function (guest) {
            return guest.guestEmail === $scope.hostAsGuest.guestEmail;
        }).length > 0;
    }

    function getSurveyAtGuestlist() {
        return SurveyService.getSurveys(waitinglistId, 'AT_GUEST_INVITATION', waitinglist.groupId).then(function (surveyInstances) {
            if (surveyInstances.length >= 1) {
                $scope.questions = surveyInstances[0].survey.surveyQuestions.map(function (question) {
                    return {
                        label: TranslationService.tk(question.question.text),
                        id: question.question.id
                    };
                });
                $scope.surveyInstanceId = surveyInstances[0].id;
            }
        });
    }

    function stopLoading() {
        $scope.loading = false;
    }

    // TOGGLE GUEST

    $scope.toggleGuest = function (guest) {
        switch (guest.importance) {
            case 'REQUIRED':
                {
                    $scope.hostAsGuest.numberOfNonAllocatedSeats += 1;
                }
            case 'OPTIONAL':
                {
                    guest.importance = null;
                    guest.numberOfSeats = 0;
                    removeFromSelectedList(guest);
                    removeFromOptionnalList(guest);
                    break;
                }
            default:
                {
                    if (guest.numberOfSeats === 0) {
                        guest.numberOfSeats = 1;
                    }
                    guest.importance = $scope.getTotalTicketsAvailable() > 0 ? 'REQUIRED' : 'OPTIONAL';
                    $scope.hostAsGuest.numberOfNonAllocatedSeats -= guest.importance === 'REQUIRED' ? 1 : 0;
                    if (guest.importance === 'REQUIRED') {
                        $scope.selectedGuestList.push(guest);
                    }
                    if (guest.importance === 'OPTIONAL') {
                        $scope.optionnalGuestList.push(guest);
                    }
                }
        }
        document.getElementById("nonAllocatedSeats").value = $scope.hostAsGuest.numberOfNonAllocatedSeats;
    };

    function removeFromSelectedList(guestToRemove) {
        $scope.selectedGuestList = $scope.selectedGuestList.filter(function (guest) {
            return guest.guestId !== guestToRemove.guestId;
        });
    }

    function removeFromOptionnalList(guestToRemove) {
        $scope.optionnalGuestList = $scope.optionnalGuestList.filter(function (guest) {
            return guest.guestId !== guestToRemove.guestId;
        });
    }

    // INCREMENT/DECREMENT NR OF SEATS

    var oldNumberOfNonAllocatedSeats = 0;
    var oldGuestNumberOfSeats = 0;
    var nonAllocatedSeatsTreatment = false;

    $scope.handleNrSeatsChange = function (direction, guest, number) {

        if (number === undefined) {
            number = 1;
        }

        // Tickets non alloués
        if (direction === null && guest === null) {
            nonAllocatedSeatsTreatment = true;
            if ($scope.hostAsGuest.numberOfNonAllocatedSeats > number) {
                direction = 'DECREMENT';
            } else if ($scope.hostAsGuest.numberOfNonAllocatedSeats < number) {
                direction = 'INCREMENT';
            }
            oldNumberOfNonAllocatedSeats = $scope.hostAsGuest.numberOfNonAllocatedSeats;
            $scope.hostAsGuest.numberOfNonAllocatedSeats = number;

            //Tickets du gest
        } else if (direction === null) {
            if (guest.numberOfSeats > number) {
                direction = 'DECREMENT';
            } else if (guest.numberOfSeats < number) {
                direction = 'INCREMENT';
            }
            oldNumberOfNonAllocatedSeats = $scope.hostAsGuest.numberOfNonAllocatedSeats;
            oldGuestNumberOfSeats = guest.numberOfSeats;
            guest.numberOfSeats = number;
        }

        if (direction === 'INCREMENT') {
            if (guest) {
                if (guest.importance === 'REQUIRED') {
                    if (guest.numberOfSeats != number) {
                        guest.numberOfSeats += number;
                    } else if (number === 1) {
                        guest.numberOfSeats = number - oldGuestNumberOfSeats;
                    }
                    nonAllocatedSeatsTreatment = true;
                    $scope.handleNrSeatsChange('DECREMENT', null, number);
                }
            } else {
                $scope.ticketsDemanded += number - oldNumberOfNonAllocatedSeats;
                if (number != $scope.hostAsGuest.numberOfNonAllocatedSeats || guest != null) {
                    $scope.hostAsGuest.numberOfNonAllocatedSeats += number;
                }
            }
        } else if (direction === 'DECREMENT') {
            if (guest) {
                if (guest.importance === 'REQUIRED') {
                    if (guest.numberOfSeats === 0) {
                        if (!guest.host) {
                            guest.selected = false;
                            $scope.toggleGuest(guest);
                        } else {
                            $scope.hostAsGuest.numberOfNonAllocatedSeats += 1;
                        }
                    } else {
                        if (guest.numberOfSeats != number) {
                            guest.numberOfSeats -= number;
                        }
                        if (number != $scope.hostAsGuest.numberOfNonAllocatedSeats || guest != null) {
                            $scope.hostAsGuest.numberOfNonAllocatedSeats += oldGuestNumberOfSeats - number;
                        }
                    }
                }
            } else if ($scope.hostAsGuest.numberOfNonAllocatedSeats > 0) {
                if (number != $scope.hostAsGuest.numberOfNonAllocatedSeats || guest != null) {
                    $scope.hostAsGuest.numberOfNonAllocatedSeats -= number - oldGuestNumberOfSeats;
                    nonAllocatedSeatsTreatment = false;
                } else if (number === $scope.hostAsGuest.numberOfNonAllocatedSeats && nonAllocatedSeatsTreatment) {
                    $scope.hostAsGuest.numberOfNonAllocatedSeats -= number - $scope.hostAsGuest.numberOfNonAllocatedSeats;
                }
                if (guest != null && guest != undefined) {
                    $scope.ticketsDemanded -= number + oldNumberOfNonAllocatedSeats;
                } else if (nonAllocatedSeatsTreatment) {
                    $scope.ticketsDemanded -= oldNumberOfNonAllocatedSeats - number;
                }
            }
        }

        document.getElementById("nonAllocatedSeats").value = $scope.hostAsGuest.numberOfNonAllocatedSeats;
        nonAllocatedSeatsTreatment = false;
    };

    // FETCH MORE

    $scope.seeMore = function () {
        $scope.page += 1;
        $scope.moreLoading = true;
        FanApiService.fetchGuestList($scope.groupId, $scope.searchGuestQuery, $scope.page, $scope.sort).then(function (result) {
            result.content.forEach(function (guest) {
                $scope.guestlist.push(guest);
                guest.numberOfSeats = 1;
                $scope.filterableGuestList.push(guest);
            });
            mapGuestsWithInvites();
            $scope.moreLoading = false;
        });
    };

    $scope.searchGuest = function (query) {
        var minStringLenthToSearch = 3;
        if (query.length >= minStringLenthToSearch) {
            $scope.searching = true;
            fetchGuestList(query).then(mapGuestsWithInvites).then(mapGuestsWithSelected).then(function () {
                $scope.searching = false;
                $scope.searchMode = true;
            });
        } else if (query.length === 0) {
            $scope.searchMode = false;
            $scope.page = 0;
            fetchGuestList().then(mapGuestsWithInvites).then(mapGuestsWithSelected);
        }
    };

    function mapGuestsWithSelected() {
        $scope.filterableGuestList = $scope.filterableGuestList.map(function (guest) {
            $scope.selectedGuestList.forEach(function (selectedGuest) {
                if (selectedGuest.guestId === guest.guestId) {
                    guest = selectedGuest;
                }
            });
            return guest;
        });
    }

    // INVITATION/BUSINESS REASON

    $scope.invitationReason = '';

    $scope.addBusinessReason = function () {
        var invitationReason = $scope.invitationReason;
        var groupColor = $scope.groupColor;

        function setBusinessReason(reason) {
            $scope.invitationReason = reason;
        }

        if ($scope.hostAsGuest.numberOfNonAllocatedSeats > 0) {
            $modal.open({
                templateUrl: 'routes/main/waitinglist/guestlist/business-reason.modal.html',
                windowClass: 'medium strs-modal-alt',
                // eslint-disable-next-line no-shadow
                controller: /* @ngInject */function controller($scope, FanApiService) {
                    $scope.invitationReason = invitationReason;
                    $scope.groupColor = groupColor;
                    $scope.confirm = function () {
                        setBusinessReason($scope.invitationReason);
                        $scope.$close();
                    };
                }
            });
        }
    };

    $scope.showRejectionReason = function (guest) {
        var groupColor = $scope.groupColor;
        $modal.open({
            templateUrl: 'routes/main/waitinglist/guestlist/invitation-reason.html',
            windowClass: 'medium strs-modal-alt',
            // eslint-disable-next-line no-shadow
            controller: /* @ngInject */function controller($scope, FanApiService) {
                $scope.title = 'Rejection Reason';
                $scope.reason = guest.rejectionReason || 'None';
                $scope.groupColor = groupColor;
            }
        });
    };

    // INVITE COMMENT

    $scope.postNewNote = function (guest) {
        if (!guest.notes) {
            guest.notes = [];
        }
        guest.notes.push({
            comment: guest.note,
            commentator: {
                id: $scope.hostAsGuest.guestId,
                lastName: $scope.hostAsGuest.lastName
            },
            new: true
        });
        guest.note = '';
        if (guest.invited) {
            addCommentsToInvite(guest.inviteId, guest);
        }
    };

    function addCommentsToInvite(inviteId, guest) {
        if (guest.notes) {
            return Promise.all(guest.notes.filter(function (note) {
                return note.new;
            }).map(function (note) {
                return FanApiService.postCommentToInvite(inviteId, note.comment).catch(console.log);
            }));
        }
        return null;
    }

    $scope.commentOnGuest = function (guest) {
        $scope.showNote = true;
        $scope.guestToComment = guest;
    };

    $scope.hideNote = function () {
        $scope.showNote = false;
    };

    // LIST

    $scope.getRequiredGuestList = function () {
        return $scope.selectedGuestList;
    };

    $scope.getNotSelectedGuests = function () {
        return $scope.filterableGuestList.filter(function (guest) {
            return !guest.selected && (!guest.invited || guest.invited && guest.inviteStatus === 'CANCELLED');
        });
    };

    // UTILS

    $scope.collapseReserve = function () {
        $scope.reserveCollapsed = !$scope.reserveCollapsed;
    };

    $scope.goBack = function () {
        $state.go('app.main.waitinglist', {
            waitinglistId: waitinglistId,
            slug: $scope.group.slug || '_'
        });
    };

    // STATS

    $scope.getTotalTicketsAvailable = function () {
        return $scope.hostAsGuest ? $scope.hostAsGuest.numberOfNonAllocatedSeats : 0;
    };

    $scope.getTotalBudgetCost = function () {
        return $scope.getTotalTicketsSelected() * Number(waitinglist.price.originalPrice) + '€';
    };

    $scope.getTotalTicketsSelected = function () {
        return $scope.selectedGuestList.reduce(function (acc, guest) {
            if (guest.selected && guest.importance === 'REQUIRED') {
                if (guest.numberOfNonAllocatedSeats) {
                    return acc + guest.numberOfSeats + guest.numberOfNonAllocatedSeats;
                }
                return acc + guest.numberOfSeats;
            }
            return acc;
        }, 0);
    };

    $scope.getTotalGuestinGuestlist = function () {
        return $scope.selectedGuestList.filter(function (guest) {
            return guest.importance === 'REQUIRED';
        }).length;
    };

    // CONFIRM

    $scope.canConfirm = function () {
        return $scope.selectedGuestList.length + $scope.optionnalGuestList.length > 0 && !$scope.loading;
    };

    $scope.confirmSelectionWithModal = function () {
        function confirm(mailStep) {
            $scope.activateCommunicationAsNextstep = mailStep;
            if ($scope.isSurveyToShow === true) {
                return SurveyService.fulfillSurveyRequirements(waitinglistId, 'BEFORE_JOINING_WAITINGLIST', null, vm.mySeatsTotal).then(function () {
                    return SurveyService.getWaitingListSurveyQuestions(waitinglistId, 'BEFORE_JOINING_WAITINGLIST').then(function (information) {
                        //   console.log(information);
                        return confirmGueslist();
                    });
                });
            }

            return confirmGueslist();
        }

        var invitationReason = $scope.invitationReason;

        var guestList = $scope.selectedGuestList;
        var numberOfNonAllocatedSeats = $scope.hostAsGuest.numberOfNonAllocatedSeats;
        var groupColor = $scope.groupColor;
        var groupId = $scope.groupId;
        var surveyInstanceId = $scope.surveyInstanceId;
        var waitingList = waitinglist;
        var questions = $scope.questions;
        $modal.open({
            templateUrl: 'routes/main/waitinglist/guestlist/confirmation-selection-modal.html',
            windowClass: 'medium strs-modal-alt',
            // eslint-disable-next-line no-shadow
            controller: /* @ngInject */function controller($scope, FanApiService, FanGroupService, TranslationService) {
                $scope.invitationReason = invitationReason;
                $scope.isCommunicationCustom = waitingList.assignationMode !== 'NO_RSVP' && waitingList.assignationMode !== 'HOST_RSVP';
                var fgLook = FanGroupService.getPublicFanGroup();
                $scope.isPublicPropertiesDisabled = fgLook && fgLook.properties && fgLook.properties.invite && fgLook.properties.invite.publicOfficialDisabled;
                $scope.numberOfNonAllocatedSeats = numberOfNonAllocatedSeats;
                $scope.groupColor = groupColor;
                $scope.guestList = guestList;
                $scope.questions = questions;
                $scope.isSurveyLinked = Boolean(surveyInstanceId);
                $scope.activateCommunicationAsNextstep = false;
                $scope.tk = TranslationService.tk;
                function initSurvey() {
                    var isOneWithoutAnswer = false;
                    Promise.all($scope.guestList.map(function (guest) {
                        return FanApiService.getSurveyAnswerForHost(surveyInstanceId, guest.guestId).then(function (result) {
                            if (result.content.length === 0) {
                                isOneWithoutAnswer = true;
                            }
                            result.content.forEach(function (question) {
                                guest.answer = {};
                                var answer = question.answers[0].answerText;
                                if (!answer) {
                                    isOneWithoutAnswer = true;
                                }
                                guest.answer[question.questionId] = answer;
                                guest.previouslyAnswered = !!answer;
                            });
                            return isOneWithoutAnswer;
                        });
                    })).then(function (res) {
                        if (isOneWithoutAnswer === true) {
                            $scope.step = 'SURVEY';
                            $scope.surveyFirstPage = true;
                        } else {
                            $scope.step = 'CONFIRM';
                        }
                        $scope.$apply();
                    });
                }

                $scope.goToSurveyStep = function () {
                    $scope.step = 'SURVEY';
                };

                $scope.step = 'LOADING';
                if (numberOfNonAllocatedSeats > 0 && !invitationReason) {
                    $scope.step = 'INVITATION_REASON';
                } else if (questions.length > 0) {
                    initSurvey();
                } else {
                    $scope.step = 'CONFIRM';
                }

                $scope.setNextStep = function (isCommunicationNextStep) {
                    $scope.activateCommunicationAsNextstep = isCommunicationNextStep;
                };

                $scope.confirm = function () {
                    confirm($scope.activateCommunicationAsNextstep);
                    $scope.$close();
                };

                $scope.answerForAll = '';
                $scope.handleChange = function (answerForAll) {
                    var questionId = $scope.questions[0].id;
                    $scope.guestList.forEach(function (guest) {
                        if (!guest.previouslyAnswered) {
                            if (!guest.answer) {
                                guest.answer = {};
                            }
                            guest.answer[questionId] = answerForAll;
                        }
                    });
                };

                $scope.backToConfirm = function () {
                    $scope.step = 'CONFIRM';
                };

                $scope.isPublicOfficial = function (guest) {
                    if (guest.customInfos) {
                        var publics = guest.customInfos.filter(function (customInfo) {
                            return customInfo.semanticName === 'public-official';
                        });
                        if (publics.length > 0) {
                            return publics[0].informationValue;
                        }
                    }

                    return false;
                };

                $scope.confirmPublicOfficial = function () {
                    Promise.all($scope.guestList.filter(function (guest) {
                        return guest.public;
                    }).map(function (guest) {
                        var customInfo = {
                            informationValue: true,
                            semanticName: 'public-official'
                        };
                        return FanApiService.addCustomInfo(groupId, guest.guestId, customInfo);
                    })).then(function (result) {
                        $scope.backToConfirm();
                    });
                };

                $scope.getSelectedNumber = function () {
                    return $scope.guestList.filter(function (guest) {
                        return guest.public;
                    }).length;
                };

                $scope.goToPublicStep = function () {
                    $scope.step = 'PUBLIC_OFFICIAL';
                };

                $scope.goToConfirm = function () {
                    $scope.step = 'CONFIRM';
                    $scope.surveyFirstPage = false;
                };

                $scope.confirmSurvey = function () {
                    Promise.all($scope.guestList.map(function (guest) {
                        var answers = [];
                        questions.forEach(function (question) {
                            var answer = {
                                answers: [{
                                    answerText: guest.answer ? guest.answer[question.id] : ''
                                }],
                                questionId: question.id,
                                surveyInstanceId: surveyInstanceId
                            };
                            answers.push(answer);
                        });
                        return FanApiService.answerSurvey(surveyInstanceId, guest.guestId, answers);
                    })).then(function (result) {
                        $scope.goToConfirm();
                    });
                };
            }
        });
    };

    function getInvites() {
        FanApiService.fetchInviteList(waitinglistId).then(function (result) {
            vm.inviteList = result.content;

            vm.numberOfInvitesApproved = result.content.filter(function (invite) {
                return invite.status === 'APPROVED';
            }).length;
            vm.numberOfInvitesPending = result.content.filter(function (invite) {
                return invite.status === 'PENDING';
            }).length;
            vm.numberOfInvitesRejected = result.content.filter(function (invite) {
                return invite.status === 'REJECTED';
            }).length;
        });
    }

    $scope.confirmSelectionWithSurvey = function (e) {
        return SurveyService.fulfillSurveyRequirements(waitinglistId, 'BEFORE_JOINING_WAITINGLIST', null, vm.mySeatsTotal).then(function () {
            return SurveyService.getWaitingListSurveyQuestions(waitinglistId, 'BEFORE_JOINING_WAITINGLIST').then(function (information) {
                //   console.log(information);
                return $scope.confirmSelectionWithoutModal();
            });
        });
    };

    $scope.confirmSelectionWithoutModal = function () {

        function confirm(mailStep) {
            $scope.activateCommunicationAsNextstep = mailStep;
            if ($scope.isSurveyToShow === true) {
                return SurveyService.fulfillSurveyRequirements(waitinglistId, 'BEFORE_JOINING_WAITINGLIST', null, vm.mySeatsTotal).then(function () {
                    return SurveyService.getWaitingListSurveyQuestions(waitinglistId, 'BEFORE_JOINING_WAITINGLIST').then(function (information) {
                        //   console.log(information);
                        return confirmGueslist();
                    });
                });
            }

            return confirmGueslist();
        }

        var invitationReason = $scope.invitationReason;

        var guestList = $scope.selectedGuestList;
        var numberOfNonAllocatedSeats = $scope.hostAsGuest.numberOfNonAllocatedSeats;
        var groupColor = $scope.groupColor;
        var groupId = $scope.groupId;
        var surveyInstanceId = $scope.surveyInstanceId;
        var waitingList = waitinglist;
        var questions = $scope.questions;

        /* @ngInject */function test($scope, FanApiService, FanGroupService, TranslationService) {

            $scope.invitationReason = invitationReason;
            $scope.isCommunicationCustom = waitingList.assignationMode !== 'NO_RSVP' && waitingList.assignationMode !== 'HOST_RSVP';
            var fgLook = FanGroupService.getPublicFanGroup();
            $scope.isPublicPropertiesDisabled = fgLook && fgLook.properties && fgLook.properties.invite && fgLook.properties.invite.publicOfficialDisabled;
            $scope.numberOfNonAllocatedSeats = numberOfNonAllocatedSeats;
            $scope.groupColor = groupColor;
            $scope.guestList = guestList;
            $scope.questions = questions;
            $scope.isSurveyLinked = Boolean(surveyInstanceId);
            $scope.activateCommunicationAsNextstep = false;
            $scope.tk = TranslationService.tk;
            function initSurvey() {
                var isOneWithoutAnswer = false;
                Promise.all($scope.guestList.map(function (guest) {
                    return FanApiService.getSurveyAnswerForHost(surveyInstanceId, guest.guestId).then(function (result) {
                        if (result.content.length === 0) {
                            isOneWithoutAnswer = true;
                        }
                        result.content.forEach(function (question) {
                            guest.answer = {};
                            var answer = question.answers[0].answerText;
                            if (!answer) {
                                isOneWithoutAnswer = true;
                            }
                            guest.answer[question.questionId] = answer;
                            guest.previouslyAnswered = !!answer;
                        });
                        return isOneWithoutAnswer;
                    });
                })).then(function (res) {
                    if (isOneWithoutAnswer === true) {
                        $scope.step = 'SURVEY';
                        $scope.surveyFirstPage = true;
                    } else {
                        $scope.step = 'CONFIRM';
                    }
                    $scope.$apply();
                });
            }

            $scope.goToSurveyStep = function () {
                $scope.step = 'SURVEY';
            };

            $scope.step = 'LOADING';
            if (numberOfNonAllocatedSeats > 0 && !invitationReason) {
                $scope.step = 'INVITATION_REASON';
            } else if (questions.length > 0) {
                initSurvey();
            } else {
                $scope.step = 'CONFIRM';
            }

            $scope.setNextStep = function (isCommunicationNextStep) {
                $scope.activateCommunicationAsNextstep = isCommunicationNextStep;
            };

            $scope.confirm = function () {
                confirm($scope.activateCommunicationAsNextstep);
                $scope.$close();
            };

            $scope.answerForAll = '';
            $scope.handleChange = function (answerForAll) {
                var questionId = $scope.questions[0].id;
                $scope.guestList.forEach(function (guest) {
                    if (!guest.previouslyAnswered) {
                        if (!guest.answer) {
                            guest.answer = {};
                        }
                        guest.answer[questionId] = answerForAll;
                    }
                });
            };

            $scope.backToConfirm = function () {
                $scope.step = 'CONFIRM';
            };

            $scope.isPublicOfficial = function (guest) {
                if (guest.customInfos) {
                    var publics = guest.customInfos.filter(function (customInfo) {
                        return customInfo.semanticName === 'public-official';
                    });
                    if (publics.length > 0) {
                        return publics[0].informationValue;
                    }
                }

                return false;
            };

            $scope.confirmPublicOfficial = function () {
                Promise.all($scope.guestList.filter(function (guest) {
                    return guest.public;
                }).map(function (guest) {
                    var customInfo = {
                        informationValue: true,
                        semanticName: 'public-official'
                    };
                    return FanApiService.addCustomInfo(groupId, guest.guestId, customInfo);
                })).then(function (result) {
                    $scope.backToConfirm();
                });
            };

            $scope.getSelectedNumber = function () {
                return $scope.guestList.filter(function (guest) {
                    return guest.public;
                }).length;
            };

            $scope.goToPublicStep = function () {
                $scope.step = 'PUBLIC_OFFICIAL';
            };

            $scope.goToConfirm = function () {
                $scope.step = 'CONFIRM';
                $scope.surveyFirstPage = false;
            };

            $scope.confirmSurvey = function () {
                Promise.all($scope.guestList.map(function (guest) {
                    var answers = [];
                    questions.forEach(function (question) {
                        var answer = {
                            answers: [{
                                answerText: guest.answer ? guest.answer[question.id] : ''
                            }],
                            questionId: question.id,
                            surveyInstanceId: surveyInstanceId
                        };
                        answers.push(answer);
                    });
                    return FanApiService.answerSurvey(surveyInstanceId, guest.guestId, answers);
                })).then(function (result) {
                    $scope.goToConfirm();
                });
            };
            $scope.confirm();
        }
        test($scope, FanApiService, FanGroupService, TranslationService);
    };

    function confirmGueslist() {
        resetAllInviteOrders().then(function (res) {
            return inviteReserveList();
        }).then(function (res) {
            return inviteImportantList();
        }).then(function (importantList) {
            if ($scope.activateCommunicationAsNextstep) {
                $state.go('app.main.group.communication-list', {
                    inviteList: importantList,
                    waitingListId: waitinglistId
                });
            } else {
                $state.go('app.main.group.myinvites', {
                    waitinglistId: waitinglistId
                });
            }
        }).catch(function (err) {
            console.log('ERROR => ', err);
        });
    }

    function resetAllInviteOrders() {
        return Promise.all($scope.optionnalGuestList.filter(function (guest) {
            return guest.invited;
        }).map(function (guest) {
            guest.order = null;
            return FanApiService.editInvite(guest);
        }));
    }

    function inviteReserveList() {
        return Promise.all($scope.optionnalGuestList.map(function (guest, index) {
            guest.order = index;
            if (guest.invited) {
                if (guest.inviteStatus === 'CANCELLED') {
                    return FanApiService.inviteCancelled(guest.inviteId).then(function (invite) {
                        if (guest.note) {
                            FanApiService.postCommentToInvite(invite.id, guest.note);
                        }
                    });
                }
                return FanApiService.editInvite(guest).then(function (invite) {
                    if (guest.note) {
                        FanApiService.postCommentToInvite(invite.id, guest.note);
                    }
                });
            }
            return FanApiService.inviteGuest(guest, waitinglistId).then(function (invite) {
                if (guest.note) {
                    FanApiService.postCommentToInvite(invite.id, guest.note);
                }
            });
        }));
    }

    function inviteImportantList() {
        return Promise.all($scope.selectedGuestList.filter(function (guest) {
            return guest.selected && guest.importance === 'REQUIRED' && (guest.numberOfSeats > 0 || guest.numberOfNonAllocatedSeats > 0);
        }).map(function (guest, index) {
            guest.order = null;

            if (guest.host) {
                guest.invitationReason = $scope.invitationReason;
            }
            if (guest.invited) {
                if (guest.inviteStatus === 'CANCELLED') {
                    return FanApiService.inviteCancelled(guest.inviteId).then(function (invite) {
                        if (!guest.invited) {
                            addCommentsToInvite(invite.id, guest);
                        }
                        return invite;
                    });
                }
                return FanApiService.editInvite(guest).then(function (invite) {
                    if (!guest.invited) {
                        addCommentsToInvite(invite.id, guest);
                    }
                    return invite;
                });
            }
            return FanApiService.inviteGuest(guest, waitinglistId).then(function (invite) {
                if (!guest.invited) {
                    addCommentsToInvite(invite.id, guest);
                }
                return invite;
            });
        }));
    }

    $scope.cancelInvite = function (invite) {
        var groupColor = $scope.groupColor;
        $modal.open({
            templateUrl: 'routes/main/waitinglist/guestlist/confirmation-cancel-modal.html',
            windowClass: 'medium strs-modal-alt',
            // eslint-disable-next-line no-shadow
            controller: /* @ngInject */function controller($scope, FanApiService) {
                $scope.groupColor = groupColor;
                $scope.confirm = function () {
                    invite.selected = false;
                    FanApiService.cancelInvite(invite.inviteId).then(function (res) {
                        $scope.$close();
                        getData();
                    });
                };
            }
        });
    };

    // MODALS

    $scope.addNewGuest = function () {
        var groupColor = $scope.groupColor;
        var groupId = $scope.groupId;

        function addGuestToList(guest) {
            guest.numberOfSeats = 1;
            $scope.filterableGuestList.splice(0, 0, guest);
        }

        var questions = $scope.questions;
        var surveyInstanceId = $scope.surveyInstanceId;
        $modal.open({
            templateUrl: 'routes/main/waitinglist/guestlist/add-new-guest.modal.html',
            windowClass: 'medium strs-modal-alt',
            // eslint-disable-next-line no-shadow
            controller: /* @ngInject */function controller($scope, FanApiService) {
                $scope.groupColor = groupColor;

                $scope.languages = AppService.getLanguages();
                $scope.newGuest = {
                    lastName: '',
                    firstName: '',
                    email: '',
                    countryCallingCode: '',
                    localNumber: '',
                    lang: '',
                    company: ''
                };

                $scope.showError = false;

                $scope.surveyAnswer = {};

                $scope.questions = questions;

                $scope.confirm = function () {
                    FanApiService.createGuest($scope.newGuest, groupId).then(function (guest) {
                        var customInfo = {
                            informationValue: $scope.newGuest.company,
                            semanticName: 'company'
                        };
                        guest.customInfos = [customInfo];
                        addGuestToList(guest);
                        return guest;
                    }).then(function (guest) {
                        var customInfo = {
                            informationValue: $scope.newGuest.company,
                            semanticName: 'company'
                        };
                        if ($scope.newGuest.company) {
                            FanApiService.addCustomInfo(groupId, guest.guestId, customInfo);
                        }
                        var answers = [];
                        questions.forEach(function (question) {
                            var answer = {
                                answers: [{
                                    answerText: $scope.surveyAnswer[question.label] || ''
                                }],
                                questionId: question.id,
                                surveyInstanceId: surveyInstanceId
                            };
                            answers.push(answer);
                        });
                        if (surveyInstanceId) {
                            FanApiService.answerSurvey(surveyInstanceId, guest.guestId, answers);
                        }
                    }).then(function (surveyResult) {
                        $scope.$close();
                    }).catch(function (error) {
                        $scope.showError = true;
                    });
                };
            }
        });
    };

    $scope.importGuestList = function () {
        var groupColor = $scope.groupColor;
        var groupId = $scope.groupId;
        var guestList = $scope.filterableGuestList;
        var surveyInstanceId = $scope.surveyInstanceId;

        function addGuestToList(guest) {
            guest.numberOfSeats = 1;
            $scope.filterableGuestList.push(guest);
            guest.selected = true;
            toggleGuest(guest);
        }

        function toggleGuest(guest) {
            guest.numberOfSeats = 1;
            $scope.toggleGuest(guest);
        }

        function isGuestInList(guestToSearch) {
            return $scope.filterableGuestList.filter(function (guestToFind) {
                return guestToSearch.email === guestToFind.guestEmail;
            }).length > 0;
        }

        function selectGuest(guest) {
            $scope.filterableGuestList.forEach(function (guestToEdit) {
                if (guest.email === guestToEdit.guestEmail) {
                    guestToEdit.selected = true;
                }
            });
        }

        function getGuest(guest) {
            return $scope.filterableGuestList.filter(function (guestToEdit) {
                return guest.email === guestToEdit.guestEmail;
            })[0];
        }

        var questions = $scope.questions;

        $modal.open({
            templateUrl: 'routes/main/waitinglist/guestlist/import-guestlist.modal.html',
            windowClass: 'big strs-modal-alt',
            // eslint-disable-next-line no-shadow
            controller: /* @ngInject */function controller($scope, FanApiService) {
                $scope.groupColor = groupColor;

                $scope.languages = AppService.getLanguages();
                $scope.showError = false;
                $scope.newGuest = {
                    lastName: '',
                    firstName: '',
                    email: '',
                    countryCallingCode: '',
                    localNumber: '',
                    lang: '',
                    job: ''
                };

                $scope.triggerInputCsv = function () {
                    window.setTimeout(function () {
                        angular.element('#inputFile').trigger('click');
                    }, 0);
                };

                $scope.getTemplate = function () {
                    var csvContent = 'data:text/csv;charset=utf-8,';
                    var keys = ['firstName', 'lastName', 'mail', 'phone', 'company', 'lang'];
                    questions.forEach(function (question) {
                        keys.push(question.label);
                    });
                    $scope.keys = keys;
                    csvContent += keys.join(';') + '\r\n';
                    var encodedUri = encodeURI(csvContent);
                    var downloadLink = document.createElement('a');
                    downloadLink.href = encodedUri;
                    downloadLink.download = 'data.csv';
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                };

                $scope.removeLine = function (index) {
                    $scope.guestlist.splice(index, 1);
                };

                $scope.confirm = function () {
                    console.log("testConfirm");
                    Promise.all($scope.guestlist.map(function (guest, index) {
                        guest.email = guest.mail;
                        guest.guestEmail = guest.mail;
                        console.log(guest);
                        if (isGuestInList(guest)) {
                            selectGuest(guest);
                            toggleGuest(getGuest(guest));
                        } else {
                            return FanApiService.createGuest(guest, groupId).then(function (guestCreated) {
                                var customInfo = {
                                    informationValue: guest.company,
                                    semanticName: 'company'
                                };
                                guestCreated.customInfos = [customInfo];
                                addGuestToList(guestCreated);
                                return guestCreated;
                            }).then(function (guestCreated) {
                                var customInfo = {
                                    informationValue: guest.company,
                                    semanticName: 'company'
                                };
                                FanApiService.addCustomInfo(groupId, guestCreated.guestId, customInfo);
                            }).catch(function (e) {
                                if (!e.data.message) {
                                    var error = e.data.errors.errorsTypes.validation_errors[0];
                                    if (error.error.errorCode === 'api_guest_membership_already_exists') {
                                        guest.guestId = error.references[0];
                                        var customInfo = {
                                            informationValue: guest.company,
                                            semanticName: 'company'
                                        };
                                        guest.customInfos = [customInfo];
                                        addGuestToList(guest);
                                    }
                                }
                            });
                        }
                        return undefined;
                    })).then(function (result) {
                        var mailIndex = $scope.keys.indexOf('mail');
                        if (surveyInstanceId) {
                            $scope.csvResults.data.forEach(function (line, index) {
                                if (index > 0 && index < $scope.csvResults.data.length - 1) {
                                    guestList.forEach(function (guest) {
                                        if (guest.guestEmail === line[mailIndex]) {
                                            var answers = [];
                                            questions.forEach(function (question) {
                                                var answer = {
                                                    answers: [{
                                                        answerText: line[$scope.keys.indexOf(question.label)]
                                                    }],
                                                    questionId: question.id,
                                                    surveyInstanceId: surveyInstanceId
                                                };
                                                answers.push(answer);
                                            });
                                            FanApiService.answerSurvey(surveyInstanceId, guest.guestId, answers);
                                        }
                                    });
                                }
                            });
                        }

                        $scope.$close();
                    }.catch(function (error) {
                        console.log(error);
                    }));
                };

                $scope.uploadCSV = function (event) {
                    var data = event.target.files[0];
                    Papa.parse(data, {
                        complete: function complete(results) {
                            $scope.uploadCompleted = true;
                            $scope.keys = results.data[0];
                            $scope.csvResults = results;
                            $scope.guestlist = [];
                            results.data.forEach(function (line, indexRow) {
                                if (indexRow > 0 && indexRow < results.data.length) {
                                    var guest = {};
                                    results.data[0].forEach(function (key, indexCol) {
                                        guest[key] = line[indexCol];
                                    });
                                    $scope.guestlist.push(guest);
                                    $scope.$apply();
                                }
                            });
                        }
                    });
                };
            }
        });
    };
});