'use strict';

angular.module('app').controller('AuthenticateForgotPasswordVerifyController', function ($state, $stateParams, lodash, FanGroupService, URLService, SessionService, AuthApiService, Alert, AUTH_UPDATEPASSWORD_ERRORS, ValidationService, ANONYMOUS_FG) {
  /**
   *  CONSTANTS
   */
  var USER_DEFAULTS = {
    password: '',
    passwordConfirm: ''
  };

  /**
   *  PRIVATE VARIABLES
   */
  var queryParameters = URLService.getQueryParameters();

  /**
   *  PRIVATE FUNCTIONS
   */

  /**
   *  VM VARIABLES
   */
  var vm = this;
  vm.user = lodash.extend(USER_DEFAULTS, queryParameters);
  vm.fanGroup = FanGroupService.getPublicFanGroup();
  vm.loading = false;

  /**
   *  VM FUNCTIONS
   */
  vm.isSubmitDisabled = function (form) {
    return vm.loading;
  };

  vm.submit = function () {
    if (vm.user.password !== vm.user.passwordConfirm) {
      Alert.error('reset-password_passwords-dont-match');
      return;
    }

    vm.loading = true;
    AuthApiService.updatePassword(vm.user.password, $stateParams.token).then(function () {
      Alert.success('resetpassword_processing_validated');
      if (vm.fanGroup) {
        $state.go('app.authenticate.login', { slug: vm.fanGroup.slug });
      } else {
        // ANONYMOUS_FG
        $state.go('app.authenticate.password-reset-successfully', { slug: ANONYMOUS_FG.slug });
      }
    }).catch(function (error) {
      vm.loading = false;
      var redirectIsRequired = ValidationService.hasValidationsOf(error, ['server_error_validation_issue', 'api_authentication_unknown_user_token', 'api_authentication_user_token_expired', 'api_authentication_user_token_invalid', 'api_authentication_user_not_active', 'api_authentication_token_required', 'api_default_required_parameters_errors']);
      if (redirectIsRequired) {
        $state.go('app.authenticate.forgot-password', { expired: true });
      } else {
        ValidationService.processValidations(error, vm.verifyPasswordForm);
      }
    }).then(function () {
      vm.loading = false;
    });
  };

  /**
   *  SETUP
   */
});