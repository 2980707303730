'use strict';

angular.module('app').constant('GENERAL_VALIDATION_INPUT_NAME', 'generalValidationInput').constant('SERVER_VALIDATION_PREFIX', 'serverValidation').service('ValidationService',
/* @ngInject */function ($q, lodash, SERVER_VALIDATION_PREFIX, GENERAL_VALIDATION_INPUT_NAME) {
  var service = {};

  function isServerValidationKey(errorKey) {
    return lodash.startsWith(errorKey, SERVER_VALIDATION_PREFIX);
  }

  function generateServerValidationKey(errorCode) {
    return SERVER_VALIDATION_PREFIX + '_' + errorCode;
  }

  function addErrorMessageToInputControl(inputControl, validationError) {
    inputControl.errorMessages = inputControl.errorMessages ? inputControl.errorMessages : [];
    inputControl.errorMessages.push(validationError);
  }

  function getValidationErrorsFromBody(responseBody) {
    return responseBody.errors.errorsTypes.validation_errors;
  }

  function generateErrorValidation(error) {
    return {
      errors: {
        errorsTypes: {
          validation_errors: [{
            error: {
              errorCode: error || 'validation_error_server_error!',
              errorDescription: error || 'validation_error_server_error!'
            },
            references: ['fallback_validation'],
            timestamp: null
          }]
        }
      }
    };
  }

  function isInitialValidationConsumed(formController) {
    if (lodash.isBoolean(formController.initialValidation)) {
      return formController.initialValidationConsumed;
    }
    return true;
  }

  function setInitialValidation(formController, initialValidation) {
    if (formController.initialValidation) {
      formController.initialValidationConsumed = true;
      formController.initialValidation = false;
    }

    formController.initialValidation = lodash.isBoolean(formController.initialValidation) ? formController.initialValidation : initialValidation;

    if (initialValidation) {
      formController.$setSubmitted();
    }
  }

  // @TODO extend this method to lookup nested inputControls in nested forms
  function isInputControl(controlName, formController) {
    return lodash.isObject(formController[controlName]) && lodash.hasIn(formController[controlName], '$modelValue');
  }
  /**
   * Iterates through the given keys, verify a corrospondent
   * input field within a given form,
   * otherwise set the error to GENERAL_VALIDATION_INPUT_NAME
   *
   * @param {any} validationErrorsMap
   * @param {any} formController
   * @returns {Array} verified validationErrors aligned formController
   */
  function verifyValidationErrors(validationErrorsMap, formController) {
    return lodash.map(validationErrorsMap, function (validationError) {
      validationError.name = isInputControl(validationError.name, formController) ? validationError.name : GENERAL_VALIDATION_INPUT_NAME;
      return validationError;
    });
  }

  /**
   * Reset validity of given inputController and formController
   * for all serverValidation errors
   *
   * @param {Object} inputController angular NgModelController
   * @param {Object} formController angular FormController
   * @returns {Function} to be used by $validators within NgModelController
   */
  function resetServerValidity(inputController, formController) {
    var storedinputController = inputController;
    return function () {
      lodash.each(storedinputController.$error, function (value, errorKey) {
        if (isServerValidationKey(errorKey) && isInitialValidationConsumed(formController)) {
          console.log('resetServerValidity for : ', errorKey);
          storedinputController.$setValidity(errorKey, true);
          storedinputController.errorMessages = lodash.remove(storedinputController.errorMessages, {
            errorKey: errorKey
          });
          formController.$setValidity(errorKey, true);
        }
      });
      return true;
    };
  }

  /**
   * Reset the general server validation within a form
   *
   * @param {Object} formController angular FormController
   * @returns {unefined}
   */
  function resetGeneralServerValidation(formController) {
    var generalControl = formController[GENERAL_VALIDATION_INPUT_NAME];
    resetServerValidity(generalControl, formController)();
  }

  /**
   * Update the validity of an input and it is error message.
   *
   * @param {Object} formController - Angular FromController holding the input controller
   * @param {Object} validationError  {name: String, errorCode: String}
   * @returns {undefined}
   */

  function processInputValidation(formController, validationError) {
    var inputControl = formController[validationError.name];
    inputControl.$setValidity(validationError.errorKey, false);
    inputControl.$setTouched();
    addErrorMessageToInputControl(inputControl, validationError);
  }

  /**
   * Set validity or for fromController and it's inputControllers
   * based on the validationErrors, and reset General Server Validation
   *
   * @param {Array} validationErrors - Verified validation erros array.
   * @param {Object} formController - Angular FromController
   * @returns {undefined}
   */

  function distributeValidations(validationErrors, formController) {
    resetGeneralServerValidation(formController);
    lodash.each(validationErrors, function (validationError) {
      processInputValidation(formController, validationError);
    });
    return validationErrors;
  }

  /**
   * Enforce the correct Schema when the backend sends unfromated error.
   *
   * @param {Object} error - Backend Error response.
   * @returns {Array} Array of validation errors
   */

  function parseAndEnforceValidationSchema(error) {
    // eslint-disable-next-line init-declarations
    var responseBody;
    /**
     * @TODO to be removed once the backend
     * returns consistant response body
     */
    if (error) {
      if (error.data) {
        responseBody = error.data;
      } else if (error.body) {
        responseBody = JSON.parse(error.body);
      } else if (error.errors) {
        responseBody = JSON.parse(error.errors[0].message);
      }
    }
    responseBody = responseBody ? responseBody : generateErrorValidation(error);
    return getValidationErrorsFromBody(responseBody);
  }

  /**
   *
   * Abstracts backend validation to simple key/value
   *
   * @param {Array} validationErrors - Parsed Backend Errors.
   *
   * @returns {Array} Validation errors with corresponding Input Names
   * Example: [{name: 'firstName', errorKey: 'serverValidation_key', errorCode: 'server_validation_error_code'}]
   */

  function generateValidationErrorsMap(validationErrors) {
    var unFlattenedResult = lodash.map(validationErrors, function (error) {
      return lodash.map(error.references, function (reference) {
        return {
          name: reference,
          errorCode: error.error.errorCode,
          errorKey: generateServerValidationKey(error.error.errorCode)
        };
      });
    });
    return lodash.flatten(unFlattenedResult);
  }

  /**
   * Process the error response and update the given Angular FormController.
   *
   * @param {Object} error - Backend Error response.
   * @param {Object} formController - Angular FromController
   * @returns {Promise} Verified validation errors
   */

  function processValidations(error, formController, initialValidation) {
    var serverValidationErrors = parseAndEnforceValidationSchema(error);
    var validationErrorsMap = generateValidationErrorsMap(serverValidationErrors);
    var verifiedValidationErrors = verifyValidationErrors(validationErrorsMap, formController);
    setInitialValidation(formController, initialValidation);
    return $q.resolve(distributeValidations(verifiedValidationErrors, formController));
  }

  function hasValidationsOf(error, errorCodesToCheck) {
    var errorCodesToCheckArray = [];

    var validationErrors = parseAndEnforceValidationSchema(error);
    var existingErrorCodes = lodash.map(validationErrors, 'error.errorCode');
    var intersection = lodash.intersection(existingErrorCodes, errorCodesToCheckArray);
    return !lodash.isEmpty(intersection);
  }

  service.resetServerValidity = resetServerValidity;
  service.isServerValidationKey = isServerValidationKey;
  service.processValidations = processValidations;
  service.hasValidationsOf = hasValidationsOf;

  return service;
});