'use strict';

angular.module('app').service('AlgoliaService',
/* @ngInject */function ($log, $q, algolia, AppService) {
  var DEFAULT_PAGE_SIZE = 20;
  var service = {};

  service.search = function (query, page, pageSize) {
    var config = AppService.getEnv().algoliaConfiguration;
    if (!config) {
      $log.error('AlgoliaService - Algolia is not configured');
      return $q.reject('algolia config missing');
    }

    var client = algolia.Client(config.appId, config.apiKey, {
      protocol: 'https:'
    });
    var index = client.initIndex(config.indexName);
    var locale = AppService.getLocale();
    // Console.log('config.attributes[locale] = %s', JSON.stringify(config.attributes[locale]));
    var restrictSearchableAttributes = config.attributes[locale].join(',');

    var deferred = $q.defer();

    function translateFields(obj, fields) {
      fields.forEach(function (field) {
        if (obj[field]) {
          var translatedField = 'translated' + (field.charAt(0).toUpperCase() + field.slice(1));
          obj[translatedField] = obj[field][locale];
        }
      });
    }

    function fixWaitinglist(waitinglist) {
      waitinglist.groupSlug = waitinglist.groupId;
      translateFields(waitinglist, ['description', 'experienceName', 'venueCity', 'venueName', 'venueShortName', 'eventName', 'eventShortName', 'groupName', 'shortGroupName']);
      return waitinglist;
    }

    function fixFangroup(fangroup) {
      translateFields(fangroup, ['name', 'shortName', 'welcomeText']);
      fangroup.slug = fangroup.fanGroupId;
      return fangroup;
    }

    index.search(query, {
      restrictSearchableAttributes: restrictSearchableAttributes,
      aroundLatLngViaIP: true,
      page: page || 0,
      hitsPerPage: pageSize || DEFAULT_PAGE_SIZE
    }, function (err, content) {
      if (err) {
        $log.error('AlgoliaService - search failed', err);
        deferred.reject(err);
      } else {
        // Fix the result hits if needed
        content.hits = content.hits.map(function (hit) {
          if (hit.type === 'WAITING_LIST') {
            return fixWaitinglist(hit);
          } else if (hit.type === 'FAN_GROUP') {
            return fixFangroup(hit);
          }

          return hit;
        });
        deferred.resolve(content);
      }
    });
    return deferred.promise;
  };

  return service;
});