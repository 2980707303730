'use strict';

angular.module('app').directive('strsTag',
/* @ngInject */function () {
  return {
    restrict: 'E',
    templateUrl: 'components/strs-tag/strs-tag.html',
    transclude: true,
    replace: true,
    scope: {
      icon: '@?icon'
    }
  };
});