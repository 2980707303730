'use strict';

angular.module('app').service('MobileService',
/* @ngInject */function ($log, $window, $location, $state, $timeout, deviceDetector, BROWSERS, DEVICES, OS) {
  var DEEP_LINK_TIMEOUT_MS = 1000;
  var DEEP_LINK_DATE_TIMEOUT_MS = 500;
  var service = {};

  service.browser = deviceDetector.browser;
  service.browserVersion = deviceDetector.browser_version;
  service.BROWSERS = BROWSERS;

  service.device = deviceDetector.device;
  service.deviceVersion = deviceDetector.device_version;
  service.DEVICES = DEVICES;

  service.os = deviceDetector.os;
  service.osVersion = deviceDetector.os_version;
  service.OS = OS;

  if (service.os === OS.IOS && service.osVersion === 'unknown') {
    service.osVersion = (navigator.userAgent.match(/\b[0-9]+_[0-9]+(?:_[0-9]+)?\b/) || []).reduce(function (key, value) {
      return value;
    }, undefined);
  }

  var iosAppName = 'seaters-gets-you-live-at-sold';
  var iosAppId = 'id950964181';

  service.iosStoreLink = 'https://itunes.apple.com/app/' + iosAppName + '/' + iosAppId;

  service.iosDeeplinkHandler = function (deeplinkPath) {
    return function () {
      var url = 'seaters:/' + deeplinkPath;
      $log.debug('[MobileService] ios custom scheme deeplink', url);
      $window.location = url;
      // If your app is present, iOS will launch your app and the roundtrip
      // Time between launching your app and a user potentially returning to
      // The webpage will be longer than 500ms, thereby bypassing the execution
      // Of the redirect to the app store.
      var now = new Date().valueOf();
      $timeout(function () {
        if (new Date().valueOf() - now > DEEP_LINK_DATE_TIMEOUT_MS) {
          $log.debug('[MobileService] ios custom scheme deeplink passed');
          return;
        }
        $log.debug('[MobileService] ios custom scheme deeplink - not installed, store fallback');
        $window.location = service.iosStoreLink;
      }, DEEP_LINK_TIMEOUT_MS);
    };
  };

  var androidPackage = 'com.seaters.android';

  service.androidStoreLink = 'https://play.google.com/store/apps/details?id=' + androidPackage;

  service.androidDeeplink = function (deeplinkPath) {
    if (service.browser === BROWSERS.CHROME && service.browserVersion >= '25') {
      return 'intent://' + deeplinkPath + '#Intent;scheme=seaters;' + 'package=' + androidPackage + ';S.browser_fallback_url=' + encodeURIComponent(service.androidStoreLink) + ';end';
    }
    // For older versions of android use the custom scheme

    return 'seaters://' + deeplinkPath;
  };

  service.isAndroid = OS.ANDROID === service.os;
  service.isIOS = OS.IOS === service.os;
  service.isDeeplinkCompatible = service.isAndroid || service.isIOS;

  service.mobileRedirectionHandler = function (deeplink) {
    return function () {
      $state.go('app.mobile', { redirect: deeplink });
    };
  };

  return service;
});