/* eslint-disable quotes */
'use strict';

angular.module('app').filter('video', function (TranslationService, marked, $sanitize, $sce, lodash) {
  return function (input) {
    if (input) {
      input = $sanitize(input);

      if (lodash.includes(input, '[VIDEO=')) {
        var src = input.match(/VIDEO=.*?(?=\])/)[0].split('=')[1];
        if (lodash.includes(src, 'youtube')) {
          var iframe = "<iframe width='560' height='315' src='" + src + "' frameborder='0' allow='accelerometer; autoplay; " + "encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>";

          iframe = iframe.replace(/'/g, '"');
          input = input.replace('[VIDEO=' + src + ']', iframe);
          input = marked(input);
          return $sce.trustAsHtml(input);
        }
        return $sce.trustAsHtml(marked(input));
      }

      return $sce.trustAsHtml(marked(input));
    }

    return '';
  };
});