/* eslint-disable id-length */
/* eslint-disable no-magic-numbers */
/* eslint-disable complexity */
/* eslint-disable no-loop-func */
/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */

'use strict';

angular.module('app').directive('strsSurvey',
/* @ngInject */
function (PASSWORD_CONFIG, PASSWORD_STRENGTH, FanGroupService, SurveyService, lodash, $q, TranslationService) {
    return {
        restrict: 'E',
        templateUrl: 'components/strs-survey/strs-survey.html',
        scope: {
            wlId: '=',
            survey: '=',
            onSave: '&',
            state: '=',
            nrseats: '='
        },
        link: function link($scope) {
            $scope.step = 'INIT';

            $scope.groupColor = FanGroupService.getPublicFanGroup().color;

            $scope.questions = $scope.survey;

            $scope.numberOfSeats = new Array($scope.nrseats);

            $scope.tk = TranslationService.tk;

            $scope.edit = function () {
                $scope.submitted = false;
            };

            function init() {
                // resolve if no answers(surveys) found
                if (lodash.isEmpty($scope.questions)) {
                    return $q.resolve();
                }

                $scope.survey = $scope.questions[0].survey;

                $scope.isSurveyAnswered = false;

                $scope.questions.forEach(function (question, index) {
                    /* ------------------------ QUESTIONS TYPE MAPPING -----------------------------*/
                    question.isFreeText = question.question.type.includes('FREE_TEXT');
                    question.isMultipleChoice = question.question.type.includes('MULTIPLE_CHOICE');
                    question.isStructured = question.question.type.includes('STRUCTURED');
                    question.isAllowOther = question.question.type.includes('OTHER');
                    question.isAllowMultipleAnswer = question.question.type.includes('MULTIPLEANSWER');
                    question.mulipleChoiceType = question.question.type.includes('DROPDOWN') ? 'DROPDOWN' : question.question.type.includes('AUTO_COMPLETE') ? 'AUTOCOMPLETE' : 'RADIO';
                    /* -----------------------------------------------------------------------------*/

                    /* -----------------------CHECK IF SURVEY ALREADY ANSWERED ---------------------*/

                    if (question.answers) {
                        $scope.isSurveyAnswered = true;
                    }

                    /* -----------------------------------------------------------------------------*/

                    /* -----------------------------INIT ANSWERS-----------------------------------*/
                    question.answers = question.answers || [];
                    if (question.answers && !question.isAllowMultipleAnswer && question.answers.length > 0 && !question.isStructured) {
                        var uniqueAnswer = question.answers[0];
                        question.answer = uniqueAnswer.offeredAnswerId || uniqueAnswer.answerText;
                    }
                    if (question.answers && question.answers != undefined && question.answers.length > 0 && question.isStructured) {
                        question.answers.forEach(function (attendeeAnswer, index1) {
                            console.log(attendeeAnswer);
                            attendeeAnswer.structuredAnswer.forEach(function (structuredAnswer, index2) {
                                question.question.structure.forEach(function (attendeeLabel) {
                                    if (!attendeeLabel.answers) {
                                        attendeeLabel.answers = [];
                                    }
                                    if (structuredAnswer.value === 'true') {
                                        structuredAnswer.value = true;
                                    }
                                    if (attendeeLabel.name === structuredAnswer.name) {
                                        attendeeLabel.answers.push(structuredAnswer.value);
                                    }
                                });
                            });
                        });
                    }

                    /* -----------------------------------------------------------------------------*/

                    /* -----------------------------HANDLE OTHER ANSWER ----------------------------*/
                    question.otherAnswer = question.isAllowOther && question.answers && question.answers.length > 0 && !question.answers[0].offeredAnswerId ? question.answers[0].answerText : undefined;
                    question.otherChecked = question.otherAnswer !== undefined;
                    /* -----------------------------------------------------------------------------*/
                });

                $scope.isSurveyLocked = $scope.questions[0].inputMode === 'LOCKED' && $scope.isSurveyAnswered;

                /* --------------------FUNCTIONS USED FOR DROPDOWN QUESTIONS-----------------------*/
                $scope.onDropdownChange = function (question) {
                    question.showDropdownOther = question.answer === 'Other';
                };
                /* -----------------------------------------------------------------------------------*/

                /* --------------------FUNCTIONS USED FOR AUTOCOMPLETE QUESTIONS-----------------------*/
                $scope.handleAutoCompleteChoiceChange = function (question, text, choice) {
                    if (text) {
                        var choiceIncluded = false;
                        question.answers.forEach(function (answer) {
                            if (answer.offeredAnswerId === choice.id) {
                                choiceExist = true;
                            }
                        });
                        if (!choiceIncluded) {
                            question.answers.push({
                                translatedOfferedAnswer: text,
                                offeredAnswerId: choice.id
                            });
                        }
                    }
                };

                $scope.getAutoCompleteChoices = function (questionId, searchText) {
                    return SurveyService.searchChoices(questionId, searchText).then(function (res) {
                        $scope.autocomplete_choices = res.content;
                        return res.content;
                    });
                };

                $scope.addAnswer = function (event, question, answer) {
                    if (event.charCode === 13 && question.isAllowOther && question.isAllowMultipleAnswer) {
                        question.answers.push({
                            answerText: answer
                        });
                    }
                };

                $scope.removeAnswer = function (question, index) {
                    question.answers.splice(index, 1);
                };

                $scope.searchAutoCompleteChoices = SurveyService.searchChoices;
                /* -----------------------------------------------------------------------------------*/

                /* ------------------------------ FUNCTION FOR STRUCTURED ----------------------------*/

                $scope.attendeeIndex = [];

                $scope.moveToAttendee = function (indexAttendees, indexPaging) {

                    if (indexPaging >= 0 && indexPaging < $scope.numberOfSeats.length) {
                        $scope.attendeeIndex[indexAttendees] = Number(indexPaging);
                        var element = document.getElementById('scroll_attendees' + indexAttendees);
                        element.scrollLeft = element.offsetWidth * indexPaging;
                    }
                };

                $scope.getLastname = function (question, n) {
                    for (var i = 0; i < question.question.structure.length; i++) {
                        var attendee = question.question.structure[i];
                        if (attendee.name === 'lastName' && attendee.answers && attendee.answers[n]) {
                            return attendee.answers[n];
                        }
                    }

                    return n + 1;
                };

                $scope.triggerInputCsv = function (question) {
                    window.setTimeout(function () {
                        angular.element('#inputFile').trigger('click');
                        $scope.questionToFill = question.question;
                    }, 0);
                };

                $scope.getTemplate = function (event, question) {
                    var csvContent = 'data:text/csv;charset=utf-8,';
                    var keys = question.question.structure.map(function (attendee) {
                        return attendee.name;
                    });

                    csvContent += keys.join(';') + '\r\n';

                    // Internet Explorer 6-11
                    var isIE = /*@cc_on!@*/false || !!document.documentMode;

                    // Edge 20+
                    var isEdge = !isIE && !!window.StyleMedia;
                    if (isIE || isEdge) {
                        var blob = new Blob([keys.join(';') + '\r\n'], {
                            type: 'data:text/csv;charset=utf-8,'
                        });
                        if (navigator.msSaveBlob) {
                            // IE 10+
                            navigator.msSaveBlob(blob, 'template.csv');
                        } else {
                            var link = document.createElement("a");
                            if (link.download !== undefined) {
                                // feature detection
                                // Browsers that support HTML5 download attribute
                                var url = URL.createObjectURL(blob);
                                link.setAttribute("href", url);
                                link.setAttribute("download", 'template.csv');
                                link.style.visibility = 'hidden';
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            }
                        }
                        /*  var IEwindow = window.open();
                         IEwindow.document.write('sep=,\r\n' + csvContent);
                         IEwindow.document.close();
                         IEwindow.document.execCommand('SaveAs', true, fileName + ".csv");
                         IEwindow.close(); */
                    } else {
                        var encodedUri = encodeURI(csvContent);
                        var downloadLink = document.createElement('a');
                        downloadLink.href = encodedUri;
                        downloadLink.download = 'data.csv';
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                    }
                };

                $scope.uploadCSV = function (event, question) {
                    var data = event.target.files[0];

                    Papa.parse(data, {
                        complete: function complete(results) {
                            var obj = {};
                            results = results.data;
                            for (var i = 0; i < results[0].length; i++) {
                                var arr = [];
                                for (var j = 1; j < results.length; j++) {
                                    arr.push(results[j][i]);
                                }
                                obj[results[0][i]] = arr;
                                $scope.questionToFill.structure.forEach(function (attendee) {
                                    if (results[0][i] === attendee.name) {
                                        if (attendee.type === "CHECK_BOX") {
                                            attendee.answers = mapCSVAnswerToBoolean(arr);
                                        } else {
                                            attendee.answers = arr;
                                        }
                                    }
                                });
                            }
                            $scope.$apply();
                        }
                    });
                };

                function mapCSVAnswerToBoolean(arrayOfAnswer) {
                    return arrayOfAnswer.map(function (answer) {
                        if (answer) {
                            var answerLowerCase = answer.toLowerCase();
                            if (answerLowerCase === 'true' || answerLowerCase === 'yes' || answerLowerCase === 'ja' || answerLowerCase === 'oui') {
                                return true;
                            }
                        }
                        return false;
                    });
                }

                /* -------------------------------------------------------------------------------------*/
                $scope.close = function () {
                    $scope.$dismiss();
                };

                /*  $scope.getValidationErrors = function(name) {
                    return $scope.validationErrors.filter(function(validationError) {
                      if(validationError.name === name) {
                      }
                      return validationError.name === name;
                    });
                  }; */

                /*  $scope.hasValidationError = function(name) {
                    return $scope.getValidationErrors(name).length;
                  }; */

                $scope.getTranslatedAttendeeLabel = function (label) {
                    return TranslationService.tk(label);
                };

                $scope.submitted = false;
                $scope.submit = function (form) {
                    if ($scope.isSurveyAnswered && $scope.isSurveyLocked) {
                        return $scope.$close();
                    }

                    var surveyId = $scope.questions[0].surveyInstanceId;

                    var answers = lodash.map($scope.questions, function (question) {
                        if (question.mulipleChoiceType !== 'AUTOCOMPLETE') {
                            if (question.isMultipleChoice) {
                                if (question.otherChecked && question.isAllowOther) {
                                    question.answers = [{
                                        answerText: question.otherAnswer
                                    }];
                                } else {
                                    question.answers = [{
                                        offeredAnswerId: question.answer
                                    }];
                                }
                            } else if (question.isStructured) {
                                question.answers = [];
                                for (var i = 0; i < $scope.nrseats; i++) {
                                    var structuredAnswers = question.question.structure.map(function (attendee, index) {
                                        var answerValue = attendee.answers ? attendee.answers[i] || '' : '';
                                        if (attendee.mandatory && !answerValue) {
                                            $scope.isQuestionStructuredNotAnswered = true;
                                        }
                                        return {
                                            name: attendee.name,
                                            value: answerValue
                                        };
                                    });

                                    question.answers.push({
                                        answerText: null,
                                        structuredAnswer: structuredAnswers
                                    });
                                }
                            } else {
                                question.answers = [{
                                    answerText: question.answer
                                }];
                            }
                        }

                        return {
                            questionId: question.question.id,
                            answers: question.answers
                        };
                    });

                    $scope.mandatoryNotanswered = false;

                    function checkIfQuestionNotAnswered() {
                        $scope.questions.forEach(function (question) {
                            // console.log(question);
                            if (question.mandatory === true) {
                                if (!question.answers || question.answers.length === 0) {
                                    $scope.mandatoryNotanswered = true;
                                }
                                question.answers.forEach(function (answer) {
                                    if (question.question.type === 'STRUCTURED') {
                                        answer.structuredAnswer.forEach(function (attendee) {
                                            if (!attendee.value) {
                                                $scope.mandatoryNotanswered = true;
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }

                    checkIfQuestionNotAnswered();
                    if (!$scope.mandatoryNotanswered) {
                        $scope.onSave();
                        $scope.submitted = true;
                        return SurveyService.submitAnswers(surveyId, answers).then(function () {
                            $scope.step = 'COMPLETED';
                            scope.$apply();
                        }).catch(function (response) {});
                    }
                    // eslint-disable-next-line no-unused-expressions
                };
            }

            init();
            $scope.questions = $scope.questions;
        }
    };
});