'use strict';

angular.module('app').directive('strsAttendeeInfo', function ($modal) {
  return {
    templateUrl: 'components/strs-attendeeinfo/strs-attendeeinfo.html',
    restrict: 'E',
    scope: {
      atttitle: '=',
      btntitle: '=',
      attendee: '=',
      fields: '=',
      countries: '=',
      state: '=',
      step: '=?',
      refindex: '=?',
      onSave: '&',
      onEdit: '&'
    },
    replace: true,
    transclude: true,
    controller: function controller(
    /* @ngInject */$scope, TranslationService, lodash, FanGroupService, WIZARD_STEPS, ATT_FORM_FIELDS, AppService) {
      var vm = this;
      vm.WIZARD_STEPS = WIZARD_STEPS;
      vm.ATT_FORM_FIELDS = ATT_FORM_FIELDS;

      vm.languages = AppService.getLanguages();

      var phoneField;
      $scope.fields.forEach(function (field) {
        if (field.key === 'phoneNumber') {
          phoneField = field;
        }
      });

      if (!$scope.attendee.phoneNumber && phoneField.required) {
        $scope.attendee.phoneNumber = { countryCallingCode: '', localNumber: '' };
      }

      vm.settings_config = FanGroupService.getSettingsConfig();
      vm.showIf = function (key) {
        key = ['zipCode', 'city', 'state', 'country'].includes(key) ? 'address' : key;
        if (vm.settings_config !== undefined) {
          return vm.settings_config[key] !== 'HIDDEN' || $scope.step === 'ATTENDEE';
        }

        return true;
      };

      function init() {
        var questions = $scope.questions;
        var surveyInstanceId = $scope.surveyInstanceId;
        $modal.open({
          templateUrl: 'routes/main/waitinglist/checkout/modal-download-mode.html',
          windowClass: 'medium strs-modal-alt',
          // eslint-disable-next-line no-shadow
          controller: /* @ngInject */function controller($scope, FanApiService) {

            $scope.optionsDownloads = [{ format: "MULTIPAGE_PDF_FILE", value: "PDF Multiple page" }, { format: 'ZIP_FILE', value: "ZIP Multiple page" }];

            $scope.confirm = function () {
              FanApiService.acceptTicketsMode(listId, format).then(function () {});

              // FanApiService.createGuest($scope.newGuest, groupId).then(function(guest) {
              //     var customInfo = {
              //         informationValue: $scope.newGuest.company,
              //         semanticName: 'company'
              //     }
              //     guest.customInfos = [customInfo];
              //     addGuestToList(guest);
              //     return guest;
              // }).then(function(guest) {


              // }).then(function(surveyResult) {
              //     $scope.$close();
              // }).catch(function(error) {
              //     $scope.showError = true;
              // })
            };
          }
        });
      }

      if ($scope.state == vm.WIZARD_STEPS.COMPLETED) {
        init();
        console.log($scope.attendee);
      }

      vm.disableIf = function (key) {
        key = key === 'lastName' ? 'lastname' : key;
        key = key === 'firstName' ? 'firstname' : key;
        key = lodash.includes(['address', 'zipCode', 'city', 'state', 'country', 'citizenshipCountryCode'], key) ? 'address' : key;
        if (vm.settings_config !== undefined) {
          return vm.settings_config[key] === 'VISIBLE_DISABLED' && $scope.step === 'BILLING';
        }
        return false;
      };

      vm.translate = function (langLoc) {
        var lang = null;
        vm.languages.forEach(function (langObj) {
          if (langObj.locale === langLoc) {
            lang = langObj;
          }
        });

        return lang.name.en;
      };

      vm.showAttendeeLanguage = function () {
        if ($scope.attendee.language !== undefined) {
          return true;
        }
        return false;
      };

      vm.translatedLanguage = function (lang) {
        var translation = TranslationService.to(lang.name, 'en');
        if (angular.isString(translation)) {
          return translation;
        }
        vm.languages.forEach(function (langObj) {
          if (langObj.locale === 'en') {
            return TranslationService.to(langObj.name, 'en');
          }
          return undefined;
        });
        return undefined;
      };

      /**
      * Toggle editing of data
      */
      vm.edit = function () {
        $scope.isEditing = true;
      };

      function countryCodeToCountry(countryCode) {
        return $scope.countries.filter(function (country) {
          return country.alpha2Code === countryCode;
        }).map(function (country) {
          return country.name;
        })[0];
      }

      $scope.titles = [{ code: 'MR', name: TranslationService.t('settings_personal-information_title-mr') }, { code: 'MS', name: TranslationService.t('settings_personal-information_title-ms') }];
      $scope.$watch('attendee', function () {
        vm.displayCountry = $scope.attendee.country ? countryCodeToCountry($scope.attendee.country) : '';
        vm.displayCitizenship = $scope.attendee.citizenshipCountryCode ? countryCodeToCountry($scope.attendee.citizenshipCountryCode) : '';
        vm.displayPhone = $scope.attendee.phoneNumber ? '+' + $scope.attendee.phoneNumber.countryCallingCode + ' ' + $scope.attendee.phoneNumber.localNumber : '';
      }, true);
    },
    controllerAs: 'vm'
  };
});