'use strict';

angular.module('app').service('SurveyService',
/* @ngInject */
function ($modal, SDKService, APIService, ValidationService, TranslationService, lodash, $q, FanGroupService) {
    var service = {};
    var fanSDKService = SDKService.client.fanService;
    var fanResourceV2 = APIService.createResource('v2/fan');

    service.getAnswers = function (surveyId) {
        return fanSDKService.getAnswers(surveyId).then(function (results) {
            return results.items;
        });
    };

    service.getSurveys = function (waitinglistId, extensionPoint, fanGroupId) {
        return fanSDKService.getSurveys(waitinglistId, extensionPoint, fanGroupId).then(function (results) {
            if (results.items.length > 0) {
                var q = Promise.all(results.items[0].survey.surveyQuestions.map(function (question) {
                    return service.getChoices(question.question.id).then(function (choices) {
                        question.question.choices = choices.content;
                    });
                }));

                return q.then(function (res) {
                    return results.items;
                });
            }

            return [];
        });
    };

    service.getChoices = function (questionId) {
        return fanResourceV2.custom('GET', 'surveys/question/' + questionId + '/choices?size=5000');
    };

    service.searchChoices = function (questionId, search) {
        return fanResourceV2.custom('POST', 'surveys/question/' + questionId + '/choices/search', {
            paging: {
                size: 5000
            },
            data: {
                search: search
            }
        });
    };
    service.submitAnswers = function (surveyId, answers) {
        return fanSDKService.submitAnswers(surveyId, answers);
    };

    service.generateQuestions = function (surveyInst, survey, userAnswers) {
        var questions = lodash.map(survey.surveyQuestions, function (question) {
            var answered = lodash.find(userAnswers, {
                questionId: question.question.id
            }) || {};
            return Object.assign(question, answered, {
                surveyInstanceId: surveyInst.id,
                inputMode: surveyInst.inputMode,
                survey: survey
            });
        });
        return questions;
    };

    service.getWaitingListSurveyQuestions = function (waitinglistId, extensionPoint, fanGroupId) {
        var survey = {};
        extensionPoint = extensionPoint || 'BEFORE_JOINING_WAITINGLIST';
        return service.getSurveys(waitinglistId, extensionPoint, fanGroupId).then(function (surveys) {
            var surveyPromise = null;
            var answersPromise = null;
            var noSurveys = !surveys || lodash.isEmpty(surveys);
            if (noSurveys) {
                survey = null;
                surveyPromise = $q.resolve(null);
                answersPromise = $q.resolve(null);
            } else {
                // For now we take only the first one
                survey = surveys[0];
                surveyPromise = $q.resolve(survey);
                answersPromise = $q.resolve(service.getAnswers(survey.id));
            }

            return $q.all({
                survey: surveyPromise,
                answers: answersPromise
            });
        }).then(function (results) {
            if (results.survey) {
                results.answers = results.answers.map(function (answer) {
                    if (answer.answers.length > 0 && answer.answers[0].structuredAnswer.length > 0) {
                        answer.structuredAnswer = answer.answers.map(function (answ) {
                            var attendees = answ.structuredAnswer.map(function (attendee) {
                                return attendee.value;
                            }).join(' - ');
                            return attendees;
                        });
                    }

                    answer.formattedAnswer = answer.answers.map(function (answ) {
                        return answ.answerText || answ.translatedOfferedAnswer;
                    }).join('\n');

                    answer.answers = answer.answers;
                    return answer;
                });
                return service.generateQuestions(survey, survey.survey, results.answers);
            }
            return [];
        });
    };

    function isSurveyAnswered(questions) {
        var answered = false;
        questions.forEach(function (question) {
            if (question.answer || question.answers && question.answers.length > 0) {
                answered = true;
            }
        });
        return answered;
    }

    service.fulfillSurveyRequirements = function (waitinglistId, extensionPoint, fanGroupId, numberOfSeats) {
        extensionPoint = extensionPoint || 'BEFORE_JOINING_WAITINGLIST';
        console.log(waitinglistId);
        console.log(extensionPoint);
        console.log(fanGroupId);
        console.log(numberOfSeats);
        // 418c1401-fac9-4af3-91df-af8233c870aa
        // BEFORE_JOINING_WAITINGLIST
        // null
        //  1
        return service.getWaitingListSurveyQuestions(waitinglistId, extensionPoint, fanGroupId).then(function (prefilledQuestions) {
            console.log(prefilledQuestions);
            // resolve if no answers(surveys) found
            if (lodash.isEmpty(prefilledQuestions) || isSurveyAnswered(prefilledQuestions) && extensionPoint === 'AFTER_REGISTRATION') {
                return $q.resolve();
            }
            return $modal.open({
                templateUrl: 'components/strs-waitinglist/waitinglist-survey.modal.html',
                windowClass: 'medium strs-modal-alt',
                controller: /* @ngInject */function controller($scope, FanApiService) {
                    $scope.questions = prefilledQuestions;
                    $scope.survey = $scope.questions[0].survey;
                    $scope.groupColor = FanGroupService.getPublicFanGroup().color;
                    $scope.isSurveyAnswered = false;
                    $scope.questions.forEach(function (question, index) {
                        /* ------------------------ QUESTIONS TYPE MAPPING -----------------------------*/
                        question.isFreeText = question.question.type.includes('FREE_TEXT');
                        question.isMultipleChoice = question.question.type.includes('MULTIPLE_CHOICE');
                        question.isStructured = question.question.type.includes('STRUCTURED');
                        question.isAllowOther = question.question.type.includes('OTHER');
                        question.isAllowMultipleAnswer = question.question.type.includes('MULTIPLEANSWER');
                        question.mulipleChoiceType = question.question.type.includes('DROPDOWN') ? 'DROPDOWN' : question.question.type.includes('AUTO_COMPLETE') ? 'AUTOCOMPLETE' : 'RADIO';
                        /* -----------------------------------------------------------------------------*/

                        /* -----------------------CHECK IF SURVEY ALREADY ANSWERED ---------------------*/
                        $scope.isSurveyAnswered = isSurveyAnswered(prefilledQuestions);
                        console.log('SURVEY ANSWERED2 => ', $scope.isSurveyAnswered);
                        /* -----------------------------------------------------------------------------*/

                        /* -----------------------------INIT ANSWERS-----------------------------------*/
                        question.answers = question.answers || [];
                        if (question.answers && !question.isAllowMultipleAnswer && question.answers.length > 0 && !question.isStructured) {
                            var uniqueAnswer = question.answers[0];
                            question.answer = uniqueAnswer.offeredAnswerId || uniqueAnswer.answerText;
                        }

                        if (question.answers && question.answers.length > 0 && question.isStructured) {
                            question.answers.forEach(function (attendeeAnswer, index1) {
                                attendeeAnswer.structuredAnswer.forEach(function (structuredAnswer, index2) {
                                    question.question.structure.forEach(function (attendeeLabel) {
                                        if (!attendeeLabel.answers) {
                                            attendeeLabel.answers = [];
                                        }
                                        if (structuredAnswer.value === 'true') {
                                            structuredAnswer.value = true;
                                        }
                                        if (attendeeLabel.name === structuredAnswer.name) {
                                            attendeeLabel.answers.push(structuredAnswer.value);
                                        }
                                    });
                                });
                            });
                        }
                        /* -----------------------------------------------------------------------------*/

                        /* -----------------------------HANDLE OTHER ANSWER ----------------------------*/
                        question.otherAnswer = question.isAllowOther && question.answers && question.answers.length > 0 && !question.answers[0].offeredAnswerId ? question.answers[0].answerText : undefined;
                        question.otherChecked = question.otherAnswer !== undefined;
                        /* -----------------------------------------------------------------------------*/
                    });

                    $scope.isSurveyLocked = $scope.questions[0].inputMode === 'LOCKED' && $scope.isSurveyAnswered;

                    /* --------------------FUNCTIONS USED FOR DROPDOWN QUESTIONS-----------------------*/
                    $scope.onDropdownChange = function (question) {
                        question.showDropdownOther = question.answer === 'Other';
                    };
                    /* -----------------------------------------------------------------------------------*/

                    /* --------------------FUNCTIONS USED FOR AUTOCOMPLETE QUESTIONS-----------------------*/
                    $scope.handleAutoCompleteChoiceChange = function (question, text, choice) {
                        if (text) {
                            var choiceIncluded = false;
                            question.answers.forEach(function (answer) {
                                if (answer.offeredAnswerId === choice.id) {
                                    choiceExist = true;
                                }
                            });
                            if (!choiceIncluded) {
                                if (!question.isAllowMultipleAnswer && question.answers.length > 0) {
                                    question.answers[0].answerText = text;
                                    question.answers[0].offeredAnswerId = choice.id;
                                } else {
                                    question.answers.push({
                                        translatedOfferedAnswer: text,
                                        offeredAnswerId: choice.id
                                    });
                                }
                            }
                        }
                    };

                    $scope.getAutoCompleteChoices = function (questionId, searchText) {
                        return service.searchChoices(questionId, searchText).then(function (res) {
                            $scope.autocomplete_choices = res.content;
                            return res.content;
                        });
                    };

                    $scope.addAnswer = function (event, question, answer) {
                        if (event.charCode === 13 && question.isAllowOther) {
                            if (question.isAllowMultipleAnswer) {
                                question.answers.push({
                                    answerText: answer
                                });
                            } else if (question.answers.length > 0) {
                                question.answers[0].answerText = answer;
                            } else {
                                question.answers.push({
                                    answerText: answer
                                });
                            }
                        }
                    };

                    $scope.removeAnswer = function (question, index) {
                        question.answers.splice(index, 1);
                    };

                    $scope.searchAutoCompleteChoices = service.searchChoices;
                    /* -----------------------------------------------------------------------------------*/

                    /* ------------------------------ FUNCTION FOR STRUCTURED ----------------------------*/

                    $scope.attendeeIndex = [];
                    $scope.numberOfSeats = new Array(numberOfSeats);

                    $scope.moveToAttendee = function (indexAttendees, indexPaging) {
                        if (indexPaging >= 0 && indexPaging < $scope.numberOfSeats.length) {
                            $scope.attendeeIndex[indexAttendees] = Number(indexPaging);
                            var element = document.getElementById('scroll_attendees' + indexAttendees);
                            element.scrollLeft = element.offsetWidth * indexPaging;
                        }
                    };

                    $scope.getTranslatedAttendeeLabel = function (label) {
                        return TranslationService.tk(label);
                    };

                    var oneshot = true;
                    $scope.getLastname = function (question, n) {
                        if (oneshot) {
                            oneshot = false;
                        }
                        for (var i = 0; i < question.question.structure.length; i++) {
                            var attendee = question.question.structure[i];
                            if (attendee.name === 'lastName' && attendee.answers && attendee.answers[n]) {
                                return attendee.answers[n];
                            }
                        }

                        return n + 1;
                    };

                    $scope.triggerInputCsv = function (question) {
                        window.setTimeout(function () {
                            angular.element('#inputFile').trigger('click');
                            $scope.questionToFill = question.question;
                        }, 0);
                    };

                    $scope.getTemplate = function (event, question) {
                        var csvContent = 'data:text/csv;charset=utf-8,';

                        var keys = question.question.structure.map(function (attendee) {
                            return attendee.name;
                        });

                        csvContent += keys.join(';') + '\r\n';

                        var encodedUri = encodeURI(csvContent);

                        var downloadLink = document.createElement('a');
                        downloadLink.href = encodedUri;
                        downloadLink.download = 'data.csv';

                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                    };

                    $scope.uploadCSV = function (event, question) {
                        var data = event.target.files[0];

                        Papa.parse(data, {
                            complete: function complete(results) {
                                var obj = {};
                                results = results.data;
                                for (var i = 0; i < results[0].length; i++) {
                                    var arr = [];
                                    for (var j = 1; j < results.length; j++) {
                                        arr.push(results[j][i]);
                                    }
                                    obj[results[0][i]] = arr;
                                    $scope.questionToFill.structure.forEach(function (attendee) {
                                        if (results[0][i] === attendee.name) {
                                            if (attendee.type === "CHECK_BOX") {
                                                attendee.answers = mapCSVAnswerToBoolean(arr);
                                            } else {
                                                attendee.answers = arr;
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    };

                    function mapCSVAnswerToBoolean(arrayOfAnswer) {
                        return arrayOfAnswer.map(function (answer) {
                            if (answer) {
                                var answerLowerCase = answer.toLowerCase();
                                if (answerLowerCase === 'true' || answerLowerCase === 'yes' || answerLowerCase === 'ja' || answerLowerCase === 'oui') {
                                    return true;
                                }
                            }
                            return false;
                        });
                    }

                    /* -------------------------------------------------------------------------------------*/

                    $scope.close = function () {
                        $scope.$dismiss();
                    };

                    /*  $scope.getValidationErrors = function(name) {
                    return $scope.validationErrors.filter(function(validationError) {
                    if(validationError.name === name) {
                    }
                    return validationError.name === name;
                    });
                    }; */

                    /*  $scope.hasValidationError = function(name) {
                    return $scope.getValidationErrors(name).length;
                    }; */

                    $scope.submit = function (form) {
                        if ($scope.isSurveyAnswered && $scope.isSurveyLocked) {
                            return $scope.$close();
                        }

                        var surveyId = $scope.questions[0].surveyInstanceId;

                        var answers = lodash.map($scope.questions, function (question) {
                            if (question.mulipleChoiceType !== 'AUTOCOMPLETE') {
                                if (question.isMultipleChoice) {
                                    if (question.otherChecked && question.isAllowOther) {
                                        question.answers = [{
                                            answerText: question.otherAnswer
                                        }];
                                    } else {
                                        question.answers = [{
                                            offeredAnswerId: question.answer
                                        }];
                                    }
                                } else if (question.isStructured) {
                                    question.answers = [];
                                    $scope.isQuestionStructuredNotAnswered = false;
                                    for (var i = 0; i < numberOfSeats; i++) {
                                        var structuredAnswers = question.question.structure.map(function (attendee, index) {
                                            var answerValue = attendee.answers ? attendee.answers[i] || '' : '';
                                            if (attendee.mandatory && !answerValue) {
                                                $scope.isQuestionStructuredNotAnswered = true;
                                            }
                                            return {
                                                name: attendee.name,
                                                value: answerValue
                                            };
                                        });

                                        question.answers.push({
                                            answerText: null,
                                            structuredAnswer: structuredAnswers
                                        });
                                    }
                                } else {
                                    question.answers = [{
                                        answerText: question.answer
                                    }];
                                }
                            }

                            return {
                                questionId: question.question.id,
                                answers: question.answers
                            };
                        });

                        $scope.mandatoryNotanswered = false;

                        function checkIfQuestionNotAnswered() {
                            $scope.questions.forEach(function (question) {
                                if (question.mandatory) {
                                    if (!question.answers || question.answers.length === 0 || !question.answers[0].answerText && !question.answers[0].offeredAnswerId) {
                                        $scope.mandatoryNotanswered = true;
                                    }
                                }
                            });
                        }

                        checkIfQuestionNotAnswered();
                        $scope.mandatoryNotanswered = false;

                        if (!$scope.mandatoryNotanswered && !$scope.isQuestionStructuredNotAnswered) {
                            return service.submitAnswers(surveyId, answers).then(function () {
                                return $scope.$close();
                            }).catch(function (response) {
                                /*   return ValidationService.processValidatifulons(response, form).then(function(validationErrors) {
                                $scope.validationErrors = validationErrors;
                                }); */
                            });
                        }
                    };
                }
            }).result;
        });
    };

    return service;
});