'use strict';

angular.module('app').directive('strsValidations', function ( /* @ngInject */lodash) {
  return {
    templateUrl: 'components/strs-validations/strs-validations.html',
    transclude: true,
    scope: true,
    link: function link($scope, $element, $attrs) {
      $scope.inputControl = $scope.$eval($attrs.strsValidations);
      $scope.formControl = $scope.inputControl ? $scope.inputControl.$$parentForm : {};
    }
  };
});