'use strict';

angular.module('app').filter('range', function () {
  return function (input, min, max) {
    // Make string input int
    min = parseInt(min, 10);
    max = parseInt(max, 10);

    for (var i = min; i <= max; i++) {
      input.push(i);
    }
    return input;
  };
});