'use strict';

angular.module('app').directive('guestLoading',
/* @ngInject */
function ($window, FanGroupService) {
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: 'routes/main/waitinglist/guestlist/shared/guest/guest-loading.html',
        scope: {}
    };
});