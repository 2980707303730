'use strict';

angular.module('app').controller('AuthenticateAutologController', function ($state, $q, $stateParams, lodash, AppService, FanGroupService, URLService, $location, SessionService, $base64, Alert, ValidationService, GroupService) {
  /**
   *  CONSTANTS
   */

  var vm = this;

  function init() {
    SessionService.destroySession();
    var USER_DEFAULTS = {
      allowDirectMarketingFromSeaters: true,
      allowDirectMarketingFromPartners: true,
      email: '',
      password: ''
    };

    var queryParameters = URLService.getQueryParameters();
    vm.user = lodash.extend(USER_DEFAULTS, queryParameters);
    vm.fanGroup = FanGroupService.getPublicFanGroup();
    vm.loading = false;

    if ($location.$$search.destinationPath !== undefined) {
      var destinationPath = $base64.decode($location.$$search.destinationPath);
    }

    SessionService.doTokenLogin($stateParams.token).then(function () {
      return SessionService.joinAfterLogin(vm.fanGroup, $stateParams.destinationPath);
    }).then(function () {
      if (destinationPath !== undefined && location.origin !== undefined) {
        window.location.href = location.origin + destinationPath;
      }
    });
  }

  init();
});