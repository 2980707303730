'use strict';

angular.module('app').enum('FANGROUP_FAN_STATUS', ['MEMBER', 'CAN_JOIN', 'LOCKED', 'WAITING_FOR_APPROVAL', 'CAN_REQUEST', 'OTHER']).enum('FANGROUP_UNLOCK_ERRORS', ['CANCELLED', 'WRONG_CODE', 'SERVER_PROBLEM']).enum('FANGROUP_SIGNALS', ['JOINED_FANGROUP', 'LEFT_FANGROUP']).service('GroupService',
/* @ngInject */function (FanApiService, Alert, UtilService, AppService, $log, $location, $state, $modal, $q, $timeout, lodash, FANGROUP_ACCESS_MODE, FANGROUP_FAN_STATUS, FANGROUP_UNLOCK_ERRORS, UTIL_GENERAL_ERRORS, FANGROUP_SIGNALS, ANONYMOUS_FG) {
  var service = {};

  function promiseRejectionHandler(message) {
    return function (err) {
      if (angular.isString(err)) {
        $log.warn(message, err);
        return $q.reject(err);
      }

      $log.error(message, err);
      return $q.reject(UTIL_GENERAL_ERRORS.SERVER_PROBLEM);
    };
  }

  service.notAFanStatuses = [FANGROUP_FAN_STATUS.CAN_JOIN, FANGROUP_FAN_STATUS.WAITING_FOR_APPROVAL, FANGROUP_FAN_STATUS.LOCKED, FANGROUP_FAN_STATUS.CAN_REQUEST, FANGROUP_FAN_STATUS.OTHER];

  service.groupBySlugOrId = function (slugOrId) {
    // Workaround to allow seaters.com/_/<page> for certain pages
    if (slugOrId === ANONYMOUS_FG.slug) {
      return $q.when(ANONYMOUS_FG);
    }
    var fetchFanGroupCall = UtilService.isUUID(slugOrId) ? FanApiService.fangroupById : FanApiService.fangroupBySlug;

    return fetchFanGroupCall(slugOrId).then(function (fanGroup) {
      // For now, extend with property color, as this is used everywhere
      // TODO: Check if this is the correct way to load the color!
      AppService.setColor(fanGroup.color1);
      fanGroup = lodash.extend(fanGroup, { color: AppService.getColor() });
      AppService.setGroupVariables(fanGroup);
      return fanGroup;
    }, function (err) {
      var samlNotFanError = 403;
      if (err.errors[0].statusCode === samlNotFanError) {
        window.location.href = location.origin + '/' + slugOrId + '/authenticate/landing';
      }
    });
  };

  service.groupStatus = function (group) {
    if (group && !group.membership && group.accessMode === FANGROUP_ACCESS_MODE.CODE_PROTECTED) {
      return FANGROUP_FAN_STATUS.LOCKED;
    }

    if (!(group && group.membership)) {
      return FANGROUP_FAN_STATUS.OTHER;
    } else if (group.membership.member) {
      return FANGROUP_FAN_STATUS.MEMBER;
    } else if (group.accessMode === FANGROUP_ACCESS_MODE.PUBLIC || group.membership.request && group.membership.request.status === 'ACCEPTED') {
      return FANGROUP_FAN_STATUS.CAN_JOIN;
    } else if (group.membership.request && group.membership.request.status === 'PENDING') {
      return FANGROUP_FAN_STATUS.WAITING_FOR_APPROVAL;
    } else if (group.accessMode === FANGROUP_ACCESS_MODE.CODE_PROTECTED) {
      return FANGROUP_FAN_STATUS.LOCKED;
    } else if (group.accessMode === FANGROUP_ACCESS_MODE.PRIVATE) {
      return FANGROUP_FAN_STATUS.CAN_REQUEST;
    }
    // State that was not implemented
    $log.error('GroupService - unhandled group status', group);
    return undefined;
  };

  service.isNotAFan = function (group) {
    return lodash.includes(service.notAFanStatuses, service.groupStatus(group));
  };

  service.joinGroup = function (group) {
    $log.debug('GroupService - joining group "%s"', group.slug);
    var id = group.fanGroupId === undefined ? group.id : group.fanGroupId;
    return FanApiService.joinFanGroup(id).then(UtilService.broadcastAndReturn(FANGROUP_SIGNALS.JOINED_FANGROUP)).catch(promiseRejectionHandler('GroupService - could not join fangroup'));
  };

  service.leaveGroup = function (group) {
    $log.debug('GroupService - leaving group "%s"', group.slug);
    return FanApiService.leaveFanGroup(group.id).then(UtilService.broadcastAndReturn(FANGROUP_SIGNALS.LEFT_FANGROUP)).catch(promiseRejectionHandler('GroupService - could not leave group'));
  };

  service.shouldJoinForWaitinglistPrice = function (group) {
    return group.accessMode !== FANGROUP_ACCESS_MODE.PUBLIC && !group.membership.member;
  };

  function checkUnlockStatus(group) {
    if (!group.membership.request) {
      $log.error('GroupService - request unexpectedly removed');
      throw FANGROUP_UNLOCK_ERRORS.SERVER_PROBLEM;
    } else if (group.membership.request.status === 'PENDING') {
      return false;
    } else if (group.membership.request.status === 'ACCEPTED') {
      return true;
    } else if (group.membership.request.status === 'REJECTED') {
      throw FANGROUP_UNLOCK_ERRORS.WRONG_CODE;
    } else {
      $log.error('GroupService - unexpected membership request status %s', group.membership.request.status);
      throw FANGROUP_UNLOCK_ERRORS.SERVER_PROBLEM;
    }
  }

  service.checkUnlockStatus = checkUnlockStatus;

  service.generateUnlockFanGroupError = function (errorCode) {
    return 'unlock_fangroup_error_' + errorCode.toLowerCase();
  };

  service.requestToJoinPrivateFanGroup = function (group) {
    $log.debug('GroupService - requesting to join private group "%s"', group.slug);
    return FanApiService.requestToJoinPrivateFanGroup(group.id);
  };

  service.unlockGroup = function (group, accessCode) {
    $log.debug('GroupService - unlocking group "%s" with code "%s"', group.slug, accessCode);
    return FanApiService.joinProtectedFanGroup(group.fanGroupId || group.id, accessCode).catch(promiseRejectionHandler('GroupService - could not unlock group'));
  };

  service.canBeShared = function (group) {
    return group.accessMode === FANGROUP_ACCESS_MODE.PUBLIC;
  };

  service.canLeave = function (group) {
    var membership = group.membership;
    var statistics = group.statistics;
    return angular.isObject(membership) && membership.member && angular.isObject(statistics) && statistics.numberOfJoinedWaitingLists === 0;
  };

  /**
   * Check if a group should be joined, if it is not protected (anymore), after login or signup
   *
   * @param fanGroup
   * @returns {boolean}
   */
  service.shouldJoinFanGroup = function (fanGroup) {
    var status = this.groupStatus(fanGroup);
    return FANGROUP_FAN_STATUS.CAN_JOIN === status;
  };

  /**
   * Check if a group should be unlocked, after login
   *
   * @param fanGroup
   * @returns {boolean}
   */
  service.shouldUnlockFanGroup = function (fanGroup) {
    var status = this.groupStatus(fanGroup);
    console.log(FANGROUP_FAN_STATUS.LOCKED, status);
    return FANGROUP_FAN_STATUS.LOCKED === status;
  };

  /**
   * Check if a group requires an invitation to join after login
   *
   * @param fanGroup
   * @returns {boolean}
   */
  service.canRequestToJoinFanGroup = function (fanGroup) {
    var status = this.groupStatus(fanGroup);
    return FANGROUP_FAN_STATUS.CAN_REQUEST === status;
  };
  return service;
});