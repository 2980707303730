'use strict';

angular.module('app')
// eslint-disable-next-line
.constant('GENERAL_CONFIRMATION_TIMEOUT', 7500).controller('email-confirmation_EmailConfirmationController', function (error, emailConfirmationRedirect, StrsNotificationsStack, NOTIFICATIONS_STACK_TYPES, GENERAL_CONFIRMATION_TIMEOUT, UNCONFIRMED_EMAIL_ERRORS, $timeout, $base64, $state, AppService, WaitinglistService) {
  var vm = this;
  vm.askForEmailConfirmation = WaitinglistService.askForEmailConfirmation;
  vm.error = error;
  vm.emailConfirmationRedirect = emailConfirmationRedirect;

  if (vm.emailConfirmationRedirect) {
    var base64EncodedString = decodeURIComponent(emailConfirmationRedirect);
    try {
      var redirect = JSON.parse($base64.decode(base64EncodedString));
    } catch (err) {
      window.location.href = vm.emailConfirmationRedirect;
    }

    if (vm.error) {
      $state.go(redirect.stateName, redirect.stateParams).then(function () {
        StrsNotificationsStack.add({
          type: NOTIFICATIONS_STACK_TYPES.EMAIL_EXPIRED,
          data: { error: UNCONFIRMED_EMAIL_ERRORS.EXPIRY }
        });
      });
    } else if (redirect.origin && redirect.url) {
      window.location.href = redirect.url;
    } else if (redirect.stateName && redirect.stateParams) {
      $state.go(redirect.stateName, redirect.stateParams).then(function () {
        StrsNotificationsStack.add({
          type: NOTIFICATIONS_STACK_TYPES.GENERAL_SUCCESS,
          data: 'email_confirmation_title_email_confirmed'
        });
      });
    }
  } else if (!vm.error && !vm.emailConfirmationRedirect) {
    vm.showGeneralConfirmation = true;
    $timeout(function () {
      $state.go('app.main.group', { slug: AppService.getCurrentGroup().slug });
    }, GENERAL_CONFIRMATION_TIMEOUT);
  }
});