'use strict';

angular.module('app').controller('InformationsController', function (publicFanGroup, TranslationService) {
   /**
      *  CONSTANTS
      */

   /**
      *  PRIVATE VARIABLES
      */

   /**
      *  PRIVATE FUNCTIONS
      */

   /**
      *  VM VARIABLES
      */
   var vm = this;
   vm.group = publicFanGroup;

   //   console.log(vm.group.banner.callToActionContent);
   vm.informationText = TranslationService.t(vm.group.banner.callToActionInternalContent);
   //   console.log(vm.informationText);

   /**
      *  VM FUNCTIONS
      */

   /**
      *  SETUP
      */
});