'use strict';

angular.module('app').service('FanGroupService', function ($q, $log, $base64, lodash, FanApiService, AppService, ANONYMOUS_FG, ANONYMOUS_FG_LOOK) {
  /**
     *  CONSTANTS
     */
  var DEFAULT_FAN_GROUP_COLOR = '#3E82F7';

  var LOGIN_TYPE = [];
  var SIGNUP_TYPE = [];
  var oauthURL = '';
  var PROVIDER_NAME = {};
  var PROVIDER = '';
  var SLUG = '';
  var SETTINGS_CONFIG = {};
  var PROVIDERS = [];

  /**
     *  PRIVATE VARIABLES
     */
  var cachedPublicFanGroup = undefined;

  /**
     *  PRIVATE FUNCTIONS
     */
  function isPublicFanGroupCached(slug) {
    SLUG = slug;
    return cachedPublicFanGroup && cachedPublicFanGroup.slug === slug || slug === 'security';
  }

  /**
     *  API FUNCTIONS
     */
  function fetchPublicFanGroup(slug) {
    // Workaround to allow seaters.com/_/<page> for certain pages
    if (slug === ANONYMOUS_FG.slug) {
      return $q.when(ANONYMOUS_FG_LOOK);
    }
    if (isPublicFanGroupCached(slug)) {
      return $q.when(cachedPublicFanGroup);
    }
    return FanApiService.fangroupLookBySlug(slug).then(function (fanGroup) {
      // Homogenize the fan group color
      var currentFanGroupColor = fanGroup.color1 || fanGroup.color;
      if (angular.isString(currentFanGroupColor) && currentFanGroupColor.length > 0) {
        // Prefix color if needed
        if (currentFanGroupColor.indexOf('#') !== 0) {
          currentFanGroupColor = '#' + currentFanGroupColor;
        }

        SETTINGS_CONFIG = fanGroup.userProfileFieldsBehaviours;

        fanGroup.login.forEach(function (log) {
          if (log.type === 'OAUTH') {

            oauthURL = FanApiService.oauthAuthorizationCodeUrl(log.providerId).then(function (url) {
              return url.uri;
            });
          }

          LOGIN_TYPE.push(log.type);
          PROVIDER_NAME = log.providerName;
          PROVIDER = log.providerId;
          PROVIDERS.push({ providerId: log.providerId, loginType: log.type });
        });

        fanGroup.registration.forEach(function (register) {
          SIGNUP_TYPE.push(register);
        });

        // Check if it's a valid HEX color
        if (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(currentFanGroupColor)) {
          fanGroup.color = currentFanGroupColor;
        } else {
          fanGroup.color = DEFAULT_FAN_GROUP_COLOR;
        }
      } else {
        fanGroup.color = DEFAULT_FAN_GROUP_COLOR;
      }

      // Update the app service
      // @TODO use FanGroupService everywhere in the future
      AppService.setColor(fanGroup.color);
      AppService.setBackground(fanGroup.backgroundImageUrl);

      cachedPublicFanGroup = lodash.extend(fanGroup, { slug: slug });
      return cachedPublicFanGroup;
    });
  }

  function getPublicFanGroup() {
    return cachedPublicFanGroup;
  }
  // @TODO Remove if not used for the email-confirmation route
  function fetchFanGroupSlugFromParams(params) {
    if (angular.isObject(params) && params.slug) {
      return params.slug;
    }
    var decodedParams = JSON.parse($base64.decode(params));

    return decodedParams.stateParams.slug;
  }

  function getCurrentFanGroupSlug() {
    // Fallback to _ if needed
    return cachedPublicFanGroup && cachedPublicFanGroup.slug || '_';
  }

  function getCurrentFanGroupWrongSlug() {
    // Fallback to _ if needed
    return cachedPublicFanGroup && cachedPublicFanGroup.slug || SLUG;
  }

  function getProviders() {
    return PROVIDERS;
  }

  function getProviderNames() {
    return PROVIDER_NAME;
  }

  function getProvider() {
    return PROVIDER;
  }

  function getLoginType() {
    return LOGIN_TYPE;
  }

  function getSignupType() {
    return SIGNUP_TYPE;
  }
  function getOauthURl() {
    return oauthURL;
  }

  function getSettingsConfig() {
    return SETTINGS_CONFIG;
  }
  /**
     *  API
     */
  return {
    fetchPublicFanGroupBySlug: fetchPublicFanGroup,
    getPublicFanGroup: getPublicFanGroup,
    fetchFanGroupSlugFromParams: fetchFanGroupSlugFromParams,
    getCurrentFanGroupSlug: getCurrentFanGroupSlug,
    getLoginTypes: getLoginType(),
    getSignupType: getSignupType,
    getOauthURl: getOauthURl,
    getProviderNames: getProviderNames,
    getProvider: getProvider,
    getProviders: getProviders,
    getCurrentFanGroupWrongSlug: getCurrentFanGroupWrongSlug,
    getSettingsConfig: getSettingsConfig

  };
});