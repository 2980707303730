/* eslint-disable no-nested-ternary,no-negated-condition,no-magic-numbers */
'use strict';

angular.module('app').controller('AuthenticateLoginController', function ($state, $q, $stateParams, $scope, $window, lodash, AppService, FanGroupService, FanApiService, URLService, SessionService, Alert, ValidationService, GroupService) {
  /**
   *  CONSTANTS
   */
  var USER_DEFAULTS = {
    allowDirectMarketingFromSeaters: true,
    allowDirectMarketingFromPartners: true,
    email: '',
    password: ''
  };

  /**
   *  PRIVATE VARIABLES
   */
  var queryParameters = URLService.getQueryParameters();

  /**
   *  PRIVATE FUNCTIONS
   */

  /**
   *  VM VARIABLES
   */
  var vm = this;
  vm.user = lodash.extend(USER_DEFAULTS, queryParameters);
  vm.fanGroup = FanGroupService.getPublicFanGroup();
  vm.loading = false;
  vm.isOauth = false;
  vm.providerName = FanGroupService.getProviderNames()[AppService.getLocale()];
  vm.redirectURI = FanGroupService.getOauthURl();

  /**
   *  VM FUNCTIONS
   */
  // console.log($stateParams.redirect);

  vm.openPopup = function () {

    vm.redirectURI.then(function (uri) {
      window.location.href = uri;
    });
  };

  vm.shouldDisplayFacebook = function () {
    return lodash.includes(FanGroupService.getLoginTypes, 'FACEBOOK');
  };

  vm.checkIfSSO = function () {
    if (lodash.includes(FanGroupService.getLoginTypes, 'OAUTH')) {
      vm.isOauth = true;
    } else {
      vm.isOauth = false;
    }
    return vm.isOauth;
  };

  vm.isSubmitDisabled = function (form) {
    return vm.loading;
  };

  vm.authyActivated = true;
  vm.authy_step = false;
  vm.authyToken = '';

  vm.submit = function () {
    vm.loading = true;
    SessionService.doPasswordLogin(vm.user.email, vm.user.password, vm.authyToken).then(function (res) {
      // console.log(vm.fanGroup,$stateParams.redirect);
      return SessionService.joinAfterLogin(vm.fanGroup, $stateParams.redirect);
    }).then(function () {
      return $q.when();
    })
    // Handle errors
    .catch(function (error) {
      // console.log('ERROR 1 =>', error);
      if (JSON.parse(error.errors[0].message)) {
        var message = JSON.parse(error.errors[0].message);
        if (message.errors) {
          if (message.errors.errorsTypes.validation_errors[0].error.errorCode === 'api_authentication_user_mfa') {
            vm.authy_step = true;
            $scope.$apply();
            return null;
          }
        } else {
          vm.user = lodash.extend(vm.user, { password: '' });
          vm.loading = false;
          vm.showErrorLoginMessage = true;
          $scope.$apply();
        }
      }
      // console.log('ERROR 2 =>', error);
      return null;
      // return ValidationService.processValidations(error, vm.loginForm);
    })
    // Finish
    .then(function () {});
  };

  vm.facebookConnect = function () {
    vm.loading = true;
    SessionService.facebookConnect($stateParams.redirect).then(function () {
      vm.loading = false;
    }).catch(function () {
      vm.loading = false;
    });
  };

  /**
   *  SETUP
   */
});