'use strict';

angular.module('app')
// eslint-disable-next-line
.constant('GROUP_PAGE_SIZE', 20).constant('APP_GROUP_SCROLL_TOP', 'APP_GROUP_SCROLL_TOP').directive('onFinishRender', function ($timeout) {
    return {
        restrict: 'A',
        link: function link(scope, element, attr) {
            if (scope.$last === true) {
                $timeout(function () {
                    scope.$emit('ngRepeatFinished');
                });
            }
        }
    };
}).controller('main_group_GroupController', function ($rootScope, $timeout, group, GroupService, FanApiService, Alert, UtilService, HistoryService, InterestsService, $log, $scope, ModalsService, StrsToastService, StrsToast, SessionService, AppService, FANGROUP_FAN_STATUS, FANGROUP_UNLOCK_ERRORS, FANGROUP_ACCESS_MODE, FANGROUP_SIGNALS, APP_SETTINGS, $stateParams, GROUP_PAGE_SIZE, pageNumber, $state, $location, lodash, APP_GROUP_SCROLL_TOP, hasGeoFiltering, identity, TranslationService, StrsSearchService) {

    AppService.setColor(group.color1);
    var vm = this;

    vm.groupColor = AppService.getColor();

    $scope.isWlInInviteMode = function (waitinglist) {
        var isUserHost = identity.me.roles.filter(function (role) {
            return role === 'HOST';
        }).length === 1;
        var isWlInviteMode = waitinglist.invitationMode === 'ENABLED';
        return isUserHost && isWlInviteMode;
    };

    function performSearch(query) {
        StrsSearchService.searchWaitingList(group.id, query, {
            page: 0,
            maxPageSize: 9999
        }).then(function (publicWaitingLists) {
            vm.waitinglists = publicWaitingLists.items;
        });
    }

    vm.interests = [];
    vm.categories = [];

    vm.onInterestChange = function (interest) {
        var englishInterest = interest.interest.name.filter(function (name) {
            return name.lang === 'en';
        })[0].text;
        if (interest.selected) {
            vm.interests.push(englishInterest);
            var flatArray = [vm.interests, vm.categories].flat();
            performSearch([flatArray, vm.tags]);
        } else {
            vm.interests.splice(vm.interests.indexOf(englishInterest), 1);
            if (vm.interests.length === 0) {
                loadWaitingLists().then(function (waitinglists) {
                    vm.waitinglists = waitinglists.items;
                    vm.totalSize = waitinglists.totalSize;
                    vm.waitinglistsLoading = false;
                    executeScrollTop(loadGroupScrollTop());
                });
            }
        }
    };

    vm.onCategoryChange = function (category) {
        var englishCategory = category.name.filter(function (name) {
            return name.lang === 'en';
        })[0].text;
        if (category.selected) {
            vm.categories.push(englishCategory);
            var flatArray = [vm.interests, vm.categories].flat();
            performSearch([flatArray, vm.tags]);
        } else {
            vm.categories.splice(vm.categories.indexOf(englishCategory), 1);
            if (vm.categories.length === 0) {
                loadWaitingLists().then(function (waitinglists) {
                    vm.waitinglists = waitinglists.items;
                    vm.totalSize = waitinglists.totalSize;
                    vm.waitinglistsLoading = false;
                    executeScrollTop(loadGroupScrollTop());
                });
            }
        }
    };

    vm.removeTag = function (tag, type) {
        if (type === 'interest') {
            vm.interests.splice(vm.interests.indexOf(tag), 1);
        }
        if (type === 'category') {
            vm.categories.splice(vm.categories.indexOf(tag), 1);
        }
        if (type === 'search') {
            vm.tags.splice(vm.tags.indexOf(tag), 1);
        }
        $scope.categories.forEach(function (category) {
            category.interests.forEach(function (interest) {
                if (TranslationService.to(interest.interest.name, 'en') === tag) {
                    interest.selected = false;
                }
            });
        });
        if (vm.interests.length === 0 && vm.categories.length === 0 && vm.tags.length === 0) {
            loadWaitingLists().then(function (waitinglists) {
                vm.waitinglists = waitinglists.items;
                vm.totalSize = waitinglists.totalSize;
                vm.waitinglistsLoading = false;
                executeScrollTop(loadGroupScrollTop());
            });
        } else {
            var flatArray = [vm.interests, vm.categories].flat();
            performSearch([flatArray, vm.tags]);
        }
    };
    vm.showInterests = false;
    vm.showSearch = false;
    vm.tags = [];
    vm.addInputTag = function (tag) {
        /* if (!vm.tags.includes(tag)) {
            vm.tags.push(tag);
        } */
    };

    vm.submitTag = function (keyEvent, tag) {
        var enterCode = 13;
        if (keyEvent.which === enterCode) {
            vm.tags.push(tag);
            $scope.searchQuery = undefined;
            vm.toggleFilter();
            var flatArray = [vm.interests, vm.categories].flat();
            performSearch([flatArray, vm.tags]);
        }
    };

    vm.toggleFilter = function (filter) {
        switch (filter) {
            case 'interest':
                {
                    vm.showInterests = !vm.showInterests;
                    vm.showCategories = false;
                    vm.showSearch = false;
                    break;
                }
            case 'category':
                {
                    vm.showCategories = !vm.showCategories;
                    vm.showInterests = false;
                    vm.showSearch = false;
                    break;
                }
            case 'search':
                {
                    vm.showSearch = !vm.showSearch;
                    vm.showInterests = false;
                    vm.showCategories = false;
                    break;
                }
            default:
                {
                    vm.showCategories = false;
                    vm.showInterests = false;
                    vm.showSearch = false;
                }
        }
    };

    $scope.getInterests = function () {
        InterestsService.loadCategoriesWithInterests().then(function (result) {
            var badIndex = 3;
            result.splice(badIndex, 1);
            $scope.categories = result;
        });
    };

    $scope.getInterests();

    $scope.$on('ngRepeatFinished', function (ngRepeatFinishedEvent) {
        // Do anything
        if (localStorage.getItem('wlID')) {
            document.getElementById(localStorage.getItem('wlID')).scrollIntoView();
            localStorage.removeItem('wlID');
            localStorage.removeItem('wl_page');
        }
    });

    $scope.savePage = function () {
        localStorage.setItem('wl_page', $location.$$search.page);
    };

    function loadGroupScrollTop() {
        return localStorage.getItem(APP_GROUP_SCROLL_TOP) || 0;
    }

    function executeScrollTop(scrollTop) {
        // New anchor system maid
        var persistContextChecker = HistoryService.isInitial() ? false : $stateParams.persistContext;
        if (!persistContextChecker) return {};
        var timeout = 50;
        return window.$('html,body').animate({
            scrollTop: scrollTop
        }, timeout);
    }

    function updateParams() {
        var scrollTop = window.$(window).scrollTop();
        $state.go('.', {
            page: ++vm.pageNumber,
            persistContext: true
        }, {
            reload: false,
            notify: false
        }).then(function () {
            return executeScrollTop(scrollTop);
        });
    }

    function checkAndConvertToNumber(pageNumberToConvert) {
        var checker = lodash.chain(pageNumberToConvert).toNumber().isSafeInteger().value();
        return checker ? lodash.toNumber(pageNumberToConvert) : 1;
    }

    function loadWaitingLists() {
        vm.waitinglistsLoading = true;
        var pageSize = GROUP_PAGE_SIZE;
        // pageNumber resolves from $stateParams.page
        var pageAsNumber = checkAndConvertToNumber(pageNumber);
        pageSize = GROUP_PAGE_SIZE * pageAsNumber;
        vm.pageNumber = pageAsNumber || 1;
        vm.offset = pageSize;

        return FanApiService.fangroupWaitinglists(group.id, pageSize, 0);
    }

    vm.group = group;

    vm.groupName = !vm.group.name[AppService.getLocale()] || vm.group.name[AppService.getLocale()].length === 0 ? vm.group.name.en : vm.group.name[AppService.getLocale()];
    vm.showGeoFilteringNotification = hasGeoFiltering && !identity.me.personalInfo.geoLocation;
    if (hasGeoFiltering) {
        vm.geoFilteringNotificationInfo = {
            title: group.geoFilteringNotificationTitle || 'geo_location_correct_my_address_title',
            description: group.geoFilteringNotificationDescription || 'geo_location_correct_my_address_message'
        };
    }

    vm.closeGeoFilteringNotification = function () {
        vm.showGeoFilteringNotification = false;
    };

    vm.accessModes = FANGROUP_ACCESS_MODE;
    vm.codeProtected = vm.group.accessMode === 'CODE_PROTECTED';
    vm.navEntries = [{
        text: 'group_menu_queue-list',
        path: '/' + vm.group.slug
    }, {
        text: 'group_menu_infos',
        state: 'app.main.group.about',
        stateParams: {
            slug: vm.group.slug
        }
    }];

    var toastPositions = StrsToastService.getPositions();
    vm.onboardingPulseActive = false;
    vm.onboardingToastHandle = new StrsToast({
        sticky: true,
        position: toastPositions.BOTTOM
    });

    vm.onboardingToastHandle.onLoaded(function () {
        // Show unless the user closed the toast before
        var onboardingToastTokenShown = Boolean(localStorage.getItem(APP_SETTINGS.onboardingToastGroupShownTokenName));
        if (!onboardingToastTokenShown && (vm.groupStatus() === 'CAN_JOIN' || vm.groupStatus() === 'LOCKED')) {
            vm.onboardingToastHandle.show();
            vm.onboardingPulseActive = true;
        }
    });

    vm.onboardingToastHandle.onHideRequested(function () {
        // Remember that the user closed the toast
        vm.onboardingPulseActive = false;
        localStorage.setItem(APP_SETTINGS.onboardingToastGroupShownTokenName, true);
    });

    vm.loadMoreWaitinglists = function () {
        if (vm.waitinglistsLoading || vm.totalSize === 0 || vm.totalSize && vm.offset >= vm.totalSize) {
            // Don't do anything if we're already loading more
            return lodash.noop();
        }
        vm.waitinglistsLoading = true;
        return FanApiService.fangroupWaitinglists(group.id, GROUP_PAGE_SIZE, vm.offset).then(function (paged) {
            var nrOfItems = paged.items.length;
            vm.totalSize = paged.totalSize;
            vm.offset += nrOfItems;
            return paged.items;
        }).then(function (items) {
            vm.waitinglists = vm.waitinglists ? vm.waitinglists.concat(items) : items;
            updateParams();
            vm.waitinglistsLoading = false;
            return items;
        }).catch(function () {
            Alert.handleServerProblem('dashboard-wls - unable to fetch fan waitinglists');
            vm.waitinglistsLoading = false;
        });
    };

    function reloadGroup() {
        $log.debug('group - Reloading group...');
        return FanApiService.fangroupById(group.id).then(function (updatedGroup) {
            angular.extend(vm.group, updatedGroup);
            return vm.group;
        }, Alert.handleServerProblem('group - unable to reload group'));
    }

    function refreshToken() {
        return SessionService.refreshTokenIfNeeded();
    }

    function reloadPage() {
        return location.reload();
    }
    function reloadWaitinglists() {
        vm.waitinglistsOffset = 0;
        return vm.loadMoreWaitinglists();
    }

    vm.groupStatus = function () {
        return GroupService.groupStatus(vm.group);
    };

    vm.joining = false;
    vm.joinGroup = function () {
        // Block simultanious requests
        if (vm.joining) {
            return;
        }
        vm.joining = true;
        vm.onboardingPulseActive = false;
        vm.onboardingToastHandle.hide();
        GroupService.joinGroup(vm.group).then(reloadGroup).then(reloadWaitinglists).then(function () {
            vm.joining = false;
        }).catch(function () {
            vm.joining = false;
        });
    };

    vm.unlocking = false;
    vm.unlockGroup = function () {
        // Block simultanious requests
        if (vm.unlocking) {
            return;
        }
        vm.unlocking = true;

        // Display modal unlock dialog
        ModalsService.unlockFanGroup(vm.group, $stateParams.unlockGroupError).then(refreshToken).then(reloadPage).then(reloadGroup).then(reloadWaitinglists).then(function () {
            vm.unlocking = false;
        }).catch(function () {
            vm.unlocking = false;
        });
    };

    vm.leaving = false;
    // @TODO No longer used in any of the views
    // Invistegate complete removeal of this methond
    // and it's relations
    vm.leaveGroup = function (groupToLeave) {
        // Block simultanious requests
        if (vm.leaving) {
            return;
        }
        // Cannot leave if still in a WL
        if (group.statistics.numberOfJoinedWaitingLists > 0) {
            Alert.warning('fangroup_cannot-leave-in-wl');
            return;
        }

        var modalData = {
            title: 'group_leave-group-modal-title',
            message: 'group_leave-group-modal-message',
            acceptLabel: 'confirmation_yes_i_want',
            cancelLabel: 'confirmation_cancel',
            messageTransOptions: {
                groupname: groupToLeave.translatedName
            }
        };

        ModalsService.confirmation(modalData).then(function (res) {
            // Try to leave the group
            vm.leaving = true;
            return GroupService.leaveGroup(vm.group);
        }).then(reloadGroup).then(reloadWaitinglists).catch(function (cancel) {
            // Console.log(cancel);
        }).then(function () {
            vm.leaving = false;
        }).catch(function () {
            vm.leaving = false;
        });
    };

    vm.canBeShared = function () {
        return GroupService.canBeShared(group);
    };

    angular.extend(vm, group);

    loadWaitingLists().then(function (waitinglists) {
        vm.waitinglists = waitinglists.items;
        vm.totalSize = waitinglists.totalSize;
        vm.waitinglistsLoading = false;
        executeScrollTop(loadGroupScrollTop());
    });

    $scope.$on(FANGROUP_SIGNALS.LEFT_FANGROUP, function () {
        group.membership.member = false;
    });

    // If after a login for a group, the user is not yet a member and
    // the group is locked, then immediately show the unlock dialog
    // if ($stateParams.afterLoginUnlockGroup && FANGROUP_FAN_STATUS.LOCKED === GroupService.groupStatus(vm.group)) {

    // if the group is locked, then immediately show the unlock dialog (afterlogin condition removed for PIM flow)
    if (FANGROUP_FAN_STATUS.LOCKED === GroupService.groupStatus(vm.group)) {
        vm.unlockGroup();
    }
});