'use strict';

angular.module('app').factory('StrsToast',
/* @ngInject */function ($log, StrsToastService) {
  var positions = StrsToastService.getPositions();
  var types = StrsToastService.getTypes();

  var TOAST_DEFAULT_OPTIONS = {
    position: positions.BOTTOM,
    positionOffset: 0,
    sticky: true,
    padded: true,
    closable: true,
    type: types.DEFAULT,
    themed: false,
    themeBgColor: undefined,
    themeFgColor: undefined
  };

  return function (options) {
    options = options || {};

    this.options = angular.extend(TOAST_DEFAULT_OPTIONS, options);
    this.observers = {
      onShowRequested: [],
      onHideRequested: [],
      onLoadedRequested: [],
      onOptionsUpdated: []
    };

    this.getOptions = function () {
      return this.options;
    };

    this.updateOptions = function (updatedOptions, cb) {
      if (updatedOptions.themed) {
        if (updatedOptions.themeBgColor === undefined || updatedOptions.themeFgColor === undefined) {
          $log.error('[StrsToast] Invalid options: themeBgColor ' + 'and/or themeFgColor needs to be specified when toast is themed');
          return;
        }
      }

      if (!updatedOptions.sticky) {
        if (updatedOptions.positionOffset !== 0) {
          $log.error('[StrsToast] Invalid options: positionOffset can only be used when toast is sticky');
          return;
        }
      }

      if (!updatedOptions.type) {
        $log.error('[StrsToast] Invalid options: type should be a valid type');
        return;
      }

      this.options = angular.extend(this.options, updatedOptions);
      angular.forEach(this.observers.onOptionsUpdated, function (optionsUpdatedFn) {
        optionsUpdatedFn(cb);
      });
    };

    this.show = function (cb) {
      angular.forEach(this.observers.onShowRequested, function (showRequestedFn) {
        showRequestedFn(cb);
      });
    };

    this.hide = function (cb) {
      angular.forEach(this.observers.onHideRequested, function (hideRequestedFn) {
        hideRequestedFn(cb);
      });
    };

    this.load = function (cb) {
      angular.forEach(this.observers.onLoadedRequested, function (loadedRequestedFn) {
        loadedRequestedFn(cb);
      });
    };

    // Hooks
    this.onShowRequested = function (fn) {
      return this.observers.onShowRequested.push(fn);
    };

    this.onHideRequested = function (fn) {
      return this.observers.onHideRequested.push(fn);
    };

    this.onLoaded = function (fn) {
      return this.observers.onLoadedRequested.push(fn);
    };

    this.onOptionsUpdated = function (fn) {
      return this.observers.onOptionsUpdated.push(fn);
    };

    this.clearObservers = function () {
      this.observers.onShowRequested = [];
      this.observers.onHideRequested = [];
      this.observers.onLoadedRequested = [];
      this.observers.onOptionsUpdated = [];
    };

    return this;
  };
});