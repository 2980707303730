'use strict';

angular.module('app').enum('ERROR_STATE_REASONS', ['RESOLVE', 'UNKNOWN']).service('ErrorService',
/* @ngInject */function ($q, $state, ERROR_STATE_REASONS) {
  var service = {};

  service.resolveRejection = function (problem) {
    var error = {
      problem: problem,
      type: ERROR_STATE_REASONS.RESOLVE
    };
    return $q.reject(error);
  };

  // Service.handleStateChangeError = function(evt, to, toArgs, from, fromArgs, error) {
  //   Var stateChangeError = {
  //     To: to,
  //     ToArgs: toArgs,
  //     From: from,
  //     FromArgs: fromArgs,
  //     Error: error
  //   };
  //   Evt.preventDefault();
  //   If($state.current.name === 'app.error') {
  //     $log.error(
  //       'ErrorService - Could not load error state -- possible maintenance',
  //       StateChangeError);
  //     $state.go('maintenance');
  //   }
  //   Else {
  //     $log.debug($state.current);
  //     $log.error('ErrorService - stateChangeError', stateChangeError);
  //     $state.go('app.error', {error: stateChangeError});
  //   }
  // };

  return service;
});