'use strict';

angular.module('app').decorator('locker', function ($delegate, $window, $log) {
  function isStorageSupported(storageName) {
    try {
      var storage = $window[storageName];
      if (angular.isDefined(storage)) {
        localStorage.setItem('__storage__', '');
        localStorage.removeItem('__storage__');
      }
      return true;
    } catch (err) {
      return false;
    }
  }

  function doNothing() {
    return undefined;
  }

  if (isStorageSupported('localStorage')) {
    return $delegate;
  }

  $log.warn('localStorage is not usable (IOS private browsing?). Providing locker shim');
  // Return a shim
  return {
    shim: true,
    driver: function driver() {
      return {
        get: doNothing,
        put: doNothing,
        namespace: function namespace() {
          return this;
        }
      };
    }
  };
});