'use strict';

// Depends on foundation-datepicker:
// https://github.com/najlepsiwebdesigner/foundation-datepicker

angular.module('app').directive('survey-autocomplete', function () {
  return {
    restrict: 'A',
    link: function link(scope, element, attrs) {
      var raw = element[0];
      element.bind('scroll', function () {
        // console.log('in scroll');
        // console.log(raw.scrollTop + raw.offsetHeight);
        // console.log(raw.scrollHeight);
        if (raw.scrollTop + raw.offsetHeight === raw.scrollHeight) {
          // console.log('ouiiii');
        }
      });
    }
  };
});