'use strict';

angular.module('app').constant('LOCKER_NS_FACEBOOK', 'facebook').constant('LOCKER_FACEBOOK_REDIRECT', 'redirect').enum('FACEBOOK_ERRORS', ['UNEXPECTED', 'NOT_AUTHORIZED', 'CANCELLED', 'NO_EMAIL', 'EMAIL_EXISTS']).service('FacebookService',
/* @ngInject */function (locker, $log, $q, $window, $location, MobileService, LOCKER_NS_FACEBOOK, LOCKER_FACEBOOK_REDIRECT, FACEBOOK_ERRORS) {
  var service = {};

  var URI = $window.URI;

  // eslint-disable-next-line no-underscore-dangle
  service._appId = undefined;

  var sdk = undefined;

  service.initializeSDK = function (appId) {
    // Localhost appId override
    if ($location.host() === 'localhost') {
      appId = '882161518515065';
    }
    var deferred = $q.defer();
    // eslint-disable-next-line no-underscore-dangle
    service._appId = appId;
    $window.fbAsyncInit = function () {
      // Parse social plugins on this page
      sdk = $window.FB;
      sdk.init({
        appId: appId,
        xfbml: true,
        version: 'v2.5'
      });
      $log.debug('[Facebook] facebook initialized');
      deferred.resolve(sdk);
    };
    // Load the SDK asynchronously
    /* eslint-disable */
    (function (d, s, id) {
      var js,
          fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })($window.document, 'script', 'facebook-jssdk');
    /* eslint-enable */
  };

  var permissionScope = 'public_profile,email';

  function isFacebookPopupCapable() {
    return !(MobileService.os === MobileService.OS.IOS && MobileService.browser === MobileService.BROWSERS.CHROME);
  }

  function authorizeByRedirect(returnPath) {
    var redirectUri = URI($window.location.origin).resource(returnPath).valueOf();
    var params = {
      // eslint-disable-next-line no-underscore-dangle
      client_id: service._appId,
      redirect_uri: redirectUri,
      scope: permissionScope,
      // ['code', 'token']
      response_type: 'token'
    };
    $window.location.href = URI('https://www.facebook.com/dialog/oauth').addSearch(params).valueOf();
    return $q.defer().promise;
  }

  service.completeOAuth = function () {
    var params = {};
    var OAUTH_PARAM_LEN = 2;

    $location.hash().split('&').forEach(function (param) {
      var kv = param.split('=');
      if (kv.length === OAUTH_PARAM_LEN) {
        params[kv[0]] = kv[1];
      }
    });
    if (angular.isString(params.access_token)) {
      return $q.resolve(params.access_token);
    }

    var errorReason = params.error_reason || 'unknown';
    var error = params.error || 'unknown';
    var errorDescription = params.error_description || 'unknown';
    if (angular.isUndefined(error) || ['access_denied', 'not_authorized'].indexOf(error) >= 0) {
      $log.debug('[FacebookService] facebook oauth cancelled');
      return $q.reject(FACEBOOK_ERRORS.CANCELLED);
    }

    $log.error('[FacebookService] oauth error: %s - %s - %s', errorReason, error, errorDescription);
    return $q.reject(FACEBOOK_ERRORS.UNEXPECTED);
  };

  function authorizeByPopup() {
    var options = {
      scope: permissionScope,
      return_scopes: true
    };
    var deferred = $q.defer();
    sdk.login(function (response) {
      $log.info('[Facebook] fb connect status: %s', response.status);
      if (response.status === 'connected') {
        return deferred.resolve(response.authResponse.accessToken);
      } else if (angular.isUndefined(response.status) || ['unknown', 'not_authorized'].indexOf(response.status) >= 0) {
        return deferred.reject(FACEBOOK_ERRORS.CANCELLED);
      }

      $log.error('[Facebook] unexpected fb connect error', response);
      return deferred.reject(FACEBOOK_ERRORS.UNEXPECTED);
    }, options);
    return deferred.promise;
  }

  service.authorize = function (oauthReturnPath) {
    if (isFacebookPopupCapable()) {
      return authorizeByPopup();
    }

    return authorizeByRedirect(oauthReturnPath);
  };

  return service;
});