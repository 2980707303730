'use strict';

angular.module('app').constant('DEFAULT_LOCALE', 'en').service('TranslationService',
/* @ngInject */function ($window, $log, $sce, $sanitize, $filter, AppService, marked, DEFAULT_LOCALE, lodash) {
  var service = {};

  // eslint-disable-next-line no-underscore-dangle
  service.tkWarnings = $window._tkWarnings = {};

  // Console.log(Object.keys(_tkWarnings).join(',\n'))
  service.tk = function (key) {
    if (lodash.isArray(key)) {
      var locale = AppService.getLocale();
      var translationData = lodash.find(key, { lang: locale });

      if (!translationData) {
        return lodash.find(key, { lang: DEFAULT_LOCALE }).text;
      }

      return translationData.text;
    }

    var translation = AppService.getTranslation(key);
    if (!translation) {
      if (!service.tkWarnings[key]) {
        service.tkWarnings[key] = true;
        // $log.warn('TranslationService - missing translation for key "%s"', key);
      }
      return '[' + key + ']';
    }
    return translation;
  };

  service.to = function (object, locale, defaultLocale) {
    if (!angular.isObject(object)) {
      // $log.error('TranslationService - undefined object passed to to()');
      return '???';
    }
    return object[locale] || object[defaultLocale];
  };

  service.replace = function (translation, replacements, locale, defaultLocale) {
    var original = translation;
    if (angular.isObject(replacements)) {
      Object.keys(replacements).forEach(function (placeholder) {
        var replacement = replacements[placeholder];
        if (angular.isObject(replacement)) {
          replacement = service.to(replacement, locale, defaultLocale);
        }
        var placeholderRx = new RegExp('{{' + placeholder + '}}', 'g');
        if (!placeholderRx.test(translation)) {
          $log.warn('translation placeholder {{%s}} missing in [%s]', placeholder, original);
        }
        translation = translation.replace(placeholderRx, replacement);
      });
    }
    return translation;
  };

  service.truncate = function (text, limit) {
    var maxtext = $filter('limitTo')(text, limit);
    if (maxtext.length !== text.length) {
      text = maxtext + '...';
    }
    return text;
  };

  service.marked = function (text, outputHtml) {
    if (!text) {
      $log.warn('empty text passed to marked');
      return '';
    }
    var output = marked(text);
    output = $sanitize(output);

    return outputHtml ? $sce.trustAsHtml(output) : output;
  };

  // eslint-disable-next-line id-length
  service.t = function (keyOrObject, options) {
    if (!options) {
      options = {};
    }
    var locale = options.locale || AppService.getLocale();
    var defaultLocale = options.defaultLocale || DEFAULT_LOCALE;
    var txt = angular.isString(keyOrObject) ? service.tk(keyOrObject) : service.to(keyOrObject, locale, defaultLocale);
    if (options.replacements) {
      txt = service.replace(txt, options.replacements, locale, defaultLocale);
    }
    if (options.markdown) {
      txt = service.marked(txt);
    }
    if (options.limit) {
      txt = service.truncate(txt, options.limit);
    }
    if (options.markdown) {
      txt = $sce.trustAsHtml(txt);
    }
    return txt;
  };

  service.t2 = function (object) {
    var txt = '';
    object.forEach(function (item) {
      if (item.lang === 'en' && txt === '') {
        txt = item.text;
      }
      if (item.lang === AppService.getLocale()) {
        txt = item.text;
      }
    });
    return txt;
  };

  return service;
});