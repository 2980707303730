'use strict';

angular.module('app').enum('MODAL_RESULT', ['MODAL_CLOSED', 'MODAL_CANCELLED']).service('ModalsService',
/* @ngInject */function (FanGroupService, GroupService, URLService, $modal, $q, lodash, MODAL_RESULT, WAITINGLIST_JOIN_ERRORS) {
  var service = {};

  /**
   * Confirmation Modal
   *
   * @param {options} Object : {
   *    @member {title} String
   *    @member {message} String
   *    @member {iconStyle} String (default 'fa fa-check)
   *    @member {acceptLabel} String  (default : 'confirmation_yes')
   *    @member {cancelLabel} String  (default : 'confirmation_cancel')
   *    @member {templateUrl} String  (default : 'components/strs-modals/strs.confirmation.modal.html')
   *    @member {messageTransOptions} Object (example { groupname: 'Fan group name' } )
   *  }
   */

  service.confirmation = function (modalData) {
    modalData.windowClass = modalData.windowClass ? modalData.windowClass : 'medium strs-modal-alt';
    modalData.iconStyle = modalData.iconStyle ? modalData.iconStyle : 'fa fa-check';
    modalData.acceptLabel = modalData.acceptLabel ? modalData.acceptLabel : 'confirmation_yes';
    modalData.cancelLabel = modalData.cancelLabel ? modalData.cancelLabel : 'confirmation_cancel';
    modalData.keyboard = lodash.isBoolean(modalData.keyboard) ? modalData.keyboard : true;
    modalData.closeOnClick = lodash.isBoolean(modalData.closeOnClick) ? modalData.closeOnClick : true;

    return $modal.open({
      templateUrl: modalData.templateUrl || 'components/strs-modals/strs.confirmation.modal.html',
      windowClass: modalData.windowClass,
      controllerAs: 'modalVm',
      keyboard: modalData.keyboard,
      closeOnClick: modalData.closeOnClick,
      controller: function controller() /* @ngInject */{
        var modalVm = this;
        angular.extend(modalVm, modalData);
      }
    }).result;
  };

  service.notification = function (modalData) {
    modalData.acceptLabel = modalData.acceptLabel ? modalData.acceptLabel : 'notification_ok';
    modalData.keyboard = lodash.isBoolean(modalData.keyboard) ? modalData.keyboard : true;
    modalData.closeOnClick = lodash.isBoolean(modalData.closeOnClick) ? modalData.closeOnClick : true;

    return $modal.open({
      templateUrl: modalData.templateUrl || 'components/strs-modals/strs.notification.modal.html',
      windowClass: 'small strs-modal strs-modal-alt',
      controllerAs: 'modalVm',
      backdrop: modalData.backdrop,
      keyboard: modalData.keyboard,
      closeOnClick: modalData.closeOnClick,
      controller: function controller() /* @ngInject */{
        var modalVm = this;
        angular.extend(modalVm, modalData);
      }
    }).result;
  };

  /**
   * Shows a generic modal dialog with title and information
   *
   * @param fanGroup
   * @returns {*}
   */
  service.generalProblem = function (title, info, details) {
    return $modal.open({
      templateUrl: 'components/strs-modals/strs.generalproblem.modal.html',
      windowClass: 'medium strs-modal-alt',
      controllerAs: 'vm',
      controller: function controller() /* @ngInject */{
        var vm = this;
        vm.fanGroup = FanGroupService.getPublicFanGroup();
        vm.title = title;
        vm.info = info;
        vm.details = details;
      }
    }).result;
  };

  /**
   * Shows a modal dialog to specify an email address in case a new user tried to register with facebook
   * but has not shared email address. In such a case, the user's email address should still be requested
   *
   * @param fanGroup
   * @returns {*}
   */
  service.facebookMissingEmail = function () {
    return $modal.open({
      templateUrl: 'components/strs-modals/strs.facebook-missing-email.modal.html',
      windowClass: 'medium strs-modal-alt',
      controllerAs: 'vm',

      controller: function controller() /* @ngInject */{
        var vm = this;
        vm.validationErrors = undefined;
        vm.fanGroup = FanGroupService.getPublicFanGroup();
      }
    }).result.then(undefined, function () {
      return $q.reject('CANCELLED');
    });
  };

  /**
   *
   * Show a popup asking for mobile phone confirmation
   * When the user decides to edit his settings, OR when the
   * popup is dismissed by clicking outside of it,
   * the waiting list booking should be cancelled.
   * @returns {promise}
   *
   */
  service.askForMobileConfirmation = function () {
    return $modal.open({
      templateUrl: 'components/strs-modals/strs.unconfirmed-phone.modal.html',
      windowClass: 'medium strs-modal-alt',
      controllerAs: 'vm',
      controller: function controller( /* @ngInject */$scope, $state) {
        var vm = this;
        vm.goToSettings = function () {
          $scope.$dismiss();
          return $state.go('app.main.settings', { slug: FanGroupService.getCurrentFanGroupSlug() });
        };
      }
    }).result;
  };

  service.wlBadgeProtected = function (wlId) {

    return $modal.open({
      templateUrl: 'components/strs-waitinglist/badge-protected.modal.html',
      windowClass: 'medium strs-modal-alt',
      keyboard: false,
      closeOnClick: false,
      controller: function controller($scope, FanApiService, badges, TranslationService) {
        $scope.close = $scope.$dismiss;
        $scope.badges = badges.badgeFanViews;
        $scope.badgeDescriptions = badges.badgeFanViews ? badges.badgeFanViews.map(function (badge) {
          return TranslationService.t2(badge.description);
        }) : [];
      },
      resolve: {
        badges: function badges(FanApiService) {
          return FanApiService.getBadgesProtection(wlId);
        }
      }
    });
  };

  /**
   * Shows a modal dialog for unlocking a protected fan group, after a user has successfully logged in.
   *
   * @param fanGroup
   * @returns {*}
   */
  service.unlockFanGroup = function (fanGroup, unlockGroupError) {

    return $modal.open({
      templateUrl: 'components/strs-modals/strs.unlock-fangroup.modal.html',
      windowClass: 'medium strs-modal-alt',
      controllerAs: 'vm',

      controller: function controller( /* @ngInject */$scope, ValidationService, FANGROUP_UNLOCK_ERRORS) {
        var vm = this;
        var unlockFormInitialized = false;

        vm.loading = false;
        vm.fanGroup = fanGroup;
        var queryParameters = URLService.getQueryParameters();
        vm.accessCode = queryParameters.accessCode;

        function processUnlockFanGroupValidation(errorCode, preFormSubmission) {
          // @TODO Unify error codes in the SDK, and use accordingly
          if (errorCode === 'Error: strs.api.fg.invalidcode') {
            errorCode = FANGROUP_UNLOCK_ERRORS.WRONG_CODE;
          } else if (errorCode === 'Error: strs.api.servererror') {
            errorCode = FANGROUP_UNLOCK_ERRORS.SERVER_PROBLEM;
          }
          var error = GroupService.generateUnlockFanGroupError(errorCode);
          return ValidationService.processValidations(error, vm.unlockForm, preFormSubmission);
        }

        function checkUnlockStatus() {
          var unlockStatus = undefined;
          if (fanGroup.membership.request && fanGroup.membership.request.status === 'REJECTED') {
            unlockStatus = FANGROUP_UNLOCK_ERRORS.WRONG_CODE;
          }
          return unlockStatus ? processUnlockFanGroupValidation(unlockStatus, true) : lodash.noop();
        }

        $scope.$watch(function () {
          return vm.unlockForm;
        }, function (newUnlockFrom, oldUnlockForm) {
          if (unlockGroupError && !unlockFormInitialized && newUnlockFrom) {
            checkUnlockStatus();
            unlockFormInitialized = true;
          }
        });

        // Try unlocking the fan group, if not successfull, leave the dialog open
        // and display the validation error results
        // Otherwise, close the dialog
        vm.submit = function () {
          GroupService.unlockGroup(vm.fanGroup, vm.accessCode).then(function (response) {
            $scope.$close(MODAL_RESULT.MODAL_CLOSED);
          }).catch(function (errorCode) {
            return processUnlockFanGroupValidation(errorCode);
          });
        };
      }
    }).result.then(undefined, function () {
      return $q.reject(MODAL_RESULT.MODAL_CANCELLED);
    });
  };

  service.changeEmail = function () {
    return $modal.open({
      templateUrl: 'components/strs-modals/strs.change-email.modal.html',
      windowClass: 'medium strs-modal-alt',
      controller: function controller(
      /* @ngInject */$scope, ValidationService, FanApiService, StrsNotificationsStack, NOTIFICATIONS_STACK_TYPES, AppService, me) {
        var vm = this;

        vm.me = me;
        vm.oldEmail = vm.me.email;
        vm.newEmail = '';

        vm.requestEmailValidation = function () {
          vm.customErrorMessage = '';
          var redirect = AppService.generateCurrentGroupEncodedRedirect();
          return FanApiService.sendValidationEmail(vm.newEmail, redirect).then(function () {
            vm.me.email = vm.newEmail;
            StrsNotificationsStack.runSequence([{
              action: 'add',
              options: {
                type: NOTIFICATIONS_STACK_TYPES.GENERAL_SUCCESS,
                data: 'change_email_text_email_changed_successfully'
              }
            }, {
              action: 'remove',
              options: {
                type: NOTIFICATIONS_STACK_TYPES.EMAIL_SENDING_ERROR
              }
            }]);
            $scope.$close(vm.me.email);
          }).catch(function (error) {
            vm.newEmail = '';
            var errorCode = 'change_email_error_general_error';
            if (error.data.message === 'E-mail is already in use') {
              errorCode = 'change_email_error_email_in_use';
            } else if (error.data.message === 'Invalid e-mail') {
              errorCode = 'change_email_error_invalid_email';
            }
            vm.customErrorMessageCode = errorCode;
            return error;
          });
        };
      },
      controllerAs: 'vm',
      resolve: {
        me: function me(FanApiService) {
          return FanApiService.me();
        }
      }
    }).result;
  };

  return service;
});