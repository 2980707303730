'use strict';

angular.module('app').filter('theme',
/* @ngInject */function ($sce) {
  return function (input, color) {
    if (input) {
      var text = input.$$unwrapTrustedValue();
      var dom = angular.element(text);

      // Color
      dom.find('a, h1, h2, h3, h4, h5, h6').css({
        color: color
      });

      var trustedValueHolder = $sce.trustAsHtml(angular.element('<div />').append(dom).html());
      return trustedValueHolder;
    }

    return input;
  };
});