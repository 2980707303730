'use strict';

angular.module('app').controller('main_checkout_CheckoutController', function ($stateParams, me, CheckoutService, AppService, WIZARD_STEPS, CHECKOUT_STEPS, CHECKOUT_STATUS, $scope, ATTENDEE_SAVING_ERRORS, FanApiService, FanGroupService, WaitinglistService, TranslationService, toastr, $state, waitinglist, waitinglistId, surveyInformation, SurveyService) {
    var checkoutStatus = $stateParams.checkoutStatus;

    // Redirect back the dashboard if we don't have a position or
    // If we didn't get the correct status from the initiating page
    if (!waitinglist || !waitinglist.position || !checkoutStatus || checkoutStatus !== CHECKOUT_STATUS.CHECKOUT_INPROGRESS) {
        $state.go('app.main.dashboard');
        return;
    }

    var vm = this;
    vm.wlId = waitinglistId;
    vm.surveyInformation = surveyInformation;
    vm.WIZARD_STEPS = WIZARD_STEPS;
    vm.me = angular.copy(me);
    vm.countries = AppService.getCountries();

    vm.waitinglist = waitinglist;
    vm.requiresAttendeeInfo = CheckoutService.attendeeInfoRequired(vm.waitinglist);
    vm.fanGroup = FanGroupService.getPublicFanGroup();
    vm.canProceedPayment = false;
    vm.canProceedTitle = waitinglist.freeWaitingList ? 'checkout_proceedconfirmation_button' : 'checkout_proceedpayment_button';

    vm.optionsDownloads = [{ id: 1, translations: TranslationService.tk('translations_formatDownload_multipage'), format: "MULTIPAGE_PDF_FILE", value: "PDF Multiple page" }, { id: 2, translations: TranslationService.tk('translations_formatDownload_ziptMultipage'), format: 'ZIP_FILE', value: "ZIP Multiple page" }];

    /**
     * List with possible available fields for the attendee information.
     * The required fields will be depending on the setup of the associated event
     *
     * For billing info, a fixed subset will be used
     * @type {*[]}
     */
    vm.billingInfoFormFields = CheckoutService.getBillingInfoFormFields();
    vm.attendeeInfoFormFields = CheckoutService.getAttendeeInfoFormFields(waitinglist);

    /**
     * The list with possible wizard steps in this checkout flow
     * @type {*[]}
     */
    vm.wizardSteps = [{
        key: 'BILLING',
        seq: 0,
        last: !vm.requiresAttendeeInfo && vm.surveyInformation === undefined,
        name: 'checkout_billinginfo_steptitle',
        content: 'routes/main/waitinglist/checkout/step_billinginfo.html',
        state: WIZARD_STEPS.INACTIVE,
        include: true
    }, {
        key: 'ATTENDEES',
        seq: 1,
        last: !vm.surveyInformation,
        name: 'checkout_requiredinfo_steptitle',
        content: 'routes/main/waitinglist/checkout/step_requiredinfo.html',
        state: WIZARD_STEPS.INACTIVE,
        include: vm.requiresAttendeeInfo
    }, {
        key: 'MODEDOWNLOAD',
        seq: 2,
        // last: !vm.surveyInformation,
        name: 'checkout_downloadmodeinfo_steptitle',
        content: 'routes/main/waitinglist/checkout/step_downloadmodeinfo.html',
        // state: WIZARD_STEPS.INACTIVE,
        include: vm.optionsDownloads
    }, {
        key: 'SURVEY',
        seq: 3,
        last: true,
        name: 'checkout_surveyinfo_steptitle',
        content: 'routes/main/waitinglist/checkout/step_survey.html',
        state: WIZARD_STEPS.INACTIVE,
        include: Boolean(vm.surveyInformation)
    }];

    vm.BILLING_STEP = 'BILLING';
    vm.ATTENDEE_STEP = 'ATTENDEE';
    vm.SURVEY_STEP = 'SURVEY';

    var keys = ['address', 'mobile', 'firstname', 'lastname', 'company'];
    var config = FanGroupService.getSettingsConfig();
    vm.atttitle = 'checkout_billinginfo_header_contest';
    vm.buttonTitle = 'checkout_save_contest';
    keys.forEach(function (key) {
        if (config[key] === undefined) {
            vm.atttitle = 'checkout_billinginfo_header';
            vm.buttonTitle = 'checkout_save';
        }
    });

    /**
     * Billing profile information (equivalent to the user profile)
     * @type {{}}
     */
    vm.billingInfoProfile = CheckoutService.presetBillingProfileFromUserProfile(vm.me);

    /**
     * The dynamic list with attendee fill in steps, part of the main ATTENDEES wizard step
     * @type {*[]}
     */
    vm.attendeeSteps = CheckoutService.presetAttendeeInfo(vm.waitinglist, vm.me);

    /**
     * Returns a step from the wizard
     * @param stepKey
     * @returns {*}
     */
    function getStep(stepKey) {
        var steps = vm.wizardSteps.filter(function (step) {
            return step.key.toString() === stepKey.toString();
        });
        return steps[0];
    }

    /**
     * Inactivates all steps coming after the requested step
     * @param currentStep
     */
    function inactivateNextSteps(currentStep) {
        // Set all next steps inactive
        for (var seq = currentStep.seq + 1; seq < vm.wizardSteps.length; seq++) {
            vm.wizardSteps[seq].state = WIZARD_STEPS.INACTIVE;
        }
    }

    /**
     * Start a step by setting it active
     * @param stepName
     */
    function startStep(stepName) {
        var step = getStep(stepName);
        step.state = WIZARD_STEPS.ACTIVE;
        inactivateNextSteps(step);
    }

    /**
     * Complete a step by setting it to complete
     * @param stepName
     */
    function completeStep(stepName) {
        var step = getStep(stepName);
        step.state = WIZARD_STEPS.COMPLETED;
    }

    /**
     * Starts the billing step
     */
    function startBillingInfoStep() {
        startStep(CHECKOUT_STEPS.BILLING);
        vm.canProceedPayment = false;
    }

    /**
     * Completes the billing step
     */
    function completeBillingInfoStep() {
        completeStep(CHECKOUT_STEPS.BILLING);
    }

    vm.completeSurveyStep = function () {
        completeStep(CHECKOUT_STEPS.SURVEY);
        checkCanProceedPayment();
        /* 
                    vm.canProceedPayment = true;
                    $scope.canProceedPayment = true;
                    vm.$apply();
                    $scope.$apply(); */
    };

    vm.isSurveyStepCompleted = function () {
        var step = getStep(CHECKOUT_STEPS.SURVEY);
        if (step.state === WIZARD_STEPS.COMPLETED) {
            return true;
        }
        return false;
    };

    /**
     * Returns the number of open attendee steps
     * @returns {boolean}
     */
    function openAttendeeSteps() {
        var filteredOpenAttendeeSteps = vm.attendeeSteps.filter(function (attStep) {
            return attStep.state.toString() !== WIZARD_STEPS.COMPLETED.toString();
        });
        return filteredOpenAttendeeSteps.length > 0;
    }

    /**
     * Checks if flow can proceed to the payment section
     */
    function checkCanProceedPayment() {
        var biStep = getStep(CHECKOUT_STEPS.BILLING);
        var attStep = getStep(CHECKOUT_STEPS.ATTENDEES);
        var surveyStep = getStep(CHECKOUT_STEPS.SURVEY);

        vm.canProceedPayment = biStep.state === WIZARD_STEPS.COMPLETED && (!vm.requiresAttendeeInfo || vm.requiresAttendeeInfo && attStep.state === WIZARD_STEPS.COMPLETED) && (!surveyInformation || surveyInformation && surveyStep.state === WIZARD_STEPS.COMPLETED);
    }

    /**
     * Completes the step for requesting attendee info
     */
    function completeAttendeeInfoStep() {
        completeStep(CHECKOUT_STEPS.ATTENDEES);

        if (surveyInformation) {
            startStep('SURVEY');
        }
        checkCanProceedPayment();
    }

    /**
     * Starts the step for requesting attendee info
     * @param checkAttStepsOpen
     */
    function startAttendeeInfoStep(checkAttStepsOpen) {
        // When coming from other step, check first if there are att steps open.
        // If so, we can open this step, otherwise, leave it (completed)
        if (checkAttStepsOpen) {
            // If no open attendee steps, then don't open this wizard step anymore, instead keep it completed right away
            if (!openAttendeeSteps()) {
                completeAttendeeInfoStep();
                return;
            }
        }

        startStep(CHECKOUT_STEPS.ATTENDEES);
        vm.canProceedPayment = false;
    }

    /**
     * Expands all attendee info steps where errors are present after attemt to save to api backend
     */
    function expandInvalidAttendeeInfoSteps() {
        vm.attendeeSteps.forEach(function (attStep) {
            if (angular.isDefined(attStep.attendee.errors)) {
                vm.editAttendeeInfo(attStep);
            }
        });
    }

    /**
     * Completes an attendee step section
     * @param currentAttStepIndex
     */
    function completeAndNextAttendeeStep(currentAttStepIndex) {
        // Update current step
        vm.attendeeSteps[currentAttStepIndex].state = WIZARD_STEPS.COMPLETED;

        // Go to next step
        if (currentAttStepIndex + 1 < vm.attendeeSteps.length) {
            if (vm.attendeeSteps[currentAttStepIndex + 1].state === WIZARD_STEPS.INACTIVE) {
                vm.attendeeSteps[currentAttStepIndex + 1].state = WIZARD_STEPS.AVAILABLE;
            }
        }

        // Test if all attendee steps were closed
        if (!openAttendeeSteps()) {
            // Wizard step completed
            completeAttendeeInfoStep();
        }
    }

    /**
     * Edit the billing info -> starts the billing info step
     */
    vm.editBillingInfo = function () {
        startBillingInfoStep();
    };

    /**
     * Update the billing info to the user profile and proceed to attendee information step
     *
     * @param data
     */
    vm.saveBillingInfo = function (data) {
        var validationResult = CheckoutService.validateBillingProfile(data);
        if (validationResult) {
            // Save the billing info to the personal info first
            // IF succesful, move to the next step
            CheckoutService.updateFanFromBillingProfile(vm.me, data).then(function (user) {
                // Update me myself and i to have the changes local
                angular.extend(me, user);

                // Complete current step
                completeBillingInfoStep();

                // Go to attendee info step
                if (vm.requiresAttendeeInfo) {
                    startAttendeeInfoStep(true);
                    vm.attendeeSteps[0].state = WIZARD_STEPS.AVAILABLE;
                } else if (surveyInformation) {
                    startStep('SURVEY');
                }

                if (surveyInformation) {
                    startStep('SURVEY');
                }

                checkCanProceedPayment();
            }, function (err) {
                toastr.error(err.data && err.data.message || TranslationService.tk('general_unexpected'));
            });
        } else {
            toastr.error(TranslationService.tk('checkout_save_billinginfo_error'));
        }
    };

    /**
     * Edits the attendee info -> starts the attendee step and within the step activates
     * the section for the particular attendee
     * @param attStep
     */
    vm.editAttendeeInfo = function (attStep) {
        startAttendeeInfoStep(false);

        // Set current attendee block in editing state
        // Keep the index of the step we are editing for after saving actions
        attStep.state = WIZARD_STEPS.ACTIVE;
    };

    /**
     * Close the current edited attendee section and proceed to the next one
     * @param data
     * @param refindex
     */
    vm.saveAttendeeInfo = function (data, refindex) {
        // Format address
        if (data.birthDate) {
            var date = new Date(data.birthDate);
            var dateString = date.getFullYear() + '-' + (date.getMonth() < 10 ? '0' + Number(date.getMonth() + 1) : Number(date.getMonth() + 1)) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
            data.birthDate = dateString;
        }

        var validationResult = CheckoutService.validateAttendeeInfo(vm.waitinglist, data);
        if (validationResult) {
            // Check if attendees have similar first and last names
            CheckoutService.checkUniqueAttendeeNames(vm.attendeeSteps);

            // Go to next step for attendee info
            completeAndNextAttendeeStep(refindex);
        } else {
            toastr.error(TranslationService.tk('checkout_save_attendeeinfo_error'));
        }
    };

    /**
     * Proceeds to payment/confirmation
     */

    vm.proceedPayment = function (optionLang) {
        vm.processing = true;
        CheckoutService.saveAttendeeInfo(vm.waitinglist, vm.attendeeSteps, vm.requiresAttendeeInfo).then(function () {
            vm.processing = false;
            // console.log(vm.optionUsedDownload);
            // console.log(vm.waitinglist.displayName);
            // Navigate back to the waitinglist screen
            $state.go('app.main.waitinglist', {
                waitinglistId: vm.waitinglist.waitingListId,
                optionUsed: vm.optionUsedDownload != undefined && vm.waitinglist.displayName != 'Voucher' ? vm.optionUsedDownload.format : null,
                groupSlug: vm.waitinglist.groupSlug,
                noHistoryAppend: true,
                checkoutStatus: vm.waitinglist.freeWaitingList || !vm.waitinglist.freeWaitingList && WaitinglistService.hasPayed(vm.waitinglist) ? CHECKOUT_STATUS.CHECKOUT_CAN_CONFIRM : CHECKOUT_STATUS.CHECKOUT_CAN_PAY
            });
        }, function (err) {
            vm.processing = false;
            toastr.error(err.data && err.data.message || err === ATTENDEE_SAVING_ERRORS.INVALID_ENTRIES && TranslationService.tk('general_invalidentries') || TranslationService.tk('general_unexpected'));

            // Expand steps that contain backend validation errors
            expandInvalidAttendeeInfoSteps();
        });
    };

    startBillingInfoStep();
});