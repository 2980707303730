'use strict';

angular.module('app').service('HistoryService',
/* @ngInject */function ($log, $rootScope, $state, $transitions, lodash) {
  var service = {};

  // Default config
  var config = {
    maxHistorySize: 10,
    // Match all by default
    sticky: [/.*/]
  };

  function stateIsSticky(stateName) {
    for (var i = 0; i < config.sticky.length; i++) {
      var pattern = config.sticky[i];
      if (pattern instanceof RegExp) {
        if (pattern.test(stateName)) {
          return true;
        }
      } else if (pattern === stateName) {
        return true;
      }
    }
    return false;
  }

  var history = [];

  function peek(historyArray) {
    return historyArray[historyArray.length - 1];
  }

  service.isInitial = function () {
    return !peek(history);
  };

  var backInvoked = false;
  service.goBack = function (defaultStateName, defaultStateParams) {
    var previous = peek(history);
    /**
     * Pop the top of the stack if it's the current state
     * TODO - compare state params (no use case yet)
     */
    if (angular.isObject(previous) && previous.name === $state.current.name) {
      // Pop the current state
      history.pop();
    }
    // If there's nothing in the history stack go to the default state
    if (history.length === 0) {
      $log.info('[HistoryService] going back to default state');
      $state.go(defaultStateName, defaultStateParams);
    } else {
      // Otherwise go to the top of the history stack
      previous = peek(history);
      $log.info('[HistoryService] going back to previous state %s', previous.name);
      $state.go(previous.name, previous.params);
    }
    backInvoked = true;
  };

  service.goToLatestStateName = function (stateName) {
    var latestState = lodash.chain(history).filter({ name: stateName }).last().value();
    return latestState ? $state.go(latestState.name, latestState.params) : null;
  };
  function appendHistory(toName, toParams) {
    if (backInvoked) {
      backInvoked = false;
    } else if (stateIsSticky(toName)) {
      // Only push sticky states
      $log.debug('[HistoryService] tracking state "%s"', toName);
      history.push({ name: toName, params: toParams });
      // Remove elements if the history becomes too long
      if (history.length > config.maxHistorySize) {
        history.splice(0, 1);
      }
    }
  }

  var initialized = false;
  service.initialize = function (cfg) {
    if (initialized) {
      $log.warn('[HistoryService] tried to initialize twice');
      return;
    }

    angular.extend(config, cfg);
    /* , from, fromArgs*/

    $transitions.onSuccess({}, function (transition) {
      var to = transition.to();
      var toArgs = transition.params();

      // eslint-disable-line angular/on-watch
      // Do not append history if the special state param noHistoryAppend is provided
      if (toArgs && toArgs.noHistoryAppend) {
        return;
      }

      appendHistory(to.name, toArgs);
    });

    initialized = true;
  };

  return service;
});