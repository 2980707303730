'use strict';

angular.module('app').controller('main_settings_ConfirmEmailController',
/* @ngInject */function ($log, FanApiService, AuthApiService, Alert, AUTH_VALIDATION_ERRORS, $modalInstance, fan) {
  var vm = this;

  vm.email = fan.email;

  vm.ctx = $modalInstance;

  vm.validate = function (email, code) {
    // Avoid sending empty codes
    if (!angular.isString(code) || code.trim() === '') {
      return;
    }
    $log.debug('[settings] validating code %s for email %s', code, email);
    AuthApiService.validateEmail(email, code).then(function () {
      $log.debug('[settings] validation code OK');
      vm.ctx.close();
    }, function (err) {
      if (err === AUTH_VALIDATION_ERRORS.WRONG_CODE) {
        $log.debug('[settings] validation failed - wrong code');
        Alert.warning('waitinglist_unconfirmed-email-modal_wrong-code' /* @trl*/);
      } else {
        vm.ctx.dismiss();
      }
    });
  };
});