'use strict';

angular.module('app').directive('hostPreview',
/* @ngInject */
function (PASSWORD_CONFIG, PASSWORD_STRENGTH) {
    return {
        restrict: 'E',
        templateUrl: 'routes/main/waitinglist/guestlist/shared/host-preview/host-preview.html',
        scope: {
            host: '=',
            groupColor: '=',
            inviteGrouped: '='
        },
        link: function link($scope) {
            $scope.getTotalTicketsSelected = function () {
                var total = $scope.inviteGrouped.wlInvites.reduce(function (acc1, wlInvite) {
                    return acc1 + wlInvite.invites.reduce(function (acc2, invite) {
                        return acc2 + Number(invite.nbrOfRequestedSeats);
                    }, 0);
                }, 0);
                return total;
            };

            $scope.getTotalCost = function () {
                var total = $scope.inviteGrouped.wlInvites.reduce(function (acc1, wlInvite) {
                    return acc1 + wlInvite.invites.reduce(function (acc2, invite) {
                        return acc2 + Number(invite.nbrOfRequestedSeats);
                    }, 0) * Number(wlInvite.waitingList.price);
                }, 0);
                return total;
            };
        }
    };
});