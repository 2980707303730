'use strict';

angular.module('app').directive('strsPulse',
/* @ngInject */function ($log, $timeout, $window, lodash, $compile) {
  var MAX_ELAPSED_MS = 5000;
  var RETRY_RATE_MS = 100;
  var PULSARS_SIZE = 50;
  var WINDOW_RESIZE_DEBOUNCE_MS = 300;
  var PULSARS_TEMPLATE = '<div class="strs-pulse__pulsars">' + '<div ng-if="active">' + '<div class="strs-pulse__pulsar strs-pulse__pulsar-outer"></div>' + '<div class="strs-pulse__pulsar strs-pulse__pulsar-inner"></div>' + '</div>' + '</div>';
  return {
    restrict: 'E',
    templateUrl: 'components/strs-pulse/strs-pulse.html',
    transclude: true,
    replace: true,
    scope: {
      active: '=strsPulseActive',
      size: '=?strsPulseSize'
    },
    link: function link($scope, element) {
      var insertedPulsarsElement = undefined;

      // Private functions
      function updatePulsarsPosition(contentElement, pulsarsElement, size) {
        size = size || PULSARS_SIZE;

        // Update position of pulsar relative to what is in ng-transclude
        // Put it right in the center
        var contentWidth = contentElement.outerWidth();
        var contentHeight = contentElement.outerHeight();

        pulsarsElement.css({
          // eslint-disable-next-line no-magic-numbers
          top: contentHeight / 2 - size / 2 + 'px',
          // eslint-disable-next-line no-magic-numbers
          left: contentWidth / 2 - size / 2 + 'px',
          width: size + 'px',
          height: size + 'px'
        });
      }

      function isContentRendered() {
        return angular.element(element.find('.strs-pulse__content-wrapper > ng-transclude > *')).length > 0;
      }

      function insertPulsars() {
        if (!insertedPulsarsElement) {
          insertedPulsarsElement = $compile(PULSARS_TEMPLATE)($scope);
          var content = angular.element(element.find('.strs-pulse__content-wrapper > ng-transclude > *'));
          content.prepend(insertedPulsarsElement);
        }
      }

      function destroyPulsars() {
        if (insertedPulsarsElement) {
          insertedPulsarsElement.remove();
        }
      }

      function checkContentRenderedRecursive(ellapsedTime) {
        if (ellapsedTime > MAX_ELAPSED_MS) {
          return;
        }

        if (isContentRendered()) {
          insertPulsars();

          $timeout(function () {
            updatePulsarsPosition(angular.element(element.find('.strs-pulse__content-wrapper > ng-transclude > *')), angular.element(element.find('.strs-pulse__pulsars')), $scope.size);
          }, 0);
          return;
        }
        $timeout(function () {
          checkContentRenderedRecursive(ellapsedTime + RETRY_RATE_MS);
        }, RETRY_RATE_MS);
      }

      var debouncedUpdate = lodash.debounce(function () {
        updatePulsarsPosition(angular.element(element.find('.strs-pulse__content-wrapper > ng-transclude > *')), angular.element(element.find('.strs-pulse__pulsars')), $scope.size);
      }, WINDOW_RESIZE_DEBOUNCE_MS);

      // Scope variables
      $scope.active = Boolean($scope.active);
      $scope.contentRendered = false;

      $scope.$watch('size', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          updatePulsarsPosition(angular.element(element.find('.strs-pulse__content-wrapper > ng-transclude > *')), angular.element(element.find('.strs-pulse__pulsars')), newValue);
        }
      });

      // Setup
      checkContentRenderedRecursive(0);

      // Check for changes to window size
      angular.element($window).bind('resize', function () {
        debouncedUpdate();
      });

      $scope.$on('$destroy', function () {
        destroyPulsars();
        angular.element($window).unbind('resize');
      });
    }
  };
});