'use strict';

angular.module('app').controller('main_communication_CommunicationController', function (FanApiService, FanGroupService, UtilService, AppService, $scope, $window, $stateParams, $state) {

    $scope.group = FanGroupService.getPublicFanGroup();
    $scope.body = '';
    $scope.subject = '';
    $scope.groupColor = $scope.group.color;
    $scope.waitingListId = $stateParams.waitingListId;

    $scope.getWaitingList = function () {
        FanApiService.waitinglistById($scope.waitingListId).then(function (waitingList) {
            $scope.waitingList = waitingList;
            $scope.body = '<div>Dear Guest,<br><br>We would be really happy to count you as our guest for this event. Please confirm your presence.&nbsp;<br><br>Best regards,&nbsp;<br><br>Invitor<br><br><br></div>';
        }).then(function (response) {
            return FanApiService.getCommunicationText($scope.waitingListId, AppService.getLocale() || 'en');
        });
    };

    $scope.getWaitingList();

    $scope.submit = function () {
        FanApiService.addCommunicationToInvite($stateParams.inviteId, $scope.body, $scope.subject).then(function (result) {
            $state.go('app.main.group.myinvites');
        });
    };

    $scope.getFormattedDate = function (date) {
        return $window.moment(date).format('dddd, MMMM Do YYYY');
    };

    $scope.getFormattedTime = function (date) {
        return $window.moment(date).format('h:mm a');
    };
});