'use strict';

angular.module('app').enum('AUTH_CLIENT_TYPE', ['WEB', 'ANDROID', 'IOS']).enum('AUTH_UPDATEPASSWORD_ERRORS', ['TOKEN_EXPIRED']).enum('AUTH_RESETPASSWORD_ERRORS', ['EMAIL_NOT_FOUND']).enum('AUTH_VALIDATION_ERRORS', ['WRONG_CODE']).service('AuthApiService',
/* @ngInject */function ($window, $q, $log, EnvService, AppService, APIService, Alert, AUTH_CLIENT_TYPE, AUTH_UPDATEPASSWORD_ERRORS, AUTH_RESETPASSWORD_ERRORS, AUTH_VALIDATION_ERRORS) {
  var service = {};

  var authResource = APIService.createResource('auth');
  var connectedResource = APIService.createResource('connected-app');
  var authResourceV2 = APIService.createResource('v2/authentication');

  function extendFromBase(data) {
    return angular.extend({
      clientType: AUTH_CLIENT_TYPE.WEB,
      clientVersion: AppService.getEnv().clientVersion
    }, data);
  }

  function authVerify(verifyData) {
    // console.log(verifyData);
    return authResource.custom('PUT', 'verify', {
      data: extendFromBase(verifyData)
    });
  }

  service.getSAMLConfig = function (provider) {
    return authResourceV2.custom('GET', 'saml/' + provider + '/configuration');
  };

  service.withIdentityQrCode = function (login, mfaToken) {
    var verifyData = {
      login: login,
      mfaToken: mfaToken
    };
    return authVerify(verifyData);
  };

  service.doPasswordLogin = function (login, password, mfaToken) {
    var verifyData = {
      login: login,
      password: password,
      mfaToken: mfaToken
    };
    return authVerify(verifyData);
  };

  service.extendSession = function (sessionToken) {
    var verifyData = {
      sessionToken: sessionToken
    };
    return authVerify(verifyData);
  };

  service.doFacebookLogin = function (accessToken) {
    return authResource.custom('PUT', 'verify-facebook', {
      data: extendFromBase({
        accessToken: accessToken
      })
    });
  };

  service.doQRCode = function (slug, isMobile, idWL, slugPIM) {

    if (isMobile === true && idWL === null) {
      return authResourceV2.custom('GET', slug + ('/configuration?redirect=' + window.location.origin + '/' + slugPIM));
    }

    if (isMobile === true && idWL != null) {
      return authResourceV2.custom('GET', slug + ('/configuration?redirect=' + window.location.origin + '/' + slugPIM + '/waitinglist/' + idWL));
    }

    if (isMobile === false) {
      return authResourceV2.custom('GET', slug + '/configuration');
    }
  };

  service.verifyIsQRCode = function (reference, slug) {
    // console.log(reference,slug);
    return authResourceV2.custom('GET', slug + '/check-transaction?reference=' + reference);
  };

  service.validateMobile = function (mobile, code) {
    return authResource.custom('PUT', 'validate', {
      data: {
        code: code,
        mobile: mobile
      }
    });
  };

  service.validateEmail = function (email, code) {
    return authResource.custom('PUT', 'validate', {
      data: {
        code: code,
        email: email
      }
    }).then(undefined, function (err) {
      if (angular.isObject(err) && angular.isObject(err.data)) {
        if (err.data.message === 'Wrong validation code') {
          return $q.reject(AUTH_VALIDATION_ERRORS.WRONG_CODE);
        }
      }
      // All other cases are unexpected errors
      return Alert.handleServerProblem('[AuthApiService] email code validation - unexpected problem')(err);
    });
  };

  service.doPasswordSignup = function (firstName, lastName, email, password, seatersOptin, thirdPartyOptin) {
    return authResource.custom('POST', 'signup', {
      data: extendFromBase({
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        language: AppService.getLocale(),
        directMarketingSettings: {
          allowDirectMarketingFromSeaters: seatersOptin,
          allowDirectMarketingFromPartners: thirdPartyOptin
        }
      })
    });
  };

  service.doFacebookSignup = function (user) {
    return authResource.custom('POST', 'signup-facebook', {
      data: extendFromBase(user)
    });
  };

  // Set email and send confirmation email
  service.resetEmail = function (email, authToken) {
    return authResource.custom('POST', 'signup/reset-email', {
      data: {
        email: email,
        token: authToken
      }
    });
  };

  service.resetPassword = function (email) {
    return authResourceV2.custom('PUT', 'reset-password', {
      data: {
        email: email
      }
    });
  };

  service.updatePassword = function (password, token) {
    return authResourceV2.custom('PUT', 'update-password', {
      data: {
        password: password,
        token: token
      }
    });
  };

  return service;
});