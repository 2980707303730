'use strict';

angular.module('app').directive('strsCellphone',
/* @ngInject */function (AppService, lodash, FanGroupService) {
  return {
    restrict: 'E',
    templateUrl: 'components/strs-cellphone/strs-cellphone.html',
    scope: {
      model: '='
    },
    link: function link($scope) {
      $scope.countries = [];
      $scope.settings_config = FanGroupService.getSettingsConfig();

      if ($scope.model) {
        $scope.local = $scope.model.localNumber;
        $scope.country = $scope.model.countryCallingCode;
      } else {
        $scope.model = {
          localNumber: null,
          countryCallingCode: null
        };
      }

      $scope.$watch(function () {
        return AppService.getCountries();
      }, function (countries) {
        $scope.countries = countries;
        if ($scope.model) {
          $scope.localNumber = $scope.model.localNumber;
          $scope.countryCallingCode = $scope.model.countryCallingCode;
        } else {
          $scope.localNumber = null;
          $scope.countryCallingCode = null;
        }
      });

      $scope.showIf = function (key) {
        return $scope.settings_config[key] !== 'HIDDEN';
      };

      $scope.disableIf = function (key) {
        return $scope.settings_config[key] === 'VISIBLE_DISABLED';
      };

      $scope.checkIfSAML = (lodash.includes(FanGroupService.getLoginTypes, 'SAML') || lodash.includes(FanGroupService.getLoginTypes, 'OAUTH')) && FanGroupService.getLoginTypes.length === 1;

      $scope.updateLocal = function (local, country) {
        $scope.country = country;
        $scope.local = local;

        $scope.model.localNumber = local;
        $scope.model.countryCallingCode = country;
      };

      $scope.updateCountry = function (country, local) {
        $scope.country = country;
        $scope.local = local;

        $scope.model.countryCallingCode = country;
        $scope.model.localNumber = local;
      };
    }
  };
});