'use strict';

angular.module('app').controller('AuthenticateOAuthController',
/* @ngInject */function ($stateParams, SessionService, $location, FanApiService, AppService, FanGroupService, lodash) {

  var vm = this;
  vm.fanGroup = FanGroupService.getPublicFanGroup();
  vm.providerName = FanGroupService.getProviderNames()[AppService.getLocale()];

  FanApiService.fangroupLookBySlug(FanGroupService.getPublicFanGroup().slug).then(function (look) {
    SessionService.completeOAuth($stateParams.provider, $location.search().code, look.fanGroupId);
  });

  vm.checkIfSSO = function () {
    if (lodash.includes(FanGroupService.getLoginTypes, 'OAUTH')) {
      vm.isOauth = true;
    } else {
      vm.isOauth = false;
    }
    return vm.isOauth;
  };
});