'use strict';

angular.module('app').controller('main_onboarding_OnboardingController', function (AppService, $modal, $scope, $log, $state, $stateParams, lodash, me, APP_SETTINGS, APP_DEFAULTCOLOR, group) {
  /**
   *  CONSTANTS
   */
  var SLICK_SLIDER_DEFAULTS = {
    fanGroupTitle: 'Seaters',
    fanGroupColor: APP_DEFAULTCOLOR,
    fanGroupProfileImage: null
  };

  /**
   *  PRIVATE VARIABLES
   */
  var slickSlider = undefined;

  /**
   *  PRIVATE FUNCTIONS
   */

  /**
   *  VM VARIABLES
   */
  var vm = this;
  vm.me = me;
  vm.carouselShowing = false;
  vm.carouselOptions = lodash.extend(SLICK_SLIDER_DEFAULTS, {
    fanGroupTitle: group.translatedName,
    fanGroupColor: group.color,
    fanGroupProfileImage: group.profileImageUrl
  });
  vm.overlayActive = true;
  vm.slideitems = [{
    slideTemplate: 'INTRO'
  }, {
    slideTemplate: 'INFO',
    slideTitle: 'onboarding-slide-join-title',
    slideIcon: 'fa fa-star',
    text1: 'onboarding-slide-join-text1',
    slideButton: 'onboarding-slide-action-next'
  }, {
    slideTemplate: 'INFO',
    slideTitle: 'onboarding-slide-wecare-title',
    slideIcon: 'fa fa-cogs',
    text1: 'onboarding-slide-wecare-text1',
    text2: 'onboarding-slide-wecare-text2',
    slideButton: 'onboarding-slide-action-next'
  }, {
    slideTemplate: 'INFO',
    slideTitle: 'onboarding-slide-inform-title',
    slideIcon: 'fa fa-envelope',
    text1: 'onboarding-slide-inform-text1',
    text2: 'onboarding-slide-inform-text2',
    slideButton: 'onboarding-slide-action-next'
  }, {
    slideTemplate: 'INFO',
    slideTitle: 'onboarding-slide-confirm-title',
    slideIcon: 'fa fa-check',
    text1: 'onboarding-slide-confirm-text1',
    text2: 'onboarding-slide-confirm-text2',
    slideButton: 'onboarding-slide-action-next'
  }, {
    slideTemplate: 'INFO',
    slideTitle: 'onboarding-slide-receiveticket-title',
    slideIcon: 'fa fa-ticket',
    text1: 'onboarding-slide-receiveticket-text1',
    text2: 'onboarding-slide-receiveticket-text2',
    text2Cls: 'strsui-slide-text-bold',
    slideButton: 'onboarding-slide-action-getstarted',
    buttonAction: vm.goToDashboard
  }];

  /**
   *  VM FUNCTIONS
   */
  vm.initSlider = function () {
    // Get reference to the slick slider component
    slickSlider = angular.element('#onboardingcarousel');
  };

  vm.nextSlide = function () {
    slickSlider.slick('slickNext');
  };

  vm.goToDashboard = function () {
    vm.overlayActive = false;
    $state.go('app.main.group', $stateParams);
  };

  /**
   *  SETUP
   */

  // Mark carousel as being shown
  localStorage.setItem(APP_SETTINGS.onboardingCarouselShownTokenName, true);
});