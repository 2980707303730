'use strict';

angular.module('app').service('SDKService',
/* @ngInject */function ($window, $log, EnvService) {
  if (!$window.SeatersSDK) {
    $log.error('Unable to load Seaters SDK');
    return undefined;
  }

  // var sdk = $window.SeatersSDK;

  // function promiseMiddleware(promise) {
  //   let deferred = $q.defer();
  //   promise.then(
  //       function (arg) {
  //         deferred.resolve(arg);
  //       },
  //       function (arg) {
  //         deferred.reject(arg);
  //       }
  //   );
  //   return deferred.promise;
  // }

  // var client = sdk.wrapClient(promiseMiddleware, sdk.getSeatersClient({
  //     apiPrefix: EnvService.apiLocation
  // }));

  // var service = {
  //   client: client,
  //   fan: sdk.fan,
  //   version: sdk.version
  // };

  // $log.debug('Loaded Seaters Javascript SDK version %s', service.version, service);
  // return service;

  var sdk = {
    client: $window.SeatersSDK.getSeatersClient({
      apiPrefix: EnvService.apiLocation
    }),
    fan: $window.SeatersSDK.fan,
    profiling: $window.SeatersSDK.profiling,
    survey: $window.SeatersSDK.survey,
    version: $window.SeatersSDK.version
  };

  $log.debug('Loaded Seaters Javascript SDK version %s', sdk.version, sdk);
  return sdk;
});