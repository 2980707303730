'use strict';

angular.module('app').service('StrsToastService',
/* @ngInject */function ($log) {
  // Constants
  var TOAST_POSITIONS = {
    TOP: 1,
    BOTTOM: 2
  };

  var TOAST_TYPES = {
    DEFAULT: 1,
    SUCCESS: 2,
    INFO: 3,
    WARNING: 4,
    ERROR: 5
  };

  function getPositions() {
    return TOAST_POSITIONS;
  }

  function getTypes() {
    return TOAST_TYPES;
  }

  return {
    getPositions: getPositions,
    getTypes: getTypes
  };
});