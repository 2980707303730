'use strict';

angular.module('app').service('AppApiService',
/* @ngInject */function (SDKService, APIService, UtilService) {
  var service = {};

  // Environment
  service.getEnv = function () {
    return SDKService.client.appService.getEnv();
  };

  // Countries
  service.getCountries = function () {
    return SDKService.client.appService.getCountries();
  };

  // Languages
  service.getLanguages = function () {
    return SDKService.client.appService.getLanguages();
  };

  service.TARGET_CLIENT = UtilService.identityMap(['WEB', 'IOS', 'ANDROID', 'ALL', 'COCKPIT']);

  // Translations
  service.translationsInTargetLocale = function (target, lang) {
    return SDKService.client.appService.getTranslations(target, lang);
  };

  service.ROLES = {
    FAN: 'FAN',
    FGO: 'FAN_GROUP_OWNER',
    ADMIN: 'ADMIN',
    TRANSLATOR: 'TRANSLATOR'
  };

  return service;
});