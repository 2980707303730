'use strict';

angular.module('app').service('StrsSidebarService',
/* @ngInject */function ($rootScope) {
  var service = {};

  service.isOpen = false;

  service.close = function () {
    if (!$rootScope.app) return;
    service.isOpen = false;
    $rootScope.app.toggleBodyScroll(service.isOpen);
  };

  service.toggle = function () {
    service.isOpen = !service.isOpen;
    $rootScope.app.toggleBodyScroll(service.isOpen);
  };

  return service;
});