'use strict';

angular.module('app').enum('ADYEN_ERRORS', ['CANCELLED', 'REFUSED', 'FATAL']).service('AdyenPaymentSystemService',
/* @ngInject */function ($log, $q, $rootScope, $window, $location, FanApiService, UtilService, ADYEN_ERRORS) {
  var service = {};

  service.pay = function (adyenConfig, currency, amount) {
    $log.debug('AdyenPaymentSystemService - initializing payment for %s %s', amount, currency);
    // Build the payload for the adyen form submission
    var payload = {
      resURL: $location.absUrl().replace(/\?.*/, '') + '?SEATERS_PAYMENT=ADYEN',
      // Should be provided by adyenConfig
      currencyCode: currency.code
    };

    ['merchantAccount', 'merchantReference', 'merchantSig', 'paymentAmount', 'sessionValidity', 'skinCode'].forEach(function (adyenProperty) {
      payload[adyenProperty] = adyenConfig[adyenProperty];
    });
    var url = adyenConfig.hppEndpoint;

    UtilService.postForm(url, payload, null, '_self');
  };

  service.canVerifyPayment = function () {
    return $location.search().SEATERS_PAYMENT === 'ADYEN';
  };

  service.verifyPayment = function () {
    var paymentDetails = $location.search();
    switch (paymentDetails.authResult) {
      case 'AUTHORISED':
        $log.info('AdyenPaymentSystemService - payment authorised');
        return $q.resolve(paymentDetails);
      case 'REFUSED':
        $log.warn('AdyenPaymentSystemService - payment refused', paymentDetails);
        return $q.reject(ADYEN_ERRORS.REFUSED);
      case 'CANCELLED':
        $log.debug('AdyenPaymentSystemService - payment cancelled by user');
        return $q.reject(ADYEN_ERRORS.CANCELLED);
      case 'PENDING':
      case 'ERROR':
        $log.error('AdyenPaymentSystemService - payment fatal error', paymentDetails);
        return $q.reject(ADYEN_ERRORS.FATAL);
      default:
        $log.error('AdyenPaymentSystemService - unexpected authResult', paymentDetails);
        return $q.reject(ADYEN_ERRORS.FATAL);
    }
  };

  return service;
});