'use strict';

angular.module('app').service('MediaService', function ($window) {
  /**
     *  CONSTANTS
     */
  var SCREEN_SIZE_SM_MAX = 640;
  var SCREEN_SIZE_MD_MIN = SCREEN_SIZE_SM_MAX + 1;
  var SCREEN_SIZE_MD_MAX = 1024;
  var SCREEN_SIZE_LG_MIN = SCREEN_SIZE_MD_MAX + 1;

  /**
     *  PRIVATE VARIABLES
     */

  /**
     *  PRIVATE FUNCTIONS
     */
  function isSmall() {
    var currentScreenSize = parseFloat($window.innerWidth);
    return currentScreenSize <= SCREEN_SIZE_SM_MAX;
  }

  function isMedium() {
    var currentScreenSize = parseFloat($window.innerWidth);
    return currentScreenSize >= SCREEN_SIZE_MD_MIN && currentScreenSize <= SCREEN_SIZE_MD_MAX;
  }

  function isLarge() {
    var currentScreenSize = parseFloat($window.innerWidth);
    return currentScreenSize >= SCREEN_SIZE_LG_MIN;
  }

  /**
     *  API FUNCTIONS
     */
  function getScreenHelper() {
    return {
      isSmall: isSmall,
      isMedium: isMedium,
      isLarge: isLarge
    };
  }

  /**
     *  API
     */
  return {
    getScreenHelper: getScreenHelper
  };
});