'use strict';

angular.module('app').run(
/* @ngInject */function ($rootScope, $log, $state, $location, $window, AppService, SessionService, TranslationService, SESSION_EVENTS, HistoryService, ModalsService, HTTP_STATUS, API_EVENTS, hasHTTPCode, URLService, FanGroupService, StrsNotificationsStack, StrsSidebarService, $transitions, $stateParams) {
  // Make translation functions available in $root
  /* eslint-disable id-length */
  $rootScope.tk = TranslationService.tk;
  $rootScope.t = TranslationService.t;
  $rootScope.to = TranslationService.to;
  $rootScope.marked = TranslationService.marked;
  /* eslint-enable id-length */

  // Override default config for the history service
  HistoryService.initialize({
    // Declare the states that we can go back to
    sticky: [/^app\.main\.dashboard\./, 'app.main.group', 'app.main.waitinglist']
  });

  var errorState = false;

  // track errorState here for defaultErrorHandler
  $transitions.onError({}, function (trans) {
    var to = trans.targetState();
    errorState = {
      name: to.name(),
      params: to.params()
    };
    return true;
  });

  $transitions.onStart({}, function (trans) {
    $log.debug('state change: %s', trans.toString());
    var to = trans.targetState().name();

    // Track initial state to be redirect to (in case of guarded initial state)
    AppService.setInitialRedirect(trans);

    // Clear NotificationStack outside the context of a wishlist
    if (to !== 'app.main.waitinglist' && !to.startsWith('app.main.waitinglist.')) {
      StrsNotificationsStack.clear();
    }

    // Close Sidebar if Open
    StrsSidebarService.close();
    return true;
  });

  $state.defaultErrorHandler(function (rejection) {
    var error = rejection && rejection.detail;
    var slug = FanGroupService.getCurrentFanGroupSlug();
    if (!error || !errorState) {
      // should not occur
      $log.error('[app] empty errorState or rejection', errorState, rejection);
      $state.go('app.error', { data: { rejection: rejection, errorState: errorState }, slug: slug });
    } else if (error.routeGuarded) {
      $log.info('[app] guarded redirect to %s(%s)', error.redirect, JSON.stringify(error.redirectParams));
      $state.go(error.redirect, error.redirectParams);
    } else if (errorState.name === 'app.error') {
      $log.warn('[app] could not navigate to app error state - api maintenance?', error);
      $state.go('maintenance');
    } else if (error.status === HTTP_STATUS.SERVICE_NOT_AVAILABLE) {
      // Redirection to error / maintenance
      $log.error('[app] rejection indicates service not available - api maintenance?', error);
      $state.go('maintenance');
    } else if (error.status === HTTP_STATUS.FORBIDDEN) {
      $log.warn('[app] unauthorized access to state "%s"', errorState.name, error);
      // Destroy the session to be sure it was not corrupted
      SessionService.destroySession();
      // redirect handled by SessionService
    } else if (error.status === HTTP_STATUS.PAGE_NOT_FOUND) {
      $log.warn('[app] some state dependencies did not resolve - 404', error);
      $state.go('app.error', { errorCode: HTTP_STATUS.PAGE_NOT_FOUND, data: error, slug: slug });
    } else if (error.status === HTTP_STATUS.UNAUTHORIZED) {
      $log.warn('[app] state dependency returned a 404', error);
      SessionService.handleUnauthorizedHTTPRequest();
      // redirect handled by SessionService
    } else if (error.type === 'not_found') {
      $log.error('[app] something unexpected went wrong', error);
      $state.go('app.error', { data: error, slug: slug });
      // redirect handled by SessionService
    } else {
      $log.error('[app] something unexpected went wrong', error);
      $state.go('app.error', { data: error, slug: slug });
    }
  });

  $transitions.onSuccess({}, function (trans) {
    // Unset errorState
    errorState = false;

    // Re-fetch the query parameters
    // TODO: invoke this inside urlservice before fetching the parameters
    URLService.updateQueryParametersFromURL();

    // Scroll to the top after each state change
    if (angular.isFunction($window.scrollTo)) {
      $window.scrollTo(0, 0);
    }
  });

  $rootScope.$on(SESSION_EVENTS.SESSION_EXPIRED, function () {
    SessionService.handleUnauthorizedHTTPRequest();
  });

  $rootScope.$on(API_EVENTS.ERROR, function (event, rejection) {
    if (hasHTTPCode(HTTP_STATUS.UNAUTHORIZED, rejection)) {
      SessionService.handleUnauthorizedHTTPRequest();
    }
  });
});