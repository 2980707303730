'use strict';

angular.module('app').directive('inviteComment',
/* @ngInject */
function ($window, FanGroupService) {
    return {
        restrict: 'E',
        templateUrl: 'routes/main/waitinglist/guestlist/shared/invite-comment/invite-comment.html',
        scope: {
            guestToComment: '=',
            hideNote: '=',
            postNewNote: '=',
            host: '=',
            location: '='
        },
        link: function link($scope) {
            $scope.groupColor = FanGroupService.getPublicFanGroup().color;

            $scope.getLastCommentDate = function (comment) {
                return $window.moment(comment.publicationDate).fromNow();
            };
        }
    };
});