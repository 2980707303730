'use strict';

angular.module('app').controller('main_invite_InviteController', function (FanApiService, FanGroupService, SurveyService, UtilService, $scope, group, $stateParams, $window, $state, $timeout, $modal, WaitinglistService, URLService) {
    var vm = this;
    $scope.group = FanGroupService.getPublicFanGroup();
    $scope.invitesForWithoutHosts = {};
    $scope.groupColor = $scope.group.color;
    $scope.groupColorLight = 'rgba(' + UtilService.hexToRgb($scope.groupColor).r + ',' + UtilService.hexToRgb($scope.groupColor).g + ',' + UtilService.hexToRgb($scope.groupColor).b + ',0.5)';
    $scope.buttonColor = $scope.groupColor;

    var queryParams = URLService.getQueryParameters();
    $scope.waitingListIdToHighlight = queryParams.WLID || $stateParams.waitinglistId;
    $scope.numberColumn = "8";
    $scope.filterableInviteList = [];
    $scope.step = 'INVITE_LIST';
    if ($scope.group.properties != undefined && $scope.group.properties.invite != undefined && $scope.group.properties.invite.guestSelectionModeNoHostFirstEnabled != undefined && $scope.group.properties.invite.guestSelectionModeNoHostFirstEnabled === true) {
        $scope.numberColumn = "8";
    } else {
        $scope.numberColumn = "8";
    }
    $scope.getData = function () {
        $scope.loading = true;
        var groupId = FanGroupService.getPublicFanGroup().fanGroupId;

        FanApiService.fetchInviteListByGroupId(groupId).then(function (result) {
            $scope.inviteList = result.content.filter(function (invite) {
                $scope.invitesForWithoutHosts = $scope.inviteList;
                return invite.status !== 'CANCELLED';
            });
            return Promise.all($scope.inviteList.map(function (invite) {
                return FanApiService.getCommentOfInvite(invite.id);
            }));
        }).then(function (comments) {
            var hostInvite = null;
            FanApiService.meAsHost().then(function (me) {
                $scope.meAsGuest = {
                    firstName: me.firstName,
                    lastName: me.lastName,
                    guestEmail: me.email,
                    guestId: me.userId,
                    numberOfSeats: 0,
                    importance: 'REQUIRED',
                    host: true
                };

                $scope.inviteList.map(function (invite) {
                    comments.forEach(function (comment) {
                        if (comment.content.length > 0 && comment.content[0].waitingListInvitationId === invite.id) {
                            invite.notes = comment.content;
                        }
                    });

                    if (invite.guest.guestId === $scope.meAsGuest.guestId) {
                        invite.guest.host = true;
                    }

                    if (invite.nonAllocatedSeatsStatus || invite.numberOfNonAllocatedSeats > 0) {
                        hostInvite = _.cloneDeep(invite);
                    }
                    return invite;
                });
                if (hostInvite) {
                    hostInvite.guest.firstName = hostInvite.nonAllocatedSeatsStatus === 'ALLOCATED' ? 'To' : 'Not';
                    hostInvite.guest.lastName = hostInvite.nonAllocatedSeatsStatus === 'ALLOCATED' ? 'Distribute' : 'Allocated';
                    hostInvite.nbrOfRequestedSeats = hostInvite.numberOfNonAllocatedSeats;
                    hostInvite.status = hostInvite.nonAllocatedSeatsStatus;
                    hostInvite.goodId = hostInvite.id;
                    hostInvite.id = 'wrong_id';
                    hostInvite.guest.host = false;
                    if (hostInvite.nbrOfRequestedSeats > 0) {
                        $scope.inviteList.splice(0, 0, hostInvite);
                    }
                }
                $scope.groupByWaitingList();
                $scope.loading = false;
            });
        });
    };

    $scope.showInvitiationReason = function (invite) {
        var groupColor = $scope.groupColor;
        $modal.open({
            templateUrl: 'routes/main/waitinglist/guestlist/invitation-reason.html',
            windowClass: 'medium strs-modal-alt',
            // eslint-disable-next-line no-shadow
            controller: /* @ngInject */function controller($scope, FanApiService) {
                $scope.title = 'Business Reason';
                $scope.reason = invite.invitationReason;
                $scope.groupColor = groupColor;
            }
        });
    };

    function isWaitingListInList(list, waitingList) {
        return list.filter(function (wl) {
            return wl.waitingList.waitingListId === waitingList.waitingListId;
        }).length > 0;
    }

    function mapInviteImportanceToList(inviteGrouped) {
        inviteGrouped.optionalInviteList = [];
        inviteGrouped.importantInviteList = [];
        inviteGrouped.invites.forEach(function (invite) {
            if (invite.importance === 'OPTIONAL') {
                inviteGrouped.optionalInviteList[invite.order] = invite;
            };
            if (invite.importance === 'REQUIRED') {
                inviteGrouped.importantInviteList.push(invite);
            };
        });
        return inviteGrouped;
    }

    $scope.commentOnGuest = function (invite) {
        $scope.showNote = true;
        $scope.inviteToComment = invite;
    };

    $scope.hideNote = function () {
        $scope.showNote = false;
    };

    $scope.postNewNote = function (invite) {
        if (!invite.notes) {
            invite.notes = [];
        }
        invite.notes.push({
            comment: invite.note,
            commentator: {
                id: $scope.meAsGuest.guestId,
                lastName: $scope.meAsGuest.lastName
            },
            new: true
        });
        invite.note = '';
        $scope.postCommentToInvite(invite);
    };

    $scope.goEditGuestList = function (inviteGrouped) {

        var pathname = window.location.pathname;

        if (pathname.includes('brightfish')) {
            $scope.toggleInvitesGroup(inviteGrouped);
        } else {
            if ($scope.group.properties != undefined && $scope.group.properties.invite != undefined && $scope.group.properties.invite.guestSelectionModeNoHostFirstEnabled != undefined && $scope.group.properties.invite.guestSelectionModeNoHostFirstEnabled === true) {
                return $state.go('app.main.waitinglist.guestlistWithoutHosts', {
                    waitinglistId: inviteGrouped.waitingList.waitingListId
                });
            }
            return $state.go('app.main.waitinglist.guestlist', {
                waitinglistId: inviteGrouped.waitingList.waitingListId
            });
        }
    };

    $scope.postCommentToInvite = function (invite) {
        Promise.all(invite.notes.filter(function (note) {
            return note.new;
        }).map(function (note) {
            return FanApiService.postCommentToInviteAsApprover(invite.id, note.comment).catch(console.log);
        }));
    };

    $scope.getLastCommentDate = function (comment) {
        return $window.moment(comment.publicationDate).fromNow();
    };

    $scope.groupByWaitingList = function () {
        $scope.inviteListGrouped = [];
        $scope.inviteList.forEach(function (invite) {
            if (isWaitingListInList($scope.inviteListGrouped, invite.waitinglist)) {
                $scope.inviteListGrouped.forEach(function (wl) {
                    if (wl.waitingList.waitingListId === invite.waitinglist.waitingListId) {
                        wl.totalInvited += 1;
                        wl.totaCost += 25;
                        wl.totalAccepted += invite.status === 'ACCEPTED' ? 1 : 0;
                        wl.invites.push(invite);
                    }
                });
            } else {
                var waitingList = invite.waitinglist;
                $scope.inviteListGrouped.push({
                    waitingList: waitingList,
                    totalAccepted: invite.status === 'ACCEPTED' ? 1 : 0,
                    totalCost: 0,
                    totalInvited: 1,
                    invites: [invite]
                });
            }
        });
        $scope.inviteListGrouped.map(function (inviteGrouped) {
            inviteGrouped.reserveCollapsed = true;
            $scope.getBudgetCost(inviteGrouped);
            return mapInviteImportanceToList(inviteGrouped);
        });

        $scope.initInviteListGrouped = _.cloneDeep($scope.inviteListGrouped);
    };

    $scope.setupCommunication = function (invite) {
        $state.go('app.main.group.communication', {
            inviteId: invite.id,
            waitingListId: invite.waitinglist.waitingListId
        });
    };

    $scope.getWaitingListName = function (waitingList) {
        if (waitingList.experienceName.length > 0) {
            return waitingList.experienceName.filter(function (name) {
                return name.lang === 'en';
            })[0].text;
        }
        if (waitingList.eventName) {
            return waitingList.eventName.filter(function (name) {
                return name.lang === 'en';
            })[0].text;
        }
    };

    $scope.getFormattedDate = function (date) {
        return $window.moment(date).format('L') + ' ' + $window.moment(date).format('LT');
    };

    function checkSeatExportUrl(invite) {
        return angular.isObject(invite) && angular.isObject(invite.position) && angular.isString(invite.position.exportedVoucherUrl) && invite.position.exportedVoucherUrl.length > 0;
    }

    function inviteRefresher(invite) {
        return function () {
            return FanApiService.fetchInviteById(invite.waitinglist.waitingListId, invite.id).then(function (update) {
                angular.extend(invite, update);
                return invite;
            });
        };
    }

    vm.exportSeatParkings = function (invite) {
        WaitinglistService.exportParkingSeat(invite.waitinglist).then(function (updatedWl) {
            angular.extend(invite.waitinglist, updatedWl);
            $window.open(invite.waitinglist.seat.exportedParkingTicketUrl, '_blank');
        }).then(function (processingDone) {
            return false;
        });
    };

    $scope.exportSeat = function (invite) {
        if (invite.position && invite.position.seatStatus === 'ASSIGNED_WITHOUT_SEATS') {
            if (invite.guest.host === true) {
                return SurveyService.fulfillSurveyRequirements(invite.waitinglist.waitingListId, 'AT_CHECKOUT', null, invite.nbrOfRequestedSeats).then(function () {
                    return FanApiService.acceptGuestSeats(invite.id);
                }).then(function (result) {
                    invite.position.seatStatus = 'RSVP_ACCEPTED';
                });
            } else {
                return FanApiService.acceptGuestSeats(invite.id).then(function (result) {
                    invite.position.seatStatus = 'RSVP_ACCEPTED';
                });
            }
        }
        return FanApiService.acceptGuestSeats(invite.id).then(function (result) {
            return $scope.downloadTicketsOfInvite(invite);
        }).catch(console.log);
    };

    $scope.getMappedStatus = function (status) {
        switch (status) {
            case 'APPROVED':
                return {
                    label: 'REVIEWED',
                    color: 'warn'
                };
            case 'REJECTED':
                return {
                    label: 'REJECTED',
                    color: 'error'
                };
            default:
                return {
                    label: 'REQUESTED',
                    color: 'warn'
                };
        }
    };

    $scope.isPublicOfficial = function (guest) {
        if (guest.customInfos) {
            var publics = guest.customInfos.filter(function (customInfo) {
                return customInfo.semanticName === 'public-official';
            });
            if (publics.length > 0) {
                return publics[0].informationValue;
            }
        }
        return false;
    };

    function promiseRejectionHandler(message) {
        return function (err) {
            if (angular.isString(err)) {
                $log.warn(message, err);
                return $q.reject(err);
            }

            return $q.reject(UTIL_GENERAL_ERRORS.SERVER_PROBLEM);
        };
    }

    $scope.exportSeatTry = 0;
    $scope.downloadTicketsOfInvite = function (invite) {
        var windowObjForDownload = $window.open('', '_blank');
        var WAITING_LIST_POLL_TIME_MS = 5000;
        var WAITING_LIST_CONDITION = 10;
        var delay = 500;
        $scope.exportSeatTry += 1;
        return $timeout(delay || 0).then(function () {
            return FanApiService.exportGuestSeats(invite.id);
        }).then(function () {
            return UtilService.retryUntil(
            // Fetch & update the waitinglist
            inviteRefresher(invite),
            // Continue until the export url is ready
            checkSeatExportUrl, WAITING_LIST_CONDITION, WAITING_LIST_POLL_TIME_MS);
        }, $scope.downloadTicketsOfInvite).then(function () {
            // $window.open(invite.position.exportedVoucherUrl, '_blank')
            windowObjForDownload.location.href = invite.position.exportedVoucherUrl;
        });
    };

    $scope.goBack = function () {
        $scope.step = 'EVENTS_LIST';
    };

    $scope.collapseReserve = function (inviteGrouped) {

        // var invitesOptional = inviteGrouped.invites.filter(function (e) {
        //     return e.importance === "OPTIONAL";
        // });
        // inviteGrouped.reserveCollapsed = (invitesOptional.length > 0)? true: false;

        inviteGrouped.reserveCollapsed = !inviteGrouped.reserveCollapsed;
    };

    $scope.getWlPeriod = function (waitingList) {
        var period = WaitinglistService.generateWaitinglistPeriod(waitingList);
        return period;
    };

    $scope.getTotalTicketsApproved = function (inviteGrouped) {
        var total = inviteGrouped.invites.filter(function (invite) {
            return invite.guest.firstName !== 'Not' && invite.status === 'APPROVED';
        }).reduce(function (acc, invite) {
            return acc + Number(invite.nbrOfRequestedSeats) + Number(invite.numberOfNonAllocatedSeats);
        }, 0);
        return total;
    };

    $scope.getTotalTicketsSelected = function (inviteGrouped) {
        var total = inviteGrouped.invites.filter(function (invite) {
            return invite.guest.firstName !== 'Not';
        }).reduce(function (acc, invite) {
            return acc + Number(invite.nbrOfRequestedSeats) + Number(invite.numberOfNonAllocatedSeats);
        }, 0);

        return total;
    };

    $scope.getBudgetCost = function (inviteGrouped) {
        var totalTickets = $scope.getTotalTicketsSelected(inviteGrouped);
        FanApiService.waitinglistPrice(inviteGrouped.waitingList.waitingListId, totalTickets).then(function (price) {
            inviteGrouped.totalCost = price.total;
        });
    };

    $scope.toggleInviteGrouped = function (inviteGrouped) {
        if ($scope.waitingListIdToHighlight) {
            $scope.waitingListIdToHighlight = null;
        } else {
            inviteGrouped.show = !inviteGrouped.show;
        }
    };

    $scope.toggleInvitesGroup = function (inviteGrouped) {

        inviteGrouped.show = !inviteGrouped.show;
        if (inviteGrouped.optionalInviteList.length > 0) {
            return inviteGrouped.reserveCollapsed = !inviteGrouped.reserveCollapsed;
        }
    };

    // TO DO: need re-work this  

    $scope.searchGuest = function (query) {
        var minQueryLength = 3;
        if (query.length > minQueryLength) {
            $scope.inviteListGrouped.map(function (inviteGrouped) {
                var found = false;
                inviteGrouped.importantInviteList.map(function (invite) {
                    var firstNameLower = invite.guest.firstName ? invite.guest.firstName.toLowerCase() : null;
                    var lastNameLower = invite.guest.firstName ? invite.guest.firstName.toLowerCase() : null;
                    if (_.includes(firstNameLower, query.toLowerCase()) || _.includes(lastNameLower, query.toLowerCase())) {
                        found = true;
                    } else {
                        invite.hide = true;
                    }
                    return invite;
                });
                inviteGrouped.show = found;
                return inviteGrouped;
            });
        } else if (query.length === 0) {
            $scope.inviteListGrouped.forEach(function (inviteGrouped) {
                inviteGrouped.show = false;
                inviteGrouped.importantInviteList.map(function (invite) {
                    invite.hide = false;
                    return invite;
                });
            });
        }
    };

    $scope.getData();
});