'use strict';

angular.module('app').service('InterestsService', function ($log, $q, $timeout, SDKService, lodash, AppService, APP_DEFAULT_LOCALE) {
  /**
   *  CONSTANTS
   */
  var FB_GRAPH_CONFIG = {
    host: 'https://graph.facebook.com',
    version: 'v2.10',
    imageEndpoint: 'picture'
  };
  var USER_INTEREST_STATUS = SDKService.profiling.USER_INTEREST_STATUS;
  var USER_INTEREST_ACTION_STATUS = SDKService.profiling.USER_INTEREST_ACTION_STATUS;
  var USER_FAN_ATTRIBUTES_STATUS = SDKService.profiling.USER_FAN_ATTRIBUTES_STATUS;
  var USER_FAN_ATTRIBUTES_ACTION_STATUS = SDKService.profiling.USER_FAN_ATTRIBUTES_ACTION_STATUS;
  var FAN_ATTRIBUTES_STATUS = SDKService.profiling.FAN_ATTRIBUTES_STATUS;

  var sorting = {
    category: {
      order: {
        asc: 'category_order:asc',
        desc: 'category_order:desc'
      },
      name: {
        asc: 'category_name:asc',
        desc: 'category_name:desc'
      }
    }
  };

  /**
   *  PRIVATE VARIABLES
   */
  var fanSDKService = SDKService.client.fanService;
  /**
   *  PRIVATE FUNCTIONS
   */

  /**
   *  API FUNCTIONS
   */
  function loadCategories() {
    return fanSDKService.getProfilingCategories({ sort: sorting.category.order.asc }).then(function (results) {
      return results.items;
    });
  }

  function loadCategory(categoryId) {
    return fanSDKService.getProfilingCategoryById(categoryId);
  }

  function getUserInterests() {
    return fanSDKService.getUserInterests().then(function (results) {
      return results.items;
    });
  }

  function loadCategoriesWithInterests() {
    return $q.all({
      categories: loadCategories(),
      interests: getUserInterests()
    }).then(function (results) {
      var categoriesWithInterests = lodash.map(results.categories, function (category) {
        category.interests = lodash.filter(results.interests, { interest: { categoryId: category.id } });
        return category;
      });
      return categoriesWithInterests;
    });
  }

  function updateUserInterest(id, status) {
    return fanSDKService.updateUserInterest({
      id: id,
      status: status
    });
  }

  function loadFanAttribute(fanAttributeId) {
    return fanSDKService.getProfilingFanAttributeById(fanAttributeId);
  }

  function fetchImageForFanAttribute(fanAttribute) {
    if (fanAttribute.status === FAN_ATTRIBUTES_STATUS.VALIDATED && fanAttribute.externalId) {
      // Return faceboo k graph image
      return $q.when(FB_GRAPH_CONFIG.host + '/' + FB_GRAPH_CONFIG.version + '/' + fanAttribute.externalId + '/' + FB_GRAPH_CONFIG.imageEndpoint);
    }

    // No image if external_identifier is not provided
    return $q.when('');
  }

  function loadFanAttributeDetailImageUrl(fanAttributeDetail) {
    return fetchImageForFanAttribute(fanAttributeDetail).then(function (imageUrl) {
      fanAttributeDetail.imageUrl = imageUrl;
    });
  }

  function loadFanAttributesImages(userFanAttributes) {
    if (lodash.isEmpty(userFanAttributes)) return $q.resolve([]);
    var promises = lodash.map(userFanAttributes, function (userFanAttribute) {
      return fetchImageForFanAttribute(userFanAttribute.fanAttribute).then(function (imageUrl) {
        userFanAttribute.fanAttribute.imageUrl = imageUrl;
        return userFanAttribute;
      });
    });
    return $q.all(promises);
  }

  function loadUserFanAttributes() {
    return fanSDKService.getUserFanAttributes().then(function (userFanAttributes) {
      // load image urls
      return loadFanAttributesImages(userFanAttributes.items);
    });
  }

  function searchFanAttributes(query) {
    var searchForValidated = true;
    return fanSDKService.seachFanAttributes(query, searchForValidated).then(function (fanAttributes) {
      return fanAttributes;
    });
  }

  function linkFanAttribute(fanAttribute) {
    var options = {
      id: fanAttribute.id,
      name: fanAttribute.name,
      status: fanAttribute.id ? USER_FAN_ATTRIBUTES_ACTION_STATUS.link : USER_FAN_ATTRIBUTES_ACTION_STATUS.create
    };
    return fanSDKService.updateUserFanAttribute(options);
  }

  function unlinkFanAttribute(fanAttribute) {
    var options = {
      id: fanAttribute.id,
      status: USER_FAN_ATTRIBUTES_ACTION_STATUS.unlink
    };
    return fanSDKService.updateUserFanAttribute(options);
  }

  function createFanAttributeFromQuery(query) {
    return {
      name: query,
      status: FAN_ATTRIBUTES_STATUS.UNVALIDATED
    };
  }

  /**
   *  API
   */
  return {
    // Categories
    loadCategories: loadCategories,
    loadCategory: loadCategory,
    loadCategoriesWithInterests: loadCategoriesWithInterests,

    // Interests
    getUserInterests: getUserInterests,
    updateUserInterest: updateUserInterest,
    USER_INTEREST_STATUS: USER_INTEREST_STATUS,
    USER_INTEREST_ACTION_STATUS: USER_INTEREST_ACTION_STATUS,

    // Fan attributes
    USER_FAN_ATTRIBUTES_STATUS: USER_FAN_ATTRIBUTES_STATUS,
    USER_FAN_ATTRIBUTES_ACTION_STATUS: USER_FAN_ATTRIBUTES_ACTION_STATUS,
    FAN_ATTRIBUTES_STATUS: FAN_ATTRIBUTES_STATUS,
    loadFanAttribute: loadFanAttribute,
    loadUserFanAttributes: loadUserFanAttributes,
    searchFanAttributes: searchFanAttributes,
    linkFanAttribute: linkFanAttribute,
    unlinkFanAttribute: unlinkFanAttribute,
    createFanAttributeFromQuery: createFanAttributeFromQuery,
    fetchImageForFanAttribute: fetchImageForFanAttribute,
    loadFanAttributeDetailImageUrl: loadFanAttributeDetailImageUrl
  };
});