'use strict';

angular.module('app').controller('main_communication_CommunicationListController', function (FanApiService, FanGroupService, TranslationService, UtilService, AppService, $scope, $window, $stateParams, $state, $timeout) {

    $scope.group = FanGroupService.getPublicFanGroup();
    $scope.defaultCommunication = { body: '', subject: '' };
    $scope.subject = '';
    $scope.groupColor = $scope.group.color;
    $scope.waitingListId = $stateParams.waitingListId;

    function init() {
        $scope.loading = true;
        getInvites().then(removeHostFromList).then(getCommunicationOfInvites).then(getWaitingList).then(finish);
    }

    function getInvites() {
        return FanApiService.fetchInviteList($scope.waitingListId).then(function (result) {
            $scope.inviteList = result.content;
        });
    }

    function removeHostFromList() {
        return FanApiService.meAsHost().then(function (me) {
            var myId = me.userId;
            $scope.inviteList = $scope.inviteList.filter(function (invite) {
                return invite.guest.guestId !== myId;
            });
            return me;
        });
    }

    function mapDefaultCommunication(invite, defaultCommunication) {
        return defaultCommunication.body.replace('${mandrill.userFullName}', invite.guest.firstName + ' ' + invite.guest.lastName).replace('${wishlist.experienceName!event.longName!}', TranslationService.t2(invite.waitinglist.experienceName) || 'en').replace('${position.numberOfSeats}', invite.nbrOfRequestedSeats).replace('\n', '<br/>');
    }

    function getDefaultCommunication(lang) {
        return FanApiService.getCommunicationText($scope.waitingListId, lang).then(function (response) {
            return response;
        }).catch(function (error) {
            console.log('ERROR =>', error);
            $timeout(function () {
                getDefaultCommunication(lang);
            }, 1000);
        });
    }

    function getCommunicationOfInvites() {
        return Promise.all($scope.inviteList.map(function (invite) {
            return FanApiService.getCommunicationOfInvite(invite.id).then(function (communication) {
                if (communication) {
                    return getDefaultCommunication(invite.guest.lang);
                }
                communication.custom = true;
                return Promise.resolve(communication);
            }).then(function (communication) {
                if (communication) {
                    $scope.defaultCommunication.body = mapDefaultCommunication(invite, communication);
                    invite.communication = communication[0] || $scope.defaultCommunication;
                } else {
                    invite.communication = 'ERROR';
                }
                return invite;
            });
        }));
    }

    function getWaitingList() {
        return FanApiService.waitinglistById($scope.waitingListId).then(function (waitingList) {
            $scope.waitingList = waitingList;
        });
    }

    function finish() {
        $scope.inviteList[0].selected = true;
        $scope.inviteSelected = $scope.inviteList[0];
        $scope.loading = false;
    }

    init();

    $scope.selectInvite = function (invite) {
        $scope.inviteSelected = invite;
    };

    $scope.getFormattedDate = function (date) {
        return $window.moment(date).format('dddd, MMMM Do YYYY');
    };

    $scope.getFormattedTime = function (date) {
        return $window.moment(date).format('h:mm a');
    };

    $scope.getGuestCompany = function (guest) {
        if (guest.customInfos) {
            var companies = guest.customInfos.filter(function (customInfo) {
                return customInfo.semanticName === 'company';
            });

            // eslint-disable-next-line no-nested-ternary
            return companies.length > 0 ? companies[0].informationValue ? companies[0].informationValue.toLowerCase() : '-' : '-';
        }
        return '-';
    };

    $scope.getGuestLastRsvp = function (guest) {
        if (guest.customInfos) {
            var lastRSVP = guest.customInfos.filter(function (customInfo) {
                return customInfo.semanticName === 'lastrsvp';
            })[0];

            return lastRSVP ? $window.moment(lastRSVP.informationValue).fromNow() : '-';
        }
        return '-';
    };

    $scope.editInviteCommunication = function () {
        $scope.editionMode = true;
    };

    $scope.saveInviteCommunication = function () {
        if ($scope.inviteSelected.communication.id) {
            FanApiService.updateCommunication($scope.inviteSelected.communication).then(function (result) {
                $scope.editionMode = false;
                $scope.inviteSelected.communication = result;
            });
        } else {
            FanApiService.addCommunicationToInvite($scope.inviteSelected.id, $scope.inviteSelected.communication.body, $scope.inviteSelected.communication.subject).then(function (result) {
                $scope.editionMode = false;
                $scope.inviteSelected.communication = result;
            });
        }
    };

    $scope.backToGuestlist = function () {
        if ($scope.group.properties != undefined && $scope.group.properties.invite != undefined && $scope.group.properties.invite.guestSelectionModeNoHostFirstEnabled != undefined && $scope.group.properties.invite.guestSelectionModeNoHostFirstEnabled === true) {
            return $state.go('app.main.waitinglist.guestlistWithoutHosts', {
                nrOfSeats: vm.nrOfSeats
            });
        }

        return $state.go('app.main.waitinglist.guestlist', {
            nrOfSeats: vm.nrOfSeats
        });
    };

    $scope.confirmCommunications = function () {
        $state.go('app.main.group.myinvites');
    };
});