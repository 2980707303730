'use strict';

angular.module('app').constant('LOCKER_NS_CACHEDRESOURCE', 'cachedresource')
// eslint-disable-next-line no-magic-numbers
.constant('CACHEDRESOURCE_DEFAULT_DELAY', 15000).factory('CachedResourceFactory',
/* @ngInject */function ($q, $log, $timeout, locker, LOCKER_NS_CACHEDRESOURCE, CACHEDRESOURCE_DEFAULT_DELAY) {
  var factory = {};

  factory.createCachedResource = function (cacheKey, promiseFn, driver, initialDelay) {
    if (!angular.isNumber(initialDelay)) {
      initialDelay = CACHEDRESOURCE_DEFAULT_DELAY;
    }

    var cachedResource = {};

    // Localstorage is the default driver
    function cachedResourceLocker() {
      return locker.driver(driver || 'local').namespace(LOCKER_NS_CACHEDRESOURCE);
    }

    cachedResource.data = false;

    var initialized = false;

    /**
    * Return a promise that will immediately resolve from cache if available
    * or otherwise fetch it from the server. If resolved from cache, the returned
    * referenced object will later be updated, so either use the resolved object or
    * keep a reference to it and watch for changes.
    * Note: does not work on primitive data as they can't be 'replaced'
    */
    cachedResource.load = function () {
      var deferred = $q.defer();
      cachedResource.data = cachedResourceLocker().get(cacheKey);
      var wasCached = Boolean(cachedResource.data);
      // Immediately resolve the promise if it was available in cache
      if (wasCached) {
        deferred.resolve(cachedResource.data);
      }
      // Wait an artificial delay if the data was already available to allow other
      // Resources to be fetched first
      var delay = initialized ? 0 : initialDelay;
      var artificialDelay = wasCached ? $timeout(delay) : $q.resolve();
      artificialDelay.then(promiseFn).then(function (data) {
        // Ensure the data fetched from the server is an object
        if (!angular.isObject(data)) {
          throw new Error('cachedResource - can only cache objects and arrays');
        }
        // Initialize if not initialized
        if (!cachedResource.data || data.prototype) {
          cachedResource.data = data instanceof Array ? [] : {};
        }
        // Replace the contents of the referred data object
        angular.extend(cachedResource.data, data);
        cachedResourceLocker().put(cacheKey, data);
        if (!wasCached) {
          deferred.resolve(cachedResource.data);
        }
        // Set initialized for no further delay
        initialized = true;
      }, function (err) {
        if (wasCached) {
          // Since we already resolved the promise, we cannot reject it
          $log.error('cachedResource - unable to fetch data', err);
        } else {
          deferred.reject(err);
        }
      });
      return deferred.promise;
    };

    cachedResource.clear = function () {
      cachedResource.data = false;
      cachedResourceLocker().forget(cacheKey);
    };

    return cachedResource;
  };

  return factory;
});