'use strict';

angular.module('app').enum('UNCONFIRMED_EMAIL_STEPS', ['SEND', 'SENT']).enum('UNCONFIRMED_EMAIL_ACTIONS', ['SEND', 'RESEND']).enum('UNCONFIRMED_EMAIL_ERRORS', ['EXPIRY', 'OTHERS']).constant('SETTINGS_VARS', {
    MIN_PASSWORD_LENGTH: 5
}).controller('main_settings_SettingsController',
/* @ngInject */function (me, AppService, FanApiService, AuthApiService, TranslationService, SessionService, FanGroupService, Alert, $scope, $log, $window, $document, $timeout, UNCONFIRMED_EMAIL_ERRORS, UNCONFIRMED_EMAIL_STEPS, UNCONFIRMED_EMAIL_ACTIONS, $modal, toastr, lodash, SETTINGS_VARS, StrsNotificationsStack, NOTIFICATIONS_STACK_TYPES, ValidationService, ModalsService, $state, GoogleAPIService, $q, SDKService) {
    var actionBtnLabels = {
        SEND: 'waitinglist_unconfirmed-email-modal_send-email-btn',
        RESEND: 'button_text_resend_email'
    };

    var vm = this;
    vm.fanGroup = FanGroupService.getPublicFanGroup();

    vm.navEntries = [{
        text: 'settings_preferences',
        icon: 'fa fa-info',
        anchor: 'settings-preferences'
    }, {
        // Personal infos
        text: 'profile_menu_personal-infos',
        icon: 'fa fa-info',
        anchor: 'settings-user'
    }, {
        // Password
        text: 'profile_menu_password',
        icon: 'fa fa-info',
        anchor: 'settings-password'
    }, {
        // Notifications
        text: 'profile_menu_notifications',
        icon: 'fa-bell-o',
        anchor: 'settings-notifications'
    }, {
        // Notifications
        text: 'profile_menu_optin',
        icon: 'fa-bell-o',
        anchor: 'settings-optin'
    }];

    vm.me = me;

    vm.showOptInPlatform = !vm.fanGroup.optInPlatformHidden;
    vm.showOptInPartner = !vm.fanGroup.optInPartnersHidden;

    vm.UNCONFIRMED_EMAIL_ERRORS = UNCONFIRMED_EMAIL_ERRORS;
    vm.steps = UNCONFIRMED_EMAIL_STEPS;
    vm.step = UNCONFIRMED_EMAIL_STEPS.SEND;

    vm.authyActivated = true;
    vm.checkIfSAML = (lodash.includes(FanGroupService.getLoginTypes, 'SAML') || lodash.includes(FanGroupService.getLoginTypes, 'OAUTH')) && FanGroupService.getLoginTypes.length === 1;

    vm.fg_name = FanGroupService.getPublicFanGroup().translatedName;

    function init() {
        vm.optInPartnersTexts = vm.fanGroup.optInPartnersTexts ? vm.fanGroup.optInPartnersTexts[me.language].replace('{{FN}}', vm.fg_name).replace('{{TC}}', vm.fanGroup.termsAndConditionsUrls[AppService.getLocale()]).replace('{{PP}}', vm.fanGroup.privacyPolicyUrls[AppService.getLocale()]) : 'I wish to receive the coming exclusive offers from the partners of ' + vm.fanGroupName;

        vm.optInPlatformTexts = vm.fanGroup.optInPlatformTexts ? vm.fanGroup.optInPlatformTexts[me.language].replace('{{TC}}', vm.fanGroup.termsAndConditionsUrls[AppService.getLocale()]).replace('{{PP}}', vm.fanGroup.privacyPolicyUrls[AppService.getLocale()]).replace('{{FN}}', vm.fg_name) : 'I wish to receive the coming exclusive offers from the partners of ' + vm.fanGroupName;
    }

    init();

    vm.isPartnersTextsExist = function () {
        return vm.fanGroup.optInPartnersTexts && vm.fanGroup.optInPartnersTexts !== null && vm.fanGroup.optInPartnersTexts !== [] && vm.fanGroup.optInPartnersTexts.en !== '';
    };

    vm.isPlatformTextsExist = function () {
        return vm.fanGroup.optInPlatformTexts && vm.fanGroup.optInPlatformTexts !== null && vm.fanGroup.optInPlatformTexts !== {} && vm.fanGroup.optInPlatformTexts.en !== '';
    };

    vm.settings_config = FanGroupService.getSettingsConfig();

    vm.hasFgTextProperties = function (phraseAppKey) {
        return FanGroupService.getPublicFanGroup() != undefined && FanGroupService.getPublicFanGroup().properties != undefined && FanGroupService.getPublicFanGroup().properties[phraseAppKey] != undefined;
    };

    vm.getFgTextPropertiesIfAny = function (phraseAppKey) {
        if (FanGroupService.getPublicFanGroup().properties[phraseAppKey][AppService.getLocale()] != undefined) {
            return FanGroupService.getPublicFanGroup().properties[phraseAppKey][AppService.getLocale()];
        }
        return FanGroupService.getPublicFanGroup().properties[phraseAppKey].en;
    };

    vm.showIf = function (key) {
        return vm.settings_config[key] !== 'HIDDEN';
    };

    vm.disableIf = function (key) {
        return vm.settings_config[key] === 'VISIBLE_DISABLED';
    };
    // Keep current language
    var currentLanguage = vm.me.language;

    vm.languages = AppService.getLanguages();
    vm.countries = AppService.getCountries();
    vm.signout = SessionService.signout;

    vm.translatedLanguage = function (lang) {
        var translation = TranslationService.to(lang.name, me.language);
        if (angular.isString(translation)) {
            return translation;
        }
        vm.languages.forEach(function (langObj) {
            if (langObj.locale === 'en') {
                return TranslationService.to(langObj.name, me.language);
            }
            return undefined;
        });
        return undefined;
    };

    vm.mobilePhoneNumber = '';
    vm.editmode = '';

    vm.applyLangChange = function () {
        return AppService.changeLocale(vm.me.language).then(function () {
            // Token contains the old locale so we must refresh it
            return SessionService.refreshTokenIfNeeded();
        }).then(function () {
            // Update current set language
            currentLanguage = vm.me.language;
        });
    };

    function checkPersonalInformation(copy) {
        var pi = copy.personalInfo;
        if (!angular.isObject(pi)) {
            return;
        }
        if (angular.isObject(pi.birthDate)) {
            pi.birthDate = pi.birthDate.toISOString().replace(/T.*/, '');
        }
    }

    function checkUser() {
        var copy = angular.copy(vm.me);
        checkPersonalInformation(copy);
        return copy;
    }

    function saveUserErrorHandler(err) {
        return toastr.error(err.data && err.data.message || TranslationService.tk('general_unexpected'));
    }

    function confirmPhoneErrorHandler(err) {
        return err.data && err.data.message ? toastr.error(TranslationService.tk(err.data.message)) : toastr.error(TranslationService.tk('general_unexpected'));
    }

    function checkGeoLocation() {
        if (vm.me.personalInfo.geoLocation || vm.disableIf('address')) return $q.resolve();
        return ModalsService.notification({
            title: 'missing_geo_location_modal_title',
            message: 'missing_geo_location_modal_message',
            acceptLabel: 'problem_ok-btn',
            keyboard: false,
            backdrop: 'static',
            closeOnClick: false
        });
    }

    vm.signout = function () {
        return SessionService.signout();
    };

    vm.toggleMfaActivation = function () {
        if (vm.me.mfaActivated) {
            FanApiService.activateMfa();
        } else {
            FanApiService.desactivateMfa();
        }
    };

    vm.saveUser = function () {

        if (vm.me.mobilePhoneNumber.localNumber === undefined || vm.me.mobilePhoneNumber.localNumber === null) {
            vm.me.mobilePhoneNumber = null;
        }

        // console.log(vm.me.mobilePhoneNumber);
        return FanApiService.updateFan(checkUser()).then(function (user) {
            angular.extend(vm.me, user);
        }).then(function () {
            // Check if we changed language before saving, if so, apply it.
            if (currentLanguage !== vm.me.language) {
                return vm.applyLangChange();
            }
            return checkGeoLocation();
        }).then(function () {
            toastr.success(TranslationService.tk('settings_user-saved-alert'));
            $state.go('app.main.group', {
                slug: FanGroupService.getPublicFanGroup().slug
            });
        }).catch(function (err) {
            // console.log(err);
            $log.debug('could not save user', err);
            saveUserErrorHandler(err);
        });
    };

    vm.savePassword = function (form) {
        if (!form.password || form.password.length === 0) {
            toastr.error(TranslationService.tk('settings_no-password-alert'));
        }
        /*  else if (form.password.length < SETTINGS_VARS.MIN_PASSWORD_LENGTH) {
                toastr.error(TranslationService.tk('settings_password-too-short-alert'));
                // eslint-disable-next-line no-negated-condition
              } */
        else if (!form.passwordConfirm || !form.passwordConfirm.length || form.password.toString() !== form.passwordConfirm.toString()) {
                toastr.error(TranslationService.tk('settings_passwords-dont-match-alert'));
            } else {
                FanApiService.updatePassword(form.passwordCurrent, form.password).then(function () {
                    toastr.success(TranslationService.tk('settings_password-changed-alert'));
                }, function (err) {
                    $log.debug('could not update password', err);
                    toastr.error(TranslationService.tk('general_unexpected'));
                });
            }
    };
    vm.changeEmail = function () {
        ModalsService.changeEmail().then(function (updatedEmail) {
            vm.me.email = updatedEmail;
        });
    };
    vm.requestPhoneValidation = function () {
        // Keep a reference to the setting's controller
        var settings = vm;
        FanApiService.sendValidationSMS(vm.me.mobilePhoneNumber).then(function () {
            var modalInstance = $modal.open({
                windowClass: 'medium strs-modal-alt',
                templateUrl: 'routes/main/settings/confirm-phone.html',
                controllerAs: 'confirmPhone',
                controller: /* @ngInject */function controller() {
                    var modalVm = this;
                    modalVm.invalid = false;
                    modalVm.validatePhone = function (token) {
                        AuthApiService.validateMobile(settings.me.mobilePhoneNumber, token).then(function ( /* Res*/response) {
                            // console.log(response);
                            settings.me.validatedMobilePhone = true;
                            modalInstance.dismiss();
                        }, function (err) {
                            $log.debug('unable to validate phone', err);
                            settings.invalid = true;
                        });
                    };
                }
            });
        }, Alert.handleServerError).catch(confirmPhoneErrorHandler);
    };

    vm.deleteAccount = function () {
        FanApiService.deleteAccount(vm.fanGroup.fanGroupId).then(function () {
            return $modal.open({
                templateUrl: 'routes/main/settings/confirm-delete.modal.html',
                windowClass: 'medium strs-modal-alt',
                controller: /* @ngInject */function controller($scope, SessionService, FanGroupService, FanApiService) {
                    $scope.step = 'CONFIRM';
                    $scope.groupColor = FanGroupService.getPublicFanGroup().color;

                    $scope.close = function () {
                        $scope.$dismiss();
                    };

                    $scope.confirm = function () {
                        $scope.step = 'DONE';
                        window.setTimeout(function () {
                            $scope.$dismiss();
                            SessionService.signout();
                        }, 5000);
                    };
                }
            });
        });
    };

    GoogleAPIService.loadGoogleMapsApi().then(function (googleMapsApi) {
        var autocomplete = '';

        function fillInAddress() {
            var place = autocomplete.getPlace();
            $scope.$apply(function () {
                vm.me.personalInfo.address = SDKService.client.appService.generateSeatersAddress(place);
            });
        }

        function initAutocomplete() {
            var options = {
                types: ['geocode'],
                placeIdOnly: false
            };
            autocomplete = new googleMapsApi.places.Autocomplete(document.getElementById('searchAddress'), options);
            // When the user selects an address from the dropdown
            autocomplete.addListener('place_changed', fillInAddress);
        }

        initAutocomplete();
    });

    vm.sendAEmailConfirmation = function () {
        function setActionBtnLabel(actionName) {
            vm.actionBtnLabel = actionBtnLabels[actionName];
        }

        var redirect = AppService.generateCurrentEncodedRedirect(vm.fanGroup);
        var email = vm.me.email;

        return FanApiService.sendValidationEmail(email, redirect).then(function (response) {
            $log.debug('[unconfirmed email] sent confirmation email');
            vm.step = UNCONFIRMED_EMAIL_STEPS.SENT;
            setActionBtnLabel(UNCONFIRMED_EMAIL_ACTIONS.RESEND);
            StrsNotificationsStack.add({
                type: NOTIFICATIONS_STACK_TYPES.GENERAL_SUCCESS,
                data: vm.prestine ? 'email_confirmation_text_email_sent_successfully' : 'waitinglist_unconfirmed-email-modal_email-resent-alert'
            });
            vm.prestine = false;
        }).catch(function (response) {
            // console.log(response);
            StrsNotificationsStack.runSequence([{
                action: 'remove',
                options: {
                    type: NOTIFICATIONS_STACK_TYPES.CONFIRM_EMAIL
                }
            }, {
                action: 'add',
                options: {
                    type: NOTIFICATIONS_STACK_TYPES.EMAIL_SENDING_ERROR,
                    data: { error: UNCONFIRMED_EMAIL_ERRORS.OTHERS }
                }
            }]);
        });
    };
});