'use strict';

angular.module('app').controller('main_interests_InterestsController', function ($scope, $q, $timeout, lodash, InterestsService, $window, UtilService) {
  /**
  *  CONSTANTS
  */
  var MEDIUM_WINDOW_SIZE = 641;
  var TIMEOUT_REMOVE_LAST_ADDED_FAN_ATTRIBUTE = 5000;
  var TIMEOUT_CATEGORY_SAVED_MESSAGE = 2500;

  /**
  *  PRIVATE VARIABLES
  */

  /**
  *  PRIVATE FUNCTIONS
  */

  /**
  *  VM VARIABLES
  */

  var vm = this;
  vm.loaded = false;

  vm.categories = [];

  vm.USER_INTEREST_STATUS = InterestsService.USER_INTEREST_STATUS;
  vm.USER_INTEREST_ACTION_STATUS = InterestsService.USER_INTEREST_ACTION_STATUS;
  vm.USER_FAN_ATTRIBUTES_STATUS = InterestsService.USER_FAN_ATTRIBUTES_STATUS;
  vm.USER_FAN_ATTRIBUTES_ACTION_STATUS = InterestsService.USER_FAN_ATTRIBUTES_ACTION_STATUS;
  vm.FAN_ATTRIBUTES_STATUS = InterestsService.FAN_ATTRIBUTES_STATUS;

  vm.userFanAttributes = [];
  vm.fanAttributes = [];
  vm.searchFanAttributesQuery = '';
  vm.loadingFanAttributes = false;
  vm.savedTimout = undefined;
  vm.MOBILE_INTEREST_SELECTION_ID_PREFIX = 'MobileInterestSelection';

  /**
  *  VM FUNCTIONS
  */

  vm.isCategoryUpdating = function (category) {
    return category.updating;
  };

  vm.isInterestUpdating = function (category, interest) {
    return vm.isCategoryUpdating(category) && interest.updating;
  };

  vm.setCategoryUpdating = function (category, updating) {
    category.updating = updating;
    category.saved = !updating;

    if (category.saved) {
      if (vm.savedTimout) {
        $timeout.cancel(vm.savedTimout);
        vm.savedTimout = undefined;
      }
      vm.savedTimout = $timeout(function () {
        category.saved = false;
      }, TIMEOUT_CATEGORY_SAVED_MESSAGE);
    }
  };

  vm.setInterestUpdating = function (category, interest, updating) {
    vm.setCategoryUpdating(category, updating);
    $timeout(function () {
      interest.updating = updating;
      $scope.$apply();
    });
  };

  vm.updateInterest = function (category, interest, status) {
    vm.setInterestUpdating(category, interest, true);
    return InterestsService.updateUserInterest(interest.interest.id, status).then(function (updatedUserInterest) {
      interest.status = updatedUserInterest.status;
      vm.setInterestUpdating(category, interest, false);
    }).catch(function (error) {
      vm.setInterestUpdating(category, interest, false);
      // console.log(error);
      // @TODO fix error handeling
    });
  };

  vm.searchFanAttribute = function () {
    $timeout(function () {
      vm.fanAttributes = [];
      $scope.$apply();
    });

    if (!vm.searchFanAttributesQuery) return;

    vm.loadingFanAttributes = true;

    InterestsService.searchFanAttributes(vm.searchFanAttributesQuery).then(function (fanAttributes) {
      var matches = lodash.cloneDeep(fanAttributes);

      // // When no results is showen and
      if (!matches.length) {
        matches.unshift(InterestsService.createFanAttributeFromQuery(vm.searchFanAttributesQuery));
      }

      // Remove if already added to userFanAttributes
      matches = lodash.filter(matches, function (fanAttribute) {
        // Match by id
        var matchById = lodash.find(vm.userFanAttributes, { fanAttribute: { id: fanAttribute.id } });
        if (matchById) return false;
        // In case of UNVALIDATED fanAttribute,  match by name
        var matchByName = lodash.find(vm.userFanAttributes, {
          fanAttribute: { name: fanAttribute.name }
        });
        return !matchByName;
      });

      // Add images to attributes
      var appendImageQueue = [];
      lodash.map(matches, function (fanAttribute) {
        appendImageQueue.push(InterestsService.fetchImageForFanAttribute(fanAttribute).then(function (imageUrl) {
          fanAttribute.imageUrl = imageUrl;
          return fanAttribute;
        }));
      });

      return $q.all(appendImageQueue);
    }).then(function (attributes) {
      $timeout(function () {
        vm.fanAttributes = attributes;
        vm.loadingFanAttributes = false;
        $scope.$apply();
      });
    }).catch(function () {
      $timeout(function () {
        vm.loadingFanAttributes = false;
        $scope.$apply();
      });
    });
  };

  vm.updateUserFanAttributes = function (addedUserFanAttribute) {
    return InterestsService.loadFanAttributeDetailImageUrl(addedUserFanAttribute.fanAttribute).then(function () {
      vm.userFanAttributes.unshift(addedUserFanAttribute);
      // Mark the recently added fan attribute
      addedUserFanAttribute.recentlyAdded = true;
    });
  };

  vm.linkFanAttribute = function (fanAttribute) {
    fanAttribute.updating = true;

    InterestsService.linkFanAttribute(fanAttribute).then(function (addedUserFanAttribute) {
      vm.searchFanAttributesQuery = '';
      vm.fanAttributes = [];
      vm.lastAddedUserFanAttribute = fanAttribute;
      fanAttribute.updating = false;

      $timeout(function () {
        vm.lastAddedUserFanAttribute = undefined;
      }, TIMEOUT_REMOVE_LAST_ADDED_FAN_ATTRIBUTE);

      vm.updateUserFanAttributes(addedUserFanAttribute);
    }).catch(function () {
      $timeout(function () {
        fanAttribute.updating = false;
        $scope.$apply();
      });
    });
  };

  vm.unlinkFanAttribute = function (userFanAttribute) {
    userFanAttribute.updating = true;
    InterestsService.unlinkFanAttribute(userFanAttribute.fanAttribute).then(function () {
      // Remove it from the current userFanAttributes
      lodash.pull(vm.userFanAttributes, userFanAttribute);
      $timeout(function () {
        userFanAttribute.updating = false;
        $scope.$apply();
      });
    }).catch(function () {
      $timeout(function () {
        userFanAttribute.updating = false;
        $scope.$apply();
      });
    });
  };

  vm.isLike = function (status) {
    return status === vm.USER_INTEREST_STATUS.LIKE;
  };

  vm.isNeutral = function (status) {
    return status === vm.USER_INTEREST_STATUS.NEUTRAL;
  };

  vm.isDislike = function (status) {
    return status === vm.USER_INTEREST_STATUS.DISLIKE;
  };

  vm.isNewInterest = function (interest) {
    return interest.status === vm.USER_INTEREST_STATUS.UNKNOWN;
  };

  vm.isFanAttributeValidated = function (fanAttribute) {
    return fanAttribute.status === vm.FAN_ATTRIBUTES_STATUS.VALIDATED;
  };

  vm.isValidated = function (userFanAttribute) {
    return userFanAttribute.fanAttribute && userFanAttribute.fanAttribute.status === vm.FAN_ATTRIBUTES_STATUS.VALIDATED;
  };

  vm.orderByValidity = function (userFanAttribute) {
    return !vm.isValidated(userFanAttribute);
  };

  // Only on mobile
  vm.toggleMobileInterestSelection = function (interest) {
    vm.clearMobileInterestSelection().then(function () {
      interest.mobileInterestSelection = !interest.mobileInterestSelection;
    });
  };

  vm.clearMobileInterestSelection = function () {
    return $timeout(function () {
      return lodash.chain(vm.categories).map('interests').flatten().each(function (interest) {
        interest.mobileInterestSelection = false;
      }).value();
    });
  };
  // Only on mobile
  vm.updateInterestAndCloseMobileInterestSelection = function (category, interest, status) {
    interest.mobileInterestSelection = false;
    vm.updateInterest(category, interest, status);
  };

  vm.clearSearch = function () {
    vm.fanAttributes = [];
    vm.searchFanAttributesQuery = '';
  };

  function clearMobileInterestSelectionOnResize() {
    if ($window.outerWidth >= MEDIUM_WINDOW_SIZE) {
      vm.clearMobileInterestSelection();
    }
  }

  function clearMobileInterestSelectionOnClick(event) {
    if (UtilService.hasAncstor(event, vm.MOBILE_INTEREST_SELECTION_ID_PREFIX)) return;
    vm.clearMobileInterestSelection();
  }

  $window.onresize = clearMobileInterestSelectionOnResize;
  $window.onclick = clearMobileInterestSelectionOnClick;

  $scope.$on('$destroy', function () {
    $window.onresize = null;
    $window.onclick = null;
  });

  /**
  *  SETUP
  */

  $q.all({
    categoriesWithInterests: InterestsService.loadCategoriesWithInterests(),
    userFanAttributes: InterestsService.loadUserFanAttributes()
  }).then(function (results) {
    vm.categories = results.categoriesWithInterests;
    vm.userFanAttributes = results.userFanAttributes;
  }).finally(function () {
    vm.loaded = true;
  });
});