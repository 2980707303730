'use strict';

angular.module('app').directive('strsEventPeriod', function ($rootScope) {
  return {
    templateUrl: 'components/strs-event-period/strs-event-period.html',
    replace: true,
    scope: {
      period: '=',
      flatUi: '=',
      startOnly: '='
    }
  };
});