'use strict';

/**
 * @ngdoc directive
 * @name seatersApp.directive:socialshare
 * @description
 * # socialshare
 */

angular.module('app').directive('socialshare', function () {
  return {
    templateUrl: 'components/socialshare/socialshare.html',
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: {
      socialshareType: '@',
      socialshareId: '='
    },
    controllerAs: 'socialshare',
    controller: function controller( /* @ngInject */$scope, SharingService, Alert, $log, $q) {
      var vm = this;

      function unknownShareType() {
        var msg = '[socialshare] unknown share type passed';
        $log.error(msg);
        Alert.error('general_unexpected');
        $q.reject(msg);
      }

      vm.share = function (provider) {
        switch ($scope.socialshareType) {
          case 'FANGROUP':
            return SharingService.shareFanGroup($scope.socialshareId, provider);
          case 'WAITINGLIST':
            return SharingService.shareWaitingList($scope.socialshareId, provider);
          default:
            return unknownShareType();
        }
      };
    }
  };
});