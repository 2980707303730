/* eslint-disable complexity */
'use strict';

angular.module('app').directive('guestWithoutHosts',
/* @ngInject */
function ($window, $modal, FanGroupService, WaitinglistService, AppService, SessionService, UtilService, FanApiService, SurveyService, $timeout, TranslationService) {
    return {
        restrict: 'E',
        templateUrl: 'routes/main/waitinglist/guestlist/shared/guest-without-hosts/guest-without-hosts.html',
        scope: {
            guest: '=',
            invite: '=',
            group: '=',
            toggle: '=',
            showNote: '=',
            showReason: '=',
            showSeats: '=',
            hideActions: "=",
            columnLength: '=',
            hideNumberTickets: '=',
            showRejection: '=',
            isOrder: '=',
            notAllocatedList: '=',
            location: '=',
            changeSeat: '=',
            nbRequestedSeats: '=',
            showColumnEdit: '=',
            isIncremOrDecrem: '=',
            isToggleDisabled: '=',
            getTickets: '=',
            getTicketsParking: '=',
            invitationReason: '=',
            nonAllocatedSeats: '=',
            survey: '=',
            cancel: '=',
            setupCommunication: '='
        },
        link: function link(scope) {
            scope.groupColor = FanGroupService.getPublicFanGroup().color;
            var Color = $window.net.brehaut.Color;
            var complementaryScheme = Color(scope.groupColor).fiveToneAScheme(scope.groupColor);
            var indexScheme = 3;
            var complementaryColor = complementaryScheme[indexScheme].toRGB();
            var maxColor = 256;
            scope.errorAlreadyEmail = null;

            scope.complementaryColor = 'rgb(' + complementaryColor.red * maxColor + ',' + complementaryColor.green * maxColor + ',' + complementaryColor.blue * maxColor + ')';

            scope.editNewGuest = function () {
                var questions = scope.questions;
                var surveyInstanceId = scope.surveyInstanceId;

                // console.log(scope.guest);
                $modal.open({
                    templateUrl: 'routes/main/waitinglist/guestlist/edit-guest.modal.html',
                    windowClass: 'medium strs-modal-alt',
                    // eslint-disable-next-line no-shadow
                    controller: /* @ngInject */function controller($scope, FanApiService) {
                        // $scope.groupColor = groupColor;

                        $scope.languages = AppService.getLanguages().filter(function (languageItem) {
                            return languageItem.locale === 'nl' || languageItem.locale === 'fr' || languageItem.locale === 'de' || languageItem.locale === 'en';
                        });

                        var company = scope.guest.customInfos.filter(function (el) {
                            return el.semanticName === 'company';
                        });
                        var jobTitle = scope.guest.customInfos.filter(function (el) {
                            return el.semanticName === 'jobtitle';
                        });

                        if (scope.guest.mobileLocalNumber != null) {

                            var phoneNumber = {
                                countryCallingCode: scope.guest.mobileCountryCallingCode,
                                localNumber: scope.guest.mobileLocalNumber
                            };

                            $scope.phoneNumber = phoneNumber;

                            $scope.newGuest = {
                                lastName: scope.guest.lastName,
                                firstName: scope.guest.firstName,
                                email: scope.guest.guestEmail,
                                mobilePhoneNumber: phoneNumber,
                                lang: scope.guest.lang,
                                company: company[0] && company[0].informationValue != undefined ? company[0].informationValue : "",
                                jobtitle: jobTitle[0] && jobTitle[0].informationValue != undefined ? jobTitle[0].informationValue : ""
                            };
                        } else {
                            $scope.newGuest = {
                                lastName: scope.guest.lastName,
                                firstName: scope.guest.firstName,
                                email: scope.guest.guestEmail,
                                lang: scope.guest.lang,
                                company: company[0] && company[0].informationValue != undefined ? company[0].informationValue : "",
                                jobtitle: jobTitle[0] && jobTitle[0].informationValue != undefined ? jobTitle[0].informationValue : ""
                            };
                        }

                        $scope.showError = false;
                        $scope.surveyAnswer = {};
                        $scope.filterableCompanyAndTitle = [];

                        $scope.questions = questions;
                        $scope.confirm = function () {
                            if ($scope.newGuest.company != null) {
                                var customInfo = {
                                    informationValue: $scope.newGuest.company,
                                    semanticName: 'company'
                                };

                                FanApiService.addCustomInfo(AppService.getCurrentGroup().id, scope.guest.guestId, customInfo).then(function (res) {
                                    for (var key in scope.guest.customInfos) {
                                        var element = scope.guest.customInfos[key];
                                        if (element.semanticName === "company") {
                                            return element.informationValue === customInfo.informationValue;
                                        }
                                    }
                                    return scope.guest.customInfos.push(customInfo);
                                }).catch(function (err) {});
                            }

                            if ($scope.newGuest.jobtitle != null) {

                                var _customInfo = {
                                    informationValue: $scope.newGuest.jobtitle,
                                    semanticName: 'jobtitle'
                                };

                                FanApiService.addCustomInfo(AppService.getCurrentGroup().id, scope.guest.guestId, _customInfo).then(function (res) {
                                    for (var key in scope.guest.customInfos) {
                                        var element = scope.guest.customInfos[key];
                                        if (element.semanticName === "jobtitle") {
                                            return element.informationValue === _customInfo.informationValue;
                                        }
                                    }
                                    return scope.guest.customInfos.push(_customInfo);
                                }).catch(function (err) {});
                            }

                            FanApiService.editGuest($scope.newGuest, AppService.getCurrentGroup().id, scope.guest.guestId).then(function (guestRemplaced) {
                                Object.assign(scope.guest, guestRemplaced);

                                return scope.guest;
                            }).then(function () {
                                $scope.$close();
                            }).catch(function (err) {
                                if (err.data.message.indexOf("Given e-mail is already in use by another user") !== -1) {
                                    return $scope.showErrorEmail = true;
                                }

                                if (err.data.message.indexOf("Can not update profile of user who is not only guest") !== -1) {
                                    return $scope.showErrorNotUpdate = true;
                                }

                                if (err.data.message.indexOf("Given e-mail is already in use by another user") === -1 && err.data.message.indexOf("Can not update profile of user who is not only guest") === -1) {
                                    return $scope.showError = true;
                                }
                            });
                        };
                    }
                });
            };

            scope.shouldDisableCheckbox = function (invite, guest) {
                var status = invite ? invite.status : guest.inviteStatus;
                if (status && status !== 'PENDING' && status !== 'REJECTED' && status !== 'CANCELLED') {
                    return true;
                }
                return false;
            };

            function getWaitinglistId(wl) {
                var waitinglist = wl || AppService.getCurrentWaitinglist();
                return waitinglist && (waitinglist.id || waitinglist.waitingListId);
            }

            scope.shouldDisplaySeatChange = function (guest) {
                var status = guest ? guest.status : guest.inviteStatus;
                return scope.location === 'GUESTLIST' && guest.importance && status !== 'CANCELLED';
            };

            scope.getMappedStatus = function (status, invite) {

                if (invite && invite.position && invite.position.status === 'HAS_SEAT') {
                    if (invite.position.seatStatus === 'ASSIGNED' || invite.position.seatStatus === 'ASSIGNED_WITHOUT_SEATS') {
                        status = 'HAS_SEAT';
                    }
                    if (invite.position.seatStatus === 'ACCEPTED' || invite.position.seatStatus === 'RSVP_ACCEPTED') {
                        status = 'ACCEPTED';
                    }
                }

                switch (status) {
                    case 'APPROVED':
                        return scope.location === 'REVIEW' ? {
                            label: TranslationService.tk('reviewed'),
                            class: 'good',
                            iconClass: 'fa-thumbs-o-up'
                        } : {
                            label: TranslationService.tk('requested'),
                            class: 'neutral',
                            iconClass: 'fa-hourglass-half'
                        };
                    case 'PENDING':
                        return {
                            label: TranslationService.tk('requested'),
                            class: 'neutral',
                            iconClass: 'fa-hourglass-half'
                        };
                    case 'HAS_SEAT':
                        return {
                            label: TranslationService.tk('to_accept'),
                            class: 'neutral',
                            iconClass: 'fa-hourglass-half'
                        };
                    case 'ACCEPTED':
                        return {
                            label: TranslationService.tk('accepted'),
                            class: 'good',
                            iconClass: 'fa-check'
                        };

                    case 'REJECTED':
                        return {
                            label: TranslationService.tk('rejected'),
                            class: 'bad',
                            iconClass: 'fa-ban'
                        };
                    default:
                        return {
                            label: TranslationService.tk('not_selected'),
                            class: 'init'
                        };
                }
            };

            scope.shouldDisplayStatus = function (invite) {
                var status = invite.status || invite.inviteStatus;
                return status && status !== 'CANCELLED';
            };

            scope.getJobTitle = function (guest) {

                if (guest.customInfos) {
                    var jobTitles = guest.customInfos.filter(function (customInfo) {
                        return customInfo.semanticName === 'jobtitle';
                    });
                    // console.log(jobTitles)
                    // eslint-disable-next-line no-nested-ternary
                    // console.log(jobTitles)
                    return jobTitles.length > 0 ? jobTitles[0].informationValue ? jobTitles[0].informationValue.toLowerCase() : '-' : '-';
                }
                return '-';
            };

            scope.getGuestCompany = function (guest) {
                if (guest.customInfos) {
                    var companies = guest.customInfos.filter(function (customInfo) {
                        return customInfo.semanticName === 'company';
                    });

                    // eslint-disable-next-line no-nested-ternary
                    return companies.length > 0 ? companies[0].informationValue ? companies[0].informationValue.toLowerCase() : '-' : '-';
                }
                return '-';
            };

            scope.getGuestLastRsvp = function (guest) {
                if (guest.customInfos) {
                    var lastRSVP = guest.customInfos.filter(function (customInfo) {
                        return customInfo.semanticName === 'lastrsvp';
                    })[0];

                    return lastRSVP ? $window.moment(lastRSVP.informationValue).fromNow() : '-';
                }
                return '-';
            };

            scope.getGuestContactCost = function (guest) {
                if (guest.customInfos) {
                    var companies = guest.customInfos.filter(function (customInfo) {
                        return customInfo.semanticName === 'contactcost';
                    });
                    return companies.length > 0 ? companies[0].informationValue.toLowerCase().replace('.00', '') + '€' : '-';
                }

                return '-';
            };

            scope.openWalletUrl = function (guest) {
                if (guest.customInfos) {
                    var walletId = guest.customInfos.filter(function (customInfo) {
                        return customInfo.semanticName === 'crossoutwalletid';
                    });
                    var fgLook = FanGroupService.getPublicFanGroup();
                    var walletUrlFromFgLook = fgLook && fgLook.properties && fgLook.properties.invite && fgLook.properties.invite.walletUrl;
                    var walletUrl = 'https://wallet.crossout.app/pages/wallet/wallet.html?wallet_id=';
                    if (walletId.length > 0) {
                        window.open((walletUrlFromFgLook ? walletUrlFromFgLook : walletUrl) + walletId[0].informationValue, "_blank");'';
                    }
                }
            };

            scope.hasWalletId = function (guest) {
                if (guest.customInfos) {
                    var walletId = guest.customInfos.filter(function (customInfo) {
                        return customInfo.semanticName === 'crossoutwalletid';
                    });
                    if (walletId != null && walletId != undefined && walletId != "" && walletId.length > 0) {
                        return walletId[0] != null && walletId[0] != undefined && walletId[0] != "" && walletId[0] != "null";
                    }
                }
                return false;
            };

            scope.getAttendance = function (guest) {
                if (guest.customInfos) {
                    var rsvpsReceived = guest.customInfos.filter(function (customInfo) {
                        return customInfo.semanticName === 'rsvps';
                    })[0] || {
                        informationValue: 0
                    };

                    var rsvpsConfirmed = guest.customInfos.filter(function (customInfo) {
                        return customInfo.semanticName === 'attendance';
                    })[0] || {
                        informationValue: 0
                    };

                    return rsvpsConfirmed.informationValue + '/' + rsvpsReceived.informationValue;
                }
                return '0/0';
            };
            function inviteRefresher(invite) {
                return function () {
                    return FanApiService.fetchInviteById(invite.waitinglist.waitingListId, invite.id).then(function (update) {
                        angular.extend(invite, update);
                        return invite;
                    });
                };
            }

            function checkSeatExportUrl(invite) {
                return angular.isObject(invite) && angular.isObject(invite.position) && angular.isString(invite.position.exportedParkingTicketUrl) && invite.position.exportedParkingTicketUrl.length > 0;
            }

            scope.isPublicOfficial = function (guest) {
                if (guest.customInfos) {
                    var publics = guest.customInfos.filter(function (customInfo) {
                        return customInfo.semanticName === 'public-official';
                    });
                    if (publics.length > 0) {
                        return publics[0].informationValue;
                    }
                }

                return false;
            };

            scope.downloadParkingSeat = function (invite) {
                var exportTicketsParking = scope.getTicketsParking;
                exportTicketsParking(invite);
            };

            scope.acceptTickets = function (invite) {
                var session = SessionService.currentSession();
                var invitedGuest = invite.guest;
                var waitingListId = invite.waitinglist.waitingListId;
                var fanGroupSlug = FanGroupService.getCurrentFanGroupSlug();
                var token = session.token;
                var lang = AppService.getLocale();

                var groupColor = scope.groupColor;
                var exportSeats = scope.getTickets;
                var exportTicketsParking = scope.getTicketsParking;
                if (invite && invite.position && (invite.position.seatStatus === 'ASSIGNED' || invite.position.seatStatus === 'ACCEPTED')) {
                    var fgLook = FanGroupService.getPublicFanGroup();
                    var skipDownloadPopUp = fgLook && fgLook.properties && fgLook.properties.invite && fgLook.properties.invite.skipDownloadPopUp;
                    if (skipDownloadPopUp) {
                        exportSeats(invite);
                    } else {
                        $modal.open({
                            templateUrl: 'routes/main/waitinglist/guestlist/shared/guest-without-hosts/export-seat.modal.html',
                            windowClass: 'medium strs-modal-alt',
                            controller: function controller(scope, $state, $timeout, FanApiService) {
                                scope.groupColor = groupColor;
                                scope.loading = false;
                                scope.done = false;
                                scope.exportSeats = function () {
                                    exportSeats(invite);
                                    exportTicketsParking(invite);
                                };
                                scope.invite = invite;
                                scope.notifyGuest = function () {
                                    scope.loading = true;
                                    scope.done = false;
                                    return FanApiService.notifyGuest(invite.id).then(function () {
                                        scope.loading = false;
                                        scope.done = true;
                                        scope.$apply();
                                    });
                                };

                                scope.editCommunication = function () {
                                    scope.$dismiss();
                                    $state.go('app.main.group.communication-list', { waitingListId: invite.waitinglist.waitingListId });
                                };
                            }
                        });
                    }
                } else {
                    if (session.identity.email !== invitedGuest.guestEmail) {
                        var prefix = AppService.getEnv().deployTarget === 'qa' ? 'https://invitation.qa-seaters.com/' : 'https://invitation.seaters.com/';
                        var redirectLink = prefix + '?waitingListId=' + waitingListId + '&fanGroupSlug=' + fanGroupSlug + '&inviteId=' + invite.id + '&code=' + token + '&lang=' + lang + '&host=true';
                        location.href = redirectLink;
                    }
                }
            };

            scope.showSurveyAnswers = function (guest) {
                var survey = scope.survey;
                var groupColor = scope.groupColor;
                $modal.open({
                    templateUrl: 'routes/main/waitinglist/guestlist/shared/guest-without-hosts/guest-without-hosts-survey-answers.modal.html',
                    windowClass: 'medium strs-modal-alt',
                    // eslint-disable-next-line no-shadow
                    controller: /* @ngInject */function controller(scope, FanApiService) {

                        scope.groupColor = groupColor;

                        function getQuestionLabel(questionId) {
                            var question = survey.questions.filter(function (q) {
                                return q.id === questionId;
                            })[0];
                            if (question) {
                                return question.label;
                            }
                            return null;
                        }

                        scope.init = function () {
                            scope.loading = true;
                            FanApiService.getSurveyAnswerForHost(survey.id, guest.guestId).then(function (answers) {
                                scope.answers = answers.content.map(function (answer) {
                                    return {
                                        answer: answer.answers[0].answerText,
                                        question: getQuestionLabel(answer.questionId)
                                    };
                                });
                                scope.loading = false;
                            });
                        };

                        scope.init();
                    }
                });
            };

            scope.$on('$viewContentLoaded', function () {
                init();
            });
        }
    };
});