'use strict';

angular.module('app').directive('strsSeparator',
/* @ngInject */function ($log) {
  return {
    restrict: 'E',
    templateUrl: 'components/strs-separator/strs-separator.html',
    transclude: true,
    replace: false,
    scope: {},
    link: function link() {
      return {};
    }
  };
});