/* eslint-disable complexity */
'use strict';

angular.module('app').directive('notAllowedTickets',
/* @ngInject */
function ($window, $modal, FanGroupService, WaitinglistService, AppService, SessionService, UtilService, FanApiService, SurveyService, $timeout, TranslationService) {
    return {
        restrict: 'E',
        templateUrl: 'routes/main/waitinglist/guestlist/shared/guest-without-hosts/not-allowed-tickets.html',
        scope: {
            guest: '=',
            invite: '=',
            group: '=',
            toggle: '=',
            showNote: '=',
            showReason: '=',
            showSeats: '=',
            hideInvitationReason: '=',
            showRejection: '=',
            location: '=',
            changeSeat: '=',
            getTickets: '=',
            getTicketsParking: '=',
            invitationReason: '=',
            nonAllocatedSeats: '=',
            survey: '=',
            cancel: '=',
            setupCommunication: '='
        },
        link: function link($scope) {
            var vm = this;
            $scope.groupColor = FanGroupService.getPublicFanGroup().color;

            var Color = $window.net.brehaut.Color;
            var complementaryScheme = Color($scope.groupColor).fiveToneAScheme($scope.groupColor);
            var indexScheme = 3;
            var complementaryColor = complementaryScheme[indexScheme].toRGB();
            var maxColor = 256;

            $scope.complementaryColor = 'rgb(' + complementaryColor.red * maxColor + ',' + complementaryColor.green * maxColor + ',' + complementaryColor.blue * maxColor + ')';

            $scope.shouldDisableCheckbox = function (invite, guest) {
                var status = invite ? invite.status : guest.inviteStatus;
                if (status && status !== 'PENDING' && status !== 'REJECTED' && status !== 'CANCELLED') {
                    return true;
                }
                return false;
            };

            function getWaitinglistId(wl) {
                var waitinglist = wl || AppService.getCurrentWaitinglist();
                return waitinglist && (waitinglist.id || waitinglist.waitingListId);
            }

            $scope.shouldDisplaySeatChange = function (guest) {
                var status = guest ? guest.status : guest.inviteStatus;
                return $scope.location === 'GUESTLIST' && guest.importance && status !== 'CANCELLED';
            };

            $scope.getMappedStatus = function (status, invite) {

                if (invite && invite.position && invite.position.status === 'HAS_SEAT') {
                    if (invite.position.seatStatus === 'ASSIGNED' || invite.position.seatStatus === 'ASSIGNED_WITHOUT_SEATS') {
                        status = 'HAS_SEAT';
                    }
                    if (invite.position.seatStatus === 'ACCEPTED' || invite.position.seatStatus === 'RSVP_ACCEPTED') {
                        status = 'ACCEPTED';
                    }
                }

                switch (status) {
                    case 'APPROVED':
                        return $scope.location === 'REVIEW' ? {
                            label: TranslationService.tk('reviewed'),
                            class: 'good',
                            iconClass: 'fa-thumbs-o-up'
                        } : {
                            label: TranslationService.tk('requested'),
                            class: 'neutral',
                            iconClass: 'fa-hourglass-half'
                        };
                    case 'PENDING':
                        return {
                            label: TranslationService.tk('requested'),
                            class: 'neutral',
                            iconClass: 'fa-hourglass-half'
                        };
                    case 'HAS_SEAT':
                        return {
                            label: TranslationService.tk('to_accept'),
                            class: 'neutral',
                            iconClass: 'fa-hourglass-half'
                        };
                    case 'ACCEPTED':
                        return {
                            label: TranslationService.tk('accepted'),
                            class: 'good',
                            iconClass: 'fa-check'
                        };

                    case 'REJECTED':
                        return {
                            label: TranslationService.tk('rejected'),
                            class: 'bad',
                            iconClass: 'fa-ban'
                        };
                    default:
                        return {
                            label: TranslationService.tk('not_selected'),
                            class: 'init'
                        };
                }
            };

            $scope.shouldDisplayStatus = function (invite) {
                if (invite && invite.length > 0) {
                    var status = invite.status || invite.inviteStatus;
                    return status && status !== 'CANCELLED';
                }
            };

            $scope.getGuestCompany = function (guest) {
                if (guest.customInfos) {
                    var companies = guest.customInfos.filter(function (customInfo) {
                        return customInfo.semanticName === 'company';
                    });

                    // eslint-disable-next-line no-nested-ternary
                    return companies.length > 0 ? companies[0].informationValue ? companies[0].informationValue.toLowerCase() : '-' : '-';
                }
                return '-';
            };

            $scope.getGuestLastRsvp = function (guest) {
                if (guest.customInfos) {
                    var lastRSVP = guest.customInfos.filter(function (customInfo) {
                        return customInfo.semanticName === 'lastrsvp';
                    })[0];

                    return lastRSVP ? $window.moment(lastRSVP.informationValue).fromNow() : '-';
                }
                return '-';
            };

            $scope.getGuestContactCost = function (guest) {
                if (guest.customInfos) {
                    var companies = guest.customInfos.filter(function (customInfo) {
                        return customInfo.semanticName === 'contactcost';
                    });
                    return companies.length > 0 ? companies[0].informationValue.toLowerCase().replace('.00', '') + '€' : '-';
                }

                return '-';
            };

            $scope.getAttendance = function (guest) {
                if (guest.customInfos) {
                    var rsvpsReceived = guest.customInfos.filter(function (customInfo) {
                        return customInfo.semanticName === 'rsvps';
                    })[0] || {
                        informationValue: 0
                    };

                    var rsvpsConfirmed = guest.customInfos.filter(function (customInfo) {
                        return customInfo.semanticName === 'attendance';
                    })[0] || {
                        informationValue: 0
                    };

                    return rsvpsConfirmed.informationValue + '/' + rsvpsReceived.informationValue;
                }
                return '0/0';
            };

            $scope.isPublicOfficial = function (guest) {
                if (guest.customInfos) {
                    var publics = guest.customInfos.filter(function (customInfo) {
                        return customInfo.semanticName === 'public-official';
                    });
                    if (publics.length > 0) {
                        return publics[0].informationValue;
                    }
                }

                return false;
            };

            $scope.downloadParkingSeat = function (invite) {
                var exportTicketsParking = $scope.getTicketsParking;
                exportTicketsParking(invite);
            };

            $scope.acceptTickets = function (invite) {
                var session = SessionService.currentSession();
                var invitedGuest = invite.guest;
                var waitingListId = invite.waitinglist.waitingListId;
                var fanGroupSlug = FanGroupService.getCurrentFanGroupSlug();
                var token = session.token;
                var lang = AppService.getLocale();
                var groupColor = $scope.groupColor;
                var exportSeats = $scope.getTickets;
                var exportTicketsParking = $scope.getTicketsParking;
                if (invite && invite.position && (invite.position.seatStatus === 'ASSIGNED' || invite.position.seatStatus === 'ACCEPTED')) {
                    var fgLook = FanGroupService.getPublicFanGroup();
                    var skipDownloadPopUp = fgLook && fgLook.properties && fgLook.properties.invite && fgLook.properties.invite.skipDownloadPopUp;
                    if (skipDownloadPopUp) {
                        exportSeats(invite);
                    } else {
                        $modal.open({
                            templateUrl: 'routes/main/waitinglist/guestlist/shared/guest-without-hosts/export-seat.modal.html',
                            windowClass: 'medium strs-modal-alt',
                            controller: function controller($scope, $state, $timeout, FanApiService) {
                                $scope.groupColor = groupColor;
                                $scope.loading = false;
                                $scope.done = false;
                                $scope.exportSeats = function () {
                                    exportSeats(invite);
                                    exportTicketsParking(invite);
                                };
                                $scope.invite = invite;
                                $scope.notifyGuest = function () {
                                    $scope.loading = true;
                                    $scope.done = false;
                                    return FanApiService.notifyGuest(invite.id).then(function () {
                                        $scope.loading = false;
                                        $scope.done = true;
                                        $scope.$apply();
                                    });
                                };

                                $scope.editCommunication = function () {
                                    $scope.$dismiss();
                                    $state.go('app.main.group.communication-list', { waitingListId: invite.waitinglist.waitingListId });
                                };
                            }
                        });
                    }
                } else {
                    if (session.identity.email !== invitedGuest.guestEmail) {
                        var prefix = AppService.getEnv().deployTarget === 'qa' ? 'https://invitation.qa-seaters.com/' : 'https://invitation.seaters.com/';
                        var redirectLink = prefix + '?waitingListId=' + waitingListId + '&fanGroupSlug=' + fanGroupSlug + '&inviteId=' + invite.id + '&code=' + token + '&lang=' + lang + '&host=true';
                        location.href = redirectLink;
                    }
                }
            };

            $scope.showSurveyAnswers = function (guest) {
                var survey = $scope.survey;
                var groupColor = $scope.groupColor;
                $modal.open({
                    templateUrl: 'routes/main/waitinglist/guestlist/shared/guest-without-hosts/guest-without-hosts-survey-answers.modal.html',
                    windowClass: 'medium strs-modal-alt',
                    // eslint-disable-next-line no-shadow
                    controller: /* @ngInject */function controller($scope, FanApiService) {

                        $scope.groupColor = groupColor;

                        function getQuestionLabel(questionId) {
                            var question = survey.questions.filter(function (q) {
                                return q.id === questionId;
                            })[0];
                            if (question) {
                                return question.label;
                            }
                            return null;
                        }

                        $scope.init = function () {
                            $scope.loading = true;
                            FanApiService.getSurveyAnswerForHost(survey.id, guest.guestId).then(function (answers) {
                                $scope.answers = answers.content.map(function (answer) {
                                    return {
                                        answer: answer.answers[0].answerText,
                                        question: getQuestionLabel(answer.questionId)
                                    };
                                });
                                $scope.loading = false;
                            });
                        };

                        $scope.init();
                    }
                });
            };

            $scope.$on('$viewContentLoaded', function () {
                init();
            });
        }
    };
});