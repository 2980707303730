'use strict';

angular.module('app').service('StrsSearchService',
/* @ngInject */function ($stateParams, $state, SEARCH_QUERY_MIN_LENGTH, SEARCH_INPUT_FIELD_ID, $timeout, SDKService, AppService, lodash) {
  var publicSDKService = SDKService.client.publicService;

  var SEARCH_UPDATE_PARAMS_TIMEOUT = 1000;
  var service = {};
  service.query = '';
  function formatSearchResults(results) {
    lodash.each(results.items, function (resultItem) {
      resultItem.translatedExperienceName = resultItem.experienceName && resultItem.experienceName[AppService.locale] || '';
      resultItem.translatedDescription = resultItem.description && resultItem.description[AppService.locale] || '';

      resultItem.translatedGroupName = resultItem.groupName[AppService.locale];
      resultItem.translatedEventName = resultItem.eventName[AppService.locale];
      resultItem.translatedVenueName = resultItem.venueName[AppService.locale];
    });
    return results;
  }
  function search(fanGroupId, query, page) {
    var locale = AppService.locale;
    return publicSDKService.searchWaitingListsInFanGroup(fanGroupId, query, locale, page).then(formatSearchResults);
  }

  service.searchWaitingList = function (groupId, query) {
    return publicSDKService.getWaitingListsInFanGroup(groupId, {
      maxPageSize: 9999,
      page: 0
    }, undefined, query, undefined).then(formatSearchResults);
  };

  service.search = search;
  service.updateParams = function (query) {
    service.query = query;
    return $timeout(function () {
      return $state.go('.', { query: service.query }, { notify: false });
    }, SEARCH_UPDATE_PARAMS_TIMEOUT);
  };

  service.isValidSearchQuery = function (query) {
    return query && query.length > SEARCH_QUERY_MIN_LENGTH;
  };

  service.isValidForNewSearch = function (newQuery, oldQuery) {
    if (newQuery !== oldQuery) {
      return service.isValidSearchQuery(newQuery);
    }
    return false;
  };

  return service;
});