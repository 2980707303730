'use strict';

angular.module('app').factory('MomentService', function ($window) {
  var moment = $window.moment;

  function mapLocaleToMomentLocale(locale) {
    if (locale === 'cn') {
      return 'zh-cn';
    }
    return locale;
  }

  function getMoment(datetime) {
    if (datetime) {
      return moment(datetime);
    }
    return moment;
  }

  function setLocale(locale) {
    moment.locale(mapLocaleToMomentLocale(locale));
  }

  return {
    getMoment: getMoment,
    setLocale: setLocale
  };
});