'use strict';

angular.module('app').constant('GA_ACCOUNT', {
  dev: {
    trackingID: 'UA-57672359-8',
    dimensions: {
      fangroup: 'dimension2',
      waitinglist: 'dimension3'
    }
  },
  prod: {
    trackingID: 'UA-57672359-1',
    dimensions: {
      fangroup: 'dimension2',
      waitinglist: 'dimension3'
    }
  }
}).service('GA_ACCOUNT').config(
/* @ngInject */function (AnalyticsProvider, EnvServiceProvider, GA_ACCOUNT) {
  var hostname = location.hostname;
  var gaAccount = GA_ACCOUNT[EnvServiceProvider.$get().name()];

  AnalyticsProvider.setAccount({
    tracker: gaAccount.trackingID,
    trackEvent: true,
    set: {
      forceSSL: true
    }
  });

  // AnalyticsProvider.enterDebugMode(true);

  var domainName = undefined;
  switch (hostname) {
    case 'localhost':
    case '0.0.0.0':
      domainName = 'none';
      break;
    default:
      domainName = hostname;
  }
  AnalyticsProvider.setDomainName(domainName);

  // Track all routes (default is true).
  AnalyticsProvider.trackPages(false);

  // Track all URL query params (default is false).
  AnalyticsProvider.trackUrlParams(false);

  // Ignore first page view (default is false).
  // Helpful when using hashes and whenever your bounce rate looks obscenely low.
  AnalyticsProvider.ignoreFirstPageLoad(false);
}).service('AnalyticsService', function ($log, $rootScope, $state, $location, $transitions, Analytics, AppService, SessionService, EnvService, API_EVENTS, SESSION_EVENTS, GA_ACCOUNT) {
  function getGroupId(fg) {
    var group = fg || AppService.getCurrentGroup();
    return group && (group.id || group.groupId);
  }

  function getWaitinglistId(wl) {
    var waitinglist = wl || AppService.getCurrentWaitinglist();
    return waitinglist && (waitinglist.id || waitinglist.waitingListId);
  }

  var service = {};

  service.initialize = function () {
    var gaAccount = GA_ACCOUNT[EnvService.name()];
    $rootScope.$on(SESSION_EVENTS.SESSION_CHANGED, function (evt, session) {
      // eslint-disable-line angular/on-watch
      // Set the user id for all tracking purposes
      Analytics.set('&uid', angular.isObject(session) && session.userId || undefined);
    });

    $transitions.onSuccess({}, function (transition) {
      var to = transition.to();
      var toArgs = transition.params();

      var toName = to.name;
      var url = $state.href(toName, toArgs) || $location.path();
      var data = {};
      if (toName.startsWith('app.main.group') || toName.startsWith('app.authenticate.login')) {
        data[gaAccount.dimensions.fangroup] = getGroupId();
      } else if (toName.startsWith('app.main.waitinglist')) {
        data[gaAccount.dimensions.fangroup] = getGroupId();
        data[gaAccount.dimensions.waitinglist] = getWaitinglistId();
      }
      Analytics.trackPage(to.name, url, data);
    });

    $rootScope.$on(API_EVENTS.REQUEST, function (evt, data) {
      // eslint-disable-line angular/on-watch
      // https://developers.google.com/analytics/devguides/collection/analyticsjs/events
      var method = data.request.method.toUpperCase();
      if (method !== 'GET') {
        // Var ts = Date.now();
        data.promise.then(function () {
          var request = data.request;
          var abstractAction = method + '|' + request.abstractUrl;
          var concreteAction = method + '|' + request.url;
          // TODO: mapping from config
          var label = undefined;
          // TODO: mapping from config
          var value = undefined;
          // All API events are interactions
          var nonInteraction = false;
          // TODO translate dimension/metric names to tracker-specific IDs
          var customData = {
            // Metrics
            // 'call_duration' => Date.now() - ts
            // Dimensions
            // 'http_status' => 200
          };
          if (data.request.abstractUrl.startsWith('/api/fan/groups/:')) {
            customData[gaAccount.dimensions.fangroup] = data.request.endpointParams && data.request.endpointParams.groupId || getGroupId();
          }
          if (data.request.abstractUrl.startsWith('/api/fan/waiting-lists/:')) {
            customData[gaAccount.dimensions.fangroup] = getGroupId();
            customData[gaAccount.dimensions.waitinglist] = data.request.endpointParams && data.request.endpointParams.waitingListId || getWaitinglistId();
          }
          Analytics.trackEvent('ABSTRACTAPI', abstractAction, label, value, nonInteraction, customData);
          Analytics.trackEvent('CONCRETEAPI', concreteAction, label, value, nonInteraction, customData);
        });
      }
    });
  };

  return service;
}).run(function (AnalyticsService) {
  AnalyticsService.initialize();
});