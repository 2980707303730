'use strict';

angular.module('app').constant('PASSWORD_CONFIG', {
  MIN_PASSWORD_LENGTH: 10,
  REQUIRED_ALPHA: true,
  REQUIRED_NUMERIC: true,
  REQUIRED_SPECIAL: true,
  REQUIRED_MIX_CASE: true
}).constant('PASSWORD_STRENGTH', {
  EMPTY: 0,
  VERY_WEAK: 1,
  WEAK: 2,
  AVERAGE: 3,
  STRONG: 4,
  VERY_STRONG: 5
}).directive('passwordValidator',
/* @ngInject */function (PASSWORD_CONFIG, PASSWORD_STRENGTH) {
  return {
    restrict: 'E',
    templateUrl: 'components/password-validator/password-validator.html',
    scope: {
      password: '='
    },
    link: function link($scope) {
      $scope.minLength = PASSWORD_CONFIG.MIN_PASSWORD_LENGTH;

      $scope.lastOne = function () {
        var totalIfLastOne = 3;

        return Number($scope.hasMinLength()) + Number($scope.hasMixCase()) + Number($scope.hasAlphaNumeric()) + Number($scope.hasSpecialChar()) === totalIfLastOne;
      };
      $scope.hasMinLength = function () {
        return $scope.password.length >= PASSWORD_CONFIG.MIN_PASSWORD_LENGTH;
      };

      $scope.hasMixCase = function () {
        return $scope.password !== $scope.password.toLowerCase() && $scope.password !== $scope.password.toUpperCase();
      };

      $scope.hasAlphaNumeric = function () {
        var num = /\d/;
        var alpha = /.*[a-zA-Z]+.*/;

        return num.test($scope.password) && alpha.test($scope.password);
      };

      $scope.hasSpecialChar = function () {
        // eslint-disable-next-line no-useless-escape
        var format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        return format.test($scope.password);
      };

      $scope.getPasswordStrengthText = function () {
        switch ($scope.getPasswordStrength()) {
          case PASSWORD_STRENGTH.VERY_WEAK:
            return 'Very Weak';
          case PASSWORD_STRENGTH.WEAK:
            return 'Weak';
          case PASSWORD_STRENGTH.AVERAGE:
            return 'Average';
          case PASSWORD_STRENGTH.STRONG:
            return 'Strong';
          case PASSWORD_STRENGTH.VERY_STRONG:
            return 'Very Strong';
          default:
            return '';
        }
      };
      $scope.getPasswordStrength = function () {
        var password = $scope.password;

        var strength = 0;
        if (password.length > 0) {
          strength = 1;
        }

        if ($scope.hasMinLength()) {
          strength++;
        }
        if ($scope.hasMixCase()) {
          strength++;
        }
        if ($scope.hasAlphaNumeric()) {
          strength++;
        }
        if ($scope.hasSpecialChar()) {
          strength++;
        }

        return strength;
      };
    }
  };
});