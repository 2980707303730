'use strict';

angular.module('app').enum('WIZARD_STEPS', ['INACTIVE', 'AVAILABLE', 'ACTIVE', 'COMPLETED']).enum('CHECKOUT_STEPS', ['BILLING', 'ATTENDEES', 'SURVEY']).enum('ATT_FORM_FIELDS', ['title', 'firstName', 'lastName', 'email', 'phoneNumber', 'address', 'zipCode', 'city', 'country', 'state', 'birthDate', 'citizenshipCountryCode', 'idNumber', 'company', 'language']).enum('ATTENDEE_SAVING_ERRORS', ['SERVER_PROBLEM', 'POLLING_TIMEOUT', 'INVALID_ENTRIES']).service('CheckoutService',
/* @ngInject */function (FanApiService, lodash, FanGroupService, $rootScope, WIZARD_STEPS, ATT_FORM_FIELDS, ATTENDEE_SAVING_ERRORS, $q) {
  var service = {};

  function requiredTextFieldValidator(data, field) {
    var fieldKey = field.key;
    return data && data[fieldKey] && data[fieldKey].length > 0 ? undefined : field.invalidPropertyKey;
  }
  function requiredObjectValidator(data, field) {
    var fieldKey = field.key;
    return data && data[fieldKey] ? undefined : field.invalidPropertyKey;
  }
  function addressValidator(data, field) {
    var address = data[ATT_FORM_FIELDS.address];
    return requiredTextFieldValidator(address, {
      key: 'line1',
      invalidPropertyKey: field.invalidPropertyKey
    });
  }
  function stateCountryValidator(data, field) {
    var country = data[ATT_FORM_FIELDS.country];
    if (country === 'CA' || country === 'US') {
      return requiredTextFieldValidator(data, field);
    }
    return undefined;
  }
  function requiredPhoneValidator(data, field) {
    var fieldKey = field.key;
    return data && data[fieldKey] && data[fieldKey].countryCallingCode && data[fieldKey].localNumber && data[fieldKey].countryCallingCode.length > 0 && data[fieldKey].localNumber.length > 0 ? undefined : field.invalidPropertyKey;
  }

  /**
   * Simple validation
   *
   * @param data
   * @param fields
   */
  function validateFields(data, fields) {
    var validationErrors = undefined;
    var fieldsToValidate = fields.filter(function (field) {
      return field.required;
    });

    fieldsToValidate.forEach(function (field) {
      var validators = field.validators;
      validators.forEach(function (validator) {
        var validationError = validator(data, field);
        if (validationError) {
          if (!validationErrors) {
            validationErrors = {};
          }
          validationErrors[field.key] = validationError;
        }
      });
    });

    data.errors = validationErrors;
  }

  /**
   * Returns a base list of possible personal info form fields to
   * be filled in for billing and/or attendee information
   * @returns {*[]}
   */
  function getBaseFormFields() {
    return [{
      key: ATT_FORM_FIELDS.title,
      name: 'checkout_att_field_title',
      widget: 'titleselect',
      required: false,
      invalidPropertyKey: 'checkout_missingfield_title',
      validators: [requiredTextFieldValidator]
    }, {
      key: ATT_FORM_FIELDS.firstName,
      name: 'checkout_att_field_firstname',
      widget: 'textline',
      required: false,
      invalidPropertyKey: 'checkout_missingfield_firstname',
      validators: [requiredTextFieldValidator]
    }, {
      key: ATT_FORM_FIELDS.lastName,
      name: 'checkout_att_field_lastname',
      widget: 'textline',
      required: false,
      invalidPropertyKey: 'checkout_missingfield_lastname',
      validators: [requiredTextFieldValidator]
    }, {
      key: ATT_FORM_FIELDS.email,
      name: 'checkout_att_field_email',
      widget: 'textline',
      required: false,
      invalidPropertyKey: 'checkout_missingfield_email',
      validators: [requiredTextFieldValidator]
    }, {
      key: ATT_FORM_FIELDS.phoneNumber,
      name: 'checkout_att_field_phone',
      widget: 'phone',
      required: false,
      invalidPropertyKey: 'checkout_missingfield_phone',
      validators: [requiredPhoneValidator]
    }, {
      key: ATT_FORM_FIELDS.birthDate,
      name: 'checkout_att_field_dateofbirth',
      widget: 'datepicker',
      required: false,
      invalidPropertyKey: 'checkout_missingfield_dateofbirth',
      validators: [requiredTextFieldValidator]
    }, {
      key: ATT_FORM_FIELDS.address,
      name: 'checkout_att_field_address',
      widget: 'addresslines',
      required: false,
      invalidPropertyKey: 'checkout_missingfield_address',
      validators: [addressValidator]
    }, {
      key: ATT_FORM_FIELDS.zipCode,
      name: 'checkout_att_field_zip',
      widget: 'textline',
      required: false,
      invalidPropertyKey: 'checkout_missingfield_zip',
      validators: [requiredTextFieldValidator]
    }, {
      key: ATT_FORM_FIELDS.city,
      name: 'checkout_att_field_city',
      widget: 'textline',
      required: false,
      invalidPropertyKey: 'checkout_missingfield_city',
      validators: [requiredTextFieldValidator]
    }, {
      key: ATT_FORM_FIELDS.state,
      name: 'checkout_att_field_state',
      widget: 'textline',
      required: false,
      invalidPropertyKey: 'checkout_missingfield_state',
      validators: [stateCountryValidator]
    }, {
      key: ATT_FORM_FIELDS.country,
      name: 'checkout_att_field_country',
      widget: 'countryselect',
      required: false,
      invalidPropertyKey: 'checkout_missingfield_country',
      validators: [requiredObjectValidator]
    }, {
      key: ATT_FORM_FIELDS.citizenshipCountryCode,
      name: 'checkout_att_field_citizenship',
      widget: 'countryselect',
      required: false,
      invalidPropertyKey: 'checkout_missingfield_citizenship',
      validators: [requiredObjectValidator]
    }, {
      key: ATT_FORM_FIELDS.idNumber,
      name: 'checkout_att_field_passportid',
      widget: 'textline',
      required: false,
      invalidPropertyKey: 'checkout_missingfield_passportid',
      validators: [requiredTextFieldValidator]
    }, {
      key: ATT_FORM_FIELDS.company,
      name: 'checkout_att_field_company',
      widget: 'textline',
      required: false,
      invalidPropertyKey: 'checkout_missingfield_company',
      validators: [requiredTextFieldValidator]
    }, {
      key: ATT_FORM_FIELDS.language,
      name: 'checkout_att_field_language',
      widget: 'language',
      required: false,
      invalidPropertyKey: 'checkout_missingfield_language',
      validators: [requiredTextFieldValidator]
    }];
  }

  /**
   * Gets a single form field from the provided list and key
   * @param fieldList
   * @param fieldKey
   * @returns {*}
   */
  function getFormField(fieldList, fieldKey) {
    var fields = fieldList.filter(function (field) {
      return field.key === fieldKey;
    });
    return fields[0];
  }

  /**
   * Maps the backend api errors back to the attendee objects
   *
   * @param attendees
   * @param errors
   */
  function mapApiValidationErrors(attendees, errors) {
    errors.forEach(function (error) {

      var refs = error.references;
      refs.forEach(function (ref) {
        // Split the reference into the attendee obj ref and the field property
        var fieldRef = ref.split('.');

        // Extract attendee index from the error and get the relevant attendee object
        var attMatcherRegex = /^attendees\[(\d+)\]/;
        var attendee = attendees[parseInt(fieldRef[0].match(attMatcherRegex)[1], 10)];

        // Get attendee property which failed the validation
        var property = fieldRef[1];

        if (!attendee.errors) {
          attendee.errors = [];
        }
        if (angular.isDefined(property)) {
          // For birthdate field, a more specific error, from the api result,
          // Will be passed instead of a general defined locally
          if (property === ATT_FORM_FIELDS.birthDate) {
            attendee.errors[property] = error.errorCode;
          } else {
            var field = getFormField(getBaseFormFields(), property);
            attendee.errors[property] = field.invalidPropertyKey;
          }
        } else {
          attendee.errors.global = error.errorCode;
        }
      });
    });
  }

  /**
   * Returns the list with fields to use when filling in the billing profile
   *
   * @returns {*|{prepareDist, packageDist}|XMLList|XML|{test}|{tmp}}
   */
  service.getBillingInfoFormFields = function () {
    var fields = angular.copy(getBaseFormFields());
    getFormField(fields, ATT_FORM_FIELDS.title).required = false;
    getFormField(fields, ATT_FORM_FIELDS.firstName).required = true;
    getFormField(fields, ATT_FORM_FIELDS.lastName).required = true;
    getFormField(fields, ATT_FORM_FIELDS.email).required = false;
    getFormField(fields, ATT_FORM_FIELDS.phoneNumber).required = true;
    getFormField(fields, ATT_FORM_FIELDS.address).required = true;
    getFormField(fields, ATT_FORM_FIELDS.zipCode).required = true;
    getFormField(fields, ATT_FORM_FIELDS.city).required = true;
    getFormField(fields, ATT_FORM_FIELDS.country).required = true;
    getFormField(fields, ATT_FORM_FIELDS.state).required = true;
    getFormField(fields, ATT_FORM_FIELDS.birthDate).required = false;
    getFormField(fields, ATT_FORM_FIELDS.citizenshipCountryCode).required = false;
    getFormField(fields, ATT_FORM_FIELDS.idNumber).required = false;
    getFormField(fields, ATT_FORM_FIELDS.company).required = false;
    getFormField(fields, ATT_FORM_FIELDS.language).required = false;

    return fields;
  };

  /**
   * Returns the list with fields to use when filling in attendee info;
   * this is based on the related event, where this has been setup
   * @param waitinglist
   * @returns {*}
   */
  service.getAttendeeInfoFormFields = function (waitinglist) {

    if (!service.attendeeInfoRequired(waitinglist)) {
      return [];
    }

    var requiredFields = waitinglist.eventRequiredAttendeeInfo;
    var fields = angular.copy(getBaseFormFields());
    getFormField(fields, ATT_FORM_FIELDS.title).required = requiredFields.indexOf(ATT_FORM_FIELDS.title) !== -1;
    getFormField(fields, ATT_FORM_FIELDS.firstName).required = requiredFields.indexOf(ATT_FORM_FIELDS.firstName) !== -1;
    getFormField(fields, ATT_FORM_FIELDS.lastName).required = requiredFields.indexOf(ATT_FORM_FIELDS.lastName) !== -1;
    getFormField(fields, ATT_FORM_FIELDS.email).required = requiredFields.indexOf(ATT_FORM_FIELDS.email) !== -1;
    getFormField(fields, ATT_FORM_FIELDS.phoneNumber).required = requiredFields.indexOf(ATT_FORM_FIELDS.phoneNumber) !== -1;
    getFormField(fields, ATT_FORM_FIELDS.address).required = requiredFields.indexOf(ATT_FORM_FIELDS.address) !== -1;
    getFormField(fields, ATT_FORM_FIELDS.zipCode).required = requiredFields.indexOf(ATT_FORM_FIELDS.zipCode) !== -1;
    getFormField(fields, ATT_FORM_FIELDS.city).required = requiredFields.indexOf(ATT_FORM_FIELDS.city) !== -1;
    getFormField(fields, ATT_FORM_FIELDS.country).required = requiredFields.indexOf(ATT_FORM_FIELDS.country) !== -1;
    getFormField(fields, ATT_FORM_FIELDS.state).required = requiredFields.indexOf(ATT_FORM_FIELDS.state) !== -1;
    getFormField(fields, ATT_FORM_FIELDS.birthDate).required = requiredFields.indexOf(ATT_FORM_FIELDS.birthDate) !== -1;
    getFormField(fields, ATT_FORM_FIELDS.citizenshipCountryCode).required = requiredFields.indexOf(ATT_FORM_FIELDS.citizenshipCountryCode) !== -1;
    getFormField(fields, ATT_FORM_FIELDS.idNumber).required = requiredFields.indexOf(ATT_FORM_FIELDS.idNumber) !== -1;
    getFormField(fields, ATT_FORM_FIELDS.company).required = requiredFields.indexOf(ATT_FORM_FIELDS.company) !== -1;
    getFormField(fields, ATT_FORM_FIELDS.language).required = requiredFields.indexOf(ATT_FORM_FIELDS.language) !== -1;

    // For attendee info, make the state always requried,
    // To be in line with backend situation, otherwise this will cause
    // Incorrect validation situations
    getFormField(fields, ATT_FORM_FIELDS.state).validators = [requiredTextFieldValidator];

    return fields;
  };

  /**
   * Returns a populated profile with data used from the user profile
   * Only the used billing profile fields are being populated
   *
   * @param userProfile
   * @param onlyBillingProfileFields
   * @returns {{}}
   */
  function presetFromUserProfile(userProfile, onlyBillingProfileFields, waitinglist) {
    var profileForEditing = {};

    profileForEditing[ATT_FORM_FIELDS.firstName] = userProfile.firstName;
    profileForEditing[ATT_FORM_FIELDS.lastName] = userProfile.lastName;

    profileForEditing[ATT_FORM_FIELDS.address] = {};
    profileForEditing[ATT_FORM_FIELDS.address].line1 = userProfile.personalInfo.address.line1;
    profileForEditing[ATT_FORM_FIELDS.address].line2 = userProfile.personalInfo.address.line2;
    profileForEditing[ATT_FORM_FIELDS.address].line3 = userProfile.personalInfo.address.line3;

    profileForEditing[ATT_FORM_FIELDS.zipCode] = userProfile.personalInfo.address.zipCode;
    profileForEditing[ATT_FORM_FIELDS.city] = userProfile.personalInfo.address.city;
    profileForEditing[ATT_FORM_FIELDS.state] = userProfile.personalInfo.address.state;
    profileForEditing[ATT_FORM_FIELDS.country] = userProfile.personalInfo.address.countryCode;
    profileForEditing[ATT_FORM_FIELDS.phoneNumber] = userProfile.mobilePhoneNumber;

    if (!onlyBillingProfileFields) {
      profileForEditing[ATT_FORM_FIELDS.title] = userProfile.personalInfo.title;
      profileForEditing[ATT_FORM_FIELDS.email] = userProfile.email;
      profileForEditing[ATT_FORM_FIELDS.citizenshipCountryCode] = userProfile.personalInfo.citizenshipCountryCode;
      profileForEditing[ATT_FORM_FIELDS.birthDate] = userProfile.personalInfo.birthDate;
      profileForEditing[ATT_FORM_FIELDS.idNumber] = userProfile.personalInfo.idNumber;
      profileForEditing[ATT_FORM_FIELDS.company] = userProfile.personalInfo.company;
      profileForEditing[ATT_FORM_FIELDS.language] = userProfile.personalInfo.language;

      // Remove redundant fields, as backend will fail validation, even if they are not required
      var fields = service.getAttendeeInfoFormFields(waitinglist);
      fields.forEach(function (field) {
        if (!field.required) {
          delete profileForEditing[field.key];
        }
      });
    }

    return profileForEditing;
  }

  /**
   * Presets the billing profile - only limited data needed
   *
   * @param userProfile
   * @returns {{}}
   */
  service.presetBillingProfileFromUserProfile = function (userProfile) {
    return presetFromUserProfile(userProfile, true);
  };

  /**
   * Updates the a user profile with the edited info from billing profile
   * @param billingInfoProfile
   */
  service.updateFanFromBillingProfile = function (userProfile, billingProfile) {
    userProfile.firstName = billingProfile[ATT_FORM_FIELDS.firstName];
    userProfile.lastName = billingProfile[ATT_FORM_FIELDS.lastName];
    userProfile.personalInfo.address = billingProfile[ATT_FORM_FIELDS.address];
    userProfile.personalInfo.address.zipCode = billingProfile[ATT_FORM_FIELDS.zipCode];
    userProfile.personalInfo.address.city = billingProfile[ATT_FORM_FIELDS.city];
    userProfile.personalInfo.address.state = billingProfile[ATT_FORM_FIELDS.state];
    userProfile.personalInfo.address.countryCode = billingProfile[ATT_FORM_FIELDS.country];
    userProfile.mobilePhoneNumber = billingProfile[ATT_FORM_FIELDS.phoneNumber];

    // Update personal profile
    return FanApiService.updateFan(userProfile);
  };

  /**
   * Validates the billing profile
   *
   * @param billingProfile
   * @returns {*|boolean}
   */
  service.validateBillingProfile = function (billingProfile) {
    var settingsConfig = FanGroupService.getSettingsConfig();
    var fields = service.getBillingInfoFormFields().map(function (field) {
      var key = field.key === 'lastName' ? 'lastname' : field.key;
      key = key === 'firstName' ? 'firstname' : key;
      key = lodash.includes(['address', 'zipCode', 'city', 'state', 'country', 'citizenshipCountryCode'], key) ? 'address' : key;
      if (settingsConfig[key] === 'HIDDEN' || settingsConfig[key] === 'VISIBLE_DISABLED') {
        field.required = false;
      }
      return field;
    });
    validateFields(billingProfile, fields);
    return angular.isUndefined(billingProfile.errors);
  };

  /**
   * Prepares and attendee editing wizard step by setting the number of sections that will be needed
   *
   * @param numberOfSeats
   * @returns {Array}
   */
  function prepareAttendeeInfoSteps(numberOfSeats) {
    var attendeeSteps = [];
    for (var i = 0; i < numberOfSeats; i++) {
      var att = {
        attendee: {},
        state: WIZARD_STEPS.INACTIVE
      };
      attendeeSteps.push(att);
    }
    return attendeeSteps;
  }

  /**
   * Filters an attendee for the fields that are required only
   *
   * @param attendeeInfo
   * @param requiredFields
   * @returns {{}}
   */
  function attendeeInfoWithRequiredFieldsOnly(attendeeInfo, requiredFields) {
    var attendeeRequiredFieldsOnly = {};
    requiredFields.forEach(function (fieldKey) {
      attendeeRequiredFieldsOnly[fieldKey] = attendeeInfo[fieldKey];
    });
    return attendeeRequiredFieldsOnly;
  }

  /**
   * Maps a single attendee address object field to a single form field
   * @param attendee
   * @param fromAddressfield
   * @param toFormfield
   */
  function mapFromAddress(attendee, fromAddressfield, toFormfield) {
    if (attendee.address[fromAddressfield] && attendee.address[fromAddressfield].length > 0) {
      attendee[toFormfield] = attendee.address[fromAddressfield];
    }
  }

  /**
   * Preset form-capable attendee data from the attendee address structure
   * @param attendee
   * @returns {*|{prepareDist, packageDist}|XMLList|XML|{test}|{tmp}}
   */
  function presetAttendeeToForm(attendee) {
    var att = angular.copy(attendee);
    if (attendee.address) {
      att[ATT_FORM_FIELDS.address] = attendee.address;
      mapFromAddress(att, 'zipCode', ATT_FORM_FIELDS.zipCode);
      mapFromAddress(att, 'city', ATT_FORM_FIELDS.city);
      mapFromAddress(att, 'state', ATT_FORM_FIELDS.state);
      mapFromAddress(att, 'countryCode', ATT_FORM_FIELDS.country);
    }
    return att;
  }

  /**
   * Maps single address related form field to the address object of an attendee
   *
   * @param attendee
   * @param fromFormfield
   * @param toAddressfield
   */
  function mapToAddress(attendee, fromFormfield, toAddressfield) {
    var value = attendee[fromFormfield];
    if (value && value.length > 0) {
      if (!attendee.address) {
        attendee.address = {};
      }
      attendee.address[toAddressfield] = value;
      delete attendee[fromFormfield];
    }
  }

  /**
   * Map form data to the correct address structure
   *
   * @param attendeeFromForm
   * @returns {*|{prepareDist, packageDist}|XMLList|XML|{test}|{tmp}}
   */
  function updateAttendeeFromForm(attendeeFromForm) {
    mapToAddress(attendeeFromForm, ATT_FORM_FIELDS.zipCode, 'zipCode');
    mapToAddress(attendeeFromForm, ATT_FORM_FIELDS.city, 'city');
    mapToAddress(attendeeFromForm, ATT_FORM_FIELDS.state, 'state');
    mapToAddress(attendeeFromForm, ATT_FORM_FIELDS.country, 'countryCode');
  }

  /**
   * Presets the attendee info, either:
   *  - If no data is available yet, presets the 1st attendee info with data from the user profile
   *  - If data was already existing from a previous attempt to save
   *
   * @param waitinglist
   * @param userProfile
   * @returns {Array}
   */
  service.presetAttendeeInfo = function (waitinglist, userProfile) {
    // If no attendee info is required, then don't bother
    if (!service.attendeeInfoRequired(waitinglist)) {
      return [];
    }

    var attendees = waitinglist.position.attendeesInfo.attendees;
    var numberOfSeats = waitinglist.position.numberOfSeats;

    // Otherwise, attendee info will be required...
    // If no attendees are available, prepare an empty set of
    // Steps and prefill the 1st one with date from the billing profile
    if (!attendees || attendees.length === 0) {
      var attSteps = prepareAttendeeInfoSteps(numberOfSeats);
      attSteps[0].attendee = attendeeInfoWithRequiredFieldsOnly(presetFromUserProfile(userProfile, false, waitinglist), waitinglist.eventRequiredAttendeeInfo);
      return attSteps;
    }

    // If attendees are already available, preset them into the steps
    var attStepsPrefilled = prepareAttendeeInfoSteps(attendees.length);
    for (var i = 0; i < attendees.length; i++) {
      var att = presetAttendeeToForm(attendees[i]);
      attStepsPrefilled[i].attendee = att;
    }
    return attStepsPrefilled;
  };

  /**
   * Checks if all attendees have different first+last names
   * @param attendees
   * @returns {boolean}
   */
  service.checkUniqueAttendeeNames = function (attendeeSteps) {
    var attWithNames = [];
    attendeeSteps.forEach(function (attStep) {
      // Clear any warnings
      delete attStep.attendee.warnings;

      var name = '';
      name = name.concat(attStep.attendee.firstName ? attStep.attendee.firstName.trim().toLowerCase() : '');
      name = name.concat(attStep.attendee.lastName ? attStep.attendee.lastName.trim().toLowerCase() : '');
      if (name.length !== 0) {
        var att = {
          name: name,
          attendee: attStep.attendee
        };
        attWithNames.push(att);
      }
    });
    if (attWithNames.length === 0) {
      return;
    }

    var uniqueNames = attWithNames.map(function (awn) {
      return { count: 1, name: awn.name, attendee: awn.attendee };
    }).reduce(function (acc, value) {
      acc[value.name] = (acc[value.name] || 0) + value.count;
      return acc;
    }, {});
    var duplicateNames = Object.keys(uniqueNames).filter(function (el) {
      return uniqueNames[el] > 1;
    });

    duplicateNames.forEach(function (el) {
      var awns = attWithNames.filter(function (awn) {
        return awn.name.toString() === el.toString();
      });
      awns.forEach(function (awn) {
        awn.attendee.warnings = { global: 'duplicate_names' };
      });
    });
  };

  /**
   * Validates attendee info
   *
   * @param waitinglist
   * @param attendeeInfo
   * @returns {*|boolean}
   */
  service.validateAttendeeInfo = function (waitinglist, attendeeInfo) {
    validateFields(attendeeInfo, service.getAttendeeInfoFormFields(waitinglist));
    return angular.isUndefined(attendeeInfo.errors);
  };

  /**
   * Save all attendee info. After saving a polling check will be done to see if the data is available
   *
   * @param waitinglist
   * @param attendeeSteps
   * @param requiresAttendees
   * @returns {*}
   */
  service.saveAttendeeInfo = function (waitinglist, attendeeSteps, requiresAttendees) {
    if (!requiresAttendees) {
      return $q.resolve();
    }

    var attendees = attendeeSteps.map(function (attStep) {
      // Delete any warnings so that they are not getting saved
      delete attStep.attendee.warnings;
      var attendee = angular.copy(attStep.attendee);
      updateAttendeeFromForm(attendee);
      return attendee;
    });

    return FanApiService.updatePositionAttendeeInfo(waitinglist.waitingListId, attendees).then(undefined, function (err) {
      var error = ATTENDEE_SAVING_ERRORS.SERVER_PROBLEM;
      if (err.errors) {
        mapApiValidationErrors(attendees, err.errors);
        for (var i = 0; i < attendeeSteps.length; i++) {
          attendeeSteps[i].attendee.errors = attendees[i].errors;
        }
        error = ATTENDEE_SAVING_ERRORS.INVALID_ENTRIES;
      }
      return $q.reject(error);
    });
  };

  /**
   * Check if attendee info is required: -> only when at least 1 required field is requested
   *
   * @param waitinglist
   * @returns {*|boolean}
   */
  service.attendeeInfoRequired = function (waitinglist) {
    return waitinglist.eventRequiredAttendeeInfo && waitinglist.eventRequiredAttendeeInfo.length > 0;
  };

  return service;
});