'use strict';

angular.module('app').controller('styleguide_StyleguideController',
/* @ngInject */function ($rootScope, $state) {
  if ($rootScope.app.env.deployTarget === 'production') {
    $state.go('app.main.dashboard');
    return;
  }

  var vm = this;
});