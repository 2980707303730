'use strict';

angular.module('app').controller('FooterController', function (publicFanGroup) {
  /**
   *  CONSTANTS
   */

  /**
   *  PRIVATE VARIABLES
   */

  /**
   *  PRIVATE FUNCTIONS
   */

  /**
   *  VM VARIABLES
   */
  var vm = this;
  vm.fanGroup = publicFanGroup;

  /**
   *  VM FUNCTIONS
   */

  /**
   *  SETUP
   */
});