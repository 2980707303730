'use strict';

/* eslint-disable no-console */

angular.module('app').directive('strsOverlay',
/* @ngInject */function ($document) {
  return {
    restrict: 'E',
    templateUrl: 'components/strs-overlay/strs-overlay.html',
    transclude: true,
    replace: false,
    scope: {
      active: '=?strsOverlayActive',
      allowScrolling: '@?strsOverlayAllowScrolling',
      clickCallback: '&?strsOverlayClickCallback'
    },
    link: function link($scope) {
      function onOverlayClicked($event) {
        $event.preventDefault();

        // When overlay is clicked (click area does not include the actual content)
        if ($scope.clickCallback !== undefined) {
          $scope.clickCallback($event);
        }
      }

      // Fetch the body
      var $body = angular.element($document[0].body);

      // Create and append the overlay element to the body
      var $overlay = angular.element('<div class="strs-overlay__overlay"></div>');
      $body.append($overlay);

      $scope.active = Boolean($scope.active);
      $scope.allowScrolling = Boolean($scope.allowScrolling);

      $scope.$watch('active', function (newValue) {
        if (newValue) {
          $overlay.addClass('strs-overlay__overlay--active');
          $overlay.bind('click', onOverlayClicked);

          if (!$scope.allowScrolling) {
            $body.addClass('strs-overlay__blocked-scrolling');
          }
        } else {
          $overlay.removeClass('strs-overlay__overlay--active');
          $overlay.unbind('click', onOverlayClicked);

          $body.removeClass('strs-overlay__blocked-scrolling');
        }
      });

      // Clean up
      $scope.$on('$destroy', function () {
        $overlay.removeClass('strs-overlay__overlay--active');
        $overlay.unbind('click', onOverlayClicked);

        $body.removeClass('strs-overlay__blocked-scrolling');
        $overlay.remove();
      });
    }
  };
});