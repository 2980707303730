'use strict';

angular.module('app').service('DateService',
/* @ngInject */function ($window, MomentService) {
  var service = {};

  service.utcMoment = function (date) {
    if (angular.isUndefined(date)) {
      // Current moment in utc+0
      return MomentService.getMoment().tz('Europe/London');
    }

    // Convert read the date in the given timezone
    return MomentService.getMoment().tz(date, 'Europe/London');
  };

  service.formatInTz = function (date, format) {
    // Throw away timezone part of the date
    date = date.replace(/[+-]\d\d:\d\d/, '');
    return MomentService.getMoment().utc(date).format(format);
  };

  service.diffDays = function (date1, date2) {
    return MomentService.getMoment()(date1).diff(MomentService.getMoment()(date2), 'days');
  };

  return service;
});