'use strict';

angular.module('app').value('ANONYMOUS_FG_LOOK', {
  slug: '_',
  name: {
    en: 'Seaters'
  },
  translatedName: 'Seaters',
  description: {
    en: 'Seaters - We fill empty seats'
  },
  translatedDescription: 'Seaters - We fill empty seats',
  profileImageUrl: 'https://static.seaters.com/file-2756c9e5-6d1d-40b3-b8db-89907cc03f4f.png',
  coverImageUrl: 'https://static.seaters.com/file-d0597628-311f-45e6-8584-8aee480596c5.png',
  backgroundImageUrl: 'https://static.seaters.com/file-12053e32-40c1-4f1b-92b5-a108491f5843.png',
  color: '0de0af',
  welcomeText: {
    en: ''
  },
  translatedWelcomeText: '',
  accessMode: 'PUBLIC'
});