'use strict';

angular.module('app').factory('NotifierFactory',
/* @ngInject */function () {
  var factory = {};

  factory.createNotifier = function () {
    var notifier = {};
    notifier.listeners = {};

    var Handle = 0;

    notifier.listen = function (listener) {
      if (!angular.isFunction(listener)) {
        throw new Error('notifier expects listener to be a function');
      }
      notifier.listeners[Handle] = listener;
      return Handle++;
    };

    notifier.detacher = function (handle) {
      return function () {
        notifier.detach(handle);
      };
    };

    notifier.detach = function (handle) {
      delete notifier[handle];
    };

    notifier.notify = function () {
      var args = Array.prototype.slice.call(arguments);
      Object.keys(notifier.listeners).forEach(function (handle) {
        notifier.listeners[handle].apply(null, args);
      });
    };

    notifier.notifyAllButMe = function (myHandle /* , ...*/) {
      // Skip first
      var args = Array.prototype.slice.call(arguments, 1);
      Object.keys(notifier.listeners).forEach(function (handle) {
        if (handle !== myHandle) {
          notifier.listeners[handle].apply(null, args);
        }
      });
    };

    return notifier;
  };

  return factory;
});