'use strict';

angular.module('app').enum('GENERAL_PAYMENT_ERRORS', ['CANCELLED', 'PSP_PROBLEM', 'ERROR', 'TIMEDOUT']).enum('SIMPLIFIED_TRANSACTION_STATUS', ['FAILED', 'COMPLETED', 'PENDING', 'NONE']).service('PaymentService',
/* @ngInject */function ($q, $log, $modal, FanApiService, BraintreePaymentSystemService, AdyenPaymentSystemService, SeatersPaymentSystemService, WaitinglistService, Alert, UtilService, TranslationService, ModalsService, SurveyService, ADYEN_ERRORS, GENERAL_PAYMENT_ERRORS, TRANSACTION_POLLING_ERRORS, SIMPLIFIED_TRANSACTION_STATUS, $rootScope) {
  // Alias
  var logP = UtilService.logAndContinue;
  function simplifiedTransactionStatus(position) {
    // CREATING | FAILURE | CREATED | APPROVED | CANCELLED | COMPLETED | REFUNDING | REFUNDED
    var transactionStatus = position.transactionStatus;
    if (!angular.isString(transactionStatus)) {
      return SIMPLIFIED_TRANSACTION_STATUS.NONE;
    } else if (['CREATING', 'CREATED', 'APPROVED', 'REFUNDING'].indexOf(transactionStatus) >= 0) {
      return SIMPLIFIED_TRANSACTION_STATUS.PENDING;
    } else if (['FAILURE', 'REFUNDED', 'CANCELLED'].indexOf(transactionStatus) >= 0) {
      return SIMPLIFIED_TRANSACTION_STATUS.FAILED;
    } else if (transactionStatus === 'COMPLETED') {
      return SIMPLIFIED_TRANSACTION_STATUS.COMPLETED;
    }

    var err = 'unknown transaction status: ' + transactionStatus;
    $log.error('[PaymentService] %s', err);
    throw new Error(err);
  }

  function transactionIsProcessed(waitinglist) {
    var position = waitinglist.position;
    var status = simplifiedTransactionStatus(position);
    switch (status) {
      case SIMPLIFIED_TRANSACTION_STATUS.NONE:
      case SIMPLIFIED_TRANSACTION_STATUS.PENDING:
        return false;
      case SIMPLIFIED_TRANSACTION_STATUS.FAILURE:
      case SIMPLIFIED_TRANSACTION_STATUS.COMPLETED:
        return true;
      default:
        var err = 'unknown simplified transaction status: ' + status;
        $log.error('[PaymentService] %s', err);
        throw new Error(err);
    }
  }

  function waitUntilTransactionIsProcessed(waitinglist) {
    var REFRESH_MIN_MS = 60;
    var REFRESH_MAX_MS = 1000;

    return WaitinglistService.refreshWlUntil(waitinglist, transactionIsProcessed, REFRESH_MIN_MS, REFRESH_MAX_MS).then(undefined, function (err) {
      var msg = 'unexpectedly stopped waiting for transaction to be processed';
      $log.error('[PaymentService] %s', msg, err);
      throw new Error(msg);
    });
  }

  function preparePositionForPayment(waitinglist) {
    // var status = simplifiedTransactionStatus(waitinglist.position);
    var status = SIMPLIFIED_TRANSACTION_STATUS.COMPLETED;
    if (status === SIMPLIFIED_TRANSACTION_STATUS.COMPLETED) {
      var err = 'possible duplicate payment - refresh pressed?';
      $log.warn('[PaymentService] %s', err);
      throw err;
    } else if (status === SIMPLIFIED_TRANSACTION_STATUS.PENDING) {
      return waitUntilTransactionIsProcessed(waitinglist).then(function (updatedWaitinglist) {
        return preparePositionForPayment(updatedWaitinglist);
      });
    } else if (status === SIMPLIFIED_TRANSACTION_STATUS.FAILURE) {
      return FanApiService.deleteWaitinglistTransaction(waitinglist.waitingListId).then(undefined, Alert.handleServerProblem('[PaymentService] unable to remove existing transaction'));
    } else if (status === SIMPLIFIED_TRANSACTION_STATUS.NONE) {
      return $q.resolve(waitinglist);
    }
    return undefined;
  }

  function pspProblemShower(psp) {
    return function (problem) {
      if (problem === 'CANCELLED') {
        Alert.warning('payment_cancelled-warning');
        return $q.reject(GENERAL_PAYMENT_ERRORS.CANCELLED);
      }

      ModalsService.generalProblem('access_seat_psp-error', 'access_seat_psp-error_' + psp + '_' + problem.toLowerCase());
      return $q.reject(GENERAL_PAYMENT_ERRORS.PSP_PROBLEM);
    };
  }

  function validatePaymentServerSide(waitinglist) {
    var position = waitinglist.position;
    var status = simplifiedTransactionStatus(position);
    if (status === SIMPLIFIED_TRANSACTION_STATUS.COMPLETED) {
      return $q.resolve(waitinglist);
    }

    var paymentFailureMessage = position.paymentFailureMessage;
    var transactionStatus = position.transactionStatus;
    $log.warn('[PaymentService] validatePaymentServerSide - failed transaction: "%s" => %s', transactionStatus, paymentFailureMessage);
    ModalsService.generalProblem('access_seat_payment-issue', 'access_seat_payment-issue_info', transactionStatus + ' : ' + paymentFailureMessage);
    return $q.reject(GENERAL_PAYMENT_ERRORS.PSP_PROBLEM);
  }

  function validateAdyenPaymentClientSide() {
    return AdyenPaymentSystemService.verifyPayment()
    // Catch payment verification problems (CLIENTSIDE)
    .then(undefined, pspProblemShower('adyen'));
  }

  function submitAdyenPayment(waitinglist, adyenPayment) {
    return FanApiService.payPosition(waitinglist.waitingListId, {
      adyenPayment: adyenPayment
    });
  }

  function processAdyenPayment(waitinglist) {
    return $q.resolve().then(logP('[PaymentService] preparing position for payment')).then(function () {
      return preparePositionForPayment(waitinglist);
    }).then(logP('[PaymentService] validating adyen payment clientside')).then(function () {
      return validateAdyenPaymentClientSide();
    }).then(logP('[PaymentService] submitting adyen payment')).then(function (adyenPayment) {
      return submitAdyenPayment(waitinglist, adyenPayment);
    }).then(logP('[PaymentService] waiting for server to process payment')).then(function () {
      return waitUntilTransactionIsProcessed(waitinglist);
    }).then(logP('[PaymentService] transaction processed, checking result')).then(function () {
      return validatePaymentServerSide(waitinglist);
    }).then(logP('[PaymentService] transaction successful')).then(undefined, function (err) {
      $log.warn('[PaymentService] processAdyenPayment failed', err);
    });
  }

  function process2StepPayment(waitinglist) {
    // Check for any 2step payment providers
    if (AdyenPaymentSystemService.canVerifyPayment(waitinglist)) {
      return processAdyenPayment(waitinglist);
    }
    $log.warn('[PaymentService] no 2step payment to process');
    return $q.resolve('NOTHING TO DO');
  }

  /**
   * Check if there is a Two-Step payment procedure pending
   */
  function canProcessPayment(waitinglist) {
    if (WaitinglistService.canPay(waitinglist)) {
      if (AdyenPaymentSystemService.canVerifyPayment(waitinglist)) {
        return true;
      }
    }
    return false;
  }

  function pay(waitinglist) {
    // Check for surveys
    return SurveyService.fulfillSurveyRequirements(waitinglist.waitingListId, 'BEFORE_PAYMENT').then(function () {
      return FanApiService.waitinglistPaymentInfo(waitinglist.waitingListId).then(function (paymentInfo) {
        console.log(paymentInfo);
        paymentInfo.idealEnabled = true;
        switch (paymentInfo.paymentSystemType) {
          case 'ADYEN':
            break;
          // return AdyenPaymentSystemService.pay(paymentInfo.adyenConfig, waitinglist.currency);
          case 'BRAINTREE':
            return BraintreePaymentSystemService.pay(waitinglist);
          case 'SEATERS':
            return SeatersPaymentSystemService.pay(waitinglist);
          default:
            throw new Error('unsupported payment system: ' + paymentInfo.paymentSystemType);
        }
      });
    });
  }

  var service = {};
  service.pay = pay;
  service.process2StepPayment = process2StepPayment;
  service.canProcessPayment = canProcessPayment;
  return service;
});