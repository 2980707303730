'use strict';

angular.module('app').controller('AppController', function ($rootScope, $scope, $state, $window, $log, $anchorScroll, $location, toastr, AppService, TranslationService, SessionService, DateService, HistoryService, FanGroupService, $document, $sanitize) {
  var vm = this;

  // Setup rootscope vars
  $rootScope.inState = vm.inState = AppService.inState;
  $rootScope.inStateHierarchy = vm.inStateHierarchy = AppService.inStateHierarchy;
  $rootScope.stateHref = vm.stateHref = AppService.stateHref;
  $rootScope.goTo = vm.goTo = AppService.goTo;
  $rootScope.formatInTz = vm.formatInTz = DateService.formatInTz;
  $rootScope.goBack = vm.goBack = HistoryService.goBack;
  $rootScope.backgroundImage = vm.backgroundImage = AppService.getBackground;

  // Setup local vm vars
  vm.locale = AppService.getLocale;
  vm.languages = AppService.getLanguages;
  vm.color = AppService.getColor;
  vm.getTransColor = AppService.getTransColor;
  vm.slug = FanGroupService.getCurrentFanGroupSlug;
  vm.name = AppService.getGroupName;
  vm.welcomeText = AppService.getGroupWelcomeText;
  vm.description = AppService.getGroupDescription;

  var defaultRulesLinks = {
    en: 'https://seaters.zendesk.com/hc/en-us',
    nl: 'https://seaters.zendesk.com/hc/nl',
    fr: 'https://seaters.zendesk.com/hc/fr'
  };
  // Experiencing some issues with group
  if (FanGroupService.getPublicFanGroup() === undefined) {
    vm.privacyPolicyUrl = 'http://www.getseaters.com/privacy/';
    vm.termsAndConditionsUrl = 'http://www.getseaters.com/user-agreement/';
    vm.rulesLinks = defaultRulesLinks[vm.locale()] || defaultRulesLinks['en'];
  } else {
    vm.privacyPolicyUrl = FanGroupService.getPublicFanGroup().privacyPolicyUrls[vm.locale()] === '' ? 'http://www.getseaters.com/privacy/' : $sanitize(FanGroupService.getPublicFanGroup().privacyPolicyUrls[vm.locale()]).replace(/javascript/g, '');
    vm.termsAndConditionsUrl = FanGroupService.getPublicFanGroup().termsAndConditionsUrls[vm.locale()] === '' ? 'http://www.getseaters.com/user-agreement/' : $sanitize(FanGroupService.getPublicFanGroup().termsAndConditionsUrls[vm.locale()]).replace(/javascript/g, '');
    vm.rulesLinks = FanGroupService.getPublicFanGroup().rulesLinks[vm.locale()] === '' ? defaultRulesLinks[vm.locale()] || defaultRulesLinks['en'] : $sanitize(FanGroupService.getPublicFanGroup().rulesLinks[vm.locale()]).replace(/javascript/g, '');
  }
  // Combine server and client env data
  vm.env = angular.extend({}, AppService.getEnv(), $window.ENV);

  // Change current locale
  vm.changeLocale = function (locale) {
    $log.debug('[AppController] changing locale', locale);
    vm.changingLocale = true;
    var promise = AppService.changeLocale(locale).then(undefined, function (err) {
      toastr.error(TranslationService.tk('general.unexpected-problem'));
      $log.error('unable to change locale', err);
    });
    promise.then(function () {
      vm.changingLocale = false;
    }).catch(function () {
      vm.changingLocale = false;
    });
    return promise;
  };

  vm.scrollTo = function (hash) {
    if ($location.hash() === hash) {
      // Call $anchorScroll() explicitly,
      // Since $location.hash hasn't changed
      $anchorScroll();
    } else {
      // Set the $location.hash to `newHash` and
      // $anchorScroll will automatically scroll to it
      $location.hash(hash);
    }
  };

  /**
  * Navigate to last known fan group about page
  */
  vm.lastGroupAbout = function () {
    $state.go('app.main.group.about', {
      slug: FanGroupService.getPublicFanGroup().slug
    });
  };

  /**
  * Navigate to last known fan group home page
  */
  vm.lastGroupHome = function () {
    $state.go('app.main.group', {
      slug: FanGroupService.getPublicFanGroup().slug
    });
  };

  /**
  * Change scrolling state from Body
  *
  */
  function disableBodyScroll() {
    $document.find('body').addClass('no-scroll');
  }

  function enableBodyScroll() {
    $document.find('body').removeClass('no-scroll');
  }

  function toggleBodyScroll(isOpen) {
    if (isOpen) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
  }
  vm.disableBodyScroll = disableBodyScroll;
  vm.enableBodyScroll = enableBodyScroll;
  vm.toggleBodyScroll = toggleBodyScroll;

  // Make app available through $root
  $rootScope.app = vm;
});