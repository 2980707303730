'use strict';

// App setting

var APP_SETTINGS = {
  // Onboarding
  onboardingToastGroupShownTokenName: 'onboardingToastGroupShown',
  onboardingToastWaitingListShownTokenName: 'onboardingToastWaitingListShown',
  onboardingCarouselShownTokenName: 'onboardingCarouselShown'
};

// Expose
angular.module('app').constant('APP_SETTINGS', APP_SETTINGS);