'use strict';

angular.module('app').directive('strsWizardstep', function () {
  /* @ngInject */
  function wizardStepController(WIZARD_STEPS) {
    var vm = this;
    vm.WIZARD_STEPS = WIZARD_STEPS;
  }

  return {
    templateUrl: 'components/strs-wizardstep/strs-wizardstep.html',
    restrict: 'E',
    scope: {
      steptitle: '=',
      step: '=',
      state: '=',
      connects: '='
    },
    replace: false,
    transclude: false,
    controller: wizardStepController,
    controllerAs: 'vm'
  };
});