'use strict';

angular.module('app').controller('AuthenticateLandingController', function (publicFanGroup, $base64, $location, $state, $stateParams, lodash, FanGroupService, SessionService, AuthApiService, WaitinglistService) {
  /**
   *  CONSTANTS
   */

  /**
   *  PRIVATE VARIABLES
   */

  /**
   *  PRIVATE FUNCTIONS
   */

  /**
   *  VM VARIABLES
   */

  var vm = this;
  vm.fanGroup = publicFanGroup;
  vm.intervalFunc = "";
  vm.showErrorLoginMessage = false;
  vm.QRCodeExpired = false;
  vm.redirectURI = FanGroupService.getOauthURl();
  vm.redirect = JSON.parse(localStorage.getItem('app.InitialRedirect'));
  vm.checkIfPIM = lodash.includes(FanGroupService.getLoginTypes, 'PIM');
  vm.isMobile = isMobileDevice();
  vm.providers = FanGroupService.getProviders();
  vm.pimProvier = FanGroupService.getProviders().filter(function (provider) {
    return provider.loginType == 'PIM';
  });
  vm.pimOnly = vm.providers.length === 1 && vm.pimProvier.length === 1 && vm.checkIfPIM;

  function init() {
    if (vm.pimProvier.length == 1) {
      isMobileDevice();
      generetedQRCode(vm.pimProvier[0]);
    }

    /*
        if(vm.checkIfPIM === true){
          isMobileDevice();
          generetedQRCode();     
        }    
    */
  }

  init();

  vm.redirectIfCustom = function () {
    if (lodash.includes(publicFanGroup.registration, 'CUSTOM')) {
      window.location.href = 'https://registration.seaters.com/' + $stateParams.slug;
    }
  };

  function isMobileDevice() {
    return typeof window.orientation !== "undefined" || navigator.userAgent.indexOf('IEMobile') !== -1 || /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) || /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform) || window.matchMedia("only screen and (max-width: 760px)").matches;
  };

  function generetedQRCode(pimProvider) {
    vm.idWl = vm.redirect.stateParams.waitinglistId != undefined ? vm.redirect.stateParams.waitinglistId : null;

    AuthApiService.doQRCode("pim/" + pimProvider.providerId, isMobileDevice(), vm.idWl, vm.redirect.stateParams.slug).then(function (res) {
      var qrCode = new QRCode(document.getElementById("qrcode"), {
        text: res.qrCodeUrl,
        width: 200,
        height: 200,
        colorDark: "#000000",
        typeNumber: 10,
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.L
      });

      qrCode.clear(); // clear the code.   
      qrCode.makeCode(res.qrCodeUrl); // make another code.
      startCheckQRCode(res.uniqueReference, "pim/" + FanGroupService.getProvider());
      vm.referenceQRCodeBTN = res.qrCodeUrl;
    });
  }

  vm.onClickRefreshQRCode = function () {
    document.getElementById("qrcode").innerHTML = "";
    vm.QRCodeExpired = false;
    generetedQRCode(vm.pimProvier[0]);
  };

  function startCheckQRCode(reference, slug) {
    vm.intervalFunc = setInterval(function () {
      checkWithreference(reference, slug);
    }, 1000);
  }

  function expiredQRCode() {
    if (vm.isMobile === true) {
      return vm.onClickRefreshQRCode();
    }

    if (vm.isMobile === false) {
      return vm.QRCodeExpired = true;
    }

    vm.showErrorLoginMessage = false;
  }

  function checkWithreference(uniqueReference, slug) {
    SessionService.verifyIsQRCode(uniqueReference, slug).then(function (res) {
      if (res.authenticationSuccess != null && res.pim.status != "EXPIRED") {
        clearInterval(vm.intervalFunc);
        SessionService.doRedirect(res.authenticationSuccess).then(function (res) {
          return SessionService.joinAfterLogin(vm.fanGroup, $stateParams.redirect);
        }).then(function () {
          return $q.when();
        }).then(function () {
          document.getElementById("qrcode").innerHTML = "";
          generetedQRCode(vm.pimProvier[0]);
        }).catch(function (error) {
          document.getElementById("qrcode").innerHTML = "";
          generetedQRCode(vm.pimProvier[0]);
          vm.showErrorLoginMessage = true;
        });
      }

      if (res.pim.status === "EXPIRED") {
        clearInterval(vm.intervalFunc);
        return expiredQRCode();
      }
    });
  }

  vm.onlySSO = (lodash.includes(FanGroupService.getLoginTypes, 'SAML') || lodash.includes(FanGroupService.getLoginTypes, 'OAUTH')) && FanGroupService.getLoginTypes.length === 1;

  vm.redirectIfSSO = function () {
    if (vm.onlySSO) {
      if (lodash.includes(FanGroupService.getLoginTypes, 'SAML')) {
        $state.go('app.authenticate.saml-login', { destinationPath: $base64.encode('/' + $stateParams.slug),
          provider: FanGroupService.getProvider() });
      }

      if (lodash.includes(FanGroupService.getLoginTypes, 'OAUTH')) {
        FanGroupService.getOauthURl().then(function (uri) {
          window.location.href = uri;
        });
      }
    } else {
      $state.go('app.authenticate.login');
    }
  };

  /**
   *  VM FUNCTIONS
   */

  /**
   *  SETUP
   */
});