'use strict';

angular.module('app').directive('strsWaitinglist', function () {
    /**
     * @TODO
     * - clean up unused methods
     * - clean up unused views realted to this directive
     * - clean up unused styles
     */
    var EXPIRATION_LOOP_TIMEOUT_MS = 1000;

    /* @ngInject */
    function WlController($scope, $timeout, TranslationService, AppService, $state, $window, WaitinglistService, FanGroupService, DateService, MomentService, $rootScope, WAITINGLIST_ACTION_STATUS) {

        var vm = this;
        vm.app = $rootScope.app;
        vm.WAITINGLIST_ACTION_STATUS = WAITINGLIST_ACTION_STATUS;
        vm.rankAndLikelihoodHidden = $scope.waitinglist.groupRankAndLikelihoodHidden || $scope.group && $scope.group.rankAndLikelihoodHidden;

        vm.isContest = $scope.waitinglist.displayMode === 'CONTEST';

        vm.isBadgeProtected = $scope.waitinglist.waitingListJoinInfoFanView && $scope.waitinglist.waitingListJoinInfoFanView.badgeFanViews && $scope.waitinglist.waitingListJoinInfoFanView.badgeFanViews.length > 0;

        vm.badgeName = vm.isBadgeProtected ? TranslationService.t2($scope.waitinglist.waitingListJoinInfoFanView.badgeFanViews[0].displayedText) : null;

        vm.badgeColor = vm.isBadgeProtected ? $scope.waitinglist.waitingListJoinInfoFanView.badgeFanViews[0].displayedColor : null;

        function setExpiration() {
            var waitinglist = $scope.waitinglist;
            var expirationBase = WaitinglistService.waitinglistExpiration(waitinglist);
            if (!expirationBase) {
                return false;
            }
            var ms = DateService.utcMoment(expirationBase).diff(DateService.utcMoment());
            // Expiration ran out => unset the position&seat as the server would do
            if (ms < 0) {
                waitinglist.position = null;
                waitinglist.seat = null;
                return false;
            }
            vm.expiration = Math.floor(MomentService.getMoment().duration(ms).asHours()) + MomentService.getMoment().utc(ms).format(':mm:ss');
            return true;
        }

        var expirationLoopTimeout = null;

        function expirationLoop() {
            if (setExpiration()) {
                expirationLoopTimeout = $timeout(expirationLoop, EXPIRATION_LOOP_TIMEOUT_MS);
            }
        }

        function cancelExpirationLoopTimeout() {
            if (expirationLoopTimeout) {
                $timeout.cancel(expirationLoopTimeout);
            }
        }

        vm.showIf = function (status) {
            return vm.waitinglistStatus === status;
        };

        vm.isComingSoon = function () {
            return vm.waitinglistStatus === WAITINGLIST_ACTION_STATUS.SOON;
        };

        vm.hasDiscount = WaitinglistService.hasDiscount;
        $scope.$on('$destroy', cancelExpirationLoopTimeout);

        var LONG_TITLE_LENGTH = 55;

        vm.goToInviteOrWaitingList = function () {
            if (FanGroupService.getPublicFanGroup() != undefined && FanGroupService.getPublicFanGroup().properties != undefined && FanGroupService.getPublicFanGroup().properties.invite != undefined && FanGroupService.getPublicFanGroup().properties.invite.skipWaitingListDetailsPage != undefined && FanGroupService.getPublicFanGroup().properties.invite.skipWaitingListDetailsPage === true) {
                return $state.go('app.main.waitinglist.guestlistWithoutHosts', {
                    nrOfSeats: 0,
                    waitinglistId: $scope.waitinglist.waitingListId
                });
            }

            return $state.go('app.main.waitinglist', { slug: FanGroupService.getCurrentFanGroupSlug(), waitinglistId: $scope.waitinglist.waitingListId, persistContext: $scope.persistContext });
        };

        function initializeWaitinglist() {
            var waitinglist = $scope.waitinglist;
            var group = $scope.group;
            var actionStatus = WaitinglistService.waitinglistStatus(waitinglist, group);
            var status = $scope.linkOnly ? 'LINK' : actionStatus.status;
            WaitinglistService.setWaitinglistHeaderImage(waitinglist);
            if (vm.waitinglistStatus !== status) {
                vm.waitinglistLink = WaitinglistService.waitinglistLink(waitinglist);
                // Cancel previous expiration loop if any
                cancelExpirationLoopTimeout();
                // Manual refresh as the interval might be a tick too late
                expirationLoop();
                vm.waitinglistActions = 'components/strs-waitinglist/actions/actions-' + status.toLowerCase().replace(/_/g, '-') + '.html';
                vm.waitinglistStatus = status;
                vm.processing = actionStatus.processing;
                vm.canPreauthorize = WaitinglistService.canPreauthorize(waitinglist);
                vm.formattedPrice = WaitinglistService.formatPrice(waitinglist);
                vm.wlPeriod = WaitinglistService.generateWaitinglistPeriod(waitinglist);
                vm.randomDistribution = WaitinglistService.isDistributionModeRandom(waitinglist);

                var wishlistTitle = waitinglist.translatedExperienceName || waitinglist.translatedEventName;
                vm.hasLongTitle = wishlistTitle.length >= LONG_TITLE_LENGTH;
            }
        }

        initializeWaitinglist();

        $scope.$watch('waitinglist', initializeWaitinglist, true);
        // $scope.$watch('group', initializeWaitinglist, true);
    }

    return {
        templateUrl: 'components/strs-waitinglist/waitinglist.html',
        restrict: 'E',
        scope: {
            waitinglist: '=',
            group: '=',
            linkOnly: '=',
            persistContext: '=',
            invitation: '=?',
            invitationList: '=?'
        },
        link: function link($scope) {},
        replace: false,
        controller: WlController,
        controllerAs: 'wl'
    };
});