'use strict';

angular.module('app').value('ANONYMOUS_FG', {
  id: '_',
  translatedName: 'Seaters',
  translatedShortName: 'Seaters',
  translatedWelcomeText: '',
  protectionCodeExplanation: '',
  name: {
    en: 'Seaters'
  },
  shortName: {
    en: 'Seaters'
  },
  welcomeText: {
    en: ''
  },
  slug: '_',
  coverImageUrl: 'https://static.seaters.com/file-d0597628-311f-45e6-8584-8aee480596c5.png',
  profileImageUrl: 'https://static.seaters.com/file-2756c9e5-6d1d-40b3-b8db-89907cc03f4f.png',
  backgroundImageUrl: 'https://static.seaters.com/file-12053e32-40c1-4f1b-92b5-a108491f5843.png',
  color1: '#0de0af',
  color2: '#ffffff',
  accessMode: 'PUBLIC',
  visibility: 'INVISIBLE',
  statistics: {
    numberOfMembers: 0,
    numberOfWaitingLists: 0,
    numberOfJoinedWaitingLists: 0,
    numberOfSeats: 0
  },
  membership: {
    invitation: null,
    request: null,
    member: false
  },
  groupCategories: [],
  fanMember: false
});