'use strict';

angular.module('app').constant('LOCKER_NS_SESSION', 'session').constant('LOCKER_SESSION_CURRENT', 'CURRENT_V3').constant('LOCKER_SESSION_OAUTH_REDIRECT', 'OAUTH_REDIRECT').enum('SESSION_FB_ERRORS', ['UNEXPECTED', 'NOT_AUTHORIZED', 'CANCELLED', 'NO_EMAIL', 'EMAIL_EXISTS']).enum('SESSION_EVENTS', ['SESSION_CHANGED', 'SESSION_EXPIRED']).service('SessionService',
/* @ngInject */function (locker, $log, $timeout, $q, $window, $rootScope, $state, $sanitize, $modal, Alert, AuthApiService, APIService, DateService, NotifierFactory, AppService, FanApiService, FacebookService, SDKService, LOCKER_NS_SESSION, LOCKER_SESSION_CURRENT, LOCKER_SESSION_OAUTH_REDIRECT, SESSION_FB_ERRORS, SESSION_EVENTS, ModalsService, HTTP_STATUS, lodash, $interval, URLService, FanGroupService, GroupService, SurveyService) {
  // Number of milliseconds to automatically extend the session before it would expire
  var EXPIRATION_BUFFER = 60000;

  // Name of the parameter which contains a session we can use to authenticate the user with
  // If filled, this value has priority over the locally stored session
  var SESSION_QUERY_PARAMETER_NAME = 'ssq';

  var service = {};

  var currentSession = false;
  var authResourceV2 = APIService.createResource('v2/authentication');
  var sessionNotifier = NotifierFactory.createNotifier();
  var sessionSDKService = SDKService.client.sessionService;

  function sessionLocker() {
    return locker.driver('local').namespace(LOCKER_NS_SESSION);
  }

  function isValidSession(session) {
    return angular.isObject(session) &&
    // Must be in the future
    DateService.utcMoment(session.expiresOn).diff(DateService.utcMoment()) > 0;
  }

  var expirationTimeout = false;

  function setSession(session) {
    currentSession = session;
    APIService.setDefaultHeader('Authorization', 'SeatersBearer ' + session.token);
    sessionLocker().put(LOCKER_SESSION_CURRENT, session);
    // When to renew is the number of millis until it expires - the expiration buffer
    var renewalMillis = DateService.utcMoment(session.expiresOn).diff(DateService.utcMoment()) - EXPIRATION_BUFFER;
    // Automatically extend the session when it's about to expire
    expirationTimeout = $timeout(service.extendSession, renewalMillis);
    service.registerSessionRelatedItem(expirationTimeout);

    // fetch the fan to properly populate the session
    return FanApiService.me().then(function (fan) {
      sessionSDKService.configureSession({
        expirationDate: currentSession.expiresOn,
        token: currentSession.token
      }, fan);
      return AppService.changeLocale(fan.language);
    }).then(function () {
      $rootScope.$emit(SESSION_EVENTS.SESSION_CHANGED, currentSession);
      return $q.when(currentSession);
    });
  }

  function unsetSession() {
    currentSession = false;
    APIService.removeDefaultHeader('Authorization');
    sessionLocker().forget(LOCKER_SESSION_CURRENT);
    service.clearSessionRelatedItems(expirationTimeout);
    $rootScope.$emit(SESSION_EVENTS.SESSION_CHANGED, currentSession);
  }

  function handleSuccessfulAuthVerify(session) {
    // console.log(session);
    return setSession(session).then(function (newSession) {
      // console.log(newSession);
      return angular.copy(newSession);
    });
  }

  service.loadSession = function () {
    // @TODO: change to 'getQueryParameters' after join protected fan group
    // @TODO:  branch is merged (does it at routing level)

    var queryParameters = URLService.loadQueryParametersFromURL();
    var preloadedSessionQueryParameter = queryParameters[SESSION_QUERY_PARAMETER_NAME];
    var session = undefined;

    if (preloadedSessionQueryParameter) {
      try {
        $log.info('[SessionService] using session from ssq query parameter');
        session = JSON.parse(preloadedSessionQueryParameter);
      } catch (e) {
        throw new Error('Session query parameter invalid: ' + preloadedSessionQueryParameter.toString());
      }
    } else {
      if (currentSession) {
        return $q.when(true);
      }
      session = sessionLocker().get(LOCKER_SESSION_CURRENT);
      // $log.info('[SessionService] using session from storage');
    }

    if (!session) {
      return $q.when(false);
    }
    if (!isValidSession(session)) {
      unsetSession();
      return $q.when(false);
    }
    return setSession(session);
  };

  function handleSessionStarted(session) {
    sessionNotifier.notify(session);
    return session;
  }

  service.verifyIsQRCode = function (reference, slug) {
    return authResourceV2.custom('GET', slug + '/check-transaction?reference=' + reference);
  };

  service.doRedirect = function (session) {
    // finishLogin 
    return SDKService.client.sessionService.finishLogin(session)
    ////////////methode a utiliser ici
    .then(handleSuccessfulAuthVerify).then(handleSessionStarted).then(function () {
      var publicFanGroup = FanGroupService.getPublicFanGroup();
      SurveyService.fulfillSurveyRequirements(null, 'AFTER_REGISTRATION', publicFanGroup.fanGroupId);
    });
  };

  service.doPasswordLogin = function (login, password, mfaToken) {
    return SDKService.client.sessionService.doEmailPasswordLogin(login, password, mfaToken)
    ////////////methode a utiliser ici
    .then(handleSuccessfulAuthVerify).then(handleSessionStarted).then(function () {
      var publicFanGroup = FanGroupService.getPublicFanGroup();
      SurveyService.fulfillSurveyRequirements(null, 'AFTER_REGISTRATION', publicFanGroup.fanGroupId);
    });
  };

  service.doTokenLogin = function (token) {
    return SDKService.client.sessionService.doStoredTokenLogin(token).then(handleSuccessfulAuthVerify).then(handleSessionStarted);
  };

  function refreshToken() {
    // console.log('REFRESHIING');
    return AuthApiService.extendSession(currentSession.token, currentSession.authClientType, currentSession.clientVersion).then(handleSuccessfulAuthVerify).then(function (session) {
      $log.info('[SessionService] session token refreshed');
      return session;
    }, function (err) {
      $log.error('[SessionService] unable to refresh token', err);
      return $q.reject(err);
    });
  }

  service.extendSession = function () {
    if (!isValidSession(currentSession)) {
      throw new Error('Current session is invalid');
    }
    $log.info('[SessionService] automatic session refresh');
    return refreshToken();
  };

  service.refreshTokenIfNeeded = function () {
    if (service.isLoggedIn()) {
      $log.info('[SessionService] manual session refresh');
      return refreshToken();
    }
    return $q.resolve();
  };

  service.currentSession = function () {
    return angular.copy(currentSession);
  };

  service.isLoggedIn = function () {
    return Boolean(currentSession) && isValidSession(currentSession);
  };

  service.destroySession = function () {
    unsetSession();
  };

  service.onSessionStarted = function (fn) {
    sessionNotifier.listen(fn);
  };

  function buildFacebookUser(accessToken, emailOverride) {
    return angular.extend({
      accessToken: accessToken,
      language: AppService.getLocale()
    }, angular.isString(emailOverride) ? { email: emailOverride } : undefined);
  }

  function handleSuccessfulLogin(session) {
    $log.info('[SessionService] succesful login');
    return setSession(session);
  }

  function handleFailedLogin(err) {
    $log.error('[SessionService] unexpected error on login', err);
    return $q.reject(err);
  }

  function handleFacebookRegistrationError(err) {
    var message = angular.isObject(err) && err.status === HTTP_STATUS.BAD_REQUEST && angular.isObject(err.data) && err.data.message;
    if (message === 'A user with given e-mail address does already exist') {
      $log.warn('[SessionService] email for FB user was already taken');
      return $q.reject(SESSION_FB_ERRORS.EMAIL_EXISTS);
    } else if (message === 'E-mail is empty') {
      $log.info('[SessionService] FB user has no email');
      return $q.reject(SESSION_FB_ERRORS.NO_EMAIL);
    }

    $log.error('[SessionService] failed to register FB user', err);
    return $q.reject(SESSION_FB_ERRORS.UNEXPECTED);
  }

  function createFacebookUser(accessToken, emailOverride) {
    var user = buildFacebookUser(accessToken, emailOverride);
    return AuthApiService.doFacebookSignup(user).then(undefined, handleFacebookRegistrationError).then(function () {
      $log.info('[SessionService] registered FB user');
      return AuthApiService.doFacebookLogin(accessToken).then(handleSuccessfulLogin, handleFailedLogin);
    }).then(undefined, function (err) {
      if (err === SESSION_FB_ERRORS.NO_EMAIL) {
        // API could not find an email with the given facebook token
        return ModalsService.facebookMissingEmail().then(function (updatedEmailOverride) {
          return createFacebookUser(accessToken, updatedEmailOverride);
        });
      }

      return $q.reject(err);
    });
  }

  function handleFacebookAuthorization(accessToken) {
    return AuthApiService.doFacebookLogin(accessToken).then(handleSuccessfulLogin).then(undefined, function (err) {
      if (angular.isObject(err) && err.status === HTTP_STATUS.UNAUTHORIZED) {
        // 401 => user did not exist yet, so create it
        return createFacebookUser(accessToken, undefined);
      }

      return handleFailedLogin(err);
    });
  }

  function handleFacebookConnectWarnings(error) {
    switch (error) {
      case SESSION_FB_ERRORS.EMAIL_EXISTS:
        Alert.warning('facebook_email-exists-warning');
        break;
      case SESSION_FB_ERRORS.NOT_AUTHORIZED:
        Alert.warning('facebook_not-authorized-alert');
        break;
      case SESSION_FB_ERRORS.CANCELLED:
        Alert.warning('facebook_cancelled-alert');
        break;
      default:
        $log.error('[SessionService] failed to fb connect', error);
        Alert.error('general_unexpected');
    }
    return $q.reject(error);
  }

  function successfulLoginHandler(redirect) {
    return function () {
      var publicFanGroup = FanGroupService.getPublicFanGroup();
      return service.joinAfterLogin(publicFanGroup, redirect).then(function () {
        return $q.when();
      })
      // Handle errors
      .catch(function () {
        Alert.error('login_failed-alert');
      });
    };
  }

  service.facebookConnect = function (redirect) {
    sessionLocker().put(LOCKER_SESSION_OAUTH_REDIRECT, redirect);
    var oauthReturnPath = $state.href('app.authenticate.oauth', { provider: 'facebook' });
    return FacebookService.authorize(oauthReturnPath).then(handleFacebookAuthorization).then(undefined, handleFacebookConnectWarnings).then(successfulLoginHandler(redirect));
  };

  function completeFacebookOAuth(redirect) {
    $log.info('[SessionService] completing facebook oauth process');
    return FacebookService.completeOAuth().then(handleFacebookAuthorization).then(undefined, function (err) {
      $state.go('app.error', { redirect: redirect });
      return $q.reject(err);
    }).then(undefined, handleFacebookConnectWarnings).then(successfulLoginHandler(redirect));
  }

  service.completeOAuth = function (provider, code, fanId) {
    var oauthRedirect = sessionLocker().get(LOCKER_SESSION_OAUTH_REDIRECT);
    if (provider === 'facebook') {
      return completeFacebookOAuth(oauthRedirect);
    }

    return service.doVerifyOAuth({ authorizationCode: code, fangroupId: fanId, provider: provider }).then(handleSuccessfulAuthVerify).then(handleSessionStarted).then(successfulLoginHandler(oauthRedirect)).then(function () {
      window.location.reload();
    });
  };

  service.doVerifyOAuth = function (input) {
    return SDKService.client.sessionService.doVerifyOAuth(input);
  };

  service.signupAndLogin = function (email, password, firstName, lastName, lang, redirect) {
    email = $sanitize(email);
    password = $sanitize(password);
    firstName = $sanitize(firstName);
    lastName = $sanitize(lastName);
    lang = $sanitize(lang);
    redirect = $sanitize(redirect);

    return SDKService.client.sessionService.doEmailPasswordSignUp(email, password, firstName, lastName, lang, redirect).then(function () /* User*/{
      $log.info('SessionService - password user created');
      return AuthApiService.doPasswordLogin(email, password);
    }).then(function (session) {
      $log.info('SessionService - password login OK');
      return setSession(session);
    });
  };

  service.isRoleHost = function () {
    return true;
  };

  service.resetEmail = function (email) {
    return AuthApiService.resetEmail(email, currentSession.token);
  };

  service.resetPassword = function (email) {
    return AuthApiService.resetPassword(email);
  };

  service.handleUnauthorizedHTTPRequest = function () {
    // When opening browser while the local token has already expired
    if (!$state.current.name) {
      service.destroySession();
      return;
    }

    // Prevent multiple modals
    if (service.handleingUnauthorizedInProgress) {
      return;
    }
    service.handleingUnauthorizedInProgress = true;

    // Canceling all ongoing timeouts & intrevals
    service.clearSessionRelatedItems();

    // Initiate the modal
    ModalsService.notification({
      title: 'notification_modal_unauthorized_title',
      message: 'notification_modal_unauthorized_message',
      acceptLabel: 'common_go_to_login',
      keyboard: false,
      backdrop: 'static',
      closeOnClick: false
    }).then(function () {
      service.destroySession();
      window.location.reload();
    }).then(function () {
      service.handleingUnauthorizedInProgress = false;
    });
  };

  function cancelItem(item) {
    if (item && item.$$timeoutId) {
      $timeout.cancel(item);
    } else {
      $interval.cancel(item);
    }
  }

  function resetSessionRelatedItems() {
    service.sessionRelatedItems = [];
  }

  service.registerSessionRelatedItem = function (item) {
    service.sessionRelatedItems.push(item);
  };

  service.clearSessionRelatedItems = function (itemToClear) {
    if (itemToClear) {
      cancelItem(itemToClear);
      lodash.pull(service.sessionRelatedItems, itemToClear);
      lodash.pull(service.sessionRelatedItems, itemToClear);
    } else {
      lodash.each(service.sessionRelatedItems, function (item) {
        cancelItem(item);
      });
      resetSessionRelatedItems();
    }
  };

  service.joinAfterLogin = function (publicFanGroup, redirect) {
    var unlockGroup = false;
    return FanGroupService.fetchPublicFanGroupBySlug(publicFanGroup.slug).then(function (fanGroup) {
      // Check if the group can be joined immediately, or if it should be unlocked, after login
      if (fanGroup.accessMode === 'PUBLIC') {
        try {
          GroupService.joinGroup(fanGroup);
        } catch (err) {
          // console.log(err);
        }
      }

      // Check if this is a protected fan group, in that case unlock will occur after login
      unlockGroup = GroupService.shouldUnlockFanGroup(fanGroup);
      return $q.when();
    })
    // Get state after login
    .then(function () {
      return AppService.afterLoginState(redirect);
    })
    // Redirect to next state
    .then(function (nextState) {
      nextState.stateParams = lodash.extend(nextState.stateParams, {
        afterLoginUnlockGroup: unlockGroup
      });
      return $state.go(nextState.stateName, nextState.stateParams);
    });
  };

  var fanResource = APIService.createResource('v2/authentication/logout');
  service.signout = function () {
    var token = JSON.parse(localStorage.getItem('session.CURRENT_V3')).token;

    fanResource.custom('PUT', '', { data: { token: token, mfaToken: '' } }).then(function () {
      service.destroySession();
      SDKService.client.sessionService.doLogout();
    }).then(function () {
      AppService.signOutRedirect();
    }).catch(function () {
      AppService.signOutRedirect();
    });
  };

  resetSessionRelatedItems();
  return service;
});