'use strict';

angular.module('app').service('GoogleAPIService',
/* @ngInject */function (AppApiService, UtilService) {
  /**
   * Loads google maps api
   * @returns {promise} google maps api object
   */
  function loadGoogleMapsApi() {
    return AppApiService.getEnv().then(function (envData) {
      var GOOGLE_MAPS_SCRIPT_URL = 'https://maps.googleapis.com/maps/api/js?key=' + envData.googleMapsApiKey + '&libraries=places';
      return UtilService.loadScript(GOOGLE_MAPS_SCRIPT_URL);
    }).then(function (loaded) {
      return window.google.maps;
    });
  }

  var service = {};
  service.loadGoogleMapsApi = loadGoogleMapsApi;
  return service;
});