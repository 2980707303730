/* eslint-disable no-magic-numbers */
'use strict';

angular.module('app').controller('AuthenticateSignupController', function ($state, $stateParams, $q, $log, lodash, AppService, FanApiService, SDKService, FanGroupService, GroupService, SessionService, URLService, Alert, APP_SETTINGS, AUTH_VALIDATION_ERRORS, ValidationService, $base64, SurveyService) {
  /**
  *  CONSTANTS
  */
  var USER_DEFAULTS = {
    allowDirectMarketingFromSeaters: null,
    allowDirectMarketingFromPartners: null,
    privacyPolicyAndTermsAndConditionOptIn: false,
    firstName: '',
    lastName: '',
    email: '',
    password: ''
  };

  /**
  *  PRIVATE VARIABLES
  *  PRIVATE VARIABLES
  */
  var queryParameters = URLService.getQueryParameters();

  /**
  *  PRIVATE FUNCTIONS
  */

  /**
  *  VM VARIABLES
  */
  var vm = this;
  vm.user = lodash.extend(USER_DEFAULTS, queryParameters);
  vm.accessCode = queryParameters.accessCode;
  vm.fanGroup = FanGroupService.getPublicFanGroup();
  vm.fanGroupName = vm.fanGroup.name[AppService.getLocale()];
  vm.loading = false;

  vm.showOptInPlatform = !vm.fanGroup.optInPlatformHidden;
  vm.showOptInPartner = !vm.fanGroup.optInPartnersHidden;

  function init() {
    vm.optInPartnersTexts = vm.fanGroup.optInPartnersTexts ? vm.fanGroup.optInPartnersTexts[AppService.getLocale()].replace('{{FN}}', vm.fanGroupName).replace('{{TC}}', vm.fanGroup.termsAndConditionsUrls[AppService.getLocale()]).replace('{{PP}}', vm.fanGroup.privacyPolicyUrls[AppService.getLocale()]) : 'I wish to receive the coming exclusive offers from the partners of ' + vm.fanGroupName;

    vm.optInPlatformTexts = vm.fanGroup.optInPlatformTexts ? vm.fanGroup.optInPlatformTexts[AppService.getLocale()].replace('{{TC}}', vm.fanGroup.termsAndConditionsUrls[AppService.getLocale()]).replace('{{PP}}', vm.fanGroup.privacyPolicyUrls[AppService.getLocale()]).replace('{{FN}}', vm.fanGroupName) : 'I wish to receive the coming exclusive offers from the partners of ' + vm.fanGroupName;

    vm.optInTermsTexts = vm.fanGroup.optInTermsTexts ? vm.fanGroup.optInTermsTexts[AppService.getLocale()].replace('{{TC}}', vm.fanGroup.termsAndConditionsUrls[AppService.getLocale()]).replace('{{PP}}', vm.fanGroup.privacyPolicyUrls[AppService.getLocale()]).replace('{{FN}}', vm.fanGroupName) : 'I wish to receive the coming exclusive offers from the partners of ' + vm.fanGroupName;
  }

  init();

  vm.showPassword = function () {
    var password = document.getElementById('password');
    if (password.type === 'password') {
      password.type = 'text';
    } else {
      password.type = 'password';
    }
  };

  vm.isPartnersTextsExist = function () {
    return vm.fanGroup.optInPartnersTexts && vm.fanGroup.optInPartnersTexts !== null && vm.fanGroup.optInPartnersTexts !== [] && vm.fanGroup.optInPartnersTexts.en !== '';
  };

  vm.isPlatformTextsExist = function () {
    return vm.fanGroup.optInPlatformTexts && vm.fanGroup.optInPlatformTexts !== null && vm.fanGroup.optInPlatformTexts !== {} && vm.fanGroup.optInPlatformTexts.en !== '';
  };

  vm.isTermsTextsExist = function () {
    return vm.fanGroup.optInTermsTexts && vm.fanGroup.optInTermsTexts !== null && vm.fanGroup.optInTermsTexts !== {} && vm.fanGroup.optInTermsTexts.en !== '';
  };

  /**
  *  VM FUNCTIONS
  */

  vm.isSubmitDisabled = function (form) {
    return vm.loading;
  };

  vm.submit = function () {
    var unlockGroup = false;
    vm.showPasswordError = false;
    var redirect = AppService.generateCurrentGroupEncodedRedirect(vm.fanGroup);
    vm.loading = true;
    var directMarketingSettings = {
      allowDirectMarketingFromPartners: vm.user.allowDirectMarketingFromPartners,
      allowDirectMarketingFromSeaters: vm.user.allowDirectMarketingFromSeaters
    };

    // Attempt sign up
    SessionService.signupAndLogin(vm.user.email, vm.user.password, vm.user.firstName, vm.user.lastName, AppService.getLocale(), redirect).then(function () {
      SDKService.client.fanService.fan().then(function (user) {
        user.directMarketingSettings = directMarketingSettings;
        SDKService.client.fanService.updateFan(user);
      });
    })
    // Fetch the fan group details
    .then(function (user) {
      return GroupService.groupBySlugOrId(vm.fanGroup.slug);
    }).then(function () {
      var publicFanGroup = FanGroupService.getPublicFanGroup();
      SurveyService.fulfillSurveyRequirements(null, 'AFTER_REGISTRATION', publicFanGroup.fanGroupId);
      return publicFanGroup;
    }).then(function (fanGroup) {
      // Check if the group can be joined immediately, or if it should be unlocked, after login
      if (GroupService.shouldJoinFanGroup(fanGroup)) {
        return GroupService.joinGroup(fanGroup);
      }

      // Request to join Private Fan group
      if (GroupService.canRequestToJoinFanGroup(fanGroup)) {
        return GroupService.requestToJoinPrivateFanGroup(fanGroup);
      }

      // Otherwise, check if protected group needs to be unlocked & accesscode is available
      if (vm.accessCode && GroupService.shouldUnlockFanGroup(fanGroup)) {
        return GroupService.unlockGroup(fanGroup, vm.accessCode).then(function (response) {
          unlockGroup = false;
          SessionService.refreshTokenIfNeeded();
          return $q.when(response);
        }).catch(function (error) {
          unlockGroup = true;
          if (error === AUTH_VALIDATION_ERRORS.WRONG_CODE) {
            $log.debug('[onboarding] validation failed - wrong code');
          }
          return $q.when(error);
        });
      }
      unlockGroup = true;
      return $q.when(vm.accessCode);
    })
    // Get state after login
    .then(function () {
      // Reset onboarding local storage - show it again for new users
      localStorage.removeItem(APP_SETTINGS.onboardingToastGroupShownTokenName);
      localStorage.removeItem(APP_SETTINGS.onboardingToastWaitingListShownTokenName);
      localStorage.removeItem(APP_SETTINGS.onboardingCarouselShownTokenName);
      return AppService.afterLoginState($stateParams.redirect);
    })
    // Redirect to next state
    .then(function (nextState) {
      nextState.stateParams = lodash.extend(nextState.stateParams, {
        afterLoginUnlockGroup: unlockGroup,
        unlockGroupError: unlockGroup
      });
      return $state.go(nextState.stateName, nextState.stateParams);
    })
    // Finish
    .then(function () {
      vm.loading = false;
      if (vm.accessCode) {
        location.reload();
      }
    })
    // Handle errors
    .catch(function (error) {
      // @TODO Dedicated error handling for:
      // - Fail to Fetch the fan group details
      // - Fail to join fan group
      // - Fail to send request To Join Private Fan Group
      // console.log(error);
      vm.loading = false;
      if (error.errors && error.errors[0] && error.errors[0].message) {
        var message = JSON.parse(error.errors[0].message);
        var isPasswordError = message.errors.errorsTypes.validation_errors[0].error.references === ['password'];
        vm.showPasswordError = true;
      }
      // Error at the signup level
      vm.user = lodash.extend(vm.user, { password: '' });
      return ValidationService.processValidations(error, vm.signupForm);
    });
  };

  vm.shouldDisplayFacebook = function () {
    return lodash.includes(FanGroupService.getSignupType(), 'FACEBOOK');
  };

  vm.facebookConnect = function () {
    vm.loading = true;
    SessionService.facebookConnect($stateParams.redirect).then(function () {
      vm.loading = false;
    }).catch(function () {
      vm.loading = false;
    });
  };

  /**
  *  SETUP
  */
});