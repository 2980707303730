'use strict';

angular.module('app').value('WAITINGLIST_DISTRIBUTION_MODE', {
    RANDOM: 'wl_positions_distribution_mode_random',
    DEFAULT: 'wl_positions_distribution_mode_fifs'
}).enum('WAITINGLIST_ACTION_STATUS', ['BECOME_FAN', 'UNLOCK', 'SOON', 'BOOK', 'WAIT', 'CONFIRM', 'GO_LIVE', 'NO_SEATS', 'ERROR']).enum('WAITINGLIST_UNLOCK_ERRORS', ['SERVER_PROBLEM', 'WRONG_CODE', 'CANCELLED']).enum('WAITINGLIST_JOIN_ERRORS', ['SERVER_PROBLEM', 'CANCELLED', 'EMAIL_NOT_CONFIRMED', 'ISSUE_WITH_SURVEY']).enum('TRANSACTION_POLLING_ERRORS', ['POLLING_TIMEDOUT', 'POLLING_FATAL_ERROR']).enum('WAITINGLIST_SIGNALS', ['JOINED_WAITINGLIST', 'LEFT_WAITINGLIST', 'ACCEPTED_SEATS', 'POSITION_OR_SEATS_EXPIRED']).enum('CHECKOUT_STATUS', ['CHECKOUT_INPROGRESS', 'CHECKOUT_CAN_PAY', 'CHECKOUT_CAN_CONFIRM']).service('WaitinglistService',
/* @ngInject */
function (FanApiService, Alert, GroupService, UtilService, DateService, $log, $modal, $q, $timeout, $state, $rootScope, ModalsService, WAITINGLIST_ACTION_STATUS, FANGROUP_FAN_STATUS, WAITINGLIST_UNLOCK_ERRORS, WAITINGLIST_JOIN_ERRORS, WAITINGLIST_SIGNALS, TRANSACTION_POLLING_ERRORS, UTIL_GENERAL_ERRORS, lodash, StrsNotificationsStack, NOTIFICATIONS_STACK_TYPES, MomentService, WAITINGLIST_DISTRIBUTION_MODE, AppService, SurveyService) {
    var WAITING_LIST_POLL_TIME_MS = 1000;
    var WAITING_LIST_CONDITION = 10;

    function promiseRejectionHandler(message) {
        return function (err) {
            if (angular.isString(err)) {
                $log.warn(message, err);
                return $q.reject(err);
            }

            $log.error(message, err);
            return $q.reject(UTIL_GENERAL_ERRORS.SERVER_PROBLEM);
        };
    }

    var service = {};

    service.waitinglistLink = function (waitinglist) {
        return $state.href('app.main.waitinglist', {
            waitinglistId: waitinglist.waitingListId,
            groupSlug: waitinglist.groupSlug
        });
    };

    service.hasJoined = function (waitinglist) {
        return angular.isObject(waitinglist.position);
    };

    service.hasExpiration = function (waitinglist) {
        return angular.isString(service.waitinglistExpiration(waitinglist));
    };

    service.waitinglistExpiration = function (waitinglist) {
        if (waitinglist.seat) {
            return waitinglist.seat.assignmentExpirationDate;
        }
        if (waitinglist.position) {
            return waitinglist.position.expirationDate;
        }
        return undefined;
    };

    service.canPreauthorize = function (waitinglist) {
        var position = waitinglist.position;
        return !waitinglist.freeWaitingList && position && position.expirationDate;
    };

    service.canPaySeat = function (waitinglist) {
        var position = waitinglist.position;
        var seat = waitinglist.seat;
        return !waitinglist.freeWaitingList && position && seat && seat.status === 'ASSIGNED' && position.transactionStatus !== 'COMPLETED';
    };

    service.canPay = function (waitinglist) {
        return service.canPreauthorize(waitinglist) || service.canPaySeat(waitinglist);
    };

    service.hasPayed = function (waitinglist) {
        var position = waitinglist.position;
        return position && position.transactionStatus === 'COMPLETED';
    };

    service.hasPreauthorized = function (waitinglist) {
        var position = waitinglist.position;
        return !waitinglist.freeWaitingList && position && position.status === 'WAITING_SEAT' && position.transactionStatus === 'COMPLETED';
    };

    service.shouldUnlockForWaitinglistPrice = function (waitinglist) {
        return waitinglist.accessMode === 'CODE_PROTECTED' && (!waitinglist.request || waitinglist.request.status !== 'ACCEPTED');
    };

    service.canBeShared = function (waitinglist, group) {
        return GroupService.canBeShared(group) && waitinglist.accessMode === 'PUBLIC';
    };

    function createStatus(status, processing) {
        return {
            status: status,
            processing: processing
        };
    }

    /* eslint-disable complexity */
    service.waitinglistStatus = function (waitinglist, group) {
        var seat = waitinglist.seat;
        var position = waitinglist.position;
        var request = waitinglist.request;

        // Comming soon
        if (waitinglist.waitingListStatus === 'PUBLISHED') {
            return createStatus(WAITINGLIST_ACTION_STATUS.SOON);
        }

        // Not in FG
        if (group && !group.membership.member) {
            return createStatus(WAITINGLIST_ACTION_STATUS.BECOME_FAN);
        }

        // Not in WL
        if (!position) {
            // Code protected WL
            if (waitinglist.accessMode === 'CODE_PROTECTED') {
                if (!request) {
                    return createStatus(WAITINGLIST_ACTION_STATUS.UNLOCK);
                } else if (request.status === 'PENDING') {
                    return createStatus(WAITINGLIST_ACTION_STATUS.UNLOCK, true);
                } else if (request.status === 'REJECTED') {
                    return createStatus(WAITINGLIST_ACTION_STATUS.UNLOCK);
                } else if (request.status === 'ACCEPTED') {
                    return createStatus(WAITINGLIST_ACTION_STATUS.BOOK);
                }

                $log.error('WaitinglistService - unexpected request status: %s', request.status, request);
                return createStatus(WAITINGLIST_ACTION_STATUS.ERROR);
            } else if (waitinglist.accessMode === 'PUBLIC') {
                // Public WL
                return createStatus(WAITINGLIST_ACTION_STATUS.BOOK);
            }

            $log.error('WaitinglistService - unexpected accessMode: %s', waitinglist.accessMode);
            return createStatus(WAITINGLIST_ACTION_STATUS.ERROR);
        }

        // In WL
        if (position.status === 'WAITING_SEAT') {
            // Right after payment there is a brief time when the WL is pending
            if (position.transactionStatus === 'COMPLETED' && angular.isString(position.expirationDate)) {
                return createStatus(WAITINGLIST_ACTION_STATUS.CONFIRM, true);
            }
            return createStatus(WAITINGLIST_ACTION_STATUS.WAIT);
        } else if (position.status === 'HAS_SEAT') {
            // In WL with seat
            if (seat) {
                if (seat.status === 'ACCEPTED') {
                    // Go live
                    return createStatus(WAITINGLIST_ACTION_STATUS.GO_LIVE);
                } else if (seat.status === 'RSVP_ACCEPTED') {
                    // Go live
                    return createStatus(WAITINGLIST_ACTION_STATUS.NO_SEATS);
                } else if (seat.status === 'ASSIGNED' || seat.status === 'ASSIGNED_WITHOUT_SEATS') {
                    // Free WL
                    if (waitinglist.freeWaitingList) {
                        return createStatus(WAITINGLIST_ACTION_STATUS.CONFIRM);
                    } else if (!position.transactionStatus) {
                        // Non free WL
                        // No payment yet
                        return createStatus(WAITINGLIST_ACTION_STATUS.CONFIRM);
                    } else if (['FAILURE', 'CANCELLED', 'REFUNDED'].indexOf(position.transactionStatus >= 0)) {
                        // Failed payment
                        return createStatus(WAITINGLIST_ACTION_STATUS.CONFIRM);
                    } else if (['CREATING', 'CREATED', 'APPROVED', 'REFUNDING'].indexOf(position.transactionStatus >= 0)) {
                        // Payment in progress
                        return createStatus(WAITINGLIST_ACTION_STATUS.CONFIRM, true);
                    } else if (position.transactionStatus === 'COMPLETED' && angular.isString(position.expirationDate)) {
                        // Right after payment there is a brief time when the WL is pending
                        return createStatus(WAITINGLIST_ACTION_STATUS.CONFIRM, true);
                    }

                    $log.error('WaitinglistService - unexpected transactionStatus: %s', position.transactionStatus);
                    return createStatus(WAITINGLIST_ACTION_STATUS.ERROR);
                } else if (waitinglist.seatDistributionMode === 'TICKET' && !angular.isString(seat.ticketingSystemType)) {
                    // Non-voucher - tickets are being requested
                    return createStatus(WAITINGLIST_ACTION_STATUS.CONFIRM, true);
                }

                $log.error('WaitinglistService - unexpected seat status: %s', seat.status);
                return createStatus(WAITINGLIST_ACTION_STATUS.ERROR);
            }

            $log.error('WaitinglistService - has seat without actual seat');
            return createStatus(WAITINGLIST_ACTION_STATUS.ERROR);
        } else if (position.status === 'BEING_PROCESSED') {
            return createStatus(WAITINGLIST_ACTION_STATUS.WAIT, true);
        }

        $log.error('WaitinglistService - unexpected position status: %s', position.status);
        return createStatus(WAITINGLIST_ACTION_STATUS.ERROR);
    };
    /* eslint-enable complexity */

    service.unlockWaitinglist = function (waitinglist) {
        var deferred = $q.defer();
        $modal.open({
            templateUrl: 'components/strs-waitinglist/unlock-waitinglist.modal.html',
            windowClass: 'medium strs-modal-alt',
            keyboard: false,
            closeOnClick: false,
            controller: /* @ngInject */function controller($scope) {
                $scope.submitCode = function (code) {
                    if (!code) return;
                    $scope.submittingInProgress = true;
                    $scope.errorDetails = '';
                    $scope.errorMessageCode = '';
                    FanApiService.unlockWaitinglist(waitinglist, code).then(function (unlockedWaitingList) {
                        $scope.submittingInProgress = false;
                        $scope.errorDetails = '';
                        $scope.errorMessageCode = '';
                        return $scope.$close(unlockedWaitingList);
                    }).catch(function (error) {
                        $scope.submittingInProgress = false;
                        console.error(error);
                        $scope.errorDetails = '';
                        if (error === 'strs.api.wl.invalidcode') {
                            $scope.errorMessageCode = 'validation_error_invalid_access_code';
                        } else {
                            $scope.errorMessageCode = 'unlock_fangroup_SERVER_PROBLEM';
                            $scope.errorDetails = error;
                        }
                    });
                };
                $scope.waitinglist = waitinglist;
            }
        }).result.then(function (unlockedWaitingList) {
            return deferred.resolve(unlockedWaitingList);
        }).catch(function () {
            deferred.reject(WAITINGLIST_UNLOCK_ERRORS.CANCELLED);
        });
        return deferred.promise;
    };

    function checkLeftWaitinglist(wl) {
        return !angular.isObject(wl.position) || wl.position.status === 'DROPPED';
    }

    function wlRefresher(wl) {
        return function () {
            return FanApiService.waitinglistById(wl.waitingListId).then(function (update) {
                angular.extend(wl, update);
                return wl;
            });
        };
    }

    service.canLeaveWaitinglist = function (waitinglist) {
        return angular.isObject(waitinglist.position) && (waitinglist.position.status === 'WAITING_SEAT' || waitinglist.position.status === 'HAS_SEAT' && waitinglist.seat.status !== 'ACCEPTED');
    };

    service.leaveWaitinglist = function (waitinglist) {
        var position = waitinglist.position;
        if (!service.canLeaveWaitinglist(waitinglist)) {
            throw new Error('[WaitinglistService] trying to leave WL without position');
        }
        var promise = position.status === 'HAS_SEAT' ? FanApiService.rejectWaitinglistSeat(waitinglist.waitingListId) : FanApiService.leaveWaitinglist(waitinglist.waitingListId);
        return promise.then(function () {
            return UtilService.retryUntil(
            // Fetch & update the waitinglist
            wlRefresher(waitinglist),
            // Continue until the export url is ready
            checkLeftWaitinglist, WAITING_LIST_CONDITION, WAITING_LIST_POLL_TIME_MS);
        }, promiseRejectionHandler('WaitinglistService - could not leave waitinglist')).then(UtilService.broadcastAndReturn(WAITINGLIST_SIGNALS.LEFT_WAITINGLIST));
    };

    service.pollTransactionStatus = function (waitinglist, limit) {
        var deferred = $q.defer();
        var attempt = 0;

        function checkTransactionStatus() {
            attempt++;
            // Polling timeout
            if (attempt > limit) {
                $log.debug('WaitinglistService - transaction status polling limit reached');
                deferred.reject(TRANSACTION_POLLING_ERRORS.POLLING_TIMEDOUT);
                return;
            }
            // Polling iteration
            FanApiService.waitinglistById(waitinglist.waitingListId).then(function (wl) {
                angular.extend(waitinglist, wl);
                if (!angular.isObject(wl.position)) {
                    $log.error('WaitinglistService - position is not set');
                    deferred.reject(TRANSACTION_POLLING_ERRORS.POLLING_FATAL_ERROR);
                } else if (wl.position.transactionStatus === 'FAILURE') {
                    deferred.reject({
                        problem: 'waitinglist position transaction marked as FAILURE',
                        paymentFailureMessage: wl.position.paymentFailureMessage
                    });
                } else if (wl.position.transactionStatus === 'COMPLETED') {
                    deferred.resolve(wl);
                } else {
                    $timeout(checkTransactionStatus, WAITING_LIST_POLL_TIME_MS);
                }
            }, deferred.reject);
        }

        checkTransactionStatus();
        return deferred.promise;
    };

    service.askForEmailConfirmation = function (fan) {
        return StrsNotificationsStack.add({
            type: NOTIFICATIONS_STACK_TYPES.CONFIRM_EMAIL,
            data: fan
        });
    };

    service.joinWaitinglist = function (waitinglist, numberOfSeats, selectedSeats, group, numberOfParkingTickets) {
        return FanApiService.me().then(function (fan) {
            if (fan.validatedEmail) {
                if (fan.validatedMobilePhone) {
                    // Resolve instantly
                    return fan;
                }
                return fan;
                /* return ModalsService.askForMobileConfirmation().catch(function() {
                    return $q.reject(WAITINGLIST_JOIN_ERRORS.CANCELLED);
                }); */
            }
            service.askForEmailConfirmation(fan);
            return $q.reject(WAITINGLIST_JOIN_ERRORS.EMAIL_NOT_CONFIRMED);
        }).then(function () {
            return FanApiService.isWlBadgeProtected(waitinglist.waitingListId);
        }).then(function (badgeProtected) {
            if (badgeProtected) {
                ModalsService.wlBadgeProtected(waitinglist.waitingListId);
                throw 'BADGE';
            }
            return badgeProtected;
        })
        // Check for surveys
        .then(function () {
            return SurveyService.fulfillSurveyRequirements(waitinglist.waitingListId, 'BEFORE_JOINING_WAITINGLIST', null, numberOfSeats).catch(function (error) {
                // @TODO Replace this with none blocking, use notification stack error
                // return $q.reso(WAITINGLIST_JOIN_ERRORS.ISSUE_WITH_SURVEY);
                throw error;
            });
        }).then(function () {
            return FanApiService.joinWaitinglist(waitinglist.waitingListId, numberOfSeats, selectedSeats, numberOfParkingTickets);
        }).then(UtilService.broadcastAndReturn(WAITINGLIST_SIGNALS.JOINED_WAITINGLIST)).catch(function (err) {
            var badgeProtectionErrorCode = 403;
            if (err.errors && err.errors[0] && err.errors[0].statusCode === badgeProtectionErrorCode) {
                ModalsService.wlBadgeProtected(waitinglist.waitingListId);
            } else if (err.data && err.data.message == "Cannot join WL without confirmed phone number") {
                return ModalsService.askForMobileConfirmation().catch(function () {
                    return $q.reject(WAITINGLIST_JOIN_ERRORS.CANCELLED);
                });
            } else {
                if (lodash.includes([WAITINGLIST_JOIN_ERRORS.CANCELLED, WAITINGLIST_JOIN_ERRORS.EMAIL_NOT_CONFIRMED], err)) {
                    return $q.reject(err);
                }

                if (err !== 'BADGE') {
                    Alert.handleServerProblem('WaitinglistService - failed to join waitinglist')(err);
                }
            }
            return undefined;
        });
    };

    service.wlRefresher = wlRefresher;

    service.refreshWlUntil = function (waitinglist, condition, limit, delay) {
        return UtilService.retryUntil(wlRefresher(waitinglist), condition, limit, delay);
    };

    function checkSeatExportUrl(wl) {
        return angular.isObject(wl) && angular.isObject(wl.seat) && angular.isString(wl.seat.exportedVoucherUrl) && wl.seat.exportedVoucherUrl.length > 0;
    }

    function checkSeatParkingExportUrl(wl) {
        return angular.isObject(wl) && angular.isObject(wl.seat) && angular.isString(wl.seat.exportedParkingTicketUrl) && wl.seat.exportedParkingTicketUrl.length > 0;
    }

    service.exportSeat = function (waitinglist, formatUsed) {
        $log.debug('WaitinglistService - exporting seat');
        console.log(formatUsed);
        // formatUsed = (formatUsed === undefined)? null : formatUsed;
        // Check if the seat was already exported
        if (checkSeatExportUrl(waitinglist)) {
            return $q.resolve(waitinglist);
        }
        // Otherwise start the export
        return FanApiService.exportWaitinglistSeat(waitinglist.waitingListId, formatUsed).then(function (res) {
            return UtilService.retryUntil(
            // Fetch & update the waitinglist
            wlRefresher(waitinglist),
            // Continue until the export url is ready
            checkSeatExportUrl, WAITING_LIST_CONDITION, WAITING_LIST_POLL_TIME_MS);
        }, promiseRejectionHandler('WaitinglistService - could not export seat'));
    };

    service.exportParkingSeat = function (waitinglist) {
        $log.debug('WaitinglistService - exporting seat');
        // Check if the seat was already exported
        if (checkSeatParkingExportUrl(waitinglist)) {
            return $q.resolve(waitinglist);
        }
        // Otherwise start the export
        return FanApiService.exportWaitinglistParkingSeat(waitinglist.waitingListId).then(function () {
            return UtilService.retryUntil(
            // Fetch & update the waitinglist
            wlRefresher(waitinglist),
            // Continue until the export url is ready
            checkSeatExportUrl, WAITING_LIST_CONDITION, WAITING_LIST_POLL_TIME_MS);
        }, promiseRejectionHandler('WaitinglistService - could not export seat'));
    };

    service.ticketingSystemTypeToName = function (type) {
        var mapping = {
            VERITIX: 'Veritix',
            DIGITICK: 'Digitick'
        };
        var name = mapping[type];
        if (angular.isString(name)) {
            return name;
        }

        $log.warn('No mapping for ticketing system type %s', type);
        return type;
    };

    function checkSeatConfirmation(wl) {
        if (angular.isObject(wl) && angular.isObject(wl.seat) && wl.seat.status === 'ACCEPTED') {
            if (wl.seatDistributionMode === 'VOUCHER') {
                return true;
            } else if (wl.seatDistributionMode === 'TICKET') {
                // TicketingSystemType is populated when done
                return angular.isString(wl.seat.ticketingSystemType);
            }
        }
        // All other cases are still in progress
        return false;
    }

    service.confirmSeat = function (waitinglist, optionUsed) {
        $log.debug('WaitinglistService - confirming seat');
        return FanApiService.acceptWaitinglistSeat(waitinglist.waitingListId, optionUsed).then(function () {
            return UtilService.retryUntil(

            // Fetch & update the waitinglist
            wlRefresher(waitinglist),
            // Continue until the export url is ready
            checkSeatConfirmation, WAITING_LIST_CONDITION, WAITING_LIST_POLL_TIME_MS);
        }, promiseRejectionHandler('WaitinglistService - could not confirm seat'));
    };

    service.formatPrice = function (waitinglist) {
        var price = waitinglist.price;
        var currency = waitinglist.currency;
        if (angular.isObject(price)) {
            if (angular.isString(price.formattedTotal)) {
                return service.hasJoined(waitinglist) ? price.formattedTotal : price.formattedFacialPrice;
            }

            // Fallback - algolia data which is missing
            if (angular.isObject(currency)) {
                return waitinglist.currency.symbol + ' ' + (service.hasJoined(waitinglist) ? price.total : price.facialPrice);
            }
        }
        return '';
    };

    service.signalAcceptedSeats = function (waitinglist) {
        if (angular.isObject(waitinglist) && angular.isObject(waitinglist.seat) && waitinglist.seat.status === 'ACCEPTED') {
            $log.debug('WaitinglistService - signaling seats accepted...');
            UtilService.broadcast(WAITINGLIST_SIGNALS.ACCEPTED_SEATS);
        }
    };

    service.signalExpiration = function () {
        $log.debug('WaitinglistService - signaling expiration...');
        UtilService.broadcast(WAITINGLIST_SIGNALS.POSITION_OR_SEATS_EXPIRED);
    };

    service.hasSeat = function (waitinglist) {
        return angular.isObject(waitinglist) && angular.isObject(waitinglist.seat);
    };

    service.hasVoucher = function (waitinglist) {
        return service.hasSeat(waitinglist) && waitinglist.seat.voucherNumber !== '/';
    };

    service.eventDate = function (waitinglist) {
        var start = waitinglist.eventStartDate;
        var end = waitinglist.eventEndDate;
        var showEnd = false;
        if (end) {
            var diff = DateService.diffDays(end, start);
            if (diff > 0) {
                showEnd = true;
            }
        }
        return {
            start: start,
            end: end,
            showEnd: showEnd
        };
    };

    service.generateWaitinglistPeriod = function (wl) {
        function start() {
            var eventStartDate = wl.eventStartDate.replace(/[+-]\d\d:\d\d/, '');
            return MomentService.getMoment(eventStartDate);
        }

        function end() {
            if (wl.eventEndDate) {
                var eventEndDate = wl.eventEndDate.replace(/[+-]\d\d:\d\d/, '');
                return MomentService.getMoment(eventEndDate);
            }
            return null;
        }

        var oneDayEvent = start().isSame(wl.eventEndDate ? wl.eventEndDate.replace(/[+-]\d\d:\d\d/, '') : null, 'day');

        var period = {};

        if (oneDayEvent) {
            period.oneDayEvent = oneDayEvent;
            period.oneDayEventTime = start().format('LT - ') + end().format('LT');
            period.oneDayEventFullDateTime = start().format('ddd DD MMM LT - ') + end().format('LT');
        }

        period.start = {
            oneLine: start().format('ddd DD MMM LT'),
            dayName: start().format('ddd'),
            dayNumber: start().format('DD'),
            month: start().format('MMM'),
            time: start().format('LT'),
            year: start().format('YYYY')
        };

        if (end()) {
            period.end = {
                oneLine: end().format('ddd DD MMM LT'),
                dayName: end().format('ddd'),
                dayNumber: end().format('DD'),
                month: end().format('MMM'),
                time: end().format('LT'),
                year: end().format('YYYY')
            };
        } else {
            period.end = null;
        }

        return period;
    };

    service.isDistributionModeRandom = function (wl) {
        return wl.positionsDistributionMode === WAITINGLIST_DISTRIBUTION_MODE.RANDOM;
    };

    service.hasDiscount = function (wl) {
        return lodash.isString(wl.discountAmount) && parseInt(wl.discountAmount, 0) || wl.price && parseInt(wl.price.discountAmount, 0);
    };

    service.setWaitinglistHeaderImage = function (wl) {
        var imagesUrl = [wl.waitingListImageUrl, wl.eventImageUrl, wl.venueImageUrl, wl.groupCoverImageUrl];
        var image = lodash.chain(imagesUrl).compact().head().value();
        wl.waitinglistHeaderImage = image;
    };

    service.setWaitinglistDescription = function (wl) {
        wl.translatedDescription = wl.description[AppService.getLocale()];
    };

    service.setWaitinglistExperienceName = function (wl) {
        wl.translatedExperienceName = wl.experienceName && wl.experienceName[AppService.getLocale()] || '';
    };
    return service;
});