'use strict';

/**
 * @ngdoc service
 * @name seatersApp.service:SharingService
 * @description
 * SharingService provides abstractions for sharing seaters content
 */

angular.module('app').service('SharingService', function (Socialshare, FanApiService, WaitinglistService, GroupService, Alert, $modal, $rootScope, $log) {
  function shareDataToAttrs(shareData, provider) {
    switch (provider) {
      case 'google':
      case 'facebook':
        return {
          socialshareUrl: shareData.url
        };
      case 'pinterest':
        return {
          socialshareUrl: shareData.url,
          socialshareMedia: shareData.mediaUrl,
          socialshareText: shareData.title
        };
      case 'twitter':
        return {
          socialshareUrl: shareData.url,
          socialshareText: shareData.title
        };
      case 'email':
        return {
          socialshareSubject: shareData.title,
          socialshareBody: shareData.url
        };
      default:
        var msg = '[SharingService] unsupported provider: ' + provider;
        $log.error(msg);
        Alert.error('general_unexpected');
        throw new Error(msg);
    }
  }

  function share(shareInfoPromiseFn, provider) {
    return shareInfoPromiseFn().then(undefined, Alert.handleServerProblem).then(function (shareData) {
      Socialshare.share({
        provider: provider,
        attrs: shareDataToAttrs(shareData, provider)
      });
    });
  }

  function shareFanGroup(fanGroupId, provider) {
    $log.debug('[SharingService] sharing fangroup %s on %s', fanGroupId, provider);
    return share(function () {
      return FanApiService.fangroupShareInfo(fanGroupId);
    }, provider);
  }

  function shareWaitingList(waitingListId, provider) {
    $log.debug('[SharingService] sharing waitinglist %s on %s', waitingListId, provider);
    return share(function () {
      return FanApiService.waitinglistShareInfo(waitingListId);
    }, provider);
  }

  function showMoreSharingOptions(shareFn) {
    var scope = $rootScope.$new(true);
    scope.share = shareFn;
    $modal.open({
      templateUrl: 'components/socialshare/socialshare-more.modal.html',
      windowClass: 'medium',
      scope: scope
    });
  }

  function waitingListSharingModal(waitingListId) {
    return showMoreSharingOptions(function (provider) {
      return shareWaitingList(waitingListId, provider);
    });
  }

  function fanGroupSharingModal(fanGroupId) {
    return showMoreSharingOptions(function (provider) {
      return shareFanGroup(fanGroupId, provider);
    });
  }

  return {
    shareFanGroup: shareFanGroup,
    shareWaitingList: shareWaitingList,
    waitingListSharingModal: waitingListSharingModal,
    fanGroupSharingModal: fanGroupSharingModal
  };
});