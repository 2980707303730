'use strict';

angular.module('app').directive('strsFormValidations', function ( /* @ngInject */lodash, ValidationService) {
  return {
    templateUrl: 'components/strs-validations/strs-form-validations.html',
    transclude: true,
    scope: true,
    require: 'form',
    link: function link($scope, $element, $attrs, formController) {
      $scope.formController = formController;

      function isControllerWithoutResetter(updatedFormController, field) {
        var isField = angular.isObject(updatedFormController[field]);
        var hasModelValue = isField && lodash.hasIn(updatedFormController[field], '$modelValue');
        var hasValidators = hasModelValue && angular.isObject(updatedFormController[field].$validators);
        var hasNoResetters = hasValidators && !lodash.hasIn(updatedFormController[field].$validators, 'serverValidityResetter');
        return hasNoResetters;
      }

      /**
         * 
         * Add resetter methods to all NgModelController within 
         * the required FormController.
         * 
         */
      $scope.$watchCollection(function () {
        return formController;
      }, function (updatedFormController, oldFormController) {
        Object.getOwnPropertyNames(updatedFormController).forEach(function (field) {
          if (isControllerWithoutResetter(updatedFormController, field)) {
            updatedFormController[field].$validators.serverValidityResetter = ValidationService.resetServerValidity(updatedFormController[field], updatedFormController);
          }
        });
      });
    }
  };
});