/* eslint-disable no-use-before-define */
'use strict';

angular.module('app').controller('main_approvals_ApprovalController', function (FanApiService, FanGroupService, $scope, $window, UtilService, $timeout, $modal) {

    $scope.group = FanGroupService.getPublicFanGroup();
    $scope.groupId = FanGroupService.getPublicFanGroup().fanGroupId;

    $scope.groupColor = $scope.group.color;
    $scope.groupColorLight = 'rgba(' + UtilService.hexToRgb($scope.groupColor).r + ',' + UtilService.hexToRgb($scope.groupColor).g + ',' + UtilService.hexToRgb($scope.groupColor).b + ',0.5)';
    $scope.buttonColor = $scope.groupColor;

    $scope.filterableInviteList = [];
    $scope.step = 'INVITE_LIST';
    $scope.groupBy = 'WAITING_LIST';

    $scope.listOfSelected = [];

    $scope.getData = function () {
        $scope.loading = true;
        $scope.listOfSelected = [];
        FanApiService.fetchApprovalList($scope.groupId, 'REQUIRED').then(function (result) {
            $scope.inviteList = result.content;
            return Promise.all($scope.inviteList.map(function (invite) {
                return FanApiService.getCommentOfInviteForApprover(invite.id);
            }));
        }).then(function (comments) {
            FanApiService.meAsHost().then(function (me) {
                $scope.meAsGuest = {
                    firstName: me.firstName,
                    lastName: me.lastName,
                    guestEmail: me.email,
                    guestId: me.userId,
                    numberOfSeats: 0,
                    importance: 'REQUIRED',
                    host: true
                };
            });
            var hostInvites = [];
            $scope.inviteList.map(function (invite) {
                comments.forEach(function (comment) {
                    if (comment.content.length > 0 && comment.content[0].waitingListInvitationId === invite.id) {
                        invite.notes = comment.content;
                    }
                });
                if (invite.host.hostId === invite.guest.guestId) {
                    invite.guest.host = true;
                    hostInvites.push(invite);
                }
                return invite;
            });
            hostInvites.forEach(function (hostInvite, index) {
                var invite = _.cloneDeep(hostInvite);
                invite.guest.firstName = hostInvite.nonAllocatedSeatsStatus === 'ALLOCATED' ? 'To' : 'Not';
                invite.guest.lastName = hostInvite.nonAllocatedSeatsStatus === 'ALLOCATED' ? 'Distribute' : 'Allocated';
                invite.nbrOfRequestedSeats = hostInvite.numberOfNonAllocatedSeats;
                invite.status = hostInvite.nonAllocatedSeatsStatus;
                invite.goodId = hostInvite.id;
                invite.id = 'wrong_id' + index;
                invite.notAllocated = true;
                if (hostInvite.numberOfNonAllocatedSeats > 0) {
                    $scope.inviteList.splice(0, 0, hostInvite);
                }
            });
            $scope.groupByWaitingList();
            $scope.groupByHost();
            $scope.loading = false;
        });
    };

    $scope.groupByHost = function () {
        $scope.inviteListGroupedByHost = [];
        $scope.inviteList.forEach(function (invite) {
            var notFound = true;
            $scope.inviteListGroupedByHost.forEach(function (inviteGrouped) {
                if (inviteGrouped.host.hostId === invite.host.hostId) {
                    addInviteToWlInvites(inviteGrouped.wlInvites, invite);
                    notFound = false;
                }
            });

            if (notFound) {
                $scope.inviteListGroupedByHost.push({
                    host: invite.host,
                    wlInvites: [{
                        waitingList: invite.waitinglist,
                        invites: [invite]
                    }]
                });
            }
        });

        $scope.inviteListGroupedByHost.forEach(function (inviteGrouped) {
            inviteGrouped.wlInvites.forEach(function (wlInvite) {
                wlInvite.reserveLoading = true;
                FanApiService.fetchReserve(wlInvite.waitingList.waitingListId, inviteGrouped.host.hostId).then(function (res) {
                    wlInvite.reserveLoading = false;
                    wlInvite.reserveSize = res.totalElements;
                });
            });
        });
    };

    $scope.groupByWaitingList = function () {
        $scope.inviteListGrouped = [];
        $scope.inviteList.forEach(function (invite) {
            var notFound = true;
            $scope.inviteListGrouped.forEach(function (inviteGrouped) {
                if (inviteGrouped.waitingList.waitingListId === invite.waitinglist.waitingListId) {
                    inviteGrouped.totalAccepted += invite.status === 'ACCEPTED' ? 1 : 0;
                    addGuestToInvitesOfHost(inviteGrouped.hostList, invite);
                    notFound = false;
                }
            });
            if (notFound) {
                var waitingList = invite.waitinglist;
                $scope.inviteListGrouped.push({
                    waitingList: waitingList,
                    totalAccepted: invite.status === 'ACCEPTED' ? 1 : 0,
                    showApproved: true,
                    showRejected: true,
                    showReserve: false,
                    hostList: [{
                        host: invite.host,
                        invites: [invite]
                    }]
                });
            }
        });

        $scope.inviteListGrouped.forEach(function (inviteGrouped) {
            $scope.getBudgetCost(inviteGrouped);
        });

        $scope.inviteListGrouped.forEach(function (inviteGrouped) {
            inviteGrouped.hostList.forEach(function (hostInvites) {
                hostInvites.reserveLoading = true;
                FanApiService.fetchReserve(inviteGrouped.waitingList.waitingListId, hostInvites.host.hostId).then(function (res) {
                    hostInvites.reserveLoading = false;
                    hostInvites.reserveSize = res.totalElements;
                });
            });
        });
    };

    $scope.mainList = function () {
        return $scope.groupBy === 'WAITING_LIST' ? $scope.inviteListGrouped : $scope.inviteListGroupedByHost;
    };

    $scope.secondaryList = function (inviteGrouped) {
        return $scope.groupBy === 'WAITING_LIST' ? inviteGrouped.hostList : inviteGrouped.wlInvites;
    };

    $scope.thirdList = function (object) {
        return object.invites;
    };

    function addInviteToWlInvites(wlInvites, invite) {
        var wlInList = false;
        var waitingList = invite.waitinglist;
        for (var i = 0; i < wlInvites.length; i++) {
            if (wlInvites[i].waitingList.waitingListId === waitingList.waitingListId) {
                if (wlInvites[i].invites) {
                    if (!isInviteInList(wlInvites[i].invites, invite)) {
                        wlInvites[i].invites.push(invite);
                    }
                } else {
                    wlInvites[i].invites = [];
                    wlInvites[i].invites.push(invite);
                }
                wlInList = true;
                break;
            }
        }
        if (!wlInList) {
            wlInvites.push({
                waitingList: waitingList,
                invites: [invite]
            });
        }
    }

    function addGuestToInvitesOfHost(hostList, invite) {
        var hostInList = false;
        var host = invite.host;
        for (var i = 0; i < hostList.length; i++) {
            if (hostList[i].host.hostId === host.hostId) {
                if (hostList[i].invites) {
                    if (!isInviteInList(hostList[i].invites, invite)) {
                        hostList[i].invites.push(invite);
                    }
                } else {
                    hostList[i].invites = [];
                    hostList[i].invites.push(invite);
                }
                hostInList = true;
                break;
            }
        }

        if (!hostInList) {
            hostList.push({
                host: host,
                invites: [invite]
            });
        }
    }

    function isInviteInList(list, inviteToFind) {
        return list.filter(function (invite) {
            return invite.id === inviteToFind.id;
        }).length === 1;
    }

    $scope.fetchOptionnalApprovalList = function (waitingListId) {
        FanApiService.fetchApprovalList($scope.groupId, waitingListId, 'OPTIONAL');
    };

    $scope.showInvitiationReason = function (invite, type) {
        var groupColor = $scope.groupColor;
        $modal.open({
            templateUrl: 'routes/main/waitinglist/guestlist/invitation-reason.html',
            windowClass: 'medium strs-modal-alt',
            // eslint-disable-next-line no-shadow
            controller: /* @ngInject */function controller($scope, FanApiService) {
                $scope.title = type === 'REJECTION' ? 'Rejection Reason' : 'Business Reason';
                $scope.reason = type === 'REJECTION' ? invite.rejectionReason : invite.invitationReason;
                $scope.groupColor = groupColor;
            }
        });
    };

    $scope.commentOnGuest = function (invite) {
        $scope.showNote = true;
        $scope.inviteToComment = invite;
    };

    $scope.hideNote = function () {
        $scope.showNote = false;
    };

    $scope.postNewNote = function (invite) {
        if (!invite.notes) {
            invite.notes = [];
        }
        invite.notes.push({
            comment: invite.note,
            commentator: {
                id: $scope.meAsGuest.guestId,
                lastName: $scope.meAsGuest.lastName
            },
            new: true
        });
        invite.note = '';
        $scope.postCommentToInvite(invite);
    };

    $scope.getLastCommentDate = function (comment) {
        return $window.moment(comment.publicationDate).fromNow();
    };

    $scope.getTotalTicketsSelected = function (inviteGrouped) {
        var total = inviteGrouped.hostList.reduce(function (acc1, hostInvites) {
            return acc1 + hostInvites.invites.reduce(function (acc2, invite) {
                return acc2 + Number(invite.nbrOfRequestedSeats);
            }, 0);
        }, 0);
        return total;
    };

    $scope.getTotalTicketsApproved = function (inviteGrouped) {
        var total = inviteGrouped.hostList.reduce(function (acc1, hostInvites) {
            return acc1 + hostInvites.invites.filter(function (invite) {
                return invite.status === 'APPROVED';
            }).reduce(function (acc2, invite) {
                return acc2 + Number(invite.nbrOfRequestedSeats);
            }, 0);
        }, 0);
        return total;
    };

    $scope.getBudgetCost = function (inviteGrouped) {
        var totalTickets = $scope.getTotalTicketsSelected(inviteGrouped);
        inviteGrouped.totalCost = Number(inviteGrouped.waitingList.price) * totalTickets;
    };

    $scope.getBudgetCostForHost = function (host) {
        return host.invites.reduce(function (acc, invite) {
            return acc + invite.nbrOfRequestedSeats;
        }, 0) * Number(host.invites[0].waitinglist.price);
    };

    $scope.getBudgetCostForWl = function (groupedObject) {
        return groupedObject.invites.reduce(function (acc, invite) {
            return acc + invite.nbrOfRequestedSeats;
        }, 0) * Number(groupedObject.waitingList.price);
    };

    $scope.postCommentToInvite = function (invite) {
        Promise.all(invite.notes.filter(function (note) {
            return note.new;
        }).map(function (note) {
            return FanApiService.postCommentToInviteAsApprover(invite.id, note.comment).catch(console.log);
        }));
    };

    $scope.getFormattedDate = function (date) {
        return $window.moment(date).format('L') + ' ' + $window.moment(date).format('LT');
    };

    $scope.goBack = function () {
        $scope.step = 'EVENTS_LIST';
    };

    $scope.getRequiredInviteFromList = function (list) {
        return list.filter(function (invite) {
            return invite.importance === 'REQUIRED';
        });
    };

    $scope.getOptionalInviteFromList = function (list) {
        return list.filter(function (invite) {
            return invite.importance === 'OPTIONAL';
        });
    };

    $scope.collapseReserve = function (groupedObject) {
        // eslint-disable-next-line no-negated-condition
        if (!groupedObject.alreadyCollapsedOnce) {
            var waitingListId = $scope.groupBy === 'HOST' ? groupedObject.waitingList.waitingListId : groupedObject.invites[0].waitinglist.waitingListId;
            var hostId = $scope.groupBy === 'HOST' ? groupedObject.invites[0].host.hostId : groupedObject.host.hostId;
            var maxSize = 9999;
            FanApiService.fetchReserve(waitingListId, hostId, maxSize).then(function (res) {
                groupedObject.optionalList = res.content;
                groupedObject.showReserve = !groupedObject.showReserve;
                groupedObject.alreadyCollapsedOnce = true;
            });
        } else {
            groupedObject.showReserve = !groupedObject.showReserve;
        }
    };

    $scope.toggleGuest = function (invite, selected) {
        var shouldISelectInvite = selected === undefined ? invite.selected : selected;
        if (shouldISelectInvite) {
            $scope.listOfSelected.push(invite);
        } else {
            $scope.listOfSelected = $scope.listOfSelected.filter(function (item) {
                return invite.guest.guestId !== item.guest.guestId;
            });
        }
    };

    $scope.toggleAll = function (hostList, selected) {
        hostList.forEach(function (host) {
            host.invites.filter(function (invite) {
                return invite.status === 'PENDING';
            }).forEach(function (invite) {
                invite.selected = selected;
                $scope.toggleGuest(invite, selected);
            });
        });
    };

    $scope.approveAllInvitesOf = function (inviteGrouped) {
        Promise.all($scope.listOfSelected.filter(function (invite) {
            var condition = $scope.groupBy === 'HOST' ? invite.host.hostId === inviteGrouped.host.hostId : invite.waitinglist.waitingListId === inviteGrouped.waitingList.waitingListId;
            return condition && invite.selected;
        }).map(function (invite) {
            return invite.goodId ? FanApiService.approveInviteNonAllocated(invite.goodId) : FanApiService.approveInvite(invite.id);
        })).then(function (res) {
            $scope.listOfSelected.map(function (inviteToApprove) {
                res.forEach(function (inviteApproved) {
                    if (inviteToApprove.id === inviteApproved.id || inviteToApprove.goodId === inviteApproved.id) {
                        inviteToApprove.selected = false;
                        inviteToApprove.status = inviteToApprove.goodId ? inviteApproved.nonAllocatedSeatsStatus : inviteApproved.status;
                    }
                });
                return inviteToApprove;
            });
            $scope.listOfSelected = [];
            $scope.$apply();
        }).catch(function (err) {
            console.log('ERROR => ', err);
        });
    };

    $scope.rejectAllInvitesOf = function (inviteGrouped) {
        function confirm() {
            Promise.all($scope.listOfSelected.filter(function (invite) {
                var condition = $scope.groupBy === 'HOST' ? invite.host.hostId === inviteGrouped.host.hostId : invite.waitinglist.waitingListId === inviteGrouped.waitingList.waitingListId;
                return condition && invite.selected;
            }).map(function (invite) {
                return FanApiService.rejectInvite(invite.id, invite.rejectionReason);
            })).then(function (res) {
                $scope.listOfSelected.map(function (inviteToApprove) {
                    res.forEach(function (inviteApproved) {
                        if (inviteToApprove.id === inviteApproved.id || inviteToApprove.goodId === inviteApproved.id) {
                            inviteToApprove.selected = false;
                            inviteToApprove.status = inviteApproved.status;
                        }
                    });
                    return inviteToApprove;
                });
                $scope.listOfSelected = [];
                $scope.$apply();
            }).catch(function (err) {
                console.log('ERROR => ', err);
            });
        }

        var groupColor = $scope.groupColor;
        var rejectionList = $scope.listOfSelected;

        $modal.open({
            templateUrl: 'routes/main/waitinglist/guestlist/rejection-reason.modal.html',
            windowClass: 'medium strs-modal-alt',
            // eslint-disable-next-line no-shadow
            controller: /* @ngInject */function controller($scope, FanApiService) {
                $scope.rejectionList = rejectionList;
                $scope.groupColor = groupColor;

                $scope.rejectionList.filter(function (invite) {
                    return invite.rejectionReason;
                }).map(function (invite) {
                    invite.previouslyAnswered = true;
                    return invite;
                });

                $scope.handleRejectionChange = function (rejection) {
                    $scope.rejectionList.filter(function (invite) {
                        return !invite.previouslyAnswered;
                    }).map(function (invite) {
                        invite.rejectionReason = rejection;
                        return invite;
                    });
                };

                $scope.confirm = function () {
                    confirm();
                    $scope.$close();
                };
            }
        });
    };

    /*   $scope.rejectAllInvitesOf = function(waitingListId) {
          Promise.all($scope.listOfSelected.filter(function(invite) {
              return invite.waitinglist.waitingListId === waitingListId
          }).map(function(invite) {
              return FanApiService.rejectInvite(invite.id);
          })).then(function(res) {
              $scope.getData();
          }).catch(function(err) {
              console.log('ERROR => ', err);
          })
      } */

    $scope.searchGuest = function (query) {
        var minQueryLength = 3;
        var list = $scope.mainList();
        if (query.length > minQueryLength) {
            list.map(function (objectGrouped) {
                var found = false;
                $scope.secondaryList(objectGrouped).map(function (inviteGrouped) {
                    return inviteGrouped.invites.map(function (invite) {
                        var firstNameLower = invite.guest.firstName ? invite.guest.firstName.toLowerCase() : null;
                        var lastNameLower = invite.guest.firstName ? invite.guest.firstName.toLowerCase() : null;
                        if (_.includes(firstNameLower, query.toLowerCase()) || _.includes(lastNameLower, query.toLowerCase())) {
                            found = true;
                        } else {
                            invite.hide = true;
                        }
                        return invite;
                    });
                });
                objectGrouped.show = found;
                return objectGrouped;
            });
        } else if (query.length === 0) {
            list.forEach(function (objectGrouped) {
                objectGrouped.show = false;
                $scope.secondaryList(objectGrouped).map(function (inviteGrouped) {
                    return inviteGrouped.invites.map(function (invite) {
                        invite.hide = false;
                        return invite;
                    });
                });
            });
        }
    };

    $scope.showLoadingPage = function () {
        $scope.loading = true;
        var delay = 200;
        $timeout(delay).then(function () {
            $scope.loading = false;
        });
    };
    $scope.getData();
});