'use strict';

angular.module('app').directive('formDownloadMode',
/* @ngInject */function (AppService, lodash, FanGroupService, $rootScope) {
  return {
    restrict: 'AE',
    transclude: true,
    templateUrl: 'routes/main/waitinglist/checkout/form-download-mode.html',
    scope: {
      optionLang: '=ngModel',
      // bindModel:'=ngModel',
      isVoucher: '=',
      optionsDownloads: '='
    },
    link: function link(scope) {
      scope.padding = scope.isVoucher === 'Voucher' ? '2rem' : '0rem';
      scope.optionLang = scope.optionsDownloads[0];
    }
  };
});