'use strict';

angular.module('app').directive('strsGroup', function () {
  return {
    templateUrl: 'components/strs-group/group.html',
    restrict: 'E',
    scope: {
      group: '=',
      linkOnly: '=?'
    },
    replace: false,
    controller: function controller(
    /* @ngInject */$state, $scope, Alert, GroupService, FANGROUP_ACCESS_MODE, FANGROUP_FAN_STATUS, FANGROUP_UNLOCK_ERRORS) {
      var vm = this;

      vm.accessModes = FANGROUP_ACCESS_MODE;
      vm.statuses = FANGROUP_FAN_STATUS;

      vm.groupStatus = function () {
        if ($scope.linkOnly) {
          return 'LINK';
        }
        return GroupService.groupStatus($scope.group);
      };

      vm.joinGroup = function (evt) {
        if (evt) {
          evt.stopPropagation();
        }
        var group = $scope.group;
        GroupService.joinGroup(group).then(function () {
          $state.go('app.main.group', { slug: group.slug });
        }, Alert.handleServerProblem('strsGroup - unable to join fangroup'));
      };

      vm.unlockGroup = function (evt) {
        evt.stopPropagation();
        var group = $scope.group;
        // Unlock and join or report the issue
        return GroupService.unlockGroup(group).then(undefined, function (err) {
          if (err === FANGROUP_UNLOCK_ERRORS.WRONG_CODE) {
            Alert.warning('fangroup_unlock-wrong-code');
          } else if (err !== FANGROUP_UNLOCK_ERRORS.CANCELLED) {
            Alert.serverProblem();
          }
        });
      };
    },
    controllerAs: 'fg'
  };
});