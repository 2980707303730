'use strict';

angular.module('app').controller('error_ErrorController',
/* @ngInject */function ($stateParams, $log, FanGroupService, $location, lodash, $base64) {
  var vm = this;

  vm.group = FanGroupService.getPublicFanGroup();

  vm.isSamlError = false;

  if ($location.$$search.message !== undefined) {
    vm.message = $base64.decode($location.$$search.message);
    vm.isSamlError = true;
  }

  vm.errorCode = $stateParams.errorCode;
  vm.slug = FanGroupService.getCurrentFanGroupWrongSlug();
  vm.data = $stateParams.data;
});