'use strict';

angular.module('app').service('StrsIdentity',
/* @ngInject */function ($rootScope, $q, $stateParams, FanGroupService, FanApiService, GroupService, AppService, SessionService, SDKService, lodash) {
  var service = {};
  service.cachedEntities = {};

  function returnItems(results) {
    return results.items;
  }
  function extendEntities(results) {
    angular.extend(service.cachedEntities, results);
    return results;
  }

  function loadPublicDomain(groupSlug) {
    return {
      publicFanGroup: FanGroupService.fetchPublicFanGroupBySlug(groupSlug)
    };
  }

  function generateEntitiesPromisesObject(groupSlug) {
    return {
      me: $q.when(SDKService.client.sessionService.whoami()),
      myGroups: FanApiService.joinedFanGroups().then(returnItems),
      myWishLists: FanApiService.joinedWaitinglists().then(returnItems),
      mySeats: FanApiService.activeWaitinglistsWithSeat().then(returnItems),
      currentGroup: GroupService.groupBySlugOrId(groupSlug)
    };
  }

  service.initialize = function (slug) {
    if (slug === 'security') {
      slug = JSON.parse(localStorage.getItem('app.InitialRedirect')).stateParams.slug;
    }
    var groupSlug = slug ? slug : $stateParams.slug;
    if (SessionService.isLoggedIn()) {
      return $q.all(generateEntitiesPromisesObject(groupSlug)).then(extendEntities);
    }
    return $q.all(loadPublicDomain(groupSlug)).then(extendEntities);
  };

  service.get = function (slug, forceInitialize) {
    if (forceInitialize) {
      return service.initialize(slug);
    }
    return lodash.isEmpty(service.cachedEntities) ? service.initialize(slug) : $q.resolve(service.cachedEntities);
  };

  return service;
});