'use strict';

angular.module('app').service('Alert',
/* @ngInject */function (toastr, TranslationService, $q, $log, $modal, HTTP_STATUS, hasHTTPCode) {
  var service = {};

  service.success = function (msg, options) {
    return toastr.success(TranslationService.t(msg, options));
  };

  service.error = function (msg, options) {
    return toastr.error(TranslationService.t(msg, options));
  };

  service.warning = function (msg, options) {
    return toastr.warning(TranslationService.t(msg, options));
  };

  service.info = function (msg, options) {
    return toastr.info(TranslationService.t(msg, options));
  };

  service.serverProblem = function () {
    if (arguments.length > 0) {
      $log.error.apply(null, arguments);
    };
    return service.error('waitinglist_leave_survey_error');
  };

  service.handleServerProblem = function () {
    var args = Array.prototype.slice.apply(arguments);
    return function (err) {
      // console.log(err);
      if (!hasHTTPCode(HTTP_STATUS.UNAUTHORIZED, err)) {
        service.serverProblem.apply(null, args.concat(err));
      }
      return $q.reject(err);
    };
  };

  return service;
});