'use strict';

angular.module('app').directive('trl', function (TranslationService) {
  return {
    template: function template(tElem, tAttrs) {
      if (tAttrs.trlHtml) {
        return '<ng-template ng-bind-html="translation()"></ng-template>';
      }
      return '{{  translation()  }}';
    },
    restrict: 'AE',
    scope: {
      trl: '=',
      trlMap: '=',
      limit: '@',
      trlHtml: '='
    },
    link: function link(scope, element, attributes) {
      scope.translation = function () {
        var trl = scope.trl;
        if (!(angular.isObject(trl) || angular.isString(trl))) {
          trl = attributes.trl;
        }
        var options = {
          replacements: scope.trlMap,
          limit: scope.limit
        };
        return TranslationService.t(trl, options);
      };
    }
  };
});