'use strict';

angular.module('app')
// eslint-disable-next-line complexity
.controller('main_waitinglist_WaitinglistController', function ($stateParams, groupSlug, group,
// optionUsedDownload,
waitinglistId, waitinglist, persistContext, surveyInformation, surveyCheckoutInformation, GroupService, FanGroupService, WaitinglistService, FanApiService, Alert, DateService, UtilService, AppService, PaymentService, SessionService, $log, $scope, $state, $location, $timeout, $modal, $window, $rootScope, FANGROUP_ACCESS_MODE, FANGROUP_FAN_STATUS, WAITINGLIST_ACTION_STATUS, CHECKOUT_STATUS, WAITINGLIST_UNLOCK_ERRORS, WAITINGLIST_JOIN_ERRORS, FANGROUP_UNLOCK_ERRORS, WAITINGLIST_SIGNALS, WAITINGLIST_DISTRIBUTION_MODE, TranslationService, toastr, me, ModalsService, StrsToastService, StrsToast, APP_SETTINGS, lodash, MomentService, translatedVenueConditions, SurveyService, URLService) {
    /**
     *
     * @TODO
     * - Remove unused methods
     * - Remove unused views
     * - Optimise WL initial load
     *
     */

    var vm = this;
    vm.isContest = waitinglist.displayMode === 'CONTEST';
    vm.isDetailed = waitinglist.displayMode === 'DETAILED';
    vm.isPickMode = waitinglist.positionsDistributionMode === 'wl_positions_distribution_mode_pick';
    vm.fanGroup = FanGroupService.getPublicFanGroup();
    vm.isRoleHost = me.roles.filter(function (role) {
        return role === 'HOST';
    }).length > 0;
    vm.isRoleApprover = me.roles.filter(function (role) {
        return role === 'APPROVER';
    }).length > 0;
    vm.hostInviteAccepted = false;
    vm.groupColor = group.color1;
    vm.nrOfSeatsParking = waitinglist.positionsDistributionMode === 'wl_positions_distribution_mode_with_parking' ? 0 : 1;

    vm.showTicketsDownloadForHost = function () {
        return waitinglist.position && waitinglist.position.status === 'HAS_SEAT';
    };

    vm.isWlInInviteMode = function () {
        var isUserHost = me.roles.filter(function (role) {
            return role === 'HOST';
        }).length === 1;
        var isWlInviteMode = waitinglist.invitationMode === 'ENABLED';
        return isUserHost && isWlInviteMode;
    };

    vm.shouldhideRsvpTimerAndBtn = function () {
        var fanGroup = FanGroupService.getPublicFanGroup();
        return fanGroup && fanGroup.properties && fanGroup.properties.invite && fanGroup.properties.invite.hideRsvpTimerAndBtn;
    };

    // Used for anchor
    localStorage.setItem('wlID', waitinglist.waitingListId);

    function preventAccess() {
        var modalData = {
            message: vm.isContest ? 'wishlist_is_not_accessible_modal_message_contest' : 'wishlist_is_not_accessible_modal_message',
            acceptLabel: 'go_to_fan_group',
            keyboard: false,
            backdrop: 'static',
            closeOnClick: false
        };

        ModalsService.notification(modalData).then(function (res) {
            $state.go('app.main.group', {
                slug: waitinglist.groupSlug
            });
        });
    }

    if (waitinglist.waitingListStatus === 'CLOSED' && !waitinglist.position && !waitinglist.seat) {
        preventAccess();
    }
    var WAITING_LIST_TIMEOUT_MS = 1000;
    AppService.setColor(group.color1);

    var checkoutStatus = $stateParams.checkoutStatus;

    vm.termsAndConditionsAccepted = false;

    // Ordered list of statuses - to determine step compvared/active class
    var statuses = [WAITINGLIST_ACTION_STATUS.BECOME_FAN, WAITINGLIST_ACTION_STATUS.UNLOCK, WAITINGLIST_ACTION_STATUS.SOON, WAITINGLIST_ACTION_STATUS.BOOK, WAITINGLIST_ACTION_STATUS.WAIT, WAITINGLIST_ACTION_STATUS.PROCESSING, WAITINGLIST_ACTION_STATUS.PAY, WAITINGLIST_ACTION_STATUS.CONFIRM, WAITINGLIST_ACTION_STATUS.GO_LIVE, WAITINGLIST_ACTION_STATUS.NO_SEATS];

    vm.stepStatus = function (status) {
        if (status === vm.actionStatus) {
            return 'active';
        } else if (statuses.indexOf(vm.actionStatus) > statuses.indexOf(status)) {
            return 'compvared';
        }

        return 'todo';
    };

    vm.showIf = function (status) {
        return vm.actionStatus === status;
    };

    vm.showUntil = function (status) {
        return statuses.indexOf(vm.actionStatus) <= statuses.indexOf(status);
    };
    vm.isDistributionModeRandom = function () {
        return WaitinglistService.isDistributionModeRandom(vm.waitinglist);
    };

    vm.toggleTermsAndConditions = function () {
        vm.termsAndConditionsAccepted = !vm.termsAndConditionsAccepted;
    };

    vm.isValidNumberOfSeats = function () {
        if (!vm.nrOfSeats) {
            return false;
        }

        if (!Number.isInteger(vm.nrOfSeats)) {
            return false;
        }

        if (vm.nrOfSeats < vm.numberOfSeatsRange[0] || vm.nrOfSeats > vm.numberOfSeatsRange[vm.numberOfSeatsRange.length - 1]) {
            return false;
        }

        return true;
    };

    vm.seats = [];
    vm.pickSeat = function () {
        var seatSeats = function seatSeats(seats) {
            vm.seats = seats;
            vm.nrOfSeats = seats.length;
        };

        $modal.open({
            templateUrl: 'components/pick-seat/pick-seat.modal.html',
            windowClass: 'medium strs-modal-alt',
            controller: function controller($scope, FanApiService) {
                $scope.getBlockGraph = function (block) {
                    var d;
                    if (block.graphics) {
                        if (block.type === 'STAGE') {
                            if (block.graphics.rectangle) {
                                d = 'M ' + block.graphics.rectangle.x + ' ' + block.graphics.rectangle.y + ' L' + (block.graphics.rectangle.x + block.graphics.rectangle.width) + ' ' + block.graphics.rectangle.y + ' L ' + (block.graphics.rectangle.x + block.graphics.rectangle.width) + ' ' + (block.graphics.rectangle.y + block.graphics.rectangle.height) + ' L ' + block.graphics.rectangle.x + ' ' + (block.graphics.rectangle.y + block.graphics.rectangle.height) + ' Z';
                            } else {
                                d = block.graphics.path || '';
                            }
                        } else {
                            d = block.graphics.path || '';
                        }
                    }
                    return d;
                };

                $scope.showSeats = true;

                $scope.scrollWidth = 0;
                $scope.scrollHeight = 0;

                $scope.loading = false;
                $scope.init = function () {
                    $scope.seats = vm.seats || [];

                    $scope.loading = true;
                    FanApiService.getSeatingMap(waitinglist.waitingListId).then(function (seatmap) {
                        $scope.seatmap = seatmap;
                        FanApiService.getAvailableSeats(waitinglist.waitingListId).then(function (availableSeats) {
                            seatmap.seatingMap.blocks.filter(function (block) {
                                return block.type === 'SEATS';
                            }).map(function (block, index) {
                                return block.seats.map(function (seat) {
                                    // tslint:disable-next-line:prefer-for-of
                                    var i = 0;
                                    var j = 0;
                                    for (i; i < availableSeats.length; i++) {
                                        var seatAvailable = availableSeats[i].seatLocation;
                                        // tslint:disable-next-line:triple-equals
                                        if (seatAvailable.row.replace(/ /g, '') == seat.row.replace(/ /g, '') && seatAvailable.seat == seat.number && seatAvailable.block.replace(/ /g, '') === block.name.replace(/ /g, '')) {
                                            seat.available = true;
                                            seat.ticketId = availableSeats[i].ticketAnalysisId;
                                            seat.status = availableSeats[i].seatLocationStatus;
                                            break;
                                        }
                                    }

                                    for (j; j < vm.seats.length; j++) {
                                        var seatSelected = vm.seats[j];
                                        if (seat.row.replace(/ /g, '') === seatSelected.row.replace(/ /g, '') && seat.number === seatSelected.number && seat.block.replace(/ /g, '') === seatSelected.block.replace(/ /g, '')) {
                                            seat.selected = true;
                                        }
                                    }
                                    availableSeats.splice(i, 1);
                                    return seat;
                                });
                            });

                            $scope.blocks = seatmap.seatingMap.blocks;
                            $scope.width = seatmap.seatingMap.size.width;
                            $scope.height = seatmap.seatingMap.size.height;
                            $scope.loading = false;

                            $timeout(500).then(function () {
                                angular.element(document).ready(function () {
                                    $scope.scrollWidth = document.getElementById('scrollseat').scrollWidth;
                                    $scope.scrollHeight = document.getElementById('scrollseat').scrollHeight;
                                    $scope.initGrab();
                                });
                            });
                        });
                    });
                };

                $scope.init();

                $scope.initGrab = function () {
                    var slider = document.getElementById('scrollseat');
                    var isDown = false;
                    var startX;
                    var startY;
                    var scrollLeft;
                    var scrollTop;
                    slider.addEventListener('mousedown', function (e) {
                        isDown = true;
                        slider.classList.add('active');
                        startX = e.pageX - slider.offsetLeft;
                        startY = e.pageY - slider.offsetTop;
                        scrollLeft = slider.scrollLeft;
                        scrollTop = slider.scrollTop;
                    });

                    slider.addEventListener('mouseleave', function () {
                        isDown = false;
                        slider.classList.remove('active');
                    });

                    slider.addEventListener('mouseup', function () {
                        isDown = false;
                        slider.classList.remove('active');
                    });

                    slider.addEventListener('mousemove', function (e) {
                        if (!isDown) {
                            return;
                        }
                        e.preventDefault();
                        var x = e.pageX - slider.offsetLeft;
                        var walk = (x - startX) * 3; // scroll-fast
                        slider.scrollLeft = scrollLeft - walk;

                        var y = e.pageY - slider.offsetLeft;
                        var walky = (y - startY) * 3; // scroll-fast
                        slider.scrollTop = scrollTop - walky;
                    });
                };

                $scope.seats = vm.seats;
                $scope.onSelectSeat = function (seat) {
                    if (seat.available && seat.status !== 'TAKEN') {
                        if (!seat.selected) {
                            seat.selected = true;
                            $scope.seats.push(seat);
                        } else {
                            seat.selected = false;
                            var index = $scope.seats.indexOf(seat);
                            $scope.seats.splice(index, 1);
                        }
                    }
                };

                $scope.isSelected = function (selectedSeat) {
                    return $scope.seats.filter(function (seat) {
                        return seat.ticketId === selectedSeat.ticketId;
                    }).length > 0;
                };

                $scope.onUnselectSeat = function (seat) {
                    seat.selected = false;
                    var index = $scope.seats.indexOf(seat);
                    $scope.seats.splice(index, 1);
                };

                $scope.getSeatColor = function (seat) {
                    var color = '';
                    if (seat.available) {
                        if (seat.selected) {
                            color = 'rgb(68, 138, 255)';
                        } else if (seat.status === 'TAKEN') {
                            color = '#dbdbdb';
                        } else {
                            color = 'rgb(68, 138, 255)';
                        }
                    } else {
                        color = '#dbdbdb';
                    }
                    /*  if (seat.row == 6 && seat.block == 'Fauteuils C' && ['120'].includes(seat
                             .number)) {
                         color = 'red';
                         console.log(seat.number, seat);
                         seat.isBig = true;
                     }
                     if (seat.row == 6 && seat.block == 'Fauteuils D' && ['119'].includes(seat
                             .number)) {
                         color = 'red';
                         console.log(seat.number, seat);
                         seat.isBig = true;
                     } */
                    return color;
                };

                $scope.getTransfOrigin = function (seat) {
                    return seat.x + 'px ' + seat.y + 'px';
                };

                $scope.zoom = 0;
                $scope.handleZoomIn = function () {
                    $scope.zoom += 1;

                    var currentLeft = document.getElementById('scrollseat').scrollLeft;
                    var currentTop = document.getElementById('scrollseat').scrollTop;

                    var currentWidth = document.getElementById('scrollseat').scrollWidth;
                    var currentHeight = document.getElementById('scrollseat').scrollHeight;

                    var coeffScrollLeft = currentWidth === $scope.scrollWidth ? 0.5 : currentLeft / (currentWidth - $scope.scrollWidth);
                    var coeffScrollTop = currentHeight === $scope.scrollHeight ? 0.5 : currentTop / (currentHeight - $scope.scrollHeight);

                    angular.element(document).ready(function () {
                        document.getElementById('scrollseat').scrollLeft = (document.getElementById('scrollseat').scrollWidth - $scope.scrollWidth) * coeffScrollLeft;
                        document.getElementById('scrollseat').scrollTop = (document.getElementById('scrollseat').scrollHeight - $scope.scrollHeight) * coeffScrollTop;
                    });
                };

                $scope.handleZoomOut = function () {
                    $scope.zoom = $scope.zoom > 0 ? $scope.zoom - 1 : 0;

                    var currentLeft = document.getElementById('scrollseat').scrollLeft;
                    var currentTop = document.getElementById('scrollseat').scrollTop;

                    var currentWidth = document.getElementById('scrollseat').scrollWidth;
                    var currentHeight = document.getElementById('scrollseat').scrollHeight;

                    var coeffScrollLeft = currentWidth === $scope.scrollWidth ? 0.5 : currentLeft / (currentWidth - $scope.scrollWidth);
                    var coeffScrollTop = currentHeight === $scope.scrollHeight ? 0.5 : currentTop / (currentHeight - $scope.scrollHeight);

                    if ($scope.zoom >= 0) {
                        angular.element(document).ready(function () {
                            document.getElementById('scrollseat').scrollLeft = (document.getElementById('scrollseat').scrollWidth - $scope.scrollWidth) * coeffScrollLeft;
                            document.getElementById('scrollseat').scrollTop = (document.getElementById('scrollseat').scrollHeight - $scope.scrollHeight) * coeffScrollTop;
                        });
                    }
                };

                $scope.resetZoom = function () {
                    $scope.zoom = 0;
                };

                $scope.cancel = function () {
                    $scope.$dismiss();
                };

                $scope.submit = function () {
                    seatSeats($scope.seats);
                    $scope.$dismiss();
                };
            }
        });
    };

    vm.disableJoinButton = function () {
        return vm.processing ||
        // Display of T&C for non-random wishlists
        // vm.isDistributionModeRandom() && 
        !vm.termsAndConditionsAccepted && vm.waitinglist.termsAndConditionFileURL !== null || !vm.isValidNumberOfSeats();
    };

    vm.hasDiscount = WaitinglistService.hasDiscount;
    vm.backToList = function () {
        if (persistContext) {
            return AppService.goBack();
        }
        return $state.go('app.main.group', {
            slug: group.slug
        });
    };

    vm.WAITINGLIST_ACTION_STATUS = WAITINGLIST_ACTION_STATUS;

    vm.group = $scope.group = group;
    vm.group.accessModes = FANGROUP_ACCESS_MODE;
    vm.waitinglist = $scope.waitinglist = waitinglist;

    var maxNumberOfSeats = vm.isDistributionModeRandom() ? vm.waitinglist.fixedNumberOfSeatsPerPosition : vm.waitinglist.maxNumberOfSeatsPerPosition;

    var minNumberOfSeats = vm.isDistributionModeRandom() ? vm.waitinglist.fixedNumberOfSeatsPerPosition || maxNumberOfSeats : vm.waitinglist.minNumberOfSeatsPerPosition || maxNumberOfSeats;

    maxNumberOfSeats = vm.isWlInInviteMode() ? waitinglist.maxNumberOfSeatsPerHost : maxNumberOfSeats || 1;
    minNumberOfSeats = minNumberOfSeats || 1;

    vm.numberOfSeatsRange = lodash.range(maxNumberOfSeats + 1).slice(minNumberOfSeats);
    vm.numberOfSeatsRangeForParkings = lodash.range(maxNumberOfSeats + 1).slice(0);
    vm.nrOfSeats = vm.numberOfSeatsRange.length === 1 ? _.head(vm.numberOfSeatsRange) : 1;

    var toastPositions = StrsToastService.getPositions();
    vm.onboardingPulseActive = false;
    vm.onboardingToastActive = false;
    vm.onboardingToastHandle = new StrsToast({
        sticky: true,
        position: toastPositions.BOTTOM
    });
    vm.leaveMode = false;
    vm.onBookedClicked = function () {
        vm.leaveMode = !vm.leaveMode;
    };

    function eventDateAsText() {
        return DateService.formatInTz(waitinglist.eventStartDate, $rootScope.APP.localeFormats.datetime);
    }

    vm.social = {
        url: $location.absUrl(),
        image: waitinglist.eventImageUrl,
        title: waitinglist.translatedExperienceName || waitinglist.translatedEventName,
        description: eventDateAsText() + ' @ ' + waitinglist.translatedVenueName
    };

    function startProcessing() {
        vm.processing = true;
    }

    function processingDone() {
        vm.processing = false;
    }

    function setExpiration() {
        var expiration = WaitinglistService.waitinglistExpiration(waitinglist);
        if (expiration) {
            var ms = DateService.utcMoment(expiration).diff(DateService.utcMoment());
            // Unset the position&seat if the expiration times out (mimic what server
            // Would do)
            if (ms < 0) {
                waitinglist.position = null;
                waitinglist.seat = null;
                WaitinglistService.signalExpiration();
            }
            vm.expiration = Math.floor(MomentService.getMoment().duration(ms).asHours()) + MomentService.getMoment().utc(ms).format(':mm:ss');
        } else {
            vm.expiration = null;
        }
    }

    var refreshTimeout = false;

    function refreshExpiration() {
        setExpiration();
        refreshTimeout = $timeout(refreshExpiration, WAITING_LIST_TIMEOUT_MS);
    }

    function setTicketingSystemName() {
        if (waitinglist.seat && waitinglist.seat.ticketingSystemType) {
            vm.ticketingSystemName = WaitinglistService.ticketingSystemTypeToName(waitinglist.seat.ticketingSystemType);
        }
    }

    function loadSurveyInformation() {
        return SurveyService.getWaitingListSurveyQuestions(waitinglistId).then(function (information) {
            return vm.surveyInformation = information;
        });
    }

    function loadSurveyCheckoutInformation() {
        return SurveyService.getWaitingListSurveyQuestions(waitinglistId, 'AT_CHECKOUT').then(function (information) {
            return vm.surveyCheckoutInformation = information;
        });
    }

    function reloadWaitinglist() {
        $log.debug('reloadWL ' + waitinglistId);
        return FanApiService.waitinglistById(waitinglistId).then(function (updatedWaitinglist) {
            angular.extend(waitinglist, updatedWaitinglist);
            setExpiration();
            setTicketingSystemName();
            return loadSurveyInformation().then(function () {
                if (vm.isWlInInviteMode()) {
                    return FanApiService.getMyInvite(waitinglist.waitingListId).then(function (invite) {
                        if (invite.status !== 'CANCELLED' && !waitinglist.position) {
                            waitinglist.position = {
                                numberOfSeats: invite.numberOfNonAllocatedSeats,
                                status: 'WAITING_SEAT'
                            };
                            vm.nrOfSeats = invite.numberOfNonAllocatedSeats;
                        }
                        return waitinglist;
                    });
                }
            });
        }, Alert.handleServerProblem('waitinglist - unable to reload wl'));
    }

    function reloadGroup() {
        $log.debug('reloadGroup ' + groupSlug);
        return GroupService.groupBySlugOrId(groupSlug).then(undefined, Alert.handleServerProblem('waitinglist - unable to reload fg')).then(function (updatedGroup) {
            angular.extend(group, updatedGroup);
            return reloadWaitinglist();
        });
    }

    function computeAdditionalCharges(container, additionalCharges) {
        var additionalChargesToUse = additionalCharges ? additionalCharges : container.additionalCharges || [];
        container.additionalCharges = lodash.map(additionalChargesToUse, function (charge) {
            if (charge.type === 'MULTIPLICATIVE') {
                charge.details = lodash.join([container.nrOfSeats, ' x ', waitinglist.currency.symbol, charge.price], '');
                charge.totalPrice = Number(container.nrOfSeats) * Number(charge.price);
            }
            return charge;
        });
    }

    function computePrice(container) {
        if (!angular.isNumber(container.nrOfSeats)) {
            return undefined;
        }
        if (GroupService.shouldJoinForWaitinglistPrice(group)) {
            return undefined;
        }
        if (WaitinglistService.shouldUnlockForWaitinglistPrice(waitinglist)) {
            return undefined;
        }
        if (waitinglist.freeWaitingList) {
            return undefined;
        }

        container.calculatingTotalPrice = true;
        return FanApiService.waitinglistPrice(waitinglistId, container.nrOfSeats || 1).then(function (price) {
            container.formattedPrice = null;
            container.activePrice = price;
            container.calculatingTotalPrice = false;
        }).catch(function (error) {
            container.calculatingTotalPrice = false;
            return Alert.handleServerProblem('could not fetch waitinglist price')(error);
        });
    }

    function loadAdditionalCharges() {
        return FanApiService.loadAdditionalCharges(waitinglistId).then(function (additionalCharges) {
            return computeAdditionalCharges(vm, additionalCharges);
        });
    }

    function generateNrOfSeats() {
        return vm.isDistributionModeRandom() ? waitinglist.fixedNumberOfSeatsPerPosition : waitinglist.position && waitinglist.position.numberOfSeats || (vm.numberOfSeatsRange.length === 1 ? lodash.first(vm.numberOfSeatsRange) : 1);
    }

    function initializeWl() {
        var actionStatus = WaitinglistService.waitinglistStatus(waitinglist, group);
        var groupStatus = GroupService.groupStatus(group);
        var isNotAFan = GroupService.isNotAFan(group);

        $log.debug('waitinglist - action status: "%s", group status: "%s"', actionStatus.status, groupStatus);
        vm.actionStatus = actionStatus.status;
        if (waitinglist.displayMode === 'DETAILED') {
            FanApiService.waitingListAdditionalInfos(waitinglist.waitingListId).then(function (res) {
                vm.additionalInfos = res;
            });
        }
        vm.groupStatus = groupStatus;
        vm.isNotAFan = isNotAFan;
        vm.processing = actionStatus.processing;
        vm.shouldJoinForWaitinglistPrice = GroupService.shouldJoinForWaitinglistPrice(group);
        vm.canPreauthorize = WaitinglistService.canPreauthorize(waitinglist);
        vm.hasPreauthorized = WaitinglistService.hasPreauthorized(waitinglist);
        vm.hasPayed = WaitinglistService.hasPayed(waitinglist);
        vm.hasExpiration = WaitinglistService.hasExpiration(waitinglist);
        var partial = vm.actionStatus.replace(/_/g, '-').toLowerCase();
        console.log('ACTION STATUS =>', vm.actionStatus);
        vm.statusActionsTemplate = 'routes/main/waitinglist/actions-' + partial + '.html';
        vm.statusInfoTemplate = 'routes/main/waitinglist/info-' + partial + '.html';
        setTicketingSystemName();
        WaitinglistService.signalAcceptedSeats(waitinglist);
        vm.hasVoucher = WaitinglistService.hasVoucher(waitinglist);
        vm.eventDate = WaitinglistService.eventDate(waitinglist);
        vm.formattedPrice = WaitinglistService.formatPrice(waitinglist);
        vm.nrOfSeats = generateNrOfSeats();
        WaitinglistService.setWaitinglistHeaderImage(waitinglist);
        WaitinglistService.setWaitinglistDescription(waitinglist);
        WaitinglistService.setWaitinglistExperienceName(waitinglist);
        waitinglist.translatedVenueConditions = translatedVenueConditions;
        vm.wlPeriod = WaitinglistService.generateWaitinglistPeriod(waitinglist);
        vm.surveyInformation = surveyInformation;
        vm.surveyCheckoutInformation = surveyCheckoutInformation;
        loadAdditionalCharges();
        if (vm.isWlInInviteMode()) {
            vm.getInvites();
            FanApiService.getMyInvite(waitinglist.waitingListId).then(function (invite) {
                if (invite.id) {
                    vm.inviteId = invite.id;
                    vm.invite = invite;
                    vm.hostInvited = true;
                    vm.hostNbrOfRequestedSeats = invite.nbrOfRequestedSeats;
                    if (invite.nonAllocatedSeatsStatus === 'ALLOCATED') {
                        vm.hostInviteAccepted = true;
                    }
                    if (invite.status !== 'CANCELLED' && !waitinglist.position) {
                        waitinglist.position = {
                            numberOfSeats: invite.numberOfNonAllocatedSeats,
                            status: 'WAITING_SEAT'
                        };
                        vm.nrOfSeats = invite.numberOfNonAllocatedSeats;
                    }
                }
            });
        }
        vm.onboardingToastHandle.onLoaded(function () {
            // Show unless the user closed the toast before
            var onboardingToastTokenShown = Boolean(localStorage.getItem(APP_SETTINGS.onboardingToastWaitingListShownTokenName));

            if (isNotAFan || !onboardingToastTokenShown && (vm.actionStatus === 'BOOK' || vm.actionStatus === 'UNLOCK')) {
                vm.onboardingToastHandle.show();
                vm.onboardingPulseActive = true;
                vm.onboardingToastActive = true;
            }
        });

        vm.onboardingToastHandle.onHideRequested(function () {
            // Remember that the user closed the toast
            vm.onboardingPulseActive = false;
            vm.onboardingToastActive = false;
            localStorage.setItem(APP_SETTINGS.onboardingToastWaitingListShownTokenName, true);
        });
    }
    vm.showSurveyInformation = function () {
        return !lodash.isEmpty(vm.surveyInformation) && (vm.showIf(vm.WAITINGLIST_ACTION_STATUS.WAIT) || vm.showIf(vm.WAITINGLIST_ACTION_STATUS.PAY) || vm.showIf(vm.WAITINGLIST_ACTION_STATUS.CONFIRM) || vm.showIf(vm.WAITINGLIST_ACTION_STATUS.GO_LIVE));
    };

    vm.showSurveyCheckoutInformation = function () {
        return !lodash.isEmpty(vm.surveyCheckoutInformation) && (vm.showIf(vm.WAITINGLIST_ACTION_STATUS.PAY) || vm.showIf(vm.WAITINGLIST_ACTION_STATUS.CONFIRM) || vm.showIf(vm.WAITINGLIST_ACTION_STATUS.GO_LIVE) || vm.showIf(vm.WAITINGLIST_ACTION_STATUS.NO_SEATS));
    };
    vm.editSurveyInformation = function () {
        return SurveyService.fulfillSurveyRequirements(waitinglistId).then(function () {
            return loadSurveyInformation();
        });
    };

    vm.getInvites = function () {
        FanApiService.fetchInviteList(waitinglistId).then(function (result) {
            vm.inviteList = result.content;

            vm.numberOfInvitesApproved = result.content.filter(function (invite) {
                return invite.status === 'APPROVED';
            }).length;
            vm.numberOfInvitesPending = result.content.filter(function (invite) {
                return invite.status === 'PENDING';
            }).length;
            vm.numberOfInvitesRejected = result.content.filter(function (invite) {
                return invite.status === 'REJECTED';
            }).length;
        });
    };
    vm.editSurveyCheckoutInformation = function () {
        return SurveyService.fulfillSurveyRequirements(waitinglistId, 'AT_CHECKOUT', null, waitinglist.position.numberOfSeats).then(function () {
            return loadSurveyCheckoutInformation();
        });
    };
    vm.joinGroup = function () {
        if (vm.processing) {
            startProcessing();
        }
        GroupService.joinGroup(group).then(function () {
            return GroupService.groupBySlugOrId(groupSlug).then(function (joinedGroup) {
                angular.extend(group, joinedGroup);
                vm.shouldJoinForWaitinglistPrice = false;
                return computePrice(vm);
            });
        }).then(function () {
            initializeWl();
        }).then(processingDone);
    };

    function refreshToken() {
        return SessionService.refreshTokenIfNeeded();
    }

    vm.processing = false;
    vm.unlockGroup = function () {
        // Block simultanious requests
        if (vm.processing) {
            return;
        }
        vm.processing = true;
        console.log('UNLOCKIIING');
        ModalsService.unlockFanGroup(group)
        // Unlock and join or report the issue
        .then(refreshToken).then(function () {
            // Successfully unlocked
            reloadGroup();
            location.reload();
            vm.processing = false;
        }).catch(function (error) {
            vm.processing = false;
            return error;
        });
    };

    vm.askJoinWaitinglist = function () {
        $modal.open({
            templateUrl: 'routes/main/waitinglist/m-joinwl.modal.html',
            windowClass: 'strs-modal-alt',
            controller: function controller() /* @ngInject */{
                var modalVm = this;
                modalVm.nrOfSeats = vm.numberOfSeatsRange.length === 1 ? lodash.first(vm.numberOfSeatsRange) : undefined;
                modalVm.maxNumberOfSeats = maxNumberOfSeats;
                modalVm.freeWaitingList = waitinglist.freeWaitingList;
                if (!modalVm.freeWaitingList) {
                    modalVm.price = angular.copy(waitinglist.price);
                    modalVm.currency = waitinglist.currency;
                    $scope.$watch('wl.nrOfSeats', function () {
                        $log.debug('# of seats changed in modal');
                        computePrice(modalVm);
                    });
                }
            },
            controllerAs: 'wl'
        }).result.then(vm.joinWaitinglist);
    };

    vm.joinWaitinglist = function (numberOfSeats, numberOfParkingTickets) {

        vm.onboardingPulseActive = false;
        vm.selectedSeats = vm.seats.map(function (seat) {
            return seat.ticketId;
        });
        if (me.validatedEmail) {
            startProcessing();
            // WaitinglistService.joinWaitinglist(waitinglist, numberOfSeats, vm.selectedSeats, group)
            WaitinglistService.joinWaitinglist(waitinglist, numberOfSeats, vm.selectedSeats, group, numberOfParkingTickets).then(reloadWaitinglist)
            // Error handler
            .then(undefined, function (err) {
                if (!lodash.includes([WAITINGLIST_JOIN_ERRORS.CANCELLED, WAITINGLIST_JOIN_ERRORS.EMAIL_NOT_CONFIRMED], err)) {
                    Alert.handleServerProblem('waitinglist ctrl - unable to join waiting list')(err);
                }
            }).then(processingDone);
        } else {
            WaitinglistService.askForEmailConfirmation();
            return $q.reject(WAITINGLIST_JOIN_ERRORS.EMAIL_NOT_CONFIRMED);
            // Alert.warning('waitinglist_confirm_mail_error');
        }
    };

    vm.unlocking = false;
    vm.unlockWaitinglist = function () {
        // Block simultanious requests
        if (vm.unlocking) {
            return;
        }
        vm.unlocking = true;
        WaitinglistService.unlockWaitinglist(waitinglist)
        // Unlock and join or report the issue
        .then(undefined, function (err) {
            if (err === WAITINGLIST_UNLOCK_ERRORS.WRONG_CODE) {
                Alert.warning('fangroup_unlock-wrong-code');
            } else if (err !== WAITINGLIST_UNLOCK_ERRORS.CANCELLED) {
                Alert.serverProblem();
            }
        }).then(reloadWaitinglist).then(function () {
            vm.unlocking = false;
        }).catch(function () {
            vm.unlocking = false;
        });
    };

    vm.canBeShared = function () {
        return WaitinglistService.canBeShared(waitinglist, group);
    };

    vm.leaveWaitinglist = function () {
        if (vm.onboardingToastActive) {
            vm.onboardingPulseActive = true;
        }

        var modalData = {
            title: 'wishlist_leave_wishlist_modal_title',
            message: 'wishlist_leave_wishlist_modal_message',
            acceptLabel: 'confirmation_yes_i_want',
            cancelLabel: 'confirmation_cancel',
            messageTransOptions: {
                wishlistname: waitinglist.translatedExperienceName || waitinglist.translatedEventName
            }
        };

        ModalsService.confirmation(modalData).then(function (res) {
            startProcessing();

            return WaitinglistService.leaveWaitinglist(waitinglist).catch(Alert.handleServerProblem('waitinglist ctrl - unable to leave waitinglist'));
        }).then(reloadWaitinglist).then(processingDone);
    };

    vm.computeTotal = function (nrOfSeats) {
        return parseFloat(waitinglist.price.total) * nrOfSeats;
    };

    vm.computeFee = function (nrOfSeats) {
        return parseFloat(waitinglist.price.fee) * nrOfSeats;
    };

    vm.paymentProcessing = false;
    vm.executePaySeat = function () {
        if (vm.paymentProcessing) {
            return;
        }
        vm.paymentProcessing = true;
        PaymentService.pay(waitinglist).then(function () {
            vm.paymentProcessing = false;
        }).catch(function (response) {
            console.error(response);
            vm.paymentProcessing = false;
        });
    };
    /**
     * Before paying or preauth, check if we need to verify/confirm personal info first.
     */
    vm.preauthorize = function () {
        vm.executePaySeat();
    };

    // For adyen the flow is exactly the same for payment and preauthorization
    // If changed in future, do not forget to include the check on personal info
    vm.payAndConfirmSeat = function () {
        $state.go('app.main.waitinglist.checkout', {
            checkoutStatus: CHECKOUT_STATUS.CHECKOUT_INPROGRESS
        });
    };

    // Confirm the seat
    vm.confirming = false;
    vm.executeConfirmSeat = function () {
        vm.optionUsed = $stateParams.optionUsed != null ? $stateParams.optionUsed : null;
        if (vm.confirming) {
            return;
        }
        // Single request
        vm.confirming = true;
        WaitinglistService.confirmSeat(waitinglist, vm.optionUsed).then(function () {
            vm.confirming = false;
        }).catch(function () {
            vm.confirming = false;
        });
    };
    /**
     * Before confirming, check if we need to verify/confirm personal info first.
     */
    vm.confirmSeat = function () {
        $state.go('app.main.waitinglist.checkout', {
            checkoutStatus: CHECKOUT_STATUS.CHECKOUT_INPROGRESS
        });
    };

    vm.downloadSeat = function () {
        if (vm.waitinglist.displayName === "Voucher" && waitinglist.seat.exportedVoucherUrl === null && vm.fanGroup.properties != null && vm.fanGroup.properties.waitinglists != null && vm.fanGroup.properties.waitinglists.showFormatOptions != null && vm.fanGroup.properties.waitinglists.showFormatOptions === true) {
            $modal.open({
                templateUrl: 'routes/main/waitinglist/checkout/form-download-mode.html',
                windowClass: 'medium strs-modal-alt',
                // eslint-disable-next-line no-shadow
                controller: /* @ngInject */function controller($scope, FanApiService, FanGroupService, TranslationService) {

                    $scope.isVoucherZone = vm.waitinglist.displayName === 'Voucher' ? true : false;
                    $scope.padding = vm.waitinglist.displayName === 'Voucher' ? '1rem' : '0rem';

                    $scope.optionsDownloads = [{ id: 1, translations: TranslationService.tk('translations_formatDownload_multipage'), format: "MULTIPAGE_PDF_FILE", value: "PDF Multiple page" }, { id: 2, translations: TranslationService.tk('translations_formatDownload_ziptMultipage'), format: 'ZIP_FILE', value: "ZIP Multiple page" }];
                    $scope.format = $scope.optionLang;
                    $scope.optionLang = $scope.optionsDownloads[0];
                    $scope.confirm = function (optionLang) {
                        startProcessing();
                        WaitinglistService.exportSeat(waitinglist, optionLang).then(function (updatedWl) {
                            angular.extend(waitinglist, updatedWl);
                            $window.open(waitinglist.seat.exportedVoucherUrl, '_blank');
                        }).then(function () {
                            $scope.$close();
                        }).then(processingDone);
                    };
                }
            });
        } else {
            startProcessing();
            WaitinglistService.exportSeat(waitinglist, null).then(function (updatedWl) {
                angular.extend(waitinglist, updatedWl);
                $window.open(waitinglist.seat.exportedVoucherUrl, '_blank');
            }).then(processingDone);
        }
    };

    vm.downloadParkingSeat = function () {
        startProcessing();
        WaitinglistService.exportParkingSeat(waitinglist).then(function (updatedWl) {
            angular.extend(waitinglist, updatedWl);
            $window.open(waitinglist.seat.exportedParkingTicketUrl, '_blank');
        }).then(processingDone);
    };

    // Guest Methods

    vm.joinWithInvite = function () {
        FanApiService.meAsHost().then(function (me) {
            var meAsGuest = {
                guestId: me.userId,
                numberOfSeats: 0,
                numberOfNonAllocatedSeats: vm.nrOfSeats,
                importance: 'REQUIRED',
                order: null
            };
            return FanApiService.inviteGuest(meAsGuest, waitinglistId);
        }).then(function (invite) {
            vm.hostInvited = true;
        });
        /*  */
    };

    vm.leaveWithInvite = function () {
        FanApiService.cancelInvite(vm.inviteId).then(function (invite) {
            console.log(invite);
        });
    };

    vm.goToGuestList = function () {
        sessionStorage.setItem('totalTickets', vm.nrOfSeats);

        if (vm.fanGroup.properties != undefined && vm.fanGroup.properties.invite != undefined && vm.fanGroup.properties.invite.guestSelectionModeNoHostFirstEnabled != undefined && vm.fanGroup.properties.invite.guestSelectionModeNoHostFirstEnabled === true) {
            return $state.go('app.main.waitinglist.guestlistWithoutHosts', {
                nrOfSeats: vm.nrOfSeats
            });
        }

        return $state.go('app.main.waitinglist.guestlist', {
            nrOfSeats: vm.nrOfSeats
        });
    };

    vm.goToInvite = function () {
        $state.go('app.main.group.myinvites', { waitinglistId: waitinglistId });
    };

    vm.goEditGuestList = function () {
        $state.go('app.main.waitinglist.guestlist', { waitinglistId: waitinglistId });
    };

    vm.hasWaitingListTextProperties = function (phraseAppKey) {
        return FanGroupService.getPublicFanGroup() != undefined && FanGroupService.getPublicFanGroup().properties != undefined && FanGroupService.getPublicFanGroup().properties.waitinglists != undefined && FanGroupService.getPublicFanGroup().properties.waitinglists[phraseAppKey] != undefined;
    };

    vm.getWaitingListTextPropertiesIfAny = function (phraseAppKey) {
        if (FanGroupService.getPublicFanGroup().properties.waitinglists[phraseAppKey][AppService.getLocale()] != undefined) {
            return FanGroupService.getPublicFanGroup().properties.waitinglists[phraseAppKey][AppService.getLocale()];
        }
        return FanGroupService.getPublicFanGroup().properties.waitinglists[phraseAppKey].en;
    };

    refreshExpiration();
    $scope.$on('$destroy', function () /* Event */{
        if (refreshTimeout) {
            $timeout.cancel(refreshTimeout);
        }
    });

    $scope.$watch('wl.nrOfSeats', function (value) {
        vm.nrOfSeats = value;
        console.log('WaitinglistCtrl - # of seats changed');
        computePrice(vm);
        computeAdditionalCharges(vm);
    });

    if (PaymentService.canProcessPayment(waitinglist)) {
        $log.debug('WaitingListCtrl - Processing 2step payment initialized');
        var closeBlockingMessage = UtilService.blockingMessage(TranslationService.t('waitinglist_payment-in-progress'));
        PaymentService.process2StepPayment(waitinglist, group).then(function () {
            $log.debug('WaitingListCtrl - Processing 2step payment done');
            closeBlockingMessage();
            vm.loading = false;
        }).catch(function () {
            $log.debug('WaitingListCtrl - Processing 2step payment done');
            closeBlockingMessage();
            vm.loading = false;
        });
    }

    $scope.$watch('wl.group', initializeWl, true);
    $scope.$watch('wl.waitinglist', initializeWl, true);

    $scope.$on(WAITINGLIST_SIGNALS.LEFT_WAITINGLIST, function () {
        waitinglist.position = null;
    });

    /**
     * Clear the checkout status, so that no other payment or confirmation actions can start
     */
    function resetCheckoutStatus() {
        checkoutStatus = undefined;
    }

    initializeWl();

    /**
     *  We can indicate from an external source (e.g. Embedded) that we require immediate payment
     *  without the need to fill the attendee info first (already filled)
     *  This is indicated by the 'proceedToPayment' indicator
     */
    var queryParams = URLService.getQueryParameters();
    if (queryParams.proceedToPayment !== undefined) {
        resetCheckoutStatus();
        vm.executePaySeat();
    } else if (checkoutStatus === CHECKOUT_STATUS.CHECKOUT_CAN_CONFIRM) {
        /**
        Start confirmation or payment actions, if we have returend back from the checkout page
        Make sure that initializeWl has occured first.
        The checkout status will be cleared, in order to avoid additional actions,
        in case the user leaves the WL or payment fails etc etc.
        */

        resetCheckoutStatus();
        vm.executeConfirmSeat();
    } else if (checkoutStatus === CHECKOUT_STATUS.CHECKOUT_CAN_PAY) {
        resetCheckoutStatus();
        vm.executePaySeat();
    }
});