'use strict';

angular.module('app').directive('strsLoadingIndicator', function () {
  return {
    templateUrl: 'components/strs-loading-indicator/strs-loading-indicator.html',
    restrict: 'AE',
    scope: {
      loader: '=',
      trlLabel: '=',
      label: '='
    }
  };
});