'use strict';

angular.module('app').controller('AuthenticateController', function (publicFanGroup) {
   /**
      *  CONSTANTS
      */

   /**
      *  PRIVATE VARIABLES
      */

   /**
      *  PRIVATE FUNCTIONS
      */

   /**
      *  VM VARIABLES
      */
   var vm = this;
   vm.group = publicFanGroup;

   /**
      *  VM FUNCTIONS
      */

   /**
      *  SETUP
      */
});