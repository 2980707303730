'use strict';

angular.module('app').enum('NOTIFICATIONS_STACK_TYPES', ['CONFIRM_EMAIL', 'EMAIL_CONFIRMED', 'EMAIL_SENT_SUCCESSFULLY', 'EMAIL_SENT_ERROR', 'EMAIL_EXPIRED', 'GENERAL_SUCCESS']).constant('NOTIFICATIONS_STACK_VALUES', {
  SUCCESS_TIMEOUT: 5000
}).factory('StrsNotificationsStack',
/* @ngInject */function ($log, StrsNotificationsStackService, $modal, lodash, NOTIFICATIONS_STACK_TYPES, $rootScope, $timeout, NOTIFICATIONS_STACK_VALUES) {
  var factory = {};
  var notificationStack = [];
  var modals = {};

  function find(type) {
    return lodash.find(notificationStack, { type: type });
  }

  function isReplaceable(stack) {
    return lodash.includes([NOTIFICATIONS_STACK_TYPES.GENERAL_SUCCESS], stack.type);
  }

  function highlight(options) {
    // TODO implement Highlight feature on recurring stacks
    return console.log('Highlight!');
  }

  /**
  * @param {type: string} options
  */
  function remove(options) {
    var stack = options.index ? notificationStack[options.index] : find(options.type);
    if (!stack) return;
    stack.modal.close();
    lodash.pull(notificationStack, stack);
  }

  /**
  * @param {type: string, data:any} options
  */
  function add(stack) {
    var stackExists = find(stack.type);
    if (stackExists && !isReplaceable(stackExists)) return highlight(stackExists);
    stack.modal = modals[stack.type](stack.data, stack.type);
    stack.id = Math.random();
    return notificationStack.push(stack);
  }

  function clear() {
    lodash.forEach(notificationStack, function (stack) {
      stack.modal.close();
    });
    notificationStack = [];
  }

  function mutateModal(modal, type, timeout, next) {
    modal.result = modal.result.then(function (index) {
      remove({ index: index, type: type });
      return next ? next() : lodash.noop();
    });
    if (timeout) {
      $timeout(function () {
        modal.close();
      }, NOTIFICATIONS_STACK_VALUES.SUCCESS_TIMEOUT);
    }
    return modal;
  }

  function modalDefaults() {
    return {
      windowClass: 'strs-notifications-stack',
      backdrop: false,
      keyboard: false,
      parent: angular.element('#strs-notification-stack-container')
    };
  }

  // Stacks Methods
  function loadConfirmEmailStack() {
    var modalOptions = modalDefaults();
    angular.extend(modalOptions, {
      templateUrl: 'components/strs-waitinglist/waitinglist-unconfirmed-email.modal.html',
      controllerAs: 'unconfirmedEmail',
      controller: 'waitinglist_UnconfirmedEmailController',
      resolve: {
        fan: /* @ngInject */function fan(FanApiService) {
          return FanApiService.me();
        },
        EMAIL_CONFIRMATION_ERROR: function EMAIL_CONFIRMATION_ERROR() {
          return null;
        },
        ActivateSendCall: function ActivateSendCall() {
          return true;
        }
      }
    });
    var modal = $modal.open(modalOptions);
    mutateModal(modal, NOTIFICATIONS_STACK_TYPES.CONFIRM_EMAIL);
    return modal;
  }

  function loadEmailConfirmationErrorStack(data, type) {
    var modalOptions = modalDefaults();
    angular.extend(modalOptions, {
      templateUrl: 'components/strs-waitinglist/waitinglist-unconfirmed-email-error.modal.html',
      controllerAs: 'unconfirmedEmail',
      controller: 'waitinglist_UnconfirmedEmailController',
      windowClass: 'strs-notifications-stack alert',
      resolve: {
        fan: function fan( /* @ngInject */FanApiService) {
          return FanApiService.me();
        },
        EMAIL_CONFIRMATION_ERROR: function EMAIL_CONFIRMATION_ERROR() {
          return data.error;
        },
        ActivateSendCall: function ActivateSendCall() {
          return false;
        }
      }
    });
    var modal = $modal.open(modalOptions);
    mutateModal(modal, type);
    return modal;
  }

  function success(data) {
    var modalOptions = modalDefaults();
    angular.extend(modalOptions, {
      templateUrl: 'components/strs-notifications-stack/views/strs-notifications-stack.success.modal.html',
      windowClass: 'strs-notifications-stack success',
      controllerAs: 'successStack',
      controller: function controller() {
        var vm = this;
        vm.data = data;
      }
    });

    var modal = $modal.open(modalOptions);
    mutateModal(modal, NOTIFICATIONS_STACK_TYPES.GENERAL_SUCCESS, true);
    return modal;
  }

  function runSequence(sequenceList) {
    lodash.each(sequenceList, function (sequenceItem) {
      factory[sequenceItem.action](sequenceItem.options);
    });
  }

  modals[NOTIFICATIONS_STACK_TYPES.CONFIRM_EMAIL] = loadConfirmEmailStack;
  modals[NOTIFICATIONS_STACK_TYPES.EMAIL_SENDING_ERROR] = loadEmailConfirmationErrorStack;
  modals[NOTIFICATIONS_STACK_TYPES.EMAIL_EXPIRED] = loadEmailConfirmationErrorStack;
  modals[NOTIFICATIONS_STACK_TYPES.GENERAL_SUCCESS] = success;

  factory.add = add;
  factory.remove = remove;
  factory.clear = clear;
  factory.runSequence = runSequence;
  factory.notificationStack = notificationStack;
  return factory;
});