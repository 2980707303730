'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

angular.module('app').enum('FANGROUP_ACCESS_MODE', ['CODE_PROTECTED', 'PUBLIC', 'PRIVATE']).service('FanApiService',
/* @ngInject */
function (APIService, SDKService, $q, $sanitize) {
    var service = {};

    var fanSDKService = SDKService.client.fanService;
    var fanResource = APIService.createResource('fan');
    var fanResourceV2 = APIService.createResource('v2/fan');
    var hostResource = APIService.createResource('v2/host');
    var approverResource = APIService.createResource('v2/approver');

    service.me = function () {
        return fanResource.custom('GET', '');
    };

    function sanitizeObject(object) {
        for (var key in object) {
            if (typeof object[key] === 'string') {
                object[key] = $sanitize(object[key]);
            }
            if (_typeof(object[key]) === 'object') {
                sanitizeObject(object[key]);
            }
        }
    }

    service.updateFan = function (fan) {
        sanitizeObject(fan);

        return fanResource.custom('PUT', '', {
            data: fan
        });
    };

    service.updatePassword = function (currentPassword, password) {
        return fanResource.custom('PUT', 'password', {
            // String payload
            data: {
                currentPassword: currentPassword,
                password: password
            }
        });
    };

    service.sendValidationEmail = function (email, confirmationReturnURLPath) {
        // console.log(confirmationReturnURLPath);
        return fanResource.custom('PUT', 'email', {
            data: {
                email: email,
                confirmationReturnURLPath: confirmationReturnURLPath
            }
        });
    };

    service.sendValidationSMS = function (phone) {
        return fanResource.custom('PUT', 'mobile-phone-number', {
            data: phone
        });
    };

    service.countUnreadNotifications = function () {
        return fanResource.custom('GET', 'count-unread-notifications');
    };

    service.notifications = function (pageSize, offset) {
        return fanResource.custom('GET', 'notifications', {
            queryParams: {
                maxPageSize: pageSize,
                itemOffset: offset
            }
        });
    };

    service.updateNotificationStatus = function (notificationId, status) {
        return fanResource.custom('PUT', 'notifications/:notificationId/:status', {
            endpointParams: {
                notificationId: notificationId,
                status: status
            }
        });
    };

    service.updateAllNotificationStatuses = function (status) {
        return fanResource.custom('PUT', 'all-notifications/:status', {
            endpointParams: {
                status: status
            }
        });
    };

    service.deleteAccount = function (groupId) {
        return fanResourceV2.custom('POST', 'groups/:groupId/deletion-request', {
            endpointParams: {
                groupId: groupId
            }
        });
    };

    service.getSeatingMap = function (waitingListId) {
        return fanSDKService.getSeatingMap(waitingListId);
    };

    service.getAvailableSeats = function (waitingListId) {
        return fanSDKService.getAvailableSeats(waitingListId);
    };

    service.fangroupBySlug = function (slug) {
        return fanSDKService.getFanGroupBySlug(slug);
    };

    service.fangroupById = function (groupId) {
        return fanSDKService.getFanGroup(groupId);
    };

    service.fangroupLookBySlug = function (slug) {
        return fanSDKService.getFanGroupLookBySlug(slug);
    };

    service.fangroupBySlug = function (slug) {
        return fanSDKService.getFanGroupBySlug(slug);
    };

    service.fangroupTranslatedDescription = function (groupId) {
        return fanSDKService.getFanGroupTranslatedDescription(groupId);
    };

    service.oauthAuthorizationCodeUrl = function (provider) {
        return fanSDKService.fanGroupService.getOauthAuthorizationCodeUrl(provider);
    };

    service.fangroupShareInfo = function (groupId) {
        return fanSDKService.shareFanGroup(groupId);
    };

    service.waitinglistById = function (waitinglistId) {
        return fanSDKService.getWaitingList(waitinglistId);
    };

    service.joinedWaitinglists = function (pageSize, offset) {
        return fanSDKService.getMyWaitingListsWithoutSeat({
            page: offset,
            maxPageSize: pageSize
        });
    };

    service.activeWaitinglistsWithSeat = function (pageSize, offset) {
        return fanSDKService.getMyWaitingListsWithSeat({
            page: offset,
            maxPageSize: pageSize
        });
    };

    service.historyWaitinglistsWithSeat = function (groupId, pageSize, offset) {
        return fanResource.custom('GET', 'groups/:groupId/archived-waiting-lists-with-seat', {
            endpointParams: {
                groupId: groupId
            },
            queryParams: {
                maxPageSize: 9999,
                itemOffset: 0
            }
        });
    };

    service.getBadgesProtection = function (wlId) {
        return fanSDKService.getBadgesProtection(null, wlId);
    };

    service.waitinglistShareInfo = function (waitinglistId) {
        return fanSDKService.shareWaitingList(waitinglistId);
    };

    service.joinFanGroup = function (groupId) {
        // @TODO to be removed once SDK wrapper is properly implemented
        return $q.resolve().then(function () {
            return fanSDKService.joinFanGroup(groupId);
        });
    };

    service.joinedFanGroups = function () {
        return fanSDKService.getJoinedFanGroups();
    };

    service.joinProtectedFanGroup = function (groupId, code) {
        return fanSDKService.joinProtectedFanGroup(groupId, code);
    };

    service.requestToJoinPrivateFanGroup = function (groupId) {
        return fanSDKService.requestToJoinPrivateFanGroup(groupId);
    };

    service.leaveFanGroup = function (groupId) {
        return fanSDKService.leaveFanGroup(groupId);
    };

    service.hasGeoFilteredWaitingLists = function (groupId) {
        return fanSDKService.hasGeoFilteredWaitingLists(groupId);
    };

    service.fangroupWaitinglists = function (groupId, pageSize, offset) {
        return fanResource.custom('GET', 'groups/:groupId/waiting-lists', {
            endpointParams: {
                groupId: groupId
            },
            queryParams: {
                maxPageSize: pageSize,
                itemOffset: offset
            }
        });
    };

    service.fetchGuestList = function (groupId, query, page, sort) {
        return hostResource.custom('PUT', 'groups/:groupId/guests', {
            endpointParams: {
                groupId: groupId
            },
            queryParams: {
                size: 20,
                page: page || 0,
                sort: sort
            },
            data: {
                query: query
            }
        });
    };

    service.getMyInvite = function (waitingListId) {
        return hostResource.custom('GET', 'waiting-lists/:waitingListId/my-invitation', {
            endpointParams: {
                waitingListId: waitingListId
            }
        });
    };

    service.fetchInviteList = function (waitingListId) {
        return hostResource.custom('GET', 'waiting-lists/:waitingListId/invitations', {
            endpointParams: {
                waitingListId: waitingListId
            },
            queryParams: {
                size: 9999,
                page: 0
            }
        });
    };

    service.fetchInviteById = function (waitingListId, inviteId) {
        return hostResource.custom('GET', 'invitations/:inviteId', {
            endpointParams: {
                waitingListId: waitingListId,
                inviteId: inviteId
            }

        });
    };

    service.fetchInviteListByGroupId = function (groupId) {
        return hostResource.custom('GET', 'groups/:groupId/invitations', {
            endpointParams: {
                groupId: groupId
            },
            queryParams: {
                size: 9999,
                page: 0
            }
        });
    };

    service.exportGuestSeats = function (inviteId) {
        return hostResource.custom('PUT', 'invitations/:inviteId/export-seat', {
            endpointParams: {
                inviteId: inviteId
            },
            data: null
        });
    };

    service.acceptGuestSeats = function (inviteId) {
        return hostResource.custom('POST', 'invitations/:inviteId/accept-seat', {
            endpointParams: {
                inviteId: inviteId
            },
            data: null
        });
    };

    service.acceptTicketsMode = function (listId, format) {
        return hostResource.custom('POST', 'invitations/:listId/accept-seat', {
            endpointParams: {
                listId: listId
            },
            data: {
                format: format
            }
        });
    };

    service.inviteGuest = function (guest, waitingListId) {
        return hostResource.custom('POST', 'waiting-lists/:wlId/guest/:guestId', {
            endpointParams: {
                wlId: waitingListId,
                guestId: guest.guestId
            },
            data: {
                numberOfSeats: guest.numberOfSeats,
                numberOfNonAllocatedSeats: guest.numberOfNonAllocatedSeats,
                importance: guest.importance,
                order: guest.order,
                invitationReason: guest.invitationReason
            }
        });
    };

    service.addCustomInfo = function (groupId, guestId, customInfo) {

        return hostResource.custom('POST', 'groups/:groupId/guests/:guestId/custom-info', {
            endpointParams: {
                groupId: groupId,
                guestId: guestId
            },
            data: {
                semanticName: customInfo.semanticName,
                informationValue: customInfo.informationValue
            }
        });
    };

    service.cancelInvite = function (invitationId) {
        return hostResource.custom('POST', 'invitations/:invitationId/cancel', {
            endpointParams: {
                invitationId: invitationId
            },
            data: {}
        });
    };

    service.inviteCancelled = function (invitationId) {
        return hostResource.custom('POST', 'invitations/:invitationId/retain', {
            endpointParams: {
                invitationId: invitationId
            },
            data: {}
        });
    };

    service.editInvite = function (invite) {
        return hostResource.custom('PUT', 'invitations/:invitationId', {
            endpointParams: {
                invitationId: invite.inviteId
            },
            data: {
                numberOfSeats: invite.numberOfSeats,
                numberOfNonAllocatedSeats: invite.numberOfNonAllocatedSeats,
                order: invite.order,
                importance: invite.importance,
                invitationReason: invite.invitationReason
            }
        });
    };

    service.retainInvite = function (invite) {
        return hostResource.custom('POST', 'invitations/:invitationId/retain', {
            endpointParams: {
                invitationId: invite.id
            },
            data: {
                numberOfSeats: invite.numberOfSeats,
                numberOfNonAllocatedSeats: invite.numberOfNonAllocatedSeats,
                order: invite.order,
                importance: invite.importance
            }
        });
    };

    service.answerSurvey = function (instanceId, guestId, answers) {
        return hostResource.custom('POST', 'surveys/instances/:instanceId/answers/fan/:guestId', {
            endpointParams: {
                instanceId: instanceId,
                guestId: guestId
            },
            data: {
                answers: answers
            }
        });
    };

    service.getSurveyAnswerForHost = function (instanceId, guestId) {
        return hostResource.custom('GET', 'surveys/instances/:instanceId/answers/fan/:guestId', {
            endpointParams: {
                instanceId: instanceId,
                guestId: guestId
            },
            queryParams: {
                size: 5000
            }
        });
    };

    service.addCommunicationToInvite = function (inviteId, body, subject) {
        return hostResource.custom('POST', 'invitations/:inviteId/communication', {
            endpointParams: {
                inviteId: inviteId
            },
            data: {
                body: body,
                subject: subject,
                channel: 'EMAIL'
            }
        });
    };

    service.getCommunicationOfInvite = function (inviteId) {
        return hostResource.custom('GET', 'invitations/:inviteId/communication', {
            endpointParams: {
                inviteId: inviteId
            }
        });
    };

    service.updateCommunication = function (communication) {
        return hostResource.custom('PUT', 'communication/:communicationId', {
            endpointParams: {
                communicationId: communication.id
            },
            data: {
                body: communication.body,
                subject: communication.subject,
                channel: 'EMAIL'
            }
        });
    };

    service.fetchApprovalList = function (groupId, importance, size) {
        var maxSize = 9999;
        return approverResource.custom('GET', 'groups/:groupId/invitations', {
            endpointParams: {
                groupId: groupId
            },
            queryParams: {
                size: size || maxSize,
                page: 0,
                importance: importance
            }
        });
    };

    service.fetchReserve = function (wlId, hostId, size) {
        var maxSize = 1;
        return approverResource.custom('GET', 'waiting-lists/:wlId/host/:hostId/invitations', {
            endpointParams: {
                wlId: wlId,
                hostId: hostId
            },
            queryParams: {
                size: size || maxSize,
                page: 0,
                importance: 'OPTIONAL'
            }
        });
    };

    service.fetchApprovalListByWaitingList = function (waitingListId, importance, size) {
        var maxSize = 9999;
        return fgo.custom('GET', 'waiting-lists/:waitingListId/invitations', {
            endpointParams: {
                waitingListId: waitingListId
            },
            queryParams: {
                size: size || maxSize,
                page: 0,
                importance: importance
            }
        });
    };

    service.approveInvite = function (inviteId) {
        return approverResource.custom('POST', 'invitations/:inviteId/approve', {
            endpointParams: {
                inviteId: inviteId
            },
            data: {}
        });
    };

    service.approveInviteNonAllocated = function (inviteId) {
        return approverResource.custom('POST', 'invitations/:inviteId/approve-non-allocated', {
            endpointParams: {
                inviteId: inviteId
            },
            data: {}
        });
    };

    service.rejectInvite = function (inviteId, rejectionReason) {
        return approverResource.custom('POST', 'invitations/:inviteId/reject', {
            endpointParams: {
                inviteId: inviteId
            },
            data: {
                rejectionReason: rejectionReason
            }
        });
    };

    service.postCommentToInvite = function (invitationId, note) {
        return hostResource.custom('POST', 'invitations/:invitationId/comments', {
            endpointParams: {
                invitationId: invitationId
            },
            data: {
                comment: note,
                status: 'PUBLISHED'
            }
        });
    };

    service.postCommentToInviteAsApprover = function (invitationId, note) {
        return approverResource.custom('POST', 'invitations/:invitationId/comments', {
            endpointParams: {
                invitationId: invitationId
            },
            data: {
                comment: note,
                status: 'PUBLISHED'
            }
        });
    };

    service.getCommentOfInvite = function (invitationId) {
        return hostResource.custom('GET', 'invitations/:invitationId/comments', {
            endpointParams: {
                invitationId: invitationId
            }
        });
    };
    service.getCommentOfInviteForApprover = function (invitationId) {
        return approverResource.custom('GET', 'invitations/:invitationId/comments', {
            endpointParams: {
                invitationId: invitationId
            }
        });
    };

    service.meAsHost = function () {
        return hostResource.custom('GET', '');
    };

    service.meAsApprover = function () {
        return approverResource.custom('GET', '');
    };

    service.editGuest = function (guest, groupId, guestId) {

        var phoneNumber = {
            countryCallingCode: guest.countryCallingCode,
            localNumber: guest.localNumber
        };

        if (guest.localNumber != "") {
            return hostResource.custom('POST', 'groups/:groupId/guests', {
                endpointParams: {
                    groupId: groupId
                },
                data: {
                    firstName: guest.firstName,
                    lastName: guest.lastName,
                    guestEmail: guest.email,
                    mobilePhoneNumber: phoneNumber,
                    company: guest.company,
                    jobtitle: guest.jobtitle,
                    locale: guest.lang
                }
            });
        } else {
            return hostResource.custom('POST', 'groups/:groupId/guests', {
                endpointParams: {
                    groupId: groupId
                },
                data: {
                    firstName: guest.firstName,
                    lastName: guest.lastName,
                    guestEmail: guest.email,
                    company: guest.company,
                    jobtitle: guest.jobtitle,
                    locale: guest.lang
                }
            });
        }
    };

    service.createGuest = function (guest, groupId) {

        var phoneNumber = {
            countryCallingCode: guest.countryCallingCode,
            localNumber: guest.localNumber
        };

        if (guest.localNumber != "") {
            return hostResource.custom('POST', 'groups/:groupId/guests', {
                endpointParams: {
                    groupId: groupId
                },
                data: {
                    firstName: guest.firstName,
                    lastName: guest.lastName,
                    guestEmail: guest.email,
                    mobilePhoneNumber: phoneNumber,
                    company: guest.company,
                    jobtitle: guest.jobtitle,
                    locale: guest.lang
                }
            });
        } else {
            return hostResource.custom('POST', 'groups/:groupId/guests', {
                endpointParams: {
                    groupId: groupId
                },
                data: {
                    firstName: guest.firstName,
                    lastName: guest.lastName,
                    guestEmail: guest.email,
                    company: guest.company,
                    jobtitle: guest.jobtitle,
                    locale: guest.lang
                }
            });
        }
    };

    service.notifyGuest = function (inviteId) {
        return hostResource.custom('PUT', 'invitations/:inviteId/notify-guest', {
            endpointParams: {
                inviteId: inviteId
            },
            data: null
        });
    };

    service.getCommunicationText = function (waitingListId, lang) {
        return hostResource.custom('GET', 'waiting-lists/:waitingListId/theme/default-text/:lang', {
            endpointParams: {
                waitingListId: waitingListId,
                lang: lang
            }
        });
    };

    service.joinWaitinglist = function (waitingListId, numberOfSeats, selectedSeats, numberOfParkingTickets) {
        return fanResource.custom('POST', 'waiting-lists/:waitingListId/position', {
            endpointParams: {
                waitingListId: waitingListId
            },
            data: {
                numberOfSeats: numberOfSeats,
                numberOfParkingTickets: numberOfParkingTickets
            }
        });
        // return fanSDKService.joinWaitingList(waitinglistId, numberOfSeats, selectedSeats, numberParking);
    };

    service.postPaymentReference = function (waitingListId, paymentRefFromBraintree) {
        return fanResource.custom('POST', 'waiting-lists/:waitingListId/position/payment-reference', {
            endpointParams: {
                waitingListId: waitingListId
            },
            data: {
                reference: paymentRefFromBraintree
            }
        });
    };

    service.unlockWaitinglist = function (waitinglist, code) {
        return fanSDKService.joinProtectedWaitingList(waitinglist.waitingListId, code, 1);
    };

    service.leaveWaitinglist = function (waitinglistId) {
        return fanSDKService.leaveWaitingList(waitinglistId);
    };

    service.translatedVenueConditions = function (waitinglistId) {
        return fanSDKService.getTranslatedVenueConditionsForWaitingList(waitinglistId);
    };

    service.waitinglistPrice = function (waitinglistId, numberOfSeats) {
        return fanResource.custom('GET', 'waiting-lists/:waitinglistId/price/:numberOfSeats', {
            endpointParams: {
                waitinglistId: waitinglistId,
                numberOfSeats: numberOfSeats
            }
        });
    };

    service.activateMfa = function () {
        return fanResourceV2.custom('PUT', 'activate-authy');
    };

    service.desactivateMfa = function () {
        return fanResourceV2.custom('PUT', 'desactivate-authy');
    };

    service.waitingListAdditionalInfos = function (waitingListId) {
        return fanResourceV2.custom('GET', 'waiting-lists/:waitinglistId/additional-infos', {
            endpointParams: {
                waitinglistId: waitingListId
            }
        });
    };

    service.waitinglistPaymentInfo = function (waitinglistId) {
        return fanSDKService.getPositionPaymentInfo(waitinglistId);
    };

    service.payPosition = function (waitinglistId, transaction) {
        return fanSDKService.payPosition(waitinglistId, transaction);
    };

    service.getPositionBraintreePaymentInfo = function (waitingListId) {
        return fanSDKService.getPositionBraintreePaymentInfo(waitingListId);
    };

    service.getPositionSeatersPaymentInfo = function (waitingListId) {
        return fanSDKService.getPositionSeatersPaymentInfo(waitingListId);
    };

    service.deleteWaitinglistTransaction = function (waitinglistId) {
        return fanSDKService.deletePositionSalesTransaction(waitinglistId);
    };

    service.exportWaitinglistSeat = function (waitinglistId, formatUsed) {
        return fanSDKService.exportSeats(waitinglistId, formatUsed).then(function (res) {
            console.log(res);
        });
    };

    // service.exportSeats = function(waitingListId, formatUsed) {
    //     return fanResourceV2.custom('PUT', 'waiting-lists/:waitinglistId/export-seat', {
    //         endpointParams: {
    //             waitinglistId: waitingListId
    //         },
    //         data:{
    //             format: formatUsed
    //         }
    //     });
    // };

    // service.acceptSeats = function(waitingListId, formatUsed) {
    //     return fanResourceV2.custom('PUT', 'waiting-lists/:waitinglistId/accept', {
    //         endpointParams: {
    //             waitinglistId: waitingListId
    //         },
    //         data:{
    //             format: formatUsed
    //         }
    //     });
    // };

    service.exportWaitinglistParkingSeat = function (waitingListId) {
        return fanResourceV2.custom('PUT', 'waiting-lists/:waitinglistId/export-parking-ticket', {
            endpointParams: {
                waitinglistId: waitingListId
            }
        });
    };

    service.acceptWaitinglistSeat = function (waitinglistId, optionUsed) {
        console.log("acceptSeats");
        return fanSDKService.acceptSeats(waitinglistId, optionUsed);
    };

    service.rejectWaitinglistSeat = function (waitinglistId) {
        return fanSDKService.rejectSeats(waitinglistId);
    };

    service.getBraintreeToken = function (waitinglistId) {
        return fanSDKService.positionBraintreeToken(waitinglistId);
    };

    service.updatePositionAttendeeInfo = function (waitinglistId, attendeeInfo) {
        return fanSDKService.saveAttendeesInfo(waitinglistId, attendeeInfo);
    };

    service.loadAdditionalCharges = function (waitinglistId) {
        return fanSDKService.loadAdditionalCharges(waitinglistId);
    };

    service.isWlBadgeProtected = function (waitingListId) {
        return fanSDKService.isBadgeProtected(null, waitingListId);
    };

    /**
     * Using $q.resolve to trigger $digest cycle.
     * @TODO: To be removed once the SDK provides a middleware
     */
    function convertToAngularAlikePromise(cb) {
        return function () {
            return $q.resolve().then(function () {
                return cb.call();
            });
        };
    }
    ['activeWaitinglistsWithSeat', 'joinedWaitinglists'].forEach(function (methodName) {
        service[methodName] = convertToAngularAlikePromise(service[methodName]);
    });
    return service;
});