/* eslint-disable no-magic-numbers */
/* eslint-disable id-length */
'use strict';

angular.module('app').enum('UTIL_GENERAL_ERRORS', ['CANCELLED', 'SERVER_PROBLEM', 'TIMEOUT']).service('UtilService',
/* @ngInject */
function ($document, $timeout, $q, $log, $rootScope, $modal, UTIL_GENERAL_ERRORS, lodash) {
    var service = {};

    service.identityMap = function (arr) {
        arr.forEach(function (identity) {
            arr[identity] = identity;
        });
        return arr;
    };

    service.fanWebAppUri = function (path, deployTarget) {
        if (deployTarget === 'production') {
            return 'https://www.seaters.com/' + path;
        } else if (deployTarget === 'localhost') {
            return 'http://localhost:4000/' + path;
        }

        return 'https://www.' + deployTarget + '-seaters.com/' + path;
    };

    function serializer(replacer, cycleReplacer) {
        var stack = [];
        var keys = [];

        if (cycleReplacer === null) {
            cycleReplacer = function cycleReplacer(key, value) {
                if (stack[0] === value) {
                    return '[Circular ~]';
                }
                return '[Circular ~.' + keys.slice(0, stack.indexOf(value)).join('.') + ']';
            };
        }

        return function (key, value) {
            if (stack.length > 0) {
                var thisPos = stack.indexOf(this) !== -1;
                if (thisPos) {
                    stack.splice(thisPos + 1);
                    keys.splice(thisPos, Infinity, key);
                } else {
                    stack.push(this);
                    keys.push(key);
                }
                if (stack.indexOf(value) !== -1) {
                    value = cycleReplacer.call(this, key, value);
                }
            } else {
                stack.push(value);
            }

            return replacer === null ? value : replacer.call(this, key, value);
        };
    }

    // Safe stringify
    service.safeJsonStringify = function (obj, replacer, spaces, cycleReplacer) {
        // eslint-disable-line angular/json-functions
        return JSON.stringify(obj, serializer(replacer, cycleReplacer), spaces);
    };
    // JSON;

    service.postForm = function (path, params, method, target) {
        method = method || 'post';
        target = target || '_blank';

        var form = $document[0].createElement('form');
        form.setAttribute('method', method);
        form.setAttribute('action', path);
        form.setAttribute('target', target);

        for (var key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                var hiddenField = $document[0].createElement('input');
                hiddenField.setAttribute('type', 'hidden');
                hiddenField.setAttribute('name', key);
                hiddenField.setAttribute('value', params[key]);

                form.appendChild(hiddenField);
            }
        }
        $document[0].body.appendChild(form);
        return form.submit();
    };

    service.isUUID = function (string) {
        return (/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/.test(string)
        );
    };

    service.isValidEmail = function (email) {
        // eslint-disable-next-line max-len, no-useless-escape
        return (/[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email)
        );
    };

    service.delayAnswer = function (seconds) {
        return function (answer) {
            return $timeout(seconds).then(function () {
                return answer;
            });
        };
    };

    service.retryUntil = function (promiseFn, condition, limit, delay) {
        var deferred = $q.defer();

        function retry(attempt) {
            if (attempt > limit) {
                return deferred.reject(UTIL_GENERAL_ERRORS.TIMEOUT);
            }
            return promiseFn().then(function (result) {
                $log.debug('UtilService - polling ... (%s)', attempt);
                var conditionIsMet = undefined;
                try {
                    conditionIsMet = condition(result);
                } catch (e) {
                    $log.debug('UtilService.retryUntil - condition quit with an exception', e);
                    return deferred.reject(e);
                }

                if (conditionIsMet) {
                    return deferred.resolve(result);
                }

                // Notify the new update
                deferred.notify(result);
                // Delay the next attempt if needed
                return $timeout(delay || 0).then(function () {
                    // Launch next attempt
                    retry(attempt + 1);
                });
            });
        }

        // Launch the first attempt
        retry(1);
        return deferred.promise;
    };

    service.hexToRgb = function (hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    };

    // Adding HueShift via Jacob (see comments)
    service.HueShift = function (h, s) {
        h += s;
        while (h >= 360.0) {
            h -= 360.0;
        }while (h < 0.0) {
            h += 360.0;
        }return h;
    };

    // min max via Hairgami_Master (see comments)
    service.min3 = function (a, b, c) {
        // eslint-disable-next-line no-nested-ternary
        return a < b ? a < c ? a : c : b < c ? b : c;
    };

    service.max3 = function (a, b, c) {
        // eslint-disable-next-line no-nested-ternary
        return a > b ? a > c ? a : c : b > c ? b : c;
    };

    // Complement
    /* */

    service.RGB2HSV = function (rgb) {
        // eslint-disable-next-line no-new-object
        var hsv = new Object();
        var max = service.max3(rgb.r, rgb.g, rgb.b);
        var dif = max - service.min3(rgb.r, rgb.g, rgb.b);
        hsv.saturation = max === 0.0 ? 0 : 100 * dif / max;
        if (hsv.saturation === 0) hsv.hue = 0;else if (rgb.r === max) hsv.hue = 60.0 * (rgb.g - rgb.b) / dif;else if (rgb.g === max) hsv.hue = 120.0 + 60.0 * (rgb.b - rgb.r) / dif;else if (rgb.b === max) hsv.hue = 240.0 + 60.0 * (rgb.r - rgb.g) / dif;
        if (hsv.hue < 0.0) hsv.hue += 360.0;
        hsv.value = Math.round(max * 100 / 255);
        hsv.hue = Math.round(hsv.hue);
        hsv.saturation = Math.round(hsv.saturation);
        return hsv;
    };

    // RGB2HSV and HSV2RGB are based on Color Match Remix [http://color.twysted.net/]
    // which is based on or copied from ColorMatch 5K [http://colormatch.dk/]
    service.HSV2RGB = function (hsv) {
        // eslint-disable-next-line no-new-object
        var rgb = new Object();
        if (hsv.saturation === 0) {
            rgb.r = rgb.g = rgb.b = Math.round(hsv.value * 2.55);
        } else {
            hsv.hue /= 60;
            hsv.saturation /= 100;
            hsv.value /= 100;
            var i = Math.floor(hsv.hue);
            var f = hsv.hue - i;
            var p = hsv.value * (1 - hsv.saturation);
            var q = hsv.value * (1 - hsv.saturation * f);
            var t = hsv.value * (1 - hsv.saturation * (1 - f));
            switch (i) {
                case 0:
                    rgb.r = hsv.value;
                    rgb.g = t;
                    rgb.b = p;
                    break;
                case 1:
                    rgb.r = q;
                    rgb.g = hsv.value;
                    rgb.b = p;
                    break;
                case 2:
                    rgb.r = p;
                    rgb.g = hsv.value;
                    rgb.b = t;
                    break;
                case 3:
                    rgb.r = p;
                    rgb.g = q;
                    rgb.b = hsv.value;
                    break;
                case 4:
                    rgb.r = t;
                    rgb.g = p;
                    rgb.b = hsv.value;
                    break;
                default:
                    rgb.r = hsv.value;
                    rgb.g = p;
                    rgb.b = q;
            }
            rgb.r = Math.round(rgb.r * 255);
            rgb.g = Math.round(rgb.g * 255);
            rgb.b = Math.round(rgb.b * 255);
        }
        return rgb;
    };

    service.broadcastAndReturn = function (signal) {
        return function (res) {
            $rootScope.$broadcast(signal, res);
            return res;
        };
    };

    service.broadcast = function (signal) {
        $rootScope.$broadcast(signal);
    };

    /**
     * Opens a foundation modal with the given message prefixed by a loader
     * This modal cannot be closed by clicking outside of it, nor by any key
     * @returns a method that when called, will close the modal.
     */
    service.blockingMessage = function (message) {
        var instance = $modal.open({
            template: '<p><i class="fa fa-pulse fa-spinner"></i>&nbsp;{{blocking.message}}</p>',
            controller: function controller() /* @ngInject */{
                this.message = message;
            },
            controllerAs: 'blocking',
            windowClass: 'small',
            backdrop: 'static',
            keyboard: false
        });

        return function () {
            instance.close();
        };
    };

    service.logAndContinue = function () {
        var args = [].splice.call(arguments, 0);
        return function (data) {
            $log.debug.apply(null, args);
            return data;
        };
    };

    // eslint-disable-next-line consistent-return
    function composedPath(el) {
        var path = [];
        while (el) {
            path.push(el);
            if (el.tagName === 'HTML') {
                path.push(document);
                path.push(window);
                return path;
            }
            el = el.parentElement;
        }
    }

    service.hasAncstor = function (event, ancestorId) {
        var path = event.path || event.composedPath && event.composedPath() || composedPath(event.target);
        if (path) {
            return !lodash.chain(path).filter(function (nodeElement) {
                return nodeElement.id === ancestorId;
            }).isEmpty().value();
        }
        return false;
    };

    /**
     * Loads any script dynamically from a given url
     * @param {string} url
     * @returns {promise} fulfilled once the script is loaded
     */
    service.loadScript = function (url) {
        var deferred = $q.defer();

        function resolverCall() {
            return deferred.resolve();
        }

        var scriptTag = document.createElement('script');
        scriptTag.src = url;
        scriptTag.onload = resolverCall;
        scriptTag.onreadystatechange = resolverCall;

        document.body.appendChild(scriptTag);

        return deferred.promise;
    };

    return service;
});