'use strict';

angular.module('app').service('URLService', function ( /* @ngInject */$location, $window, $q, $base64, URI, lodash) {
  /**
     *  CONSTANTS
     */
  var ENCODED_QUERY_PARAMETER_KEY = 'eq';
  var REDIRECT_PARAMETER_KEY = 'redirect_to';

  /**
     *  PRIVATE VARIABLES
     */
  var cachedQueryParamers = undefined;

  /**
     *  PRIVATE FUNCTIONS
     */
  function decodeQueryParameters(encodedQueryParameters) {
    try {
      var plainTextQueryParameters = $base64.decode(encodedQueryParameters);
      return new URI('?' + plainTextQueryParameters).search(true);
    } catch (e) {
      return {};
    }
  }

  function fetchQueryParametersFromURL() {
    var url = $location.absUrl();

    // See http://medialize.github.io/URI.js/docs.html
    var uri = new URI(url);

    // Store the query parameters
    var qp = uri.search(true);

    // Decode the encoded parameters too
    if (qp[ENCODED_QUERY_PARAMETER_KEY]) {
      var encodedQueryParameters = decodeURIComponent(qp[ENCODED_QUERY_PARAMETER_KEY]);
      var decodedQueryParameters = decodeQueryParameters(encodedQueryParameters);
      qp = lodash.extend(qp, decodedQueryParameters);
    }

    return qp;
  }

  /**
     *  API FUNCTIONS
     */

  function getQueryParameters() {
    return cachedQueryParamers;
  }

  function loadQueryParametersFromURL() {
    cachedQueryParamers = fetchQueryParametersFromURL();

    // Console.log('[URLService] Loaded (decoded) query parameters from URL:', cachedQueryParamers);
    return cachedQueryParamers;
  }

  function updateQueryParametersFromURL() {
    cachedQueryParamers = lodash.extend(cachedQueryParamers, fetchQueryParametersFromURL());

    // Console.log('[URLService] Updated (decoded) query parameters from URL:', cachedQueryParamers);
    return cachedQueryParamers;
  }

  function handleRedirect() {
    // Initialize cached query params if needed
    if (!cachedQueryParamers) {
      updateQueryParametersFromURL();
    }
    // Check if the query parameters contain the redirect key
    if (cachedQueryParamers[REDIRECT_PARAMETER_KEY]) {
      var redirectUrl = cachedQueryParamers[REDIRECT_PARAMETER_KEY];

      // Delete the original so we don't end up in a loop
      delete cachedQueryParamers[REDIRECT_PARAMETER_KEY];

      // If we are not already on this state, redirect
      var redirectFullUrl = new URI(redirectUrl).addSearch(cachedQueryParamers).toString();
      console.log('Redirecting to ', redirectFullUrl);
      $window.location.href = redirectFullUrl;
      return false;
    }

    return true;
  }

  /**
     *  API
     */
  return {
    getQueryParameters: getQueryParameters,
    loadQueryParametersFromURL: loadQueryParametersFromURL,
    updateQueryParametersFromURL: updateQueryParametersFromURL,
    handleRedirect: handleRedirect
  };
});