'use strict';

/**
 * <* set-focus> triggers focus once as soon as the element is loaded in the dom
 */

angular.module('app').directive('setFocus', function ($timeout) {
  return {
    restrict: 'A',
    link: function link(scope, element) {
      $timeout(function () {
        element[0].focus();
      });
    }
  };
});